import { Betrix } from './models';

const showBetrixQuickButtons = localStorage.getItem('showBetrixQuickButtons')
const showQuickButtons = (showBetrixQuickButtons) ? JSON.parse(showBetrixQuickButtons) : true

const betrix: Betrix = {
    odds: [],
    correctScoreOdds: [],
    // doublettes: [],
    selectionOdds: {
        anyOther: 0,
        back: 0,
        lay: 0,

        anyOtherProbability: 0,
        backProbability: 0,
        layProbability: 0,

        backlay: 0,
        backany: 0,
        layany: 0,
        
        backLAY: 0,
        backANY: 0,
        
        layBACK: 0,
        layANY: 0,
        
        anyBACK: 0,
        anyLAY: 0,
    },
    showQuickButtons,
    suggestedMarkets: [],
}

export default betrix