import BetslipHistory from './views/pages/BetslipHistory'
import Home from './views/pages/Home'
import LiveBetting from './views/pages/LiveBetting'
import SportLeague from './views/pages/SportLeague';
import SportMain from './views/pages/SportMain';
import SportMatch from './views/pages/SportMatch';

interface Route {
    path: string
    component: any
    exact: boolean
}

const routes: Route[] = [
    {
        path: '/',
        component: Home,
        exact: true
    },
    {
        path: '/betslip-history',
        component: BetslipHistory,
        exact: true
    },
    {
        path: '/live-betting',
        component: LiveBetting,
        exact: true
    },
    {
        path: '/:sport/:region/:league/:matchid',
        component: SportMatch,
        exact: true
    },
    {
        path: '/:sport/:region/:league',
        component: SportLeague,
        exact: true
    },
    {
        path: '/:sport',
        component: SportMain,
        exact: true
    }
];

export default routes;