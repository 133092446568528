import dataServer from '../../../network/rest'
import { MarketOdds } from '../../../state/stores/feed/models'
import { SportId, BetTypeId, BetTypeGroupId, OutcomeId, ScopeId } from '../../../state/enums'
import { getDataForMMRequest, saveStandardBSItem, removeBSStandardItem, getMarketMakerSelections, toggleMMOddsItem } from '../../../utils'
import { MarketMakerSelection } from '../../../state/stores/marketmaker/models'
import { store } from '../../../state'
import { BSStandardItem, BSBetrixItem } from '../../../state/stores/betslip/models'

function addMMPropertiesToOddsItem(oddsItem: MarketOdds, oddsValue: string, probability: number) {
    const MMOddsItem: MarketOdds = oddsItem

    MMOddsItem.oddsvalue = oddsValue
    MMOddsItem.probability = probability.toString()
    MMOddsItem.bettypeid = BetTypeId.MMGeneral
    MMOddsItem.outcomeid = OutcomeId.BetrixYes

    return MMOddsItem
}

function getBetslipStandardItemIndex(matchId: string, betTypeId: number) {
    const betslip: BSStandardItem[] = store.get(main => main.betslip.standard)
    const itemFound = betslip.find(b => b.matchId === matchId && b.betTypeId === betTypeId)
    if (itemFound) {
        return itemFound.index
    }
    
    return 0
}

function getOddsItemForBetslip(selection: MarketMakerSelection, bettypegroupid: BetTypeGroupId, bettypeid: BetTypeId, matchId: string) {
    const { oddsValue, outcomeId, probability, value } = selection

    const oddsItemForBetslip: MarketOdds = {
        bettypegroupid,
        bettypeid,
        islive: false,
        matchid: matchId.toString(),
        name: '',
        oddsvalue: oddsValue,
        oldoddsvalue: oddsValue,
        // psboddsvalue: oddsValue,
        outcomeid: outcomeId,
        outcomename: '',
        probability: probability.toString(),
        value
    }

    return oddsItemForBetslip
}

function updateMarketMaker(isCS: boolean, matchId: string, market: string, odds: string, probability: number, invOdds: string, invProbability: number, otherOddsWithInsurance: Array<{i: number, b: number, o: number, odds: number, probability: number}>) {

    store.update(main => {
        if (main.marketmaker && main.marketmaker[matchId] && main.marketmaker[matchId][market]) {
            main.marketmaker[matchId][market].odds = odds
            main.marketmaker[matchId][market].probability = probability
            main.marketmaker[matchId][market].invOdds = invOdds
            main.marketmaker[matchId][market].invProbability = invProbability
            if (!isCS && main.marketmaker[matchId][market].selections && main.marketmaker[matchId][market].selections.length === 1) {
                main.marketmaker[matchId][market].selections[0].betTypeId = undefined
            }



        }
        return main
    })
}

export function getBetslipBetrixItemIndex(matchId: string) {
    const betslip: BSBetrixItem[] = store.get(main => main.betslip.betrix)
    const itemFound = betslip.find(b => b.matchId === matchId)
    if (itemFound) {
        return itemFound.index
    }
    return 0
}

export function handleOddsItemClick(token: string, isCS: boolean, scopeId: ScopeId, psbOdds: boolean, options: MarketOdds[], margin: number, marginParameter: number, oddsMin: number, oddsMax: number, hasMarketMaker: boolean, item: MarketOdds, optionsLength: number, awayTeam: string, homeTeam: string, sportId: SportId, typeProperty: string, setActiveBetTypeId?: (betTypeId: BetTypeId) => void) {
    if (hasMarketMaker) {
        const { bettypegroupid, bettypeid, matchid, outcomeid, islive, name, sportid } = item
        if (setActiveBetTypeId) {
            setActiveBetTypeId(bettypeid)
        }
        const newMarketMaker = toggleMMOddsItem(token, options, margin, marginParameter, oddsMin, oddsMax, item, optionsLength, sportId, scopeId, true)
        const betTypeData = newMarketMaker[matchid] && newMarketMaker[matchid][typeProperty]
        if (betTypeData) {
            if (betTypeData.selections.length === 1) {
                const oddsItem: MarketOdds = {
                    bettypegroupid,
                    bettypeid: betTypeData.selections[0].betTypeId ? betTypeData.selections[0].betTypeId : bettypeid,
                    islive,
                    matchid,
                    name,
                    oddsvalue: betTypeData.selections[0].oddsValue,
                    oldoddsvalue: betTypeData.selections[0].oddsValue,
                    // psboddsvalue: betTypeData.selections[0].oddsValue,
                    outcomeid: betTypeData.selections[0].outcomeId,
                    outcomename: '',
                    probability: betTypeData.selections[0].probability.toString(),
                    value: betTypeData.selections[0].value,
                    sportid
                }
                saveStandardBSItem(scopeId, awayTeam, homeTeam, oddsItem, sportId)

                const data = getDataForMMRequest(betTypeData.selections, margin, marginParameter, oddsMin, oddsMax, options)

                if (bettypegroupid === BetTypeGroupId.CorrectScore) {
                    if (bettypegroupid === BetTypeGroupId.CorrectScore && betTypeData.selections) {
                        data.selections = []
                        data.options = []
                        if (options) {
                            for (const item of options) {
                                if (item.oddsvalue && item.oddsvalue.length) {
                                    data.options.push(item)
                                }
                            }
                        }
                        for (const item of betTypeData.selections) {
                            data.selections.push({
                                insurance: item.insured,
                                outcomeId: item.outcomeId,
                                probability: item.probability,
                                oddsvalue: item.oddsValue,
                                betTypeId: item.betTypeId
                            })
                        }
                    }
                }
                data['t'] = token
                dataServer.request('post', 'insert-market-maker-odds', undefined, data, response => {
                    if (response) {
                        if (response.odds && response.probability) {
                            const oddsValue = response.odds.toString()
                            const probability = response.probability
                            const invOddsValue = response.invOdds.toString()
                            const invProbability = response.invProbability

                            updateMarketMaker(isCS, matchid, typeProperty, oddsValue, probability, invOddsValue, invProbability, response.otherOddsWithInsurance)
                        }
                    }
                })
                if (psbOdds) {
                    const psbodds: Array<{b: number, i: number, o: Array<{o: number, i: number, s: number/*, p?: number*/}>/*, v?: string, skip?: boolean*/}> = store.get(main => main.iframeresponse.psbodds)
                    // REMOVE FROM LIST
                    if (bettypegroupid === BetTypeGroupId.CorrectScore) {
                        for (let i: number = 0; i < psbodds.length; i++) {
                            if (psbodds[i].i === bettypegroupid) {
                                psbodds.splice(i, 1)
                                break
                            }
                        }
                    } else {
                        for (let i: number = 0; i < psbodds.length; i++) {
                            if (psbodds[i].b === bettypeid && psbodds[i].i === bettypegroupid) {
                                psbodds.splice(i, 1)
                                break
                            }
                        }
                    }

                    const orOddsInsured: Array<{bettypegroupid: number, bettypeid: number, value: number}> = store.get(main => main.iframeresponse.orOddsInsured)
                    for (let i: number = 0; i < orOddsInsured.length; i++) {
                        if (bettypegroupid === BetTypeGroupId.CorrectScore || bettypegroupid === BetTypeGroupId.EuropeanHandicap) {
                            if (orOddsInsured[i].bettypegroupid === bettypegroupid) {
                                orOddsInsured.splice(i, 1)
                                break
                            }
                        } else {
                            if (orOddsInsured[i].bettypegroupid === bettypegroupid && orOddsInsured[i].bettypeid === bettypeid) {
                                orOddsInsured.splice(i, 1)
                                break
                            }
                        }
                    }
                    store.update(main => {
                        main.iframeresponse.psbodds = psbodds
                        main.iframeresponse.orOddsInsured = orOddsInsured
                        return main
                    })
                }
            } else {
                const MMSelections = getMarketMakerSelections(betTypeData.selections)
                if (MMSelections.length > 1 || (MMSelections.length === 1 &&
                    (bettypegroupid === BetTypeGroupId.CorrectScore && MMSelections[0].betTypeId && MMSelections[0].betTypeId !== bettypeid && betTypeData.selections.map(s => s.betTypeId).includes(bettypeid)) ||
                    (MMSelections[0].outcomeId !== outcomeid && betTypeData.selections.map(s => s.outcomeId).includes(outcomeid)))) {
                    const data = getDataForMMRequest(betTypeData.selections, margin, marginParameter, oddsMin, oddsMax, options)
                    if (bettypegroupid === BetTypeGroupId.CorrectScore && betTypeData.selections) {
                        data.selections = []
                        data.options = []
                        if (options) {
                            for (const item of options) {
                                if (item.oddsvalue && item.oddsvalue.length) {
                                    data.options.push(item)
                                }
                            }
                        }
                        for (const item of betTypeData.selections) {
                            data.selections.push({
                                insurance: item.insured,
                                outcomeId: item.outcomeId,
                                probability: item.probability,
                                oddsvalue: item.oddsValue,
                                betTypeId: item.betTypeId
                            })
                        }
                    }
                    data['t'] = token
                    dataServer.request('post', 'insert-market-maker-odds', undefined, data, response => {
                        if (response) {
                            if (response.odds && response.probability && response.invOdds && response.invProbability) {
                                const oddsValue = response.odds.toString()
                                const probability = response.probability
                                const invOddsValue = response.invOdds.toString()
                                const invProbability = response.invProbability

                                updateMarketMaker(isCS, matchid, typeProperty, oddsValue, probability, invOddsValue, invProbability, response.otherOddsWithInsurance)

                                const oddsItemForBetslip = getOddsItemForBetslip(betTypeData.selections[0], bettypegroupid, bettypeid, matchid)
                                const MMOddsItem = addMMPropertiesToOddsItem(oddsItemForBetslip, oddsValue, probability)

                                saveStandardBSItem(scopeId, awayTeam, homeTeam, MMOddsItem, sportId, betTypeData.selections, bettypeid)
                            }
                            if (psbOdds && response.bettypegroupid && response.bettypeid && response.outcomeids && response.newOdds && response.stakes && response.OrOddsInsured) {
                                const psbodds: Array<{b: number, i: number, o: Array<{o: number, i: number, s: number}>}> = store.get(main => main.iframeresponse.psbodds)
                                // CHECK IF ALREADY THERE
                                let found: number = -1
                                if (response.bettypegroupid === BetTypeGroupId.CorrectScore) {
                                    for (let i: number = 0; i < psbodds.length; i++) {
                                        if (psbodds[i].i === response.bettypegroupid) {
                                            found = i
                                            break
                                        }
                                    }
                                } else {
                                    for (let i: number = 0; i < psbodds.length; i++) {
                                        if (psbodds[i].b === response.bettypeid && psbodds[i].i === response.bettypegroupid) {
                                            found = i
                                            break
                                        }
                                    }
                                }
                                // PREPARE NEW ARRAY
                                const o: Array<{o: number, i: number, s: number}> = []
                                // const psboddsvalue3: Array<{odds: string, id: number}> = []
                                for (let i: number = 0; i < response.newOdds.length; i++) {
                                    // psboddsvalue3.push({odds: response.newOdds[i], id: response.outcomeids[i]})
                                    o.push({o: response.newOdds[i].toString(), i: response.bettypegroupid === BetTypeGroupId.CorrectScore ? response.bettypeids[i] : response.outcomeids[i], s: response.stakes[i]})
                                }
                                // ADD TO THE LIST
                                if (found === -1) {
                                    if (response.bettypegroupid === BetTypeGroupId.CorrectScore) {
                                        psbodds.push({b: 0, i: response.bettypegroupid, o})
                                    } else {
                                        psbodds.push({b: response.bettypeid, i: response.bettypegroupid, o})
                                    }
                                } else { // REPLACE IN THE LIST
                                    if (response.bettypegroupid === BetTypeGroupId.CorrectScore) {
                                        psbodds[found] = {b: 0, i: response.bettypegroupid, o}
                                    } else {
                                        psbodds[found] = {b: response.bettypeid, i: response.bettypegroupid, o}
                                    }
                                }
                                const orOddsInsured: Array<{bettypegroupid: number, bettypeid: number, value: number}> = store.get(main => main.iframeresponse.orOddsInsured)
                                // CHECK IF ALREADY THERE
                                found = -1
                                for (let i: number = 0; i < orOddsInsured.length; i++) {
                                    if (response.bettypegroupid === BetTypeGroupId.CorrectScore || response.bettypegroupid === BetTypeGroupId.EuropeanHandicap) {
                                        if (orOddsInsured[i].bettypegroupid === response.bettypegroupid) {
                                            found = i
                                            break
                                        }
                                    } else {
                                        if (orOddsInsured[i].bettypegroupid === response.bettypegroupid && orOddsInsured[i].bettypeid === response.bettypeid) {
                                            found = i
                                            break
                                        }
                                    }
                                }
                                // ADD TO THE LIST
                                if (found === -1) {
                                    orOddsInsured.push({bettypegroupid: response.bettypegroupid, bettypeid: response.bettypeid, value: response.OrOddsInsured})
                                } else { // REPLACE IN THE LIST
                                    orOddsInsured[found] = {bettypegroupid: response.bettypegroupid, bettypeid: response.bettypeid, value: response.OrOddsInsured}
                                }
                                store.update(main => {
                                    main.iframeresponse.psbodds = psbodds
                                    main.iframeresponse.orOddsInsured = orOddsInsured
                                    return main
                                })

                            }
                        }
                    })
                } else {
                    removeBSStandardItem(getBetslipStandardItemIndex(matchid, bettypeid))
                }
            }
        } else {
            removeBSStandardItem(getBetslipStandardItemIndex(matchid, bettypeid))
        }
    } else {
        saveStandardBSItem(scopeId, awayTeam, homeTeam, item, sportId)
    }
}