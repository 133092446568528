import React from 'react'
import clsx from 'clsx'
import { MarketOdds } from '../../../../state/stores/feed/models'
import { MatrixValue, BetTypeId, SportId, ScopeId } from '../../../../state/enums'
import { roundOddsValue, isMobile, getRegionFlag, isSportWinner } from '../../../../utils'
import { getGoalsText } from '../../../../strings'
import {betrixHockeyMaxGoals, betrixPeriodHockeyMaxGoals} from '../../../../assets'
import {TrapezoidGeometry} from '../Matrix'
import CSS from 'csstype';

interface Props {
    goals: number[]
    homeGoals: number
    odds: MarketOdds[]
    quickButtonsMatrix: MatrixValue[][]
    selectedHomeGoals: number
    selectedItems: MatrixValue[][]
    handleSelectedItem: (awayGoals: number, homeGoals: number) => void
    sportId: SportId
    scopeId: ScopeId
    trapezoidGeometry: TrapezoidGeometry
    o0: Array<{name:'Winner', outcomes: Array<{name: string, value: number, country?: string}>}>
    o01: Array<{name:'Winner', outcomes: Array<{name: string, value: number, country?: string}>}>
    AwayGoals: number
    HomeGoals: number
    intersectionOdds: string
    betrixToggle: boolean
    selectedItemsNumber: number
}

function getTrapezoidTextStyle(selected: MatrixValue, homeGoals: number, awayGoals: number, _: TrapezoidGeometry): CSS.Properties {
    const c: number = 1.52
    const style: CSS.Properties = {
        zIndex: 9999,
        top: _.J[homeGoals][awayGoals].top + '%',
        bottom: 100 - _.D[homeGoals][awayGoals].top + '%',
        right: (100 - Math.min(_.J[homeGoals][awayGoals].left, _.G[homeGoals][awayGoals].left)) + '%',
        left: Math.max(_.F[homeGoals][awayGoals].left, _.C[homeGoals][awayGoals].left) + '%',
        padding: (((_.h1[homeGoals][awayGoals] * _.height / 100 / c) - 34)/2) + 'px'
    }
    return style
}

function getTrapezoidBackground(homeGoals: number, awayGoals: number): 'transparent'|'rgba(0,0,0,.1)' {
    if (homeGoals % 2 === 0) {
        return awayGoals % 2 === 0 ? 'rgba(0,0,0,.1)' : 'transparent'
    } else {
        return awayGoals % 2 === 1 ? 'rgba(0,0,0,.1)' : 'transparent'
    }
}

function getTrapezoid(selected: MatrixValue, homeGoals: number, awayGoals: number, _: TrapezoidGeometry): JSX.Element[] {
    const t: JSX.Element[] = []
    const color: 'rgba(0, 145, 135, 0.6)'|'rgba(243, 139, 0, 0.6)'|'transparent'|'rgba(0,0,0,.1)' = selected === MatrixValue.Selected ? 'rgba(0, 145, 135, 0.6)' : selected === MatrixValue.Insured ? 'rgba(243, 139, 0, 0.6)' : getTrapezoidBackground(homeGoals, awayGoals)
    const style: CSS.Properties = {
        zIndex: 9998,
        background: 'transparent',
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderWidth: '0px',
    }
    const top: number = _.J[homeGoals][awayGoals].top
    const bottom: number = 100 - _.D[homeGoals][awayGoals].top
    style.top = top + '%'
    style.bottom = bottom + '%'
    style.width = 0
    const styleLeft = JSON.parse(JSON.stringify(style))
    const styleRight = JSON.parse(JSON.stringify(style))
    style.background = color
    style.width = 'auto'
    const right: number = 100 - Math.min(_.J[homeGoals][awayGoals].left, _.G[homeGoals][awayGoals].left)
    const Right: number = 100 - Math.max(_.J[homeGoals][awayGoals].left, _.G[homeGoals][awayGoals].left)
    const left: number = Math.max(_.F[homeGoals][awayGoals].left, _.C[homeGoals][awayGoals].left)
    const Left: number = Math.min(_.F[homeGoals][awayGoals].left, _.C[homeGoals][awayGoals].left)
    style.right =  right + '%'
    style.left =  left + '%'

    const c: number = 1.52
    // LEFT
    styleLeft.left = Left + '%' 
    styleLeft.top = top + '%' 

    if (_.w1[homeGoals][awayGoals] > 0) {
        styleLeft.borderBottomWidth = (_.h1[homeGoals][awayGoals] * _.height / 100 / c) + 'px'
        styleLeft.borderBottomColor = color
    } else {
        styleLeft.borderTopWidth = (_.h1[homeGoals][awayGoals] * _.height / 100 / c) + 'px'
        styleLeft.borderTopColor = color

    }
    styleLeft.borderLeftWidth = (Math.abs(_.w1[homeGoals][awayGoals]) * _.width / 100 / c) + 'px'
    styleLeft.borderLeftColor = 'transparent'

    // RIGHT
    styleRight.right = Right + '%' 
    styleRight.top = top + '%' 

    if (_.w2[homeGoals][awayGoals] < 0) {
        styleRight.borderBottomWidth = (_.h2[homeGoals][awayGoals] * _.height / 100 / c) + 'px'
        styleRight.borderBottomColor = color
    } else {
        styleRight.borderTopWidth = (_.h2[homeGoals][awayGoals] * _.height / 100 / c) + 'px'
        styleRight.borderTopColor = color

    }
    styleRight.borderRightWidth = (Math.abs(_.w2[homeGoals][awayGoals]) * _.width / 100 / c) + 'px'
    styleRight.borderRightColor = 'transparent'

    t.push(<div key = "center" style = {style}>&nbsp;</div>)
    t.push(<div key = "left" style = {styleLeft}>&nbsp;</div>)
    t.push(<div key = "right" style = {styleRight}>&nbsp;</div>)

    return t
}

function getFullCountryName(country: string): string {
    if (country === 'GER') { return 'Germany'}
    if (country === 'AUT') { return 'Austria'}
    if (country === 'ITA') { return 'Italy'}
    if (country === 'SLO') { return 'Slovenia'}
    if (country === 'POL') { return 'Poland'}
    if (country === 'CZE') { return 'Czech Republic'}
    if (country === 'RUS') { return 'Russia'}
    if (country === 'JPN') { return 'Japan'}
    if (country === 'NOR') { return 'Norway'}
    if (country === 'SUI') { return 'Switzerland'}
    if (country === 'CAN') { return 'Canada'}
    if (country === 'FIN') { return 'Finland'}
    if (country === 'EST') { return 'Estonia'}
    return country
}

function BetrixOddsRow({ selectedItemsNumber, betrixToggle, intersectionOdds, o0, o01, trapezoidGeometry, scopeId, sportId, goals, homeGoals, odds, quickButtonsMatrix, selectedHomeGoals, selectedItems, handleSelectedItem, HomeGoals, AwayGoals }: Props) {
    return (
        <div className="betrix-row">
            {goals.map(awayGoals => {
                const matrixValue = selectedItems[homeGoals] ? selectedItems[homeGoals][awayGoals] : MatrixValue.NotSelected
                const quickButtonsValue = quickButtonsMatrix[homeGoals][awayGoals]
                const homeGoalsText = getGoalsText(homeGoals)
                const awayGoalsText = getGoalsText(awayGoals)
                const goalsText = `${homeGoalsText}${awayGoalsText}`
                let betTypeId = BetTypeId[goalsText]
                let oddsFound = odds.find(o => o.bettypeid === betTypeId)
                let oddsValue = (oddsFound) ? oddsFound.oddsvalue : '1.00'
                const oddsProbability = (oddsFound) ? oddsFound.probability : '0'
                let text: string
                let region: {flag: string, name: string}
                if (isSportWinner(sportId)) {
                    if (scopeId === ScopeId.FT) {
                        const O0: number = homeGoals * AwayGoals + awayGoals
                        text = o0[0].outcomes[O0].name
                        if (text.indexOf(', ') !== -1) {
                            const Text: string[] = text.split(', ')
                            text = Text[1] + ' ' + Text[0]
                        }
                        let country = o0[0].outcomes[O0].country
                        oddsValue = o0[0].outcomes[O0].value.toString()
                        country = getFullCountryName(country)
                        region = getRegionFlag(country)
                    } else {
                        const O01: number = homeGoals * AwayGoals + awayGoals
                        text = o01[0].outcomes[O01].name
                        if (text.indexOf(', ') !== -1) {
                            const Text: string[] = text.split(', ')
                            text = Text[1] + ' ' + Text[0]
                        }
                        let country = o01[0].outcomes[O01].country
                        oddsValue = o01[0].outcomes[O01].value.toString()
                        country = getFullCountryName(country)
                        region = getRegionFlag(country)
                    }
                }
                let edge: number
                let trapezoid: string
                if (sportId === SportId.Hockey) {
                    const goalsToLookFor: number = scopeId !== ScopeId.FT ? betrixPeriodHockeyMaxGoals : betrixHockeyMaxGoals
                    if (homeGoals === 0 && awayGoals === 0) {edge = 1}
                    if (homeGoals === 0 && awayGoals === goalsToLookFor) {edge = 2}
                    if (homeGoals === goalsToLookFor && awayGoals === 0) {edge = 3}
                    if (homeGoals === goalsToLookFor && awayGoals === goalsToLookFor) {edge = 4}
                } else if (trapezoidGeometry) {
                    trapezoid = homeGoals.toString() + awayGoals.toString()
                }

                const scoreDisabled = +oddsProbability === 0 || +oddsValue === 1

                let intersectionOddsToShow: string = intersectionOdds && intersectionOdds.length && !isNaN(intersectionOdds as any) ? roundOddsValue(intersectionOdds, 2) : ''
                if (matrixValue === MatrixValue.Selected && selectedItemsNumber === 1) {
                    intersectionOddsToShow = roundOddsValue(oddsValue,3)
                }
                return (
                    <div
                        key={awayGoals}
                        className={clsx(
                            sportId === SportId.Hockey ? isMobile() ? 'hockey-mobile' : 'hockey-desktop' : '',
                            sportId === SportId.Hockey ? edge ? scopeId !== ScopeId.FT ? 'betrix-item betrix-item-hockey pr noft betrix-item-hockey' + edge : 'betrix-item betrix-item-hockey pr ft betrix-item-hockey' + edge : scopeId !== ScopeId.FT ? 'betrix-item betrix-item-hockey nopr noft' : 'betrix-item betrix-item-hockey nopr ft' : trapezoid ? 'betrix-item noPr' : 'betrix-item nopr',
                            `home-goals-${selectedHomeGoals}`,
                            ((matrixValue === MatrixValue.Selected || (quickButtonsValue === MatrixValue.Selected && matrixValue !== MatrixValue.Insured)) && 'selected'),
                            matrixValue === MatrixValue.Insured && 'insured',
                            scoreDisabled && 'score-disabled'
                        )}
                        onClick={() => !scoreDisabled && handleSelectedItem(awayGoals, homeGoals)}
                    >
                        {edge ?
                        <>
                        <div className = {'rectangle' + edge}>&nbsp;</div>
                        <div className = {'round' + edge}>&nbsp;</div>
                        <div className = {'Rectangle' + edge}>&nbsp;</div>

                        <div className = "spans">
                            <span>{`${homeGoals} : ${awayGoals}`}</span>
                            <span>{scoreDisabled ? '-' : betrixToggle ? roundOddsValue(oddsValue, 3) : matrixValue === MatrixValue.Selected ? intersectionOddsToShow : matrixValue === MatrixValue.Insured ? 'Insured' : roundOddsValue(oddsValue, 3)}</span>
                        </div>
                        </>
                        : !trapezoid ?
                        <>
                            {region ? <img title = {region.name} style = {{position: 'absolute', top: (homeGoals/HomeGoals * 100 + 1.5) + '%', marginLeft: 'auto', marginRight: 'auto', left: awayGoals/AwayGoals * 100 + '%', right: (AwayGoals-awayGoals-1)/AwayGoals * 100 + '%', height: '16px', width: 'auto'}} src = {region.flag}/> : void 0}
                            <span style = {{textAlign: 'center', width: '100%', paddingLeft: '5%', paddingRight: '5%', marginTop: sportId === SportId.Cycling ? '-20px' : ''}}>
                                {text ? text : `${homeGoals} : ${awayGoals}`}
                            </span>
                            {isSportWinner(sportId) ?
                            <span style = {{fontSize: sportId === SportId.Cycling ? '18px' : '20px', position: 'absolute', top: (homeGoals/HomeGoals * 100 + (sportId === SportId.Cycling ? 70 : 65)/HomeGoals) + '%', textAlign: 'center', width: 'auto', marginLeft: 'auto', marginRight: 'auto', left: awayGoals/AwayGoals * 100 + '%', right: (AwayGoals-awayGoals-1)/AwayGoals * 100 + '%'}}>
                                {scoreDisabled ? '-' : betrixToggle ? roundOddsValue(oddsValue, 3) : matrixValue === MatrixValue.Selected ? intersectionOddsToShow : matrixValue === MatrixValue.Insured ? 'Insured' : roundOddsValue(oddsValue, 3)}
                            </span>
                            : <span>{scoreDisabled ? '-' : betrixToggle ? roundOddsValue(oddsValue, 3) : matrixValue === MatrixValue.Selected ? intersectionOddsToShow : matrixValue === MatrixValue.Insured ? 'Insured' : roundOddsValue(oddsValue, 3)}</span>}
                        </> : void 0}
                        {trapezoid ?
                        <>
                        {getTrapezoid(selectedItems[homeGoals] ? selectedItems[homeGoals][awayGoals] : MatrixValue.NotSelected, homeGoals, awayGoals, trapezoidGeometry)}
                        <div className = "spant" style = {getTrapezoidTextStyle(selectedItems[homeGoals] ? selectedItems[homeGoals][awayGoals] : MatrixValue.NotSelected, homeGoals, awayGoals, trapezoidGeometry)}>
                            <span>{`${homeGoals} : ${awayGoals}`}</span>
                            <span>{scoreDisabled ? '-' : betrixToggle ? roundOddsValue(oddsValue, 3) : matrixValue === MatrixValue.Selected ? intersectionOddsToShow : matrixValue === MatrixValue.Insured ? 'Insured' : roundOddsValue(oddsValue, 3)}</span>
                        </div>
                        </>
                        : void 0}
                    </div>
                )
            })}
        </div>
    )
}

export default BetrixOddsRow