import React from 'react'
import clsx from 'clsx'
import OddsItem from '../../OddsItem'
import { getMarketNameAndOptions, fillValuesWithOdds, getClosestValue, roundOddsValue, getOutcomeText, isMobile, sortBySize, GetNameFromMarketGroup, clearMMOddsItem} from '../../../../utils'
import { MarketMaker } from '../../../../state/stores/marketmaker/models'
import { MarketOdds } from '../../../../state/stores/feed/models'
import { SportId, BetTypeGroupId, BetTypeId, ScopeId } from '../../../../state/enums'
import { MarketGroupItemElement } from '../../../../state/stores/settings/models'
import { store } from '../../../../state'
import FaIcon from '../../FaIcon'
import MarketScope from '../../MarketScope'
import { scopes } from '../../../../assets'

interface Props {
    hideBetAgainst: boolean
    token: string
    awayTeam: string
    homeTeam: string
    market: string
    marketMaker: MarketMaker
    odds: MarketOdds[]
    odds1: MarketOdds[]
    odds2: MarketOdds[]
    odds3: MarketOdds[]
    sportId: SportId
    hasNormalValues?: boolean
    marketGroupData: MarketGroupItemElement
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    oldOdds?: boolean
    psbOdds?: boolean
    Odds: number[]
    betAgainst: (invOddsValue: string, matchid: string, typeProperty: string, options) => void
}

interface State {
    activeScope: ScopeId
    oddsChanged: boolean
    otherOptionsOpened: boolean
}

class MarketBoxWithValue extends React.Component<Props, State> {
    private closestOdds: MarketOdds[] = []
    private closestValue = ''
    private sortedValues: string[] = []
    private valuesWithOdds: {[value: string]: MarketOdds[]} = {}
    constructor(props: Props) {
        super(props)

        this.state = {
            activeScope: ScopeId.FT,
            oddsChanged: false,
            otherOptionsOpened: false
        }
        const valuesWithOdds = fillValuesWithOdds(props.odds, props.marketGroupData)        
        const closestValue: string = getClosestValue(valuesWithOdds)
        const sortedValues: string[] = Object.keys(valuesWithOdds).sort((a: string, b: string) => sortBySize(+a, +b))
        this.closestOdds = (closestValue !== '') ? valuesWithOdds[closestValue] : []
        this.closestValue = closestValue
        this.sortedValues = sortedValues
        this.valuesWithOdds = valuesWithOdds
    }
    componentDidUpdate(prevProps: Props) {
        const { activeScope, oddsChanged } = this.state
        const oddsKey =
            activeScope === ScopeId.FT ?
                'odds'
            : activeScope === ScopeId.P1 || activeScope === ScopeId.H1 || activeScope === ScopeId.J1 ?
                'odds1'
            : activeScope === ScopeId.P2 || activeScope === ScopeId.H2 ?
                'odds2'
            : activeScope === ScopeId.P3 ?
                'odds3'
            : ''

        const areOddsDifferent: boolean = checkDifferentOdds(prevProps[oddsKey], this.props[oddsKey])
        if (!oddsChanged && areOddsDifferent) {
            const valuesWithOdds = fillValuesWithOdds(this.props[oddsKey])
            const closestValue: string = getClosestValue(valuesWithOdds)
            const sortedValues: string[] = Object.keys(valuesWithOdds).sort((a: string, b: string) => sortBySize(+a, +b))
            this.closestOdds = (closestValue !== '') ? valuesWithOdds[closestValue] : []
            this.closestValue = closestValue
            this.sortedValues = sortedValues
            this.valuesWithOdds = valuesWithOdds

            this.setState({
                oddsChanged: true
            })
        }
    }
    handleOtherOptionsOpened = () => {
        const { otherOptionsOpened } = this.state
        this.setState({
            otherOptionsOpened: !otherOptionsOpened
        })
    }
    handleChangeScope = (value: ScopeId) => {
        this.setState({ activeScope: value })
    }
    render() {
        const {
            token,
            betAgainst,
            Odds,
            minOdds,
            maxOdds,
            minCSOdds,
            maxCSOdds,
            marginParameter,
            margin,
            awayTeam,
            hasNormalValues,
            homeTeam,
            market,
            marketGroupData,
            marketMaker,
            sportId,
            oldOdds,
            psbOdds,
            odds,
            odds1,
            odds2,
            odds3,
            hideBetAgainst
        } = this.props
        const { activeScope, otherOptionsOpened } = this.state
        const scopeOdds =
            activeScope === ScopeId.FT ?
                odds
            : activeScope === ScopeId.P1 || activeScope === ScopeId.H1 || activeScope === ScopeId.J1 ?
                odds1
            : activeScope === ScopeId.P2 || activeScope === ScopeId.H2 ?
                odds2
            : activeScope === ScopeId.P3 ?
                odds3
            : ''
        const teamName: string = 'unknown'
        const marketName: string =
            (marketGroupData && marketGroupData.name) ?
                GetNameFromMarketGroup(marketGroupData.name, homeTeam, awayTeam)
            :
            (BetTypeGroupId[market] === BetTypeGroupId.OverUnder && !hasNormalValues) ?
                'Asian Total Goals'
            : (BetTypeGroupId[market] === BetTypeGroupId.AsianHandicap && hasNormalValues) ?
                'Handicap'
            :
                getMarketNameAndOptions(market, sportId, { teamName }).name

        const orOddsInsured: Array<{bettypegroupid: number, bettypeid: number, value: number}> = store.get(main => main.iframeresponse.orOddsInsured)
        if (!scopeOdds || scopeOdds.length === 0) {return <div/>}
        const betTypeGroupId = scopeOdds[0].bettypegroupid
        let OrOddsInsured: number
        for (const item of orOddsInsured) {
            if (item.bettypegroupid === betTypeGroupId) {
                OrOddsInsured = item.value
                break
            }
        }
        const betTypeId = scopeOdds[0].bettypeid
        const matchId = scopeOdds[0].matchid
        let typeProperty = BetTypeId[betTypeId]
        let TypeProperty
        let options = []

        for (const item of scopeOdds) {
            if (item.value === this.closestValue) {
                if (TypeProperty === undefined) {
                    TypeProperty = BetTypeId[item.bettypeid]
                }
                options.push(item)
            }
        }

        if (typeProperty && typeProperty.startsWith('EuropeanHandicap')) {
            typeProperty = 'EuropeanHandicap'
        }
        let shouldCheckMM: boolean
        if (marketMaker && marketMaker[matchId]) {
            for (const item of Object.keys(marketMaker[matchId])) {
                if (item.startsWith(typeProperty)) {
                    const betType = marketMaker[matchId] && marketMaker[matchId][item]
                    shouldCheckMM = betType && betType.selections.length > 1
                    break
                }
            }
        }
        const betType = marketMaker[matchId] && marketMaker[matchId][TypeProperty]
        const oddsValueToShow: string = (betType) ? roundOddsValue(betType.odds, 2) : ''
        const invOddsValueToShow: string = (betType) ? roundOddsValue(betType.invOdds, 2) : ''

        const sportScopes = scopes[sportId]

        const oddsKey =
            activeScope === ScopeId.FT ?
                'odds'
            : activeScope === ScopeId.P1 || activeScope === ScopeId.H1 || activeScope === ScopeId.J1 ?
                'odds1'
            : activeScope === ScopeId.P2 || activeScope === ScopeId.H2 ?
                'odds2'
            : activeScope === ScopeId.P3 ?
                'odds3'
            : ''
        const valuesWithOdds = fillValuesWithOdds(this.props[oddsKey])
        const closestValue: string = getClosestValue(valuesWithOdds)
        const sortedValues: string[] = Object.keys(valuesWithOdds).sort((a: string, b: string) => sortBySize(+a, +b))
        this.closestOdds = (closestValue !== '') ? valuesWithOdds[closestValue] : []
        this.closestValue = closestValue
        this.sortedValues = sortedValues
        this.valuesWithOdds = valuesWithOdds

        return (
            <div className="market-box">
                {shouldCheckMM &&
                    <FaIcon
                        className="reset-market-maker"
                        icon="times"
                        type="solid"
                        onClick={() => clearMMOddsItem(betTypeGroupId, matchId, typeProperty)}
                    />
                }
                <h3 className="market-name thin" style = {{position: 'relative'}}>
                    {OrOddsInsured ? <div className = "or-odds-insured">
                        ({OrOddsInsured.toFixed(2)})
                    </div> : void 0}
                    <div>{marketName}</div>
                    <MarketScope
                        Odds={Odds}
                        activeScope={activeScope}
                        scopes={sportScopes}
                        handleChangeScope={this.handleChangeScope}
                    />
                    {(invOddsValueToShow && parseFloat(invOddsValueToShow) !== 1) && !hideBetAgainst ?
                        <button onClick = {() => betAgainst(invOddsValueToShow, matchId, TypeProperty, options)} className="invert-button">
                            <span>Bet against: </span>
                            <span>{roundOddsValue(invOddsValueToShow, 3)}</span>
                        </button>
                    : null}
                </h3>
                <div className = "market-menu">
                    <div className = "blank">
                        line
                    </div>
                    <div className = "values">
                        <div>{homeTeam}</div>
                        <div>Draw</div>
                        <div>{awayTeam}</div>
                    </div>
                </div>
                <div className="value-wrapper first">
                    <div
                        className={this.sortedValues.length === 1 ? 'value-item value-item-no-click' : 'value-item'}
                        onClick={this.handleOtherOptionsOpened}
                    >
                        <span className="value">{this.closestValue}</span>
                        <span className="arrow-down"/>
                    </div>
                    <div className="odds-wrapper">
                        {this.closestOdds.map((item, index) => {
                            const { outcomeid, outcomename, value } = item
                            const selectionText: string = (isMobile()) ? outcomename : getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    margin = {margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={index}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className="single-odds align-center"
                                    item={item}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={this.closestOdds.length}
                                    options={this.closestOdds}
                                    selectionText={selectionText}
                                    sportId={sportId}
                                    scopeId = {activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })}
                    </div>
                </div>
                {oldOdds ?
                <div className="value-wrapper value-wrapper-disabled">
                    <div
                        className="value-item"
                        onClick={this.handleOtherOptionsOpened}
                    >
                        <span className="value">{this.closestValue}</span>
                        <span className="arrow-down"/>
                    </div>
                    <div className="odds-wrapper odds-wrapper-disabled">
                        {this.closestOdds.map((item, index) => {
                            const { outcomeid, outcomename, value } = item
                            const selectionText: string = (isMobile()) ? outcomename : getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    margin = {margin}
                                    marginParameter = {marginParameter}
                                    key={index + '_'}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className="single-odds align-center"
                                    item={item}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={this.closestOdds.length}
                                    options={this.closestOdds}
                                    selectionText={selectionText}
                                    sportId={sportId}
                                    disabled = {true}
                                    scopeId = {activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })}
                    </div>
                </div> : void 0}
                {psbOdds ?
                <div className="value-wrapper value-wrapper-enabled">
                    <div
                        className="value-item"
                        onClick={this.handleOtherOptionsOpened}
                    >
                        <span className="value">{this.closestValue}</span>
                        <span className="arrow-down"/>
                    </div>
                    <div className="odds-wrapper odds-wrapper-enabled">
                        {this.closestOdds.map((item, index) => {
                            const { outcomeid, outcomename, value } = item
                            const selectionText: string = (isMobile()) ? outcomename : getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    margin = {margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={index + '__'}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className="single-odds align-center"
                                    item={item}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={this.closestOdds.length}
                                    options={this.closestOdds}
                                    selectionText={selectionText}
                                    sportId={sportId}
                                    disabled = {false}
                                    scopeId = {activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })}
                    </div>
                </div> : void 0}

                <div className={clsx('other-options', otherOptionsOpened && 'opened')}>
                    {this.sortedValues.filter(s => s !== this.closestValue).map(itemValue =>
                        <>
                        <div key={itemValue} className="value-wrapper">
                            <div className="value-item">
                                <span className="value">{itemValue}</span>
                            </div>
                            <div className="odds-wrapper">
                                {this.valuesWithOdds[itemValue].map((item, index) => {
                                    const { outcomeid, outcomename, value } = item
                                    const selectionText: string = (isMobile()) ? outcomename : getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                                    return (
                                        <OddsItem token = {token} psbOdds = {psbOdds}
                                            margin = {margin}
                                            marginParameter = {marginParameter}
                                            minOdds={minOdds}
                                            maxOdds={maxOdds}
                                            minCSOdds={minCSOdds}
                                            maxCSOdds={maxCSOdds}
                                            key={index}
                                            awayTeam={awayTeam}
                                            homeTeam={homeTeam}
                                            className="single-odds align-center"
                                            item={item}
                                            marketMaker={true}
                                            marketMakerData={marketMaker}
                                            optionsLength={this.valuesWithOdds[itemValue].length}
                                            options={this.valuesWithOdds[itemValue]}
                                            selectionText={selectionText}
                                            sportId={sportId}
                                            scopeId = {activeScope}
                                            oddsValueToShow = {oddsValueToShow}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        {oldOdds ?
                        <div key={itemValue + '_'} className="value-wrapper value-wrapper-disabled">
                            <div className="value-item">
                                <span className="value">{itemValue}</span>
                            </div>
                            <div className="odds-wrapper odds-wrapper-disabled">
                                {this.valuesWithOdds[itemValue].map((item, index) => {
                                    const { outcomeid, outcomename, value } = item
                                    const selectionText: string = (isMobile()) ? outcomename : getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                                    return (
                                        <OddsItem token = {token} psbOdds = {psbOdds}
                                            margin = {margin}
                                            marginParameter = {marginParameter}
                                            minOdds={minOdds}
                                            maxOdds={maxOdds}
                                            minCSOdds={minCSOdds}
                                            maxCSOdds={maxCSOdds}
                                            key={index+'_'}
                                            awayTeam={awayTeam}
                                            homeTeam={homeTeam}
                                            className="single-odds align-center"
                                            item={item}
                                            marketMaker={true}
                                            marketMakerData={marketMaker}
                                            optionsLength={this.valuesWithOdds[itemValue].length}
                                            options={this.valuesWithOdds[itemValue]}
                                            selectionText={selectionText}
                                            sportId={sportId}
                                            disabled = {true}
                                            scopeId = {activeScope}
                                            oddsValueToShow = {oddsValueToShow}
                                        />
                                    )
                                })}
                            </div>
                        </div> : void 0}
                        {psbOdds ?
                        <div key={itemValue + '__'} className="value-wrapper value-wrapper-enabled">
                            <div className="value-item">
                                <span className="value">{itemValue}</span>
                            </div>
                            <div className="odds-wrapper odds-wrapper-enabled">
                                {this.valuesWithOdds[itemValue].map((item, index) => {
                                    const { outcomeid, outcomename, value } = item
                                    const selectionText: string = (isMobile()) ? outcomename : getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                                    return (
                                        <OddsItem token = {token} psbOdds = {psbOdds}
                                            margin = {margin}
                                            marginParameter = {marginParameter}
                                            minOdds={minOdds}
                                            maxOdds={maxOdds}
                                            minCSOdds={minCSOdds}
                                            maxCSOdds={maxCSOdds}
                                            key={index+'__'}
                                            awayTeam={awayTeam}
                                            homeTeam={homeTeam}
                                            className="single-odds align-center"
                                            item={item}
                                            marketMaker={true}
                                            marketMakerData={marketMaker}
                                            optionsLength={this.valuesWithOdds[itemValue].length}
                                            options={this.valuesWithOdds[itemValue]}
                                            selectionText={selectionText}
                                            sportId={sportId}
                                            disabled = {false}
                                            scopeId = {activeScope}
                                            oddsValueToShow = {oddsValueToShow}
                                        />
                                    )
                                })}
                            </div>
                        </div> : void 0}
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default MarketBoxWithValue

const checkDifferentOdds = (previousOdds: MarketOdds[], currentOdds: MarketOdds[]): boolean => {
    if (currentOdds.length !== previousOdds.length) {
        return true
    }

    if (previousOdds[0] && currentOdds[0] && previousOdds[0].matchid !== currentOdds[0].matchid) {
        return true
    }

    return false
}