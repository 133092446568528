import React from 'react'
import clsx from 'clsx'
import { isMobile } from '../../../../../utils'
import { NewQuickButton } from '../..'

interface Props {
    awayTeam: string
    homeTeam: string
    isLineSelected: boolean
    newQuickButtons: { name: string, value: NewQuickButton }[][]
    selectedNewQuickButton: NewQuickButton
    handleNewQuickButton: (type: NewQuickButton) => void
}

function BetrixQuickButtonsNewView({ awayTeam, homeTeam, isLineSelected, newQuickButtons, selectedNewQuickButton, handleNewQuickButton }: Props) {
    return (
        <div>
            {newQuickButtons.map((item, index) => (
                <div key={index}>
                    {item.map((button, i) => {
                        let buttonText = button.name

                        if (buttonText.includes('{home}')) {
                            const stringToReplace = isMobile() ? 'Home team' : homeTeam
                            buttonText = buttonText.replace('{home}', stringToReplace)
                        }

                        if (buttonText.includes('{away}')) {
                            const stringToReplace = isMobile() ? 'Away team' : awayTeam
                            buttonText = buttonText.replace('{away}', stringToReplace)
                        }
                        
                        return (
                            <button
                                key={i}
                                className={clsx('new-quick-button', selectedNewQuickButton === button.value && 'active')}
                                onClick={() => handleNewQuickButton(button.value)}
                                disabled={isLineSelected}
                            >
                                {buttonText}
                            </button>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}

export default BetrixQuickButtonsNewView