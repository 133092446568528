import { store } from '../../state';
import { Regions } from '../../state/stores/feed/models';
import { createMultiSystemsPayout, fillRegionsWithLeagues, fillMainMarkets, fillMainMarket, groupHeaderMatches, getResponseMessage, setPopupContent, fillCorrectScoreOdds, fillBetrixSelectionOdds, emptyBetslip, sortByProperty, emptyMarketMaker } from '../../utils';

interface Route {
    hasLoader: boolean
    name: availableRESTRoutes
    url: string
    mapper: (data: any) => void
}

export type availableRESTRoutes =
    // 'get-doublettes' |
    // 'insert-football-model' |
    'encrypt'|
    'get-decrypt'|
    'get-decrypt2'|
    'get-odds-bet-id' |
    'get-available-leagues' |
    'get-available-matches' |
    'get-available-matches-today' |
    'get-available-sports' |
    'get-betslips' |
    'get-betslip-items' |
    'get-betslip-subitems' |
    'get-cashout' |
    'get-header-matches' |
    'get-main-market' |
    'get-odds' |
    'get-odds-today' |
    'get-suggested-markets' |
    'insert-betslip' |
    'insert-cashout' |
    'insert-market-maker-odds' |
    'update-market-maker-odds' |
    'insert-market-maker-probs' |
    'get-calculate-betrix-odds' |
    'update-football-model' |
    'get-calculate-odds' |
    'get-calculate-probabilities' |
    'insert-system-single' |
    'insert-system-single-betslip' |
    'insert-multi-systems' |
    'insert-multi-systems-betslip' |
    'get-look-and-feel'
// new routes - firebase functions
export const RESTRoutes: Route[] = [
        {
            hasLoader: false,
            name: 'get-odds-bet-id',
            url: 'oddsBetId',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.odds = fillMainMarkets(data, main.feed.odds)
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'insert-multi-systems',
            url: 'multiSystemsNew',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betrixCombo.multiSystems = data
                        main.betrixCombo.MSPayout = createMultiSystemsPayout(data)
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'insert-multi-systems-betslip',
            url: 'multiSystemsNew',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betslip.betrixCombo.MSData = data
                        main.betslip.betrixCombo.MSPayout = createMultiSystemsPayout(data)
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'insert-system-single',
            url: 'systemSingle',
            mapper: (data: any) => {
            }
        },
        {
            hasLoader: false,
            name: 'insert-system-single-betslip',
            url: 'systemSingle',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betslip.betrixCombo.SSData = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'get-calculate-betrix-odds',
            url: 'calculateBetrixOdds',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betrix.selectionOdds = fillBetrixSelectionOdds(data)
                        return main
                    })
                }
            }
        },
        {
            hasLoader: true,
            name: 'get-calculate-odds',
            url: 'calculateOdds',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betrix.odds = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: true,
            name: 'get-look-and-feel',
            url: 'lookAndFeel',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.settingsSoccer = data
                        main.settingsHockey = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'get-calculate-probabilities',
            url: 'calculateProbabilities',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.iframeresponse.tenant_id = data.tenant_id
                        main.iframeresponse.account_id = data.account_id
                        main.iframeresponse.min_bet = data.min_bet
                        main.iframeresponse.max_bet = data.max_bet
                        main.iframeresponse.currency = data.currency

                        main.iframeresponse.xh = data.xh
                        main.iframeresponse.xa = data.xa
                        main.iframeresponse.xh1 = data.xh1
                        main.iframeresponse.xa1 = data.xa1
                        main.iframeresponse.xh2 = data.xh2
                        main.iframeresponse.xa2 = data.xa2
                        main.iframeresponse.xh3 = data.xh3
                        main.iframeresponse.xa3 = data.xa3


                        main.iframeresponse.fullThreshold = data.fullThreshold
                        main.iframeresponse.periodThreshold = data.periodThreshold
    
                        main.iframeresponse.odds = data.odds
                        main.iframeresponse.correctScoreProbs = data.correctScoreProbs
                        // main.iframeresponse.anyOtherProb = data.anyOtherProb
                        main.iframeresponse.anyOtherProbDraw = data.anyOtherProbDraw
                        main.iframeresponse.anyOtherProbHome = data.anyOtherProbHome
                        main.iframeresponse.anyOtherProbAway = data.anyOtherProbAway
    
                        main.iframeresponse.newCorrectScoreOdds = data.newCorrectScoreOdds
                        main.iframeresponse.newAnyOtherOddDraw = data.newAnyOtherOddDraw
                        main.iframeresponse.newAnyOtherOddHome = data.newAnyOtherOddHome
                        main.iframeresponse.newAnyOtherOddAway = data.newAnyOtherOddAway
                        main.iframeresponse.newAnyOtherOddBettypeidDraw = data.newAnyOtherOddBettypeidDraw
                        main.iframeresponse.newAnyOtherOddBettypeidHome = data.newAnyOtherOddBettypeidHome
                        main.iframeresponse.newAnyOtherOddBettypeidAway = data.newAnyOtherOddBettypeidAway
    
                        main.iframeresponse.odds1 = data.odds1
                        main.iframeresponse.correctScoreProbs1 = data.correctScoreProbs1
                        // main.iframeresponse.anyOtherProb1 = data.anyOtherProb1
                        main.iframeresponse.anyOtherProb1Draw = data.anyOtherProb1Draw
                        main.iframeresponse.anyOtherProb1Home = data.anyOtherProb1Home
                        main.iframeresponse.anyOtherProb1Away = data.anyOtherProb1Away
    
                        main.iframeresponse.newCorrectScoreOdds1 = data.newCorrectScoreOdds1
                        main.iframeresponse.newAnyOtherOdd1Draw = data.newAnyOtherOdd1Draw
                        main.iframeresponse.newAnyOtherOdd1Home = data.newAnyOtherOdd1Home
                        main.iframeresponse.newAnyOtherOdd1Away = data.newAnyOtherOdd1Away
                        main.iframeresponse.newAnyOtherOddBettypeid1Draw = data.newAnyOtherOddBettypeid1Draw
                        main.iframeresponse.newAnyOtherOddBettypeid1Home = data.newAnyOtherOddBettypeid1Home
                        main.iframeresponse.newAnyOtherOddBettypeid1Away = data.newAnyOtherOddBettypeid1Away
    
                        main.iframeresponse.odds2 = data.odds2
                        main.iframeresponse.correctScoreProbs2 = data.correctScoreProbs2
                        // main.iframeresponse.anyOtherProb2 = data.anyOtherProb2
                        main.iframeresponse.anyOtherProb2Draw = data.anyOtherProb2Draw
                        main.iframeresponse.anyOtherProb2Home = data.anyOtherProb2Home
                        main.iframeresponse.anyOtherProb2Away = data.anyOtherProb2Away
    
                        main.iframeresponse.newCorrectScoreOdds2 = data.newCorrectScoreOdds2
                        main.iframeresponse.newAnyOtherOdd2Draw = data.newAnyOtherOdd2Draw
                        main.iframeresponse.newAnyOtherOdd2Home = data.newAnyOtherOdd2Home
                        main.iframeresponse.newAnyOtherOdd2Away = data.newAnyOtherOdd2Away
                        main.iframeresponse.newAnyOtherOddBettypeid2Draw = data.newAnyOtherOddBettypeid2Draw
                        main.iframeresponse.newAnyOtherOddBettypeid2Home = data.newAnyOtherOddBettypeid2Home
                        main.iframeresponse.newAnyOtherOddBettypeid2Away = data.newAnyOtherOddBettypeid2Away
    
                        main.iframeresponse.odds3 = data.odds3
                        main.iframeresponse.correctScoreProbs3 = data.correctScoreProbs3
                        // main.iframeresponse.anyOtherProb3 = data.anyOtherProb3
                        main.iframeresponse.anyOtherProb3Draw = data.anyOtherProb3Draw
                        main.iframeresponse.anyOtherProb3Home = data.anyOtherProb3Home
                        main.iframeresponse.anyOtherProb3Away = data.anyOtherProb3Away
    
                        main.iframeresponse.newCorrectScoreOdds3 = data.newCorrectScoreOdds3
                        main.iframeresponse.newAnyOtherOdd3Draw = data.newAnyOtherOdd3Draw
                        main.iframeresponse.newAnyOtherOdd3Home = data.newAnyOtherOdd3Home
                        main.iframeresponse.newAnyOtherOdd3Away = data.newAnyOtherOdd3Away
                        main.iframeresponse.newAnyOtherOddBettypeid3Draw = data.newAnyOtherOddBettypeid3Draw
                        main.iframeresponse.newAnyOtherOddBettypeid3Home = data.newAnyOtherOddBettypeid3Home
                        main.iframeresponse.newAnyOtherOddBettypeid3Away = data.newAnyOtherOddBettypeid3Away
    
                        main.iframeresponse.o0 = data.o0
                        main.iframeresponse.stage = data.stage
                        main.iframeresponse.league = data.league
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'encrypt',
            url: 'encrypt',
            mapper: (data: any) => {
            }
        },
        {
            hasLoader: false,
            name: 'get-decrypt',
            url: 'decrypt',
            mapper: (data: any) => {
            }
        },
        {
            hasLoader: false,
            name: 'get-decrypt2',
            url: 'decrypt2',
            mapper: (data: any) => {
            }
        },
        {
            hasLoader: true,
            name: 'get-available-leagues',
            url: 'availableLeagues',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        const regions: Regions = fillRegionsWithLeagues(data)
                        main.feed.leagues = data
                        main.feed.regions = regions
                        return main
                    })
                }
            }
        },
        {
            hasLoader: true,
            name: 'get-available-matches',
            url: 'availableMatches',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.matches = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: true,
            name: 'get-available-matches-today',
            url: 'availableMatchesToday',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.matchesToday = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: true,
            name: 'get-available-sports',
            url: 'availableSports',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.sports = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'get-betslips',
            url: 'betslip',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betslip.history.standard = sortByProperty(data, 'insertedat').reverse()
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'get-betslip-items',
            url: 'betslipItem',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betslip.history.items = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'get-betslip-subitems',
            url: 'betslipSubitem',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betslip.history.subitems = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'get-cashout',
            url: 'cashout',
            mapper: (data: any) => {
                if (data.resolved) {
                    store.update(main => {
                        main.betslip.cashout = data.cashout
                        return main
                    })
                }
            }
        },
    /*
        {
            hasLoader: true,
            name: 'get-doublettes',
            url: 'doublettes',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betrix.doublettes = data
                        return main
                    })
                }
            }
        },
    */
        {
            hasLoader: true,
            name: 'get-header-matches',
            url: 'headerMatches',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.headerMatches = groupHeaderMatches(data)
                        return main
                    })
                }
            }
        },
        {
            hasLoader: true,
            name: 'get-main-market',
            url: 'mainMarket',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.mainMarkets = data
                        return main
                    })
                }
            }
        },
    /*
        {
            hasLoader: true,
            name: 'get-odds',
            url: 'odds',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.odds = fillMainMarkets(data)
                        return main
                    })
                }
            }
        },
    */
        {
            hasLoader: true,
            name: 'get-odds',
            url: 'odds',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.odds = fillMainMarkets(data, main.feed.odds)
                        return main
                    })
                }
            }
        },
        {
            hasLoader: true,
            name: 'get-odds-today',
            url: 'oddsToday',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.feed.oddsToday = fillMainMarket(data)
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'get-suggested-markets',
            url: 'betrixMarkets',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betrix.suggestedMarkets = data
                        return main
                    })
                }
            }
        },
        {
            hasLoader: false,
            name: 'insert-betslip',
            url: 'betslip',
            mapper: (data: any) => {
                const message: string = getResponseMessage(data)
                setPopupContent(message)
                emptyBetslip()
                emptyMarketMaker()
            }
        },
        {
            hasLoader: false,
            name: 'insert-cashout',
            url: 'cashout',
            mapper: (data: any) => {
                const message: string = getResponseMessage(data)
                setPopupContent(message)
            }
        },
    /*
        {
            hasLoader: false,
            name: 'insert-football-model',
            url: 'football-model',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betrix.correctScoreOdds = fillCorrectScoreOdds(data)
                        main.betrix.selectionOdds = fillBetrixSelectionOdds(data)
                        return main
                    })
                }
            }
        },
    */
        {
            hasLoader: false,
            name: 'insert-market-maker-odds',
            url: 'calculateOdd',
            mapper: (data: any) => {
            }
        },
        {
            hasLoader: false,
            name: 'insert-market-maker-probs',
            url: 'marketMakerProbs',
            mapper: (data: any) => {
            }
        },
    
        {
            hasLoader: false,
            name: 'update-football-model',
            url: 'footballModel',
            mapper: (data: any) => {
                if (typeof data !== 'string') {
                    store.update(main => {
                        main.betrix.correctScoreOdds = fillCorrectScoreOdds(data)
                        main.betrix.selectionOdds = fillBetrixSelectionOdds(data)
                        return main
                    })
                }
            }
        }
];

// old routes
export const REST_Routes: Route[] = [
    {
        hasLoader: false,
        name: 'get-odds-bet-id',
        url: 'odds-bet-id',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.odds = fillMainMarkets(data, main.feed.odds)
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'insert-multi-systems',
        url: 'multi-systems-new',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betrixCombo.multiSystems = data
                    main.betrixCombo.MSPayout = createMultiSystemsPayout(data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'insert-multi-systems-betslip',
        url: 'multi-systems-new',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betslip.betrixCombo.MSData = data
                    main.betslip.betrixCombo.MSPayout = createMultiSystemsPayout(data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'insert-system-single',
        url: 'system-single',
        mapper: (data: any) => {
        }
    },
    {
        hasLoader: false,
        name: 'insert-system-single-betslip',
        url: 'system-single',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betslip.betrixCombo.SSData = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-calculate-betrix-odds',
        url: 'calculate-betrix-odds',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betrix.selectionOdds = fillBetrixSelectionOdds(data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-calculate-odds',
        url: 'calculate-odds',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betrix.odds = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-look-and-feel',
        url: 'look-and-feel',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.settingsSoccer = data
                    main.settingsHockey = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-calculate-probabilities',
        url: 'calculate-probabilities',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.iframeresponse.tenant_id = data.tenant_id
                    main.iframeresponse.account_id = data.account_id
                    main.iframeresponse.min_bet = data.min_bet
                    main.iframeresponse.max_bet = data.max_bet
                    main.iframeresponse.currency = data.currency

                    main.iframeresponse.fullThreshold = data.fullThreshold
                    main.iframeresponse.periodThreshold = data.periodThreshold

                    main.iframeresponse.odds = data.odds
                    main.iframeresponse.correctScoreProbs = data.correctScoreProbs
                    // main.iframeresponse.anyOtherProb = data.anyOtherProb
                    main.iframeresponse.anyOtherProbDraw = data.anyOtherProbDraw
                    main.iframeresponse.anyOtherProbHome = data.anyOtherProbHome
                    main.iframeresponse.anyOtherProbAway = data.anyOtherProbAway

                    main.iframeresponse.newCorrectScoreOdds = data.newCorrectScoreOdds
                    main.iframeresponse.newAnyOtherOddDraw = data.newAnyOtherOddDraw
                    main.iframeresponse.newAnyOtherOddHome = data.newAnyOtherOddHome
                    main.iframeresponse.newAnyOtherOddAway = data.newAnyOtherOddAway
                    main.iframeresponse.newAnyOtherOddBettypeidDraw = data.newAnyOtherOddBettypeidDraw
                    main.iframeresponse.newAnyOtherOddBettypeidHome = data.newAnyOtherOddBettypeidHome
                    main.iframeresponse.newAnyOtherOddBettypeidAway = data.newAnyOtherOddBettypeidAway

                    main.iframeresponse.odds1 = data.odds1
                    main.iframeresponse.correctScoreProbs1 = data.correctScoreProbs1
                    // main.iframeresponse.anyOtherProb1 = data.anyOtherProb1
                    main.iframeresponse.anyOtherProb1Draw = data.anyOtherProb1Draw
                    main.iframeresponse.anyOtherProb1Home = data.anyOtherProb1Home
                    main.iframeresponse.anyOtherProb1Away = data.anyOtherProb1Away

                    main.iframeresponse.newCorrectScoreOdds1 = data.newCorrectScoreOdds1
                    main.iframeresponse.newAnyOtherOdd1Draw = data.newAnyOtherOdd1Draw
                    main.iframeresponse.newAnyOtherOdd1Home = data.newAnyOtherOdd1Home
                    main.iframeresponse.newAnyOtherOdd1Away = data.newAnyOtherOdd1Away
                    main.iframeresponse.newAnyOtherOddBettypeid1Draw = data.newAnyOtherOddBettypeid1Draw
                    main.iframeresponse.newAnyOtherOddBettypeid1Home = data.newAnyOtherOddBettypeid1Home
                    main.iframeresponse.newAnyOtherOddBettypeid1Away = data.newAnyOtherOddBettypeid1Away

                    main.iframeresponse.odds2 = data.odds2
                    main.iframeresponse.correctScoreProbs2 = data.correctScoreProbs2
                    // main.iframeresponse.anyOtherProb2 = data.anyOtherProb2
                    main.iframeresponse.anyOtherProb2Draw = data.anyOtherProb2Draw
                    main.iframeresponse.anyOtherProb2Home = data.anyOtherProb2Home
                    main.iframeresponse.anyOtherProb2Away = data.anyOtherProb2Away

                    main.iframeresponse.newCorrectScoreOdds2 = data.newCorrectScoreOdds2
                    main.iframeresponse.newAnyOtherOdd2Draw = data.newAnyOtherOdd2Draw
                    main.iframeresponse.newAnyOtherOdd2Home = data.newAnyOtherOdd2Home
                    main.iframeresponse.newAnyOtherOdd2Away = data.newAnyOtherOdd2Away
                    main.iframeresponse.newAnyOtherOddBettypeid2Draw = data.newAnyOtherOddBettypeid2Draw
                    main.iframeresponse.newAnyOtherOddBettypeid2Home = data.newAnyOtherOddBettypeid2Home
                    main.iframeresponse.newAnyOtherOddBettypeid2Away = data.newAnyOtherOddBettypeid2Away

                    main.iframeresponse.odds3 = data.odds3
                    main.iframeresponse.correctScoreProbs3 = data.correctScoreProbs3
                    // main.iframeresponse.anyOtherProb3 = data.anyOtherProb3
                    main.iframeresponse.anyOtherProb3Draw = data.anyOtherProb3Draw
                    main.iframeresponse.anyOtherProb3Home = data.anyOtherProb3Home
                    main.iframeresponse.anyOtherProb3Away = data.anyOtherProb3Away

                    main.iframeresponse.newCorrectScoreOdds3 = data.newCorrectScoreOdds3
                    main.iframeresponse.newAnyOtherOdd3Draw = data.newAnyOtherOdd3Draw
                    main.iframeresponse.newAnyOtherOdd3Home = data.newAnyOtherOdd3Home
                    main.iframeresponse.newAnyOtherOdd3Away = data.newAnyOtherOdd3Away
                    main.iframeresponse.newAnyOtherOddBettypeid3Draw = data.newAnyOtherOddBettypeid3Draw
                    main.iframeresponse.newAnyOtherOddBettypeid3Home = data.newAnyOtherOddBettypeid3Home
                    main.iframeresponse.newAnyOtherOddBettypeid3Away = data.newAnyOtherOddBettypeid3Away

                    main.iframeresponse.o0 = data.o0
                    main.iframeresponse.stage = data.stage
                    main.iframeresponse.league = data.league
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'encrypt',
        url: 'encrypt',
        mapper: (data: any) => {
        }
    },
    {
        hasLoader: false,
        name: 'get-decrypt',
        url: 'decrypt',
        mapper: (data: any) => {
        }
    },
    {
        hasLoader: false,
        name: 'get-decrypt2',
        url: 'decrypt2',
        mapper: (data: any) => {
        }
    },
    {
        hasLoader: true,
        name: 'get-available-leagues',
        url: 'available-leagues',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    const regions: Regions = fillRegionsWithLeagues(data)
                    main.feed.leagues = data
                    main.feed.regions = regions
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-available-matches',
        url: 'available-matches',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.matches = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-available-matches-today',
        url: 'available-matches-today',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.matchesToday = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-available-sports',
        url: 'available-sports',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.sports = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-betslips',
        url: 'betslip',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betslip.history.standard = sortByProperty(data, 'insertedat').reverse()
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-betslip-items',
        url: 'betslip-item',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betslip.history.items = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-betslip-subitems',
        url: 'betslip-subitem',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betslip.history.subitems = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-cashout',
        url: 'cashout',
        mapper: (data: any) => {
            if (data.resolved) {
                store.update(main => {
                    main.betslip.cashout = data.cashout
                    return main
                })
            }
        }
    },
/*
    {
        hasLoader: true,
        name: 'get-doublettes',
        url: 'doublettes',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betrix.doublettes = data
                    return main
                })
            }
        }
    },
*/
    {
        hasLoader: true,
        name: 'get-header-matches',
        url: 'header-matches',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.headerMatches = groupHeaderMatches(data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-main-market',
        url: 'main-market',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.mainMarkets = data
                    return main
                })
            }
        }
    },
/*
    {
        hasLoader: true,
        name: 'get-odds',
        url: 'odds',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.odds = fillMainMarkets(data)
                    return main
                })
            }
        }
    },
*/
    {
        hasLoader: true,
        name: 'get-odds',
        url: 'odds',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.odds = fillMainMarkets(data, main.feed.odds)
                    return main
                })
            }
        }
    },
    {
        hasLoader: true,
        name: 'get-odds-today',
        url: 'odds-today',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.feed.oddsToday = fillMainMarket(data)
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'get-suggested-markets',
        url: 'betrix-markets',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betrix.suggestedMarkets = data
                    return main
                })
            }
        }
    },
    {
        hasLoader: false,
        name: 'insert-betslip',
        url: 'betslip',
        mapper: (data: any) => {
            const message: string = getResponseMessage(data)
            setPopupContent(message)
            emptyBetslip()
            emptyMarketMaker()
        }
    },
    {
        hasLoader: false,
        name: 'insert-cashout',
        url: 'cashout',
        mapper: (data: any) => {
            const message: string = getResponseMessage(data)
            setPopupContent(message)
        }
    },
/*
    {
        hasLoader: false,
        name: 'insert-football-model',
        url: 'football-model',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betrix.correctScoreOdds = fillCorrectScoreOdds(data)
                    main.betrix.selectionOdds = fillBetrixSelectionOdds(data)
                    return main
                })
            }
        }
    },
*/
    {
        hasLoader: false,
        name: 'insert-market-maker-odds',
        url: 'calculate-odd',
        mapper: (data: any) => {
        }
    },
    {
        hasLoader: false,
        name: 'insert-market-maker-probs',
        url: 'market-maker-probs',
        mapper: (data: any) => {
        }
    },

    {
        hasLoader: false,
        name: 'update-football-model',
        url: 'football-model',
        mapper: (data: any) => {
            if (typeof data !== 'string') {
                store.update(main => {
                    main.betrix.correctScoreOdds = fillCorrectScoreOdds(data)
                    main.betrix.selectionOdds = fillBetrixSelectionOdds(data)
                    return main
                })
            }
        }
    }
];

// export default RESTRoutes;
