import { Betslip, SSMarketOptions, BetslipMode, BetslipSubmode } from './models';

const betslip: Betslip = {
    betrix: [],
    betrixCombo: {
        MSData: [],
        MSPayout: [],
        multiSystems: [],
        SSBetweenOption: undefined,
        SSData: {
            atLeast: {
                maxStake: [],
                odds: []
            },
            atMost: {
                maxStake: [],
                odds: []
            },
            between: {
                maxStake: [],
                odds: []
            },
            exactly: {
                maxStake: [],
                odds: []
            },
            maxStake: 0,
            odds: 0,
            Odds: 0
        },
        SSMarket: SSMarketOptions.HomeWins,
        SSRange: [],
        systemSingle: []
    },
    cashout: '',
    history: {
        items: [],
        subitems: [],
        standard: []
    },
    index: -1,
    mode: BetslipMode.Standard,
    standard: [],
    submode: BetslipSubmode.MultiSystems
}

export default betslip