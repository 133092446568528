import { LiveServerRouteDefinition } from './types'

type LiveServerRoute = Array<
    LiveServerRouteDefinition<'change-interest'> |
    LiveServerRouteDefinition<'header-matches'> |
    LiveServerRouteDefinition<'new-live-match'>
>

const WSRoutes: LiveServerRoute = [
    {
        name: 'change-interest',
        mapper: data => {
        }
    },
    {
        name: 'header-matches',
        mapper: data => {
        }
    },
    {
        name: 'new-live-match',
        mapper: data => {
        }
    }
]

export default WSRoutes