import React from 'react';

export type FaIconType = 'solid' | 'regular' | 'brand'

interface Props {
    icon: string
    type: FaIconType
    className?: string
    id?: string
    style?: React.CSSProperties
    title?: string
    onClick?: (e?: any) => void
}

const FaIcon = ({ className, icon, id, onClick, style, title, type }: Props) => {
    const prefix: string = (type === 'solid') ? 'fas' : (type === 'regular') ? 'far' : 'fab'
    const originalClassName: string = `${prefix} fa-${icon}`
    const additionalClassName: string = (className) ? className : ''
    const originalStyle = (onClick) ? {...style, cursor: 'pointer'} : style
    
    return (
        <i 
            className={`${originalClassName} ${additionalClassName}`}
            id={id}
            style={originalStyle}
            onClick={onClick}
            title={title}
        />
    )
}

export default FaIcon;