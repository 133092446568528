import React from 'react'
import OddsItem from '../../OddsItem'
import FaIcon from '../../FaIcon'
import clsx from 'clsx'
import { BetrixSuggestedMarket as BetrixSuggestedMarketModel, Doublette } from '../../../../state/stores/betrix/models'
import { getMarketNameAndOptions, isDoublette, getOutcomeTextFull } from '../../../../utils'
import { MarketMaker } from '../../../../state/stores/marketmaker/models'
import { MatchMarket, MarketOdds } from '../../../../state/stores/feed/models'
import { SportId, BetTypeGroupId, BetTypeId, OutcomeId, ScopeId } from '../../../../state/enums'
import './style.scss'

interface Props {
    token: string
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    awayTeam: string
    homeTeam: string
    doublettes: Doublette[]
    insuredValues: {awayGoals: number, homeGoals: number}[]
    odds: MatchMarket
    openedButton: boolean
    marketMaker: MarketMaker
    selectedValues: {awayGoals: number, homeGoals: number}[]
    sportId: SportId
    suggestedMarkets: BetrixSuggestedMarketModel[]
    handleSuggestedMarketsOpened: (suggestedMarketsOpened: boolean) => void
    scopeId: ScopeId
}

function BetrixSuggestedMarkets({ token, scopeId, minOdds, maxOdds, minCSOdds, maxCSOdds, marginParameter, margin, awayTeam, doublettes, handleSuggestedMarketsOpened, homeTeam, marketMaker, odds, openedButton, suggestedMarkets, selectedValues, sportId }: Props) {
    const homeGoals: number = (selectedValues.length) ? selectedValues[0].homeGoals : 0
    const awayGoals: number = (selectedValues.length) ? selectedValues[0].awayGoals : 0
    const oddsForSuggestedMarkets: MarketOdds[] = getOddsForSuggestedMarkets(awayGoals, homeGoals, odds, suggestedMarkets)
    const allOdds = oddsForSuggestedMarkets.map(o => { return { betTypeGroupId: o.bettypegroupid, betTypeId: o.bettypeid, outcomeId: o.outcomeid }})
    
    return (
        <div className={clsx('suggested-markets-wrapper', openedButton && 'opened')}>
            <div
                className="mobile-overlay"
                onClick={() => handleSuggestedMarketsOpened(false)}
            />
            <div className="data-wrapper">
                <FaIcon
                    className="times"
                    icon="times"
                    onClick={() => handleSuggestedMarketsOpened(false)}
                    type="solid"
                />
                <h1>BET SUGGESTIONS</h1>
                <div className="suggested-markets">
                    {oddsForSuggestedMarkets.map((item, index) => {
                        const { bettypegroupid, bettypeid, oddsvalue, outcomeid, value } = item
                        const { name } = getMarketNameAndOptions(BetTypeGroupId[bettypegroupid], sportId)
                        const selectionText: string = getOutcomeTextFull(awayTeam, homeTeam, outcomeid, value)
                        const oddsItem = { betTypeGroupId: bettypegroupid, betTypeId: bettypeid, outcomeId: outcomeid }
                        const isDoubletteValue = isDoublette(doublettes, oddsItem, allOdds)

                        if (!oddsvalue || isDoubletteValue) {
                            return null
                        }

                        return (
                            <div key={index} className="market-row">
                                <h3>{name}</h3>
                                <OddsItem token = {token}
                                    options = {oddsForSuggestedMarkets}
                                    psbOdds = {false}
                                    margin = {margin}
                                    marginParameter = {marginParameter}
                                    minOdds = {minOdds}
                                    maxOdds = {maxOdds}
                                    minCSOdds = {minCSOdds}
                                    maxCSOdds = {maxCSOdds}
                                    awayTeam={awayTeam}
                                    className="market-odds"
                                    homeTeam={homeTeam}
                                    item={item}
                                    marketMaker={false}
                                    marketMakerData={marketMaker}
                                    optionsLength={oddsForSuggestedMarkets.length}
                                    selectionText={selectionText}
                                    sportId={sportId}
                                    scopeId={scopeId}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default BetrixSuggestedMarkets

function getOddsForSuggestedMarkets(awayGoals: number, homeGoals: number, odds: MatchMarket, suggestedMarkets: BetrixSuggestedMarketModel[]) {
    const oddsForSuggestedMarkets: MarketOdds[] = []

    for (const market of suggestedMarkets) {
        const { bt, btg, value } = market
        const btgId: BetTypeGroupId = (btg === BetTypeGroupId.WinMargin) ? BetTypeGroupId.WinMargin2 : btg 
        const marketName: string = BetTypeGroupId[btgId]
        const marketOdds: MarketOdds[] = odds[marketName]
        if (marketOdds) {
            const isEHZero: boolean = bt === BetTypeId.EuropeanHandicapZero
            const suggestedMarketOdds: MarketOdds[] = (isEHZero) ? odds[BetTypeGroupId[BetTypeGroupId.ThreeWay]] : marketOdds.filter(m => m.bettypeid === bt)
            const betTypeGroupId: BetTypeGroupId = (isEHZero) ? BetTypeGroupId.ThreeWay : btgId
            if (suggestedMarketOdds && suggestedMarketOdds.length) {
                const scoreOdds: MarketOdds | undefined = getScoreOdds(awayGoals, homeGoals, betTypeGroupId, suggestedMarketOdds, value)
                if (scoreOdds) {
                    oddsForSuggestedMarkets.push(scoreOdds)
                }
            }
        }
    }

    return oddsForSuggestedMarkets
}

function getScoreOdds(awayGoals: number, homeGoals: number, market: BetTypeGroupId, suggestedMarketOdds: MarketOdds[], value: string | null) {
    switch (market) {
        case BetTypeGroupId.BothTeamsToScore:
            if (homeGoals > 0 && awayGoals > 0) {
                return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.BTTSYes)
            }
            return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.BTTSNo)
        case BetTypeGroupId.ThreeWay:
            if (homeGoals > awayGoals) {
                return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.HomeWin)
            }
            if (homeGoals < awayGoals) {
                return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.AwayWin)
            }
            return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.Draw)
        case BetTypeGroupId.EuropeanHandicap:
            if (value && ((homeGoals + +value) > awayGoals)) {
                return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.EHHome)
            }
            if (value && ((homeGoals + +value) < awayGoals)) {
                return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.EHAway)
            }
            return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.EHDraw)
        case BetTypeGroupId.AsianHandicap:
            if ((homeGoals === awayGoals) || (value && ((homeGoals + parseFloat(value)) >= awayGoals))) {
                return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.AHHome)
            }
            return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.AHAway)
        case BetTypeGroupId.OverUnder:
            if (value && ((homeGoals + awayGoals) > parseFloat(value))) {
                return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.Over)
            }
            return suggestedMarketOdds.find(s => s.outcomeid === OutcomeId.Under)
        default:
            return undefined
    }
}