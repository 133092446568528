import React from 'react'
import { MarketGroup } from '../../../state/stores/settings/models'
import './style.scss'
import { getNameFromMarketGroup } from '../../../utils'
import { Platform } from '../../../state/singles'
interface Props {
    activeGroup: number
    groups: Array<MarketGroup>
    onItemClick: (index: number) => void
    homeTeam: string
    awayTeam: string
    hideBetrix: boolean
    hideAllMarkets: boolean
    platform: Platform
}

const MarketGroupSelector = ({ platform, hideAllMarkets, hideBetrix, activeGroup, homeTeam, awayTeam, groups, onItemClick }: Props) => (
    <ul className="MarketGroupSelector">
        {!hideBetrix ?
        <li
            className={(activeGroup === groups.length + (hideAllMarkets ? 0 : 1)) ? 'active' : ''}
            onClick={() => onItemClick(groups.length + 1)}
        >
            Betrix™
        </li> : void 0}

        {groups.map((item, index) => item && item.multiOutcomeMarkets !== false ? (
            <li
                key={index}
                className={(activeGroup === index) ? 'active' : ''}
                onClick={() => onItemClick(index)}
            >
                {getNameFromMarketGroup(item.name, homeTeam, awayTeam, platform)}
            </li>
        ) : void 0)}
        {!hideAllMarkets ?
        <li
            className={(activeGroup === groups.length) ? 'active' : ''}
            onClick={() => onItemClick(groups.length)}
        >
            {platform === 'mobile' ? 'ALL' : 'ALL MARKETS'}
        </li> : void 0}
    </ul>
)

export default MarketGroupSelector