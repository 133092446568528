const config = {
    configuration_id: 1,
    data_server_url: 'https://scraper.oc.gamingeniusdev.com',
    // data_server_url: 'https://us-central1-oddscraft-test.cloudfunctions.net/sportmodel',
    // data_server_url: 'http://localhost:9090',
    // data_server_url: 'http://localhost:5001/oddscraft-test/us-central1/sportmodel',
    local_settings: true,
    odds_in_hockey_correct_score_row: 7,
    odds_in_hockey_period_correct_score_row: 5,
    odds_in_soccer_correct_score_row: 6,
    odds_in_soccer_period_correct_score_row: 5,
    tenant_id: 1,
    bookmaker_id: 0
}

export default config