import React from 'react'
import FaIcon from '../../FaIcon'
import InfoTooltip from '../../InfoTooltip'
import { roundOddsValue, getSportIcon, getOutcomeTextBetslip, capitalize, isSportWinner, getNameFromBetType} from '../../../../utils'
import { SportId, OutcomeId } from '../../../../state/enums'
import { MarketMakerSelection } from '../../../../state/stores/marketmaker/models'
import { BSBetrixSubitem } from '../../../../state/stores/betslip/models'
import './style.scss'
import BetrixScoreMatrix from '../../BetrixScoreMatrix'
import clsx from 'clsx'

interface Props {
    awayTeam: string
    homeTeam: string
    marketName: string
    oddsValue: string
    outcomeText: string
    sportId: SportId
    extendedClassName?: string
    handleExtended?: (extended: boolean) => void
    iconName?: string
    isAnyOtherResultHome?: boolean;
    isAnyOtherResultDraw?: boolean;
    isAnyOtherResultAway?: boolean;
    MMSelections?: Array<MarketMakerSelection>
    showOnlyMatch?: boolean
    subitems?: Array<BSBetrixSubitem>
    league?: string
    stage?: string
}

const BetslipItemData = ({ league, stage, awayTeam, extendedClassName, homeTeam, iconName, isAnyOtherResultHome, isAnyOtherResultDraw, isAnyOtherResultAway, marketName, MMSelections, oddsValue, outcomeText, showOnlyMatch, sportId, subitems, handleExtended }: Props) => {
    const subitemsAnyOtherDraw = subitems && subitems.length && subitems.find(s => s.homeGoals === -1 && s.awayGoals === -1)
    const subitemsAnyOtherHome = subitems && subitems.length && subitems.find(s => s.homeGoals === -2 && s.awayGoals === -2)
    const subitemsAnyOtherAway = subitems && subitems.length && subitems.find(s => s.homeGoals === -3 && s.awayGoals === -3)

    const anyOtherResultDescriptionHome: string = 'Any other result (Home win)'
    const anyOtherResultDescriptionDraw: string = 'Any other result (Draw)'
    const anyOtherResultDescriptionAway: string = 'Any other result (Away win)'
    
    return (
        <div className="BetslipItemData">
            {!showOnlyMatch &&
                <>
                    <div className="outcome-wrapper">
                        <span className="outcome-value">{outcomeText}</span>
                        {iconName &&
                            <FaIcon
                                icon={iconName}
                                type="solid"
                                onClick={handleExtended}
                            />
                        }
                        {isAnyOtherResultHome && anyOtherResultDescriptionHome.length && <InfoTooltip description={anyOtherResultDescriptionHome}/>}
                        {isAnyOtherResultDraw && anyOtherResultDescriptionDraw.length && <InfoTooltip description={anyOtherResultDescriptionDraw}/>}
                        {isAnyOtherResultAway && anyOtherResultDescriptionAway.length && <InfoTooltip description={anyOtherResultDescriptionAway}/>}
                        <span className="odds-value">{roundOddsValue(oddsValue, 2)}</span>
                    </div>
                    {(MMSelections && MMSelections.length) ?
                        <div className={`scores-wrapper ${extendedClassName}`}>
                            {MMSelections.map((selection, i) => {
                                const className: string = (selection.insured) ? 'insured' : 'selected'
                                const MMSelectionOutcomeText: string = selection.outcomeId === OutcomeId.CorrectScore && selection.betTypeId ? getNameFromBetType(selection.betTypeId) : getOutcomeTextBetslip(awayTeam, homeTeam, selection.outcomeId, selection.value, { betTypeId: selection.betTypeId })
                                return (
                                    <div
                                        key={i}
                                        className={className}
                                    >
                                        <span>{MMSelectionOutcomeText}</span>
                                        <span>{`(${capitalize(className)})`}</span>
                                    </div>
                                )
                            })}
                        </div>
                        : null}
                    {(subitems && subitems.length) ?
                        <div className={clsx('scores-wrapper', extendedClassName, subitems.length > 8 && 'with-matrix')}>
                            {subitems.length <= 8 ?
                                subitems.map((subitem, i) => {
                                    const { awayGoals, homeGoals, insured } = subitem

                                    const isAnyOtherDraw = homeGoals === - 1 && awayGoals === -1
                                    const isAnyOtherHome = homeGoals === - 2 && awayGoals === -2
                                    const isAnyOtherAway = homeGoals === - 3 && awayGoals === -3
                                     
                                    let selectionText = (isAnyOtherDraw) ? 'Any other result (Draw)' : (isAnyOtherHome) ? 'Any other result (Home win)' : (isAnyOtherAway) ? 'Any other result (Away win)' :`${homeGoals}:${awayGoals}`
                                    const insuredClassName: string = (insured) ? 'insured' : ''
                                    const titleText: string = (insured) ? 'Insured' : (isSportWinner(sportId) ? 'Winner' : 'Score')
                                    if (isSportWinner(sportId)) {
                                        selectionText = subitem.desc + (subitem.Desc ? ' (' + subitem.Desc + ')' : '')
                                    }
                                    return (
                                        <div
                                            key={i}
                                            className={insuredClassName}
                                        >
                                            <span>{titleText}</span>
                                            <span>{selectionText}</span>
                                            {isAnyOtherHome && anyOtherResultDescriptionHome.length && <InfoTooltip description={anyOtherResultDescriptionHome}/>}
                                            {isAnyOtherDraw && anyOtherResultDescriptionDraw.length && <InfoTooltip description={anyOtherResultDescriptionDraw}/>}
                                            {isAnyOtherAway && anyOtherResultDescriptionAway.length && <InfoTooltip description={anyOtherResultDescriptionAway}/>}
                                        </div>
                                    )
                                })
                            :
                                <>
                                    <BetrixScoreMatrix data={subitems}/>
                                    {subitemsAnyOtherHome &&
                                        <div className={clsx('subitems-any-other', subitemsAnyOtherHome.insured && 'insured')}>
                                            <span>&#43;</span>
                                            <span>Any Other Result (Home win)</span>
                                            <InfoTooltip description={anyOtherResultDescriptionHome}/>
                                        </div>
                                    }
                                    {subitemsAnyOtherDraw &&
                                        <div className={clsx('subitems-any-other', subitemsAnyOtherDraw.insured && 'insured')}>
                                            <span>&#43;</span>
                                            <span>Any Other Result (Draw)</span>
                                            <InfoTooltip description={anyOtherResultDescriptionDraw}/>
                                        </div>
                                    }
                                    {subitemsAnyOtherAway &&
                                        <div className={clsx('subitems-any-other', subitemsAnyOtherAway.insured && 'insured')}>
                                            <span>&#43;</span>
                                            <span>Any Other Result (Away win)</span>
                                            <InfoTooltip description={anyOtherResultDescriptionAway}/>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        : null}
                    <div className="market-wrapper">
                        <span>{isSportWinner(sportId) ? marketName : marketName.replace('{home}', homeTeam).replace('{away}', awayTeam)}</span>
                    </div>
                </>
            }
            <div className="match-wrapper">
                <img src={getSportIcon(sportId)} />
                {isSportWinner(sportId) ?
                <span style = {{marginLeft: '-16px'}}>{league ? league : ''} {stage ? stage : ''} {sportId === SportId.Cycling ? '' : awayTeam} {sportId === SportId.Cycling ? '' : 'HS' + homeTeam}</span>
                :
                <span title={`${homeTeam} vs ${awayTeam}`}>{`${homeTeam} vs ${awayTeam}`}</span>}
            </div>
        </div>
    )
}

export default BetslipItemData