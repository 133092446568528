import React from 'react'
import { roundOddsValue, isMobile } from '../../../../utils'
import './style.scss'

interface Props {
    activeStakeInput: number
    betValue: number
    oddsIndex: number
    stake: string
    handleStake: (value: string) => void
    setActiveStakeInput: (stake: number) => void
    currency: string
    min_bet: number
    max_bet: number
}

const StakeWrapper = ({ activeStakeInput, betValue, handleStake, oddsIndex, setActiveStakeInput, stake, currency, min_bet, max_bet }: Props) => {
    const activeClassName: string = (activeStakeInput === oddsIndex) ? 'active' : ''

    return (
        <div className="stake-wrapper">
            <div className="input-wrapper" style = {{position: 'relative'}}>
                <span title = "minimum bet" style = {{position: 'absolute', left: '-10px', top: '-13px', fontSize: '12px', color: '#BE0000', fontWeight: 700}}>{min_bet}{currency}</span>
                <span title = "maximum bet"style = {{position: 'absolute', right: '19px', top: '-13px', fontSize: '12px', color: '#BE0000', fontWeight: 700}}>{max_bet}{currency}</span>
                <input
                    className={`betslip-input ${activeClassName}`}
                    type="text"
                    value={stake}
                    readOnly={isMobile()}
                    onChange={(event) => handleStake(event.target.value)}
                    onFocus={() => isMobile() && setActiveStakeInput(oddsIndex)}
                />
                <span>{currency}</span>
            </div>
            <div className="bet-wrapper">
                <span>To Return: </span>
                &nbsp;
                <span>{`${roundOddsValue(betValue.toString(), 2)} ${currency}`}</span>
            </div>
        </div>
    )
}

export default StakeWrapper