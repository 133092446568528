import React from 'react';
import App from './views/app';
import dataServer from './network/rest';
import { render } from 'react-dom';
import { Provide, store } from './state';
import { Settings } from './state/stores/settings/models'
// dataServer.request('get', 'get-doublettes', {})


// dataServer.request('get', 'get-available-leagues')
// dataServer.request('get', 'get-available-matches')
// dataServer.request('get', 'get-available-sports')
// dataServer.request('get', 'get-main-market')
// dataServer.request('get', 'get-betrix-combo', {})
// dataServer.request('get', 'get-favourite-leagues', {})
// dataServer.request('get', 'get-multi-systems-betslip', {})
// dataServer.request('get', 'get-custom-betrix-combo', {})
// import { local_settings } from './config.json'
import config from './configjson'
if (config.local_settings) {
    const lookandfeelHockey: string = `
    {
        "defaultSport": 4,
        "marketGroups": [
            {
                "name": "Popular",
                "marketGroupItem": [
                    {
                        "items": [
                            {
                                "name": "1X2",
                                "outcomes": [
                                    {
                                        "id": 1,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 2,
                                        "name": "Draw"
                                    },
                                    {
                                        "id": 3,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 1
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Correct Score",
                                "outcomes": [
                                    {
                                        "id": 11,
                                        "name": "Yes"
                                    },
                                    {
                                        "id": 29,
                                        "name": ""
                                    }
                                ],
                                "bettypegroupid": 8
                            }
                        ]
                    },


                    {
                        "items": [
                            {
                                "name": "Total Goals",
                                "plus": true,
                                "nopush": true,
                                "outcomes": [
                                    {
                                        "id": 6,
                                        "name": "Over"
                                    },
                                    {
                                        "id": 7,
                                        "name": "Under"
                                    }
                                ],
                                "bettypegroupid": 2
                            },
                            {
                                "name": "Asian Total Goals",
                                "plus": true,
                                "nopush": false,
                                "outcomes": [
                                    {
                                        "id": 6,
                                        "name": "Over"
                                    },
                                    {
                                        "id": 7,
                                        "name": "Under"
                                    }
                                ],
                                "bettypegroupid": 2
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Handicap",
                                "plus": true,
                                "nopush": true,
                                "outcomes": [
                                    {
                                        "id": 74,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 75,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 5
                            },
                            {
                                "name": "Asian Handicap",
                                "plus": true,
                                "nopush": false,
                                "outcomes": [
                                    {
                                        "id": 74,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 75,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 5
                            }
                        ]
                    }
                ]
            }
        ]
    }`

    const lookandfeelSoccer: string = `
    {
        "defaultSport": 1,
        "marketGroups": [
            {
                "name": "Popular",
                "marketGroupItem": [
                    {
                        "items": [
                            {
                                "name": "1X2",
                                "outcomes": [
                                    {
                                        "id": 1,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 2,
                                        "name": "Draw"
                                    },
                                    {
                                        "id": 3,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 1
                            },
                            {
                                "name": "European Handicap",
                                "plus": true,
                                "outcomes": [
                                    {
                                        "id": 8,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 9,
                                        "name": "Draw"
                                    },
                                    {
                                        "id": 10,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 12
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Bet Against",
                                "outcomes": [
                                    {
                                        "id": 98,
                                        "name": "Against {home}"
                                    },
                                    {
                                        "id": 97,
                                        "name": "Against Draw"
                                    },
                                    {
                                        "id": 12,
                                        "name": "Against {away}"
                                    }
                                ],
                                "bettypegroupid": 64
                            },
                            {
                                "name": "Double Chance",
                                "outcomes": [
                                    {
                                        "id": 69,
                                        "name": "Home or Draw"
                                    },
                                    {
                                        "id": 70,
                                        "name": "Home or Away"
                                    },
                                    {
                                        "id": 71,
                                        "name": "Draw or Away"
                                    }
                                ],
                                "bettypegroupid": 4
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Both Teams to Score",
                                "outcomes": [
                                    {
                                        "id": 4,
                                        "name": "Yes"
                                    },
                                    {
                                        "id": 5,
                                        "name": "No"
                                    }
                                ],
                                "bettypegroupid": 13
                            },
                            {
                                "name": "Draw No Bet",
                                "outcomes": [
                                    {
                                        "id": 76,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 77,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 6
                            },
                            {
                                "name": "Odd/Even Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 80,
                                        "name": "Odd"
                                    },
                                    {
                                        "id": 81,
                                        "name": "Even"
                                    }
                                ],
                                "bettypegroupid": 10
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Total Goals",
                                "plus": true,
                                "nopush": true,
                                "outcomes": [
                                    {
                                        "id": 6,
                                        "name": "Over"
                                    },
                                    {
                                        "id": 7,
                                        "name": "Under"
                                    }
                                ],
                                "bettypegroupid": 2
                            },
                            {
                                "name": "Asian Total Goals",
                                "plus": true,
                                "nopush": false,
                                "outcomes": [
                                    {
                                        "id": 6,
                                        "name": "Over"
                                    },
                                    {
                                        "id": 7,
                                        "name": "Under"
                                    }
                                ],
                                "bettypegroupid": 2
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Handicap",
                                "plus": true,
                                "nopush": true,
                                "outcomes": [
                                    {
                                        "id": 74,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 75,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 5
                            },
                            {
                                "name": "Asian Handicap",
                                "plus": true,
                                "nopush": false,
                                "outcomes": [
                                    {
                                        "id": 74,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 75,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 5
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Correct Score",
                                "outcomes": [
                                    {
                                        "id": 11,
                                        "name": "Yes"
                                    },
                                    {
                                        "id": 29,
                                        "name": ""
                                    }
                                ],
                                "bettypegroupid": 8
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 108,
                                        "name": "{home} 3+"
                                    },
                                    {
                                        "id": 109,
                                        "name": "{home} 2"
                                    },
                                    {
                                        "id": 110,
                                        "name": "{home} 1"
                                    },
                                    {
                                        "id": 111,
                                        "name": "Draw (0)"
                                    },
                                    {
                                        "id": 112,
                                        "name": "{away} +1"
                                    },
                                    {
                                        "id": 113,
                                        "name": "{away} +2"
                                    },
                                    {
                                        "id": 114,
                                        "name": "{away} 3+"
                                    }
                                ],
                                "bettypegroupid": 33
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 135,
                                        "name": "{home} 4+"
                                    },
                                    {
                                        "id": 136,
                                        "name": "{home} 3"
                                    },
                                    {
                                        "id": 137,
                                        "name": "{home} 2"
                                    },
                                    {
                                        "id": 138,
                                        "name": "{home} 1"
                                    },
                                    {
                                        "id": 139,
                                        "name": "Draw (0)"
                                    },
                                    {
                                        "id": 140,
                                        "name": "{away} -1"
                                    },
                                    {
                                        "id": 141,
                                        "name": "{away} -2+"
                                    }
                                ],
                                "bettypegroupid": 38
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 142,
                                        "name": "{home} 2+"
                                    },
                                    {
                                        "id": 143,
                                        "name": "{home} 1"
                                    },
                                    {
                                        "id": 144,
                                        "name": "Draw (0)"
                                    },
                                    {
                                        "id": 145,
                                        "name": "{away} -1"
                                    },
                                    {
                                        "id": 146,
                                        "name": "{away} -2"
                                    },
                                    {
                                        "id": 147,
                                        "name": "{away} -3"
                                    },
                                    {
                                        "id": 148,
                                        "name": "{away} -4+"
                                    }
                                ],
                                "bettypegroupid": 39
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 149,
                                        "name": "{home} 5+"
                                    },
                                    {
                                        "id": 150,
                                        "name": "{home} 4"
                                    },
                                    {
                                        "id": 151,
                                        "name": "{home} 3"
                                    },
                                    {
                                        "id": 152,
                                        "name": "{home} 2"
                                    },
                                    {
                                        "id": 153,
                                        "name": "{home} 1"
                                    },
                                    {
                                        "id": 154,
                                        "name": "Draw (0)"
                                    },
                                    {
                                        "id": 155,
                                        "name": "{away} -1+"
                                    }
                                ],
                                "bettypegroupid": 40
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 156,
                                        "name": "{home} 1+"
                                    },
                                    {
                                        "id": 157,
                                        "name": "Draw (0)"
                                    },
                                    {
                                        "id": 158,
                                        "name": "{away} -1"
                                    },
                                    {
                                        "id": 159,
                                        "name": "{away} -2"
                                    },
                                    {
                                        "id": 160,
                                        "name": "{away} -3"
                                    },
                                    {
                                        "id": 161,
                                        "name": "{away} -4"
                                    },
                                    {
                                        "id": 162,
                                        "name": "{away} -5+"
                                    }
                                ],
                                "bettypegroupid": 41
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 295,
                                        "name": "{home} 6+"
                                    },
                                    {
                                        "id": 296,
                                        "name": "{home} 5"
                                    },
                                    {
                                        "id": 297,
                                        "name": "{home} 4"
                                    },
                                    {
                                        "id": 298,
                                        "name": "{home} 3"
                                    },
                                    {
                                        "id": 299,
                                        "name": "{home} 2"
                                    },
                                    {
                                        "id": 300,
                                        "name": "{home} 1"
                                    },
                                    {
                                        "id": 301,
                                        "name": "{home} 0-"
                                    }
                                ],
                                "bettypegroupid": 70
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 302,
                                        "name": "{away} 0+"
                                    },
                                    {
                                        "id": 303,
                                        "name": "{away} -1"
                                    },
                                    {
                                        "id": 304,
                                        "name": "{away} -2"
                                    },
                                    {
                                        "id": 305,
                                        "name": "{away} -3"
                                    },
                                    {
                                        "id": 306,
                                        "name": "{away} -4"
                                    },
                                    {
                                        "id": 307,
                                        "name": "{away} -5"
                                    },
                                    {
                                        "id": 308,
                                        "name": "{away} -6+"
                                    }
                                ],
                                "bettypegroupid": 71
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 309,
                                        "name": "{home} 7+"
                                    },
                                    {
                                        "id": 310,
                                        "name": "{home} 6"
                                    },
                                    {
                                        "id": 311,
                                        "name": "{home} 5"
                                    },
                                    {
                                        "id": 312,
                                        "name": "{home} 4"
                                    },
                                    {
                                        "id": 313,
                                        "name": "{home} 3"
                                    },
                                    {
                                        "id": 314,
                                        "name": "{home} 2"
                                    },
                                    {
                                        "id": 315,
                                        "name": "{home} 1-"
                                    }
                                ],
                                "bettypegroupid": 72
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Win Margin",
                                "outcomes": [
                                    {
                                        "id": 316,
                                        "name": "{away} -1+"
                                    },
                                    {
                                        "id": 317,
                                        "name": "{away} -2"
                                    },
                                    {
                                        "id": 318,
                                        "name": "{away} -3"
                                    },
                                    {
                                        "id": 319,
                                        "name": "{away} -4"
                                    },
                                    {
                                        "id": 320,
                                        "name": "{away} -5"
                                    },
                                    {
                                        "id": 321,
                                        "name": "{away} -6"
                                    },
                                    {
                                        "id": 322,
                                        "name": "{away} -7+"
                                    }
                                ],
                                "bettypegroupid": 73
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Half markets",
                "marketGroupItem": [
                    {
                        "items": [
                            {
                                "name": "Half Time/Full Time",
                                "outcomes": [
                                    {
                                        "id": 251,
                                        "name": "1/1"
                                    },
                                    {
                                        "id": 252,
                                        "name": "X/1"
                                    },
                                    {
                                        "id": 253,
                                        "name": "2/1"
                                    },
                                    {
                                        "id": 254,
                                        "name": "1/X"
                                    },
                                    {
                                        "id": 255,
                                        "name": "X/X"
                                    },
                                    {
                                        "id": 256,
                                        "name": "2/X"
                                    },
                                    {
                                        "id": 257,
                                        "name": "1/2"
                                    },
                                    {
                                        "id": 258,
                                        "name": "X/2"
                                    },
                                    {
                                        "id": 259,
                                        "name": "2/2"
                                    }
                                ],
                                "bettypegroupid": 9
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "HT/FT with hcp",
                                "outcomes": [
                                    {
                                        "id": 243,
                                        "name": "1/1"
                                    },
                                    {
                                        "id": 244,
                                        "name": "X2/1"
                                    },
                                    {
                                        "id": 245,
                                        "name": "1/X2"
                                    },
                                    {
                                        "id": 246,
                                        "name": "X2/X2"
                                    }
                                ],
                                "bettypegroupid": 29
                            },
                            {
                                "name": "HT/FT with hcp",
                                "outcomes": [
                                    {
                                        "id": 247,
                                        "name": "1X/1X"
                                    },
                                    {
                                        "id": 248,
                                        "name": "2/1X"
                                    },
                                    {
                                        "id": 249,
                                        "name": "1X/2"
                                    },
                                    {
                                        "id": 250,
                                        "name": "2/2"
                                    }
                                ],
                                "bettypegroupid": 30
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "First Half/Second Half",
                                "outcomes": [
                                    {
                                        "id": 231,
                                        "name": "1/1"
                                    },
                                    {
                                        "id": 232,
                                        "name": "X/1"
                                    },
                                    {
                                        "id": 233,
                                        "name": "2/1"
                                    },
                                    {
                                        "id": 234,
                                        "name": "1/X"
                                    },
                                    {
                                        "id": 235,
                                        "name": "X/X"
                                    },
                                    {
                                        "id": 236,
                                        "name": "2/X"
                                    },
                                    {
                                        "id": 237,
                                        "name": "1/2"
                                    },
                                    {
                                        "id": 238,
                                        "name": "X/2"
                                    },
                                    {
                                        "id": 239,
                                        "name": "2/2"
                                    }
                                ],
                                "bettypegroupid": 62
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Half Time or Full Time",
                                "outcomes": [
                                    {
                                        "id": 228,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 229,
                                        "name": "Draw"
                                    },
                                    {
                                        "id": 230,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 61
                            },
                            {
                                "name": "First Half or Second Half",
                                "outcomes": [
                                    {
                                        "id": 240,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 241,
                                        "name": "Draw"
                                    },
                                    {
                                        "id": 242,
                                        "name": "{away}"
                                    }
                                ],
                                "bettypegroupid": 63
                            },
                            {
                                "name": "Highest Scoring Half",
                                "outcomes": [
                                    {
                                        "id": 40,
                                        "name": "First"
                                    },
                                    {
                                        "id": 41,
                                        "name": "Second"
                                    },
                                    {
                                        "id": 42,
                                        "name": "Tie"
                                    }
                                ],
                                "bettypegroupid": 19
                            }
                        ]
                    }
                ],
                "multiOutcomeMarkets": true
            },
            {
                "name": "Goal markets",
                "marketGroupItem": [
                    {
                        "items": [
                            {
                                "name": "Time of First Goal",
                                "outcomes": [
                                    {
                                        "id": 50,
                                        "name": "0' - 10'"
                                    },
                                    {
                                        "id": 51,
                                        "name": "11' - 20'"
                                    },
                                    {
                                        "id": 52,
                                        "name": "21' - 30'"
                                    },
                                    {
                                        "id": 53,
                                        "name": "31' - 40'"
                                    },
                                    {
                                        "id": 54,
                                        "name": "41' - 50'"
                                    },
                                    {
                                        "id": 55,
                                        "name": "51' - 60'"
                                    },
                                    {
                                        "id": 56,
                                        "name": "61' - 70'"
                                    },
                                    {
                                        "id": 57,
                                        "name": "71' - 80'"
                                    },
                                    {
                                        "id": 58,
                                        "name": "81'+"
                                    },
                                    {
                                        "id": 260,
                                        "name": "None"
                                    }
                                ],
                                "bettypegroupid": 22
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Time of First Goal (v2)",
                                "outcomes": [
                                    {
                                        "id": 88,
                                        "name": "0' - 15'"
                                    },
                                    {
                                        "id": 89,
                                        "name": "16' - 30'"
                                    },
                                    {
                                        "id": 90,
                                        "name": "31' - 45'"
                                    },
                                    {
                                        "id": 91,
                                        "name": "46' - 60'"
                                    },
                                    {
                                        "id": 92,
                                        "name": "61' - 75'"
                                    },
                                    {
                                        "id": 93,
                                        "name": "76'+"
                                    },
                                    {
                                        "id": 261,
                                        "name": "None"
                                    }
                                ],
                                "bettypegroupid": 27
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Time of First Goal (v3)",
                                "outcomes": [
                                    {
                                        "id": 94,
                                        "name": "0' - 30'"
                                    },
                                    {
                                        "id": 95,
                                        "name": "31' - 60'"
                                    },
                                    {
                                        "id": 96,
                                        "name": "61'+"
                                    },
                                    {
                                        "id": 262,
                                        "name": "No goal"
                                    }
                                ],
                                "bettypegroupid": 28
                            },
                            {
                                "name": "First Two Goals",
                                "outcomes": [
                                    {
                                        "id": 65,
                                        "name": "Both"
                                    },
                                    {
                                        "id": 66,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 67,
                                        "name": "{away}"
                                    },
                                    {
                                        "id": 68,
                                        "name": "Under 2 goals"
                                    }
                                ],
                                "bettypegroupid": 25
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Total Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 22,
                                        "name": "No goals"
                                    },
                                    {
                                        "id": 23,
                                        "name": "1 goal"
                                    },
                                    {
                                        "id": 24,
                                        "name": "2 goals"
                                    },
                                    {
                                        "id": 25,
                                        "name": "3 goals"
                                    },
                                    {
                                        "id": 26,
                                        "name": "4 goals"
                                    },
                                    {
                                        "id": 27,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 28,
                                        "name": "6+ goals"
                                    }
                                ],
                                "bettypegroupid": 15
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Total Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 267,
                                        "name": "0-1 goals"
                                    },
                                    {
                                        "id": 268,
                                        "name": "2 goals"
                                    },
                                    {
                                        "id": 269,
                                        "name": "3 goals"
                                    },
                                    {
                                        "id": 270,
                                        "name": "4 goals"
                                    },
                                    {
                                        "id": 271,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 272,
                                        "name": "6 goals"
                                    },
                                    {
                                        "id": 273,
                                        "name": "7+ goals"
                                    }
                                ],
                                "bettypegroupid": 66
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Total Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 274,
                                        "name": "0-2 goals"
                                    },
                                    {
                                        "id": 275,
                                        "name": "3 goals"
                                    },
                                    {
                                        "id": 276,
                                        "name": "4 goals"
                                    },
                                    {
                                        "id": 277,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 278,
                                        "name": "6 goals"
                                    },
                                    {
                                        "id": 279,
                                        "name": "7 goals"
                                    },
                                    {
                                        "id": 280,
                                        "name": "8+ goals"
                                    }
                                ],
                                "bettypegroupid": 67
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Total Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 281,
                                        "name": "0-3 goals"
                                    },
                                    {
                                        "id": 282,
                                        "name": "4 goals"
                                    },
                                    {
                                        "id": 283,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 284,
                                        "name": "6 goals"
                                    },
                                    {
                                        "id": 285,
                                        "name": "7 goals"
                                    },
                                    {
                                        "id": 286,
                                        "name": "8 goals"
                                    },
                                    {
                                        "id": 287,
                                        "name": "9+ goals"
                                    }
                                ],
                                "bettypegroupid": 68
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Total Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 288,
                                        "name": "0-4 goals"
                                    },
                                    {
                                        "id": 289,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 290,
                                        "name": "6 goals"
                                    },
                                    {
                                        "id": 291,
                                        "name": "7 goals"
                                    },
                                    {
                                        "id": 292,
                                        "name": "8 goals"
                                    },
                                    {
                                        "id": 293,
                                        "name": "9 goals"
                                    },
                                    {
                                        "id": 294,
                                        "name": "10+ goals"
                                    }
                                ],
                                "bettypegroupid": 69
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Total Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 339,
                                        "name": "0-5 goals"
                                    },
                                    {
                                        "id": 340,
                                        "name": "6 goals"
                                    },
                                    {
                                        "id": 341,
                                        "name": "7 goals"
                                    },
                                    {
                                        "id": 342,
                                        "name": "8 goals"
                                    },
                                    {
                                        "id": 343,
                                        "name": "9 goals"
                                    },
                                    {
                                        "id": 344,
                                        "name": "10 goals"
                                    },
                                    {
                                        "id": 345,
                                        "name": "11+ goals"
                                    }
                                ],
                                "bettypegroupid": 78
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Total Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 346,
                                        "name": "0-6 goals"
                                    },
                                    {
                                        "id": 347,
                                        "name": "7 goals"
                                    },
                                    {
                                        "id": 348,
                                        "name": "8 goals"
                                    },
                                    {
                                        "id": 349,
                                        "name": "9 goals"
                                    },
                                    {
                                        "id": 350,
                                        "name": "10 goals"
                                    },
                                    {
                                        "id": 351,
                                        "name": "11 goals"
                                    },
                                    {
                                        "id": 352,
                                        "name": "12+ goals"
                                    }
                                ],
                                "bettypegroupid": 79
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Number of Goals",
                                "outcomes": [
                                    {
                                        "id": 30,
                                        "name": "0 or 1"
                                    },
                                    {
                                        "id": 31,
                                        "name": "2 or 3"
                                    },
                                    {
                                        "id": 32,
                                        "name": "4 or more"
                                    }
                                ],
                                "bettypegroupid": 16
                            },
                            {
                                "name": "Team First to Score",
                                "market": {
                                    "id": 27
                                },
                                "outcomes": [
                                    {
                                        "id": 37,
                                        "name": "{home}"
                                    },
                                    {
                                        "id": 38,
                                        "name": "{away}"
                                    },
                                    {
                                        "id": 39,
                                        "name": "None"
                                    }
                                ],
                                "bettypegroupid": 18
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "Both Teams to Score",
                                "outcomes": [
                                    {
                                        "id": 4,
                                        "name": "Yes"
                                    },
                                    {
                                        "id": 5,
                                        "name": "No"
                                    }
                                ],
                                "bettypegroupid": 13
                            },
                            {
                                "name": "Teams to Score",
                                "outcomes": [
                                    {
                                        "id": 33,
                                        "name": "Both"
                                    },
                                    {
                                        "id": 34,
                                        "name": "Home only"
                                    },
                                    {
                                        "id": 35,
                                        "name": "Away only"
                                    },
                                    {
                                        "id": 36,
                                        "name": "None"
                                    }
                                ],
                                "bettypegroupid": 17
                            }
                        ]
                    }
                ],
                "multiOutcomeMarkets": true
            },
            {
                "name": "Team markets",
                "marketGroupItem": [
                    {
                        "items": [
                            {
                                "name": "{home} Total Goals",
                                "plus": true,
                                "nopush": true,
                                "outcomes": [
                                    {
                                        "id": 59,
                                        "name": "Over"
                                    },
                                    {
                                        "id": 60,
                                        "name": "Under"
                                    },
                                    {
                                        "id": 61,
                                        "name": ""
                                    },
                                    {
                                        "id": 62,
                                        "name": ""
                                    }
                                ],
                                "bettypegroupid": 23
                            },
                            {
                                "name": "{away} Total Goals",
                                "plus": true,
                                "nopush": true,
                                "outcomes": [
                                    {
                                        "id": 59,
                                        "name": ""
                                    },
                                    {
                                        "id": 60,
                                        "name": ""
                                    },
                                    {
                                        "id": 61,
                                        "name": "Over"
                                    },
                                    {
                                        "id": 62,
                                        "name": "Under"
                                    }
                                ],
                                "bettypegroupid": 23
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "{home} Number of Goals",
                                "market": {
                                    "id": 358
                                },
                                "outcomes": [
                                    {
                                        "id": 105,
                                        "name": "0 or 1"
                                    },
                                    {
                                        "id": 106,
                                        "name": "2 or 3"
                                    },
                                    {
                                        "id": 107,
                                        "name": "4 or more"
                                    }
                                ],
                                "bettypegroupid": 32
                            },
                            {
                                "name": "{home} Exact Number of Goals",
                                "market": {
                                    "id": 21
                                },
                                "outcomes": [
                                    {
                                        "id": 46,
                                        "name": "No goals"
                                    },
                                    {
                                        "id": 47,
                                        "name": "1 goal"
                                    },
                                    {
                                        "id": 48,
                                        "name": "2 goals"
                                    },
                                    {
                                        "id": 49,
                                        "name": "3+ goals"
                                    }
                                ],
                                "bettypegroupid": 21
                            },
                            {
                                "name": "{home} Exact Number of Goals",
                                "market": {
                                    "id": 2114
                                },
                                "outcomes": [
                                    {
                                        "id": 323,
                                        "name": "0-1 goals"
                                    },
                                    {
                                        "id": 324,
                                        "name": "2 goals"
                                    },
                                    {
                                        "id": 325,
                                        "name": "3 goals"
                                    },
                                    {
                                        "id": 326,
                                        "name": "4+ goals"
                                    }
                                ],
                                "bettypegroupid": 74
                            },
                            {
                                "name": "{home} Exact Number of Goals",
                                "market": {
                                    "id": 2116
                                },
                                "outcomes": [
                                    {
                                        "id": 327,
                                        "name": "0-2 goals"
                                    },
                                    {
                                        "id": 328,
                                        "name": "3 goals"
                                    },
                                    {
                                        "id": 329,
                                        "name": "4 goals"
                                    },
                                    {
                                        "id": 330,
                                        "name": "5+ goals"
                                    }
                                ],
                                "bettypegroupid": 75
                            },
                            {
                                "name": "{home} Exact Number of Goals",
                                "market": {
                                    "id": 2118
                                },
                                "outcomes": [
                                    {
                                        "id": 331,
                                        "name": "0-3 goals"
                                    },
                                    {
                                        "id": 332,
                                        "name": "4 goals"
                                    },
                                    {
                                        "id": 333,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 334,
                                        "name": "6+ goals"
                                    }
                                ],
                                "bettypegroupid": 76
                            },
                            {
                                "name": "{home} Exact Number of Goals",
                                "market": {
                                    "id": 2120
                                },
                                "outcomes": [
                                    {
                                        "id": 335,
                                        "name": "0-4 goals"
                                    },
                                    {
                                        "id": 336,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 337,
                                        "name": "6 goals"
                                    },
                                    {
                                        "id": 338,
                                        "name": "7+ goals"
                                    }
                                ],
                                "bettypegroupid": 77
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "{home} Time of First Goal",
                                "market": {
                                    "id": 1591
                                },
                                "outcomes": [
                                    {
                                        "id": 263,
                                        "name": "0' - 30'"
                                    },
                                    {
                                        "id": 264,
                                        "name": "31' - 60'"
                                    },
                                    {
                                        "id": 265,
                                        "name": "61'+"
                                    },
                                    {
                                        "id": 266,
                                        "name": "None"
                                    }
                                ],
                                "bettypegroupid": 65
                            },
                            {
                                "name": "{home} Highest Scoring Half",
                                "market": {
                                    "id": 8
                                },
                                "outcomes": [
                                    {
                                        "id": 43,
                                        "name": "First"
                                    },
                                    {
                                        "id": 44,
                                        "name": "Second"
                                    },
                                    {
                                        "id": 45,
                                        "name": "Tie"
                                    }
                                ],
                                "bettypegroupid": 20
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "{home} Highest Scoring Half (v2)",
                                "market": {
                                    "id": 41
                                },
                                "outcomes": [
                                    {
                                        "id": 99,
                                        "name": "First & Yes"
                                    },
                                    {
                                        "id": 100,
                                        "name": "First & No"
                                    },
                                    {
                                        "id": 101,
                                        "name": "Second & Yes"
                                    },
                                    {
                                        "id": 102,
                                        "name": "Second & No"
                                    },
                                    {
                                        "id": 103,
                                        "name": "Tie & Yes"
                                    },
                                    {
                                        "id": 104,
                                        "name": "Tie & No"
                                    }
                                ],
                                "bettypegroupid": 31
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "{away} Number of Goals",
                                "market": {
                                    "id": 359
                                },
                                "outcomes": [
                                    {
                                        "id": 105,
                                        "name": "0 or 1"
                                    },
                                    {
                                        "id": 106,
                                        "name": "2 or 3"
                                    },
                                    {
                                        "id": 107,
                                        "name": "4 or more"
                                    }
                                ],
                                "bettypegroupid": 32
                            },
                            {
                                "name": "{away} Exact Number of Goals",
                                "market": {
                                    "id": 22
                                },
                                "outcomes": [
                                    {
                                        "id": 46,
                                        "name": "No goals"
                                    },
                                    {
                                        "id": 47,
                                        "name": "1 goal"
                                    },
                                    {
                                        "id": 48,
                                        "name": "2 goals"
                                    },
                                    {
                                        "id": 49,
                                        "name": "3+ goals"
                                    }
                                ],
                                "bettypegroupid": 21
                            },
                            {
                                "name": "{away} Exact Number of Goals",
                                "market": {
                                    "id": 2115
                                },
                                "outcomes": [
                                    {
                                        "id": 323,
                                        "name": "0-1 goals"
                                    },
                                    {
                                        "id": 324,
                                        "name": "2 goals"
                                    },
                                    {
                                        "id": 325,
                                        "name": "3 goals"
                                    },
                                    {
                                        "id": 326,
                                        "name": "4+ goals"
                                    }
                                ],
                                "bettypegroupid": 74
                            },
                            {
                                "name": "{away} Exact Number of Goals",
                                "market": {
                                    "id": 2117
                                },
                                "outcomes": [
                                    {
                                        "id": 327,
                                        "name": "0-2 goals"
                                    },
                                    {
                                        "id": 328,
                                        "name": "3 goals"
                                    },
                                    {
                                        "id": 329,
                                        "name": "4 goals"
                                    },
                                    {
                                        "id": 330,
                                        "name": "5+ goals"
                                    }
                                ],
                                "bettypegroupid": 75
                            },
                            {
                                "name": "{away} Exact Number of Goals",
                                "market": {
                                    "id": 2119
                                },
                                "outcomes": [
                                    {
                                        "id": 331,
                                        "name": "0-3 goals"
                                    },
                                    {
                                        "id": 332,
                                        "name": "4 goals"
                                    },
                                    {
                                        "id": 333,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 334,
                                        "name": "6+ goals"
                                    }
                                ],
                                "bettypegroupid": 76
                            },
                            {
                                "name": "{away} Exact Number of Goals",
                                "market": {
                                    "id": 2121
                                },
                                "outcomes": [
                                    {
                                        "id": 335,
                                        "name": "0-4 goals"
                                    },
                                    {
                                        "id": 336,
                                        "name": "5 goals"
                                    },
                                    {
                                        "id": 337,
                                        "name": "6 goals"
                                    },
                                    {
                                        "id": 338,
                                        "name": "7+ goals"
                                    }
                                ],
                                "bettypegroupid": 77
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "{away} Time of First Goal",
                                "market": {
                                    "id": 1592
                                },
                                "outcomes": [
                                    {
                                        "id": 263,
                                        "name": "0' - 30'"
                                    },
                                    {
                                        "id": 264,
                                        "name": "31' - 60'"
                                    },
                                    {
                                        "id": 265,
                                        "name": "61'+"
                                    },
                                    {
                                        "id": 266,
                                        "name": "None"
                                    }
                                ],
                                "bettypegroupid": 65
                            },
                            {
                                "name": "{away} Highest Scoring Half",
                                "market": {
                                    "id": 9
                                },
                                "outcomes": [
                                    {
                                        "id": 43,
                                        "name": "First"
                                    },
                                    {
                                        "id": 44,
                                        "name": "Second"
                                    },
                                    {
                                        "id": 45,
                                        "name": "Tie"
                                    }
                                ],
                                "bettypegroupid": 20
                            }
                        ]
                    },
                    {
                        "items": [
                            {
                                "name": "{away} Highest Scoring Half (v2)",
                                "market": {
                                    "id": 42
                                },
                                "outcomes": [
                                    {
                                        "id": 99,
                                        "name": "First & Yes"
                                    },
                                    {
                                        "id": 100,
                                        "name": "First & No"
                                    },
                                    {
                                        "id": 101,
                                        "name": "Second & Yes"
                                    },
                                    {
                                        "id": 102,
                                        "name": "Second & No"
                                    },
                                    {
                                        "id": 103,
                                        "name": "Tie & Yes"
                                    },
                                    {
                                        "id": 104,
                                        "name": "Tie & No"
                                    }
                                ],
                                "bettypegroupid": 31
                            }
                        ]
                    }
                ]
            }
        ],
        "defaultMarketGroup": 0,
        "showInsurancesInWebsite": true,
        "showMarketMakerInBetrix": false
    }`

    store.update(main => {
        main.settingsSoccer = JSON.parse(lookandfeelSoccer) as Settings
        main.settingsHockey = JSON.parse(lookandfeelHockey) as Settings
        return main
    })
} else {
    dataServer.request('get', 'get-look-and-feel', {})
}
const rootElement = document.getElementById('root') as HTMLDivElement;
const app = (
    <Provide>
        <App/>
    </Provide>
)

// if (rootElement.hasChildNodes()) {
//     hydrate(app, rootElement);
// } else {
//     render(app, rootElement);
// }

render(app, rootElement)