import React, { useEffect } from 'react'
import BetslipItemData from '../../BetslipItemData'
import FaIcon from '../../../../components/FaIcon'
import MultiSystemsPayout from '../../../MultiSystemsPayout'
import { getAllowedBetrixComboItems, getTeamNameFromOutcome, getMarketNameAndOptions, getOutcomeText, saveBSMultiSystemsItems, makeMultiSystemsRequest } from '../../../../../utils'
import { BSMultiSystemsItem, BSStandardItem, BSBetrixItem } from '../../../../../state/stores/betslip/models'
import { MultiSystemsData, BetrixComboSettingsItem } from '../../../../../state/stores/betrix-combo/models'
import { BetTypeGroupId, MatrixValue, OutcomeId } from '../../../../../state/enums'
import { store } from '../../../../../state'
import './style.scss'

interface Props {
    betrixItems: Array<BSBetrixItem>
    betslipItems: Array<BSMultiSystemsItem>
    data: Array<MultiSystemsData>
    payout: Array<MatrixValue>
    settings: Array<BetrixComboSettingsItem>
    standardItems: Array<BSStandardItem>
}

const BetrixComboMultiSystems = ({ betrixItems, betslipItems, data, payout, settings, standardItems }: Props) => {    
    const allowedStandardItems = getAllowedBetrixComboItems(standardItems, settings)
    const allowedBetrixItems = getAllowedBetrixItems(betrixItems)
    const allItemsLength = allowedBetrixItems.length + allowedStandardItems.length
    const standardItemsChanged = (allowedStandardItems.length) ? checkStandardItemsChange(standardItems, betslipItems) : false
    const betrixSubitemsChanged = (allowedBetrixItems.length) ? checkBetrixSubitemsChange(betrixItems, betslipItems) : false

    useEffect(() => {
        if (allItemsLength && (allItemsLength !== betslipItems.length || betrixSubitemsChanged || standardItemsChanged)) {
            const newItems = saveBSMultiSystemsItems(allowedBetrixItems, allowedStandardItems)
            makeMultiSystemsRequest(newItems, true)
        }
    })

    return (
        <div className="BetrixComboMultiSystems">
            {(allItemsLength > 0) ?
                <div className="items-container">
                    {allowedBetrixItems.map((item, index) => {
                        const { awayTeam, homeTeam, oddsValue, sportId, subitems } = item
                        const extendedIconName = (betslipItems[index] && betslipItems[index].opened) ? 'chevron-up' : 'chevron-down'
                        const extendedClassName = (betslipItems[index] && betslipItems[index].opened) ? 'extended' : ''
                        const overlayClassName = (betslipItems[index] && betslipItems[index].disabled) ? 'active' : ''
                        const disabledIconName = (betslipItems[index] && betslipItems[index].disabled) ? 'times' : 'check'
                        
                        return (
                            <div key={index} className="item-wrapper">
                                <BetslipItemData
                                    awayTeam={awayTeam}
                                    extendedClassName={extendedClassName}
                                    homeTeam={homeTeam}
                                    iconName={extendedIconName}
                                    marketName="Correct Score (Full Time)"
                                    oddsValue={oddsValue}
                                    outcomeText="Multi Selection"
                                    sportId={sportId}
                                    subitems={subitems}
                                    handleExtended={() => handleItemOpened(betslipItems, index)}
                                />
                                <FaIcon
                                    icon={disabledIconName}
                                    type="solid"
                                    onClick={() => disableItem(betslipItems, index)}
                                />
                                <div className={`disabled-overlay ${overlayClassName}`} />
                            </div>
                        )
                    })}
                    {allowedStandardItems.map((item, index) => {
                        const { awayTeam, betTypeGroupId, homeTeam, MMSelections, oddsValue, outcomeId, outcomeValue, sportId} = item
                        const teamName: string = getTeamNameFromOutcome(awayTeam, homeTeam, outcomeId)
                        const { name } = getMarketNameAndOptions(BetTypeGroupId[betTypeGroupId], sportId, { teamName })
                        const outcomeText: string = getOutcomeText(awayTeam, homeTeam, outcomeId, outcomeValue)
                        const indexToCheck = index + allowedBetrixItems.length
                        const iconName = (MMSelections) ? ((betslipItems[indexToCheck] && betslipItems[indexToCheck].opened) ? 'chevron-up' : 'chevron-down') : ''
                        const extendedClassName = (MMSelections) ? ((betslipItems[indexToCheck] && betslipItems[indexToCheck].opened) ? 'extended' : '') : ''
                        const overlayClassName = (betslipItems[indexToCheck] && betslipItems[indexToCheck].disabled) ? 'active' : ''
                        const disabledIconName = (betslipItems[indexToCheck] && betslipItems[indexToCheck].disabled) ? 'times' : 'check'

                        
                        return (
                            <div key={index} className="item-wrapper">
                                <BetslipItemData
                                    awayTeam={awayTeam}
                                    extendedClassName={extendedClassName}
                                    homeTeam={homeTeam}
                                    iconName={iconName}
                                    marketName={name}
                                    MMSelections={MMSelections}
                                    oddsValue={oddsValue}
                                    outcomeText={outcomeText}
                                    sportId={sportId}
                                    handleExtended={() => MMSelections && handleItemOpened(betslipItems, index)}
                                />
                                <FaIcon
                                    icon={disabledIconName}
                                    type="solid"
                                    onClick={() => disableItem(betslipItems, indexToCheck)}
                                />
                                <div className={`disabled-overlay ${overlayClassName}`} />
                            </div>
                        )
                    })}
                    <MultiSystemsPayout
                        data={data}
                        forBetslip={true}
                        payout={payout}
                    />
                </div>
            :
                <span className="empty-betslip">No valid matches for Multi Systems calculations.</span>
            }
        </div>
    )
}

export default BetrixComboMultiSystems

const checkBetrixSubitemsChange = (betrix: Array<BSBetrixItem>, betslip: Array<BSMultiSystemsItem>): boolean => {
    for (const betrixItem of betrix) {
        const itemFound = betslip.find(b => b.matchId === betrixItem.matchId)
        if (itemFound && itemFound.subitems && betrixItem.subitems) {
            if (itemFound.subitems.length === betrixItem.subitems.length) {
                if (itemFound.outcomeId !== betrixItem.outcomeId) {
                    return true
                }
                return false
            } else {
                return true
            }
        }
    }
    
    return false
}

const checkStandardItemsChange = (standard: Array<BSStandardItem>, betslip: Array<BSMultiSystemsItem>): boolean => {
    for (const standardItem of standard) {
        const itemFound = betslip.find(b => b.matchId === standardItem.matchId)
        if (itemFound && itemFound.outcomeId !== standardItem.outcomeId) {
            return true
        }
    }
    
    return false
}

const disableItem = (items: Array<BSMultiSystemsItem>, index: number) => {
    const newItems = items

    if (newItems[index]) {
        if (newItems[index].disabled) {
            newItems[index].disabled = false
        } else if (!newItems[index].disabled && newItems.filter(n => !n.disabled).length > 1) {
            newItems[index].disabled = true
        }

        makeMultiSystemsRequest(newItems, true)

        store.update(main => {
            main.betslip.betrixCombo.multiSystems = newItems
            return main
        })
    }
}

const getAllowedBetrixItems = (items: Array<BSBetrixItem>): Array<BSBetrixItem> => {
    const allowedBetrixItems: Array<BSBetrixItem> = items.filter(i =>
        (i.outcomeId === OutcomeId.BetrixYes || i.outcomeId === OutcomeId.BetrixNo || i.outcomeId === OutcomeId.BetrixOut ||
        i.outcomeId === OutcomeId.BetrixYesNo || i.outcomeId === OutcomeId.BetrixYesOut || i.outcomeId === OutcomeId.BetrixNoOut) &&
        !i.subitems.map(s => s.insured).filter(Boolean).length
    )

    return allowedBetrixItems
}

const handleItemOpened = (betslipItems: Array<BSMultiSystemsItem>, index: number) => {
    const newItems = betslipItems

    newItems[index].opened = !newItems[index].opened

    store.update(main => {
        main.betslip.betrixCombo.multiSystems = newItems
        return main
    })
}