import React from 'react'
import './style.scss'

interface Props {
    data: any[]
    headerItems: string[]
    noDataMessage: JSX.Element
    proportions: string[]
    rowMapper: (item: any, index: number, proportions: string[]) => JSX.Element
}

const Table = ({ data, headerItems, noDataMessage, proportions, rowMapper }: Props) => {
    if (!data.length) {
        return noDataMessage
    }

    return (
        <div className="table">
            <div className="table-header">
                {headerItems.map((item, index) => {
                    const width: string = proportions[index]
                    return (
                        <div
                            key={index}
                            style={{ width }}
                        >
                            {item}
                        </div>
                    )
                })}
            </div>
            <div className="table-data">
                {data.length && typeof data !== 'string' ?
                    data.map((item, index) => {
                        return rowMapper(item, index, proportions)
                    })
                :
                    <div />
                }
            </div>
        </div>
    )
}

export default Table