import React from 'react'
import FaIcon from '../../FaIcon'
import { BetslipHistoryItem } from '../../../../state/stores/betslip/models'

interface Props {
    index: number
    item: BetslipHistoryItem
    possiblePayout: string
    setCashoutIndex: (index: number, item: BetslipHistoryItem) => void
}

const ShowCashout = ({ index, item, possiblePayout, setCashoutIndex }: Props) => (
    <>
        <div className="payout">
            <FaIcon
                icon="coins"
                type="solid"
            />
            <span>{possiblePayout}</span>
        </div>
        <button
            className="cashout-button show-cashout"
            onClick={() => setCashoutIndex(index, item)}
        >
            SHOW CASHOUT
        </button>
    </>
)

export default ShowCashout