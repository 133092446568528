import React from 'react'
import { BetTypeGroupId } from '../../../state/enums'

interface Props {
    market: string
}

const EmptyCells = ({ market }: Props) => {
    const length: number = getLength(market)
    const array = Array.from(Array(length).keys())

    return (
        <>
            {array.map(item => 
                <div key={item}/>
            )}
        </>
    )
}

export default EmptyCells

const getLength = (market: string): number => {
    switch(market) {
        case BetTypeGroupId[BetTypeGroupId.ThreeWay]:
        case BetTypeGroupId[BetTypeGroupId.DoubleChance]:
        case BetTypeGroupId[BetTypeGroupId.EuropeanHandicap]:
            return 3
        default:
            return 2
    }
}