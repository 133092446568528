import React from 'react'
import './style.scss'

const Spinner = () => (
    <div className="spinner">
        <div/>
        <div/>
        <div/>
    </div>
)

export default Spinner