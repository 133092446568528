import React from 'react'
import FaIcon from '../../FaIcon'
import { roundOddsValue, roundProduct } from '../../../../utils'

interface Props {
    comboOdds: string
    comboPayout: string
    comboStake: string
    isSettled: boolean
}

const Combo = ({ comboOdds, comboPayout, comboStake, isSettled }: Props) => {
    const realStake: string = comboStake.replace('$', '')
    const stakeToShow: string = `€${realStake}`
    const possiblePayout: string = `€${roundProduct(+realStake * +comboOdds)}`
    const payoutToShow: string = (comboPayout) ? comboPayout.replace('$', '€') : possiblePayout
    const resultIcon: JSX.Element | null = getResultIcon(isSettled, comboPayout)

    return (
        <div className="combo">
            <h5>COMBO</h5>
            <div>{roundOddsValue(comboOdds, 3)}</div>
            <div>{stakeToShow}</div>
            {resultIcon}
            <div>
                <FaIcon
                    icon="coins"
                    type="solid"
                />
                <span>{payoutToShow}</span>
            </div>
        </div>
    )
}

export default Combo

const getResultIcon = (isSettled: boolean, payout: string): JSX.Element | null => {
    if (isSettled) {
        if (payout) {
            return (
                <FaIcon
                    icon="check"
                    type="solid"
                />
            )
        }
    
        return (
            <FaIcon
                icon="times"
                type="solid"
            />
        )
    }

    return null
}