import React from 'react'
import clsx from 'clsx'

interface Props {
    className: 'home' | 'away'
    goalsValue: number
    name: string
    setGoals: (type: 'home' | 'away', value: string) => void
}

function TeamName({ className, goalsValue, name, setGoals }: Props) {
    return (
        <div className={clsx('TeamName', className)}>
            <span>{name}</span>
        </div>
    )
}

export default TeamName