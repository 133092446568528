import { FootballModelSelection } from '../../../state/stores/betrix/models'
import { MatrixValue } from '../../../state/enums'
import { QuickButton, AdditionalButton, NewQuickButton } from '.'

function checkQuickButtonCondition(quickButton: QuickButton, additionalButton: AdditionalButton, line: string, i: number, j: number) {
    const { AwayTeam, GoalDifference, HomeTeam, Totals } = QuickButton
    const { Over, Under, Exact } = AdditionalButton
    
    if (quickButton === HomeTeam) {
        if (additionalButton === Over && i > +line) {
            return true
        }
        if (additionalButton === Under && i < +line) {
            return true
        }
        if (additionalButton === Exact && i === +line) {
            return true
        }
    }
    
    if (quickButton === AwayTeam) {
        if (additionalButton === Over && j > +line) {
            return true
        }
        if (additionalButton === Under && j < +line) {
            return true
        }
        if (additionalButton === Exact && j === +line) {
            return true
        }
    }

    if (quickButton === Totals) {
        if (additionalButton === Over && ((i + j) > +line)) {
            return true
        }
        if (additionalButton === Under && ((i + j) < +line)) {
            return true
        }
        if (additionalButton === Exact && ((i + j) === +line)) {
            return true
        }
    }

    if (quickButton === GoalDifference) {
        if (additionalButton === Over && ((!line.includes('+') && i - +line === j) || (line.includes('+') && ((i - +line.replace('+', '')) >= j)))) {
            return true
        }
        if (additionalButton === Under && ((!line.includes('+') && j - +line === i) || (line.includes('+') && ((j - +line.replace('+', '')) >= i)))) {
            return true
        }
    }
    
    return false
}

function checkNewQuickButtonCondition(newQuickButton: NewQuickButton, i: number, j: number) {
    const { AwayWin, Draw, HomeWin, Over05, Over15, Over25, Over35, Over45 } = NewQuickButton
    
    if (newQuickButton === HomeWin && i > j) {
        return true
    }

    if (newQuickButton === Draw && i === j) {
        return true
    }

    if (newQuickButton === AwayWin && j > i) {
        return true
    }

    if (newQuickButton === Over05 && i + j > 0.5) {
        return true
    }

    if (newQuickButton === Over15 && i + j > 1.5) {
        return true
    }

    if (newQuickButton === Over25 && i + j > 2.5) {
        return true
    }

    if (newQuickButton === Over35 && i + j > 3.5) {
        return true
    }

    if (newQuickButton === Over45 && i + j > 4.5) {
        return true
    }
    
    return false
}

function fillQuickMatrix(matrix: MatrixValue[][], quickButton: QuickButton, additionalButton: AdditionalButton, line: string, value: MatrixValue, homeMax: number, awayMax: number) {
    for (let i = 0; i <= homeMax; i++) {
        for (let j = 0; j <= awayMax; j++) {
            const quickButtonCondition: boolean = checkQuickButtonCondition(quickButton, additionalButton, line, i, j)
            if (quickButtonCondition) {
                matrix[i][j] = value
            }
        }
    }
}

function getActiveLines(lines: {[quickButton: string]: {[additionalButton: string]: MatrixValue}}) {
    const activeLines: {quickButton: QuickButton, additionalButton: AdditionalButton, line: string, value: MatrixValue}[] = []
    const quickButtonNames: string[] = Object.keys(lines)
    
    for (const quickButton of quickButtonNames) {
        const quickButtonValues = lines[quickButton]
        const additionalButtonNames: string[] = Object.keys(quickButtonValues)
        for (const additionalButton of additionalButtonNames) {
            const additionalButtonValues = quickButtonValues[additionalButton]
            const lineValues: string[] = Object.keys(additionalButtonValues)
            for (const item of lineValues) {
                const lineValue: MatrixValue = lines[quickButton][additionalButton][item]
                if (lineValue !== MatrixValue.NotSelected) {
                    activeLines.push({
                        quickButton: QuickButton[quickButton],
                        additionalButton: AdditionalButton[additionalButton],
                        line: item,
                        value: lineValue
                    })
                }
            }
        }
    }

    return activeLines
}

function onlyUnique(value: any, index: any, self: any) { 
    return self.indexOf(value) === index
}

export function checkMatrixValueSelected(selectedItems: MatrixValue[][], selectedItemAnyOther0: MatrixValue, selectedItemAnyOther1: MatrixValue, selectedItemAnyOther2: MatrixValue, homeMax: number, awayMax: number) {
    if (selectedItems) {
        for (let i = 0; i <= homeMax; i++) {
            if (selectedItems[i]) {
                for (let j = 0; j <= awayMax; j++) {
                    if (selectedItems[i][j] === MatrixValue.Selected) {
                        return true
                    }
                }
            }
        }
    }
    
    if (selectedItemAnyOther0 === MatrixValue.Selected) {
        return true
    }

    if (selectedItemAnyOther1 === MatrixValue.Selected) {
        return true
    }

    if (selectedItemAnyOther2 === MatrixValue.Selected) {
        return true
    }
    
    return false
}

export function checkResetButtonAvailability(selectedItems: MatrixValue[][], quickButtonsMatrix: MatrixValue[][], selectedItemAnyOtherHome: MatrixValue, selectedItemAnyOtherDraw: MatrixValue, selectedItemAnyOtherAway: MatrixValue, homeMax: number, awayMax: number) {
    for (let i = 0; i <= homeMax; i++) {
        for (let j = 0; j <= awayMax; j++) {
            if (selectedItems[i][j] !== MatrixValue.NotSelected || quickButtonsMatrix[i][j] !== MatrixValue.NotSelected) {
                return true
            }
        }
    }
    
    if (selectedItemAnyOtherHome === MatrixValue.Selected) {
        return true
    }

    if (selectedItemAnyOtherDraw === MatrixValue.Selected) {
        return true
    }

    if (selectedItemAnyOtherAway === MatrixValue.Selected) {
        return true
    }

    return false
}

export function checkSuggestedMarketsAvailability(selectedItems: MatrixValue[][]) {
    const selectedValues = getMatrixValues(selectedItems, MatrixValue.Selected)
    const insuredValues = getMatrixValues(selectedItems, MatrixValue.Insured)
    const suggestedMarketsAvailable: boolean = selectedValues.length === 1 && !insuredValues.length

    return suggestedMarketsAvailable
}

export function createEmptyMatrix(homeMax: number, awayMax: number) {
    const newMatrix: MatrixValue[][] = []

    for (let i = 0; i <= homeMax; i++) {
        newMatrix[i] = new Array(awayMax + 1).fill(MatrixValue.NotSelected)
    }
    return newMatrix
}

export function createFootballModelSelection(matrixValues: MatrixValue[][], selectedItemAnyOtherHome: MatrixValue, selectedItemAnyOtherDraw: MatrixValue, selectedItemAnyOtherAway: MatrixValue, homeMax: number, awayMax: number) {
    const footballModelSelection: FootballModelSelection[] = []
    for (let i = 0; i <= matrixValues.length - 1; i++) {
        for (let j = 0; j <= matrixValues[0].length - 1; j++) {
            if (matrixValues[i][j] !== MatrixValue.NotSelected) {
                const insurance: boolean = matrixValues[i][j] === MatrixValue.Insured
                const selection: FootballModelSelection = {
                    awayGoals: j,
                    homeGoals: i,
                    insurance
                }
                footballModelSelection.push(selection)
            }
        }
    }

    if (selectedItemAnyOtherDraw !== MatrixValue.NotSelected) {
        footballModelSelection.push({
            awayGoals: -1,
            homeGoals: -1,
            insurance: selectedItemAnyOtherDraw === MatrixValue.Insured
        })
    }

    if (selectedItemAnyOtherHome !== MatrixValue.NotSelected) {
        footballModelSelection.push({
            awayGoals: -2,
            homeGoals: -2,
            insurance: selectedItemAnyOtherHome === MatrixValue.Insured
        })
    }

    if (selectedItemAnyOtherAway !== MatrixValue.NotSelected) {
        footballModelSelection.push({
            awayGoals: -3,
            homeGoals: -3,
            insurance: selectedItemAnyOtherAway === MatrixValue.Insured
        })
    }

    return footballModelSelection
}

export function createMobileGrid(matrixWrapper: HTMLElement, homeMax: number, awayMax: number) {
    const { height, left, top, width } = matrixWrapper.getBoundingClientRect()
    const mobileGrid: number[][][] = []

    const widthPortion: number = width / (awayMax + 1)
    const heightPortion: number = height / (homeMax + 1)
    const offset = 10

    for (let i = 0; i <= homeMax; i++) {
        mobileGrid[i] = new Array(awayMax + 1).fill([])
        for (let j = 0; j <= awayMax; j++) {
            const itemLeft: number = left + (j * widthPortion) + offset
            const itemRight: number = left + (j * widthPortion) + widthPortion - offset
            const itemTop: number = top + (i * heightPortion) + offset
            const itemBottom: number = top + (i * heightPortion) + heightPortion - offset
            mobileGrid[i][j] = [itemLeft, itemRight, itemTop, itemBottom]
        }
    }

    return mobileGrid
}

export function createQuickMatrix(selectedLines: {[quickButton: string]: {[additionalButton: string]: MatrixValue}}, homeMax: number, awayMax: number) {
    let newMatrix = createEmptyMatrix(homeMax, awayMax)
    const activeLines = getActiveLines(selectedLines)
    const activeQuickButtons = activeLines.map(a => a.quickButton)
    const uniqueActiveQuickButtons = activeQuickButtons.filter(onlyUnique)
    
    if (!activeQuickButtons.length) {
        newMatrix = createEmptyMatrix(homeMax, awayMax)
    } else if (uniqueActiveQuickButtons.length === 1) {
        newMatrix = createEmptyMatrix(homeMax, awayMax)
        for (let i = 0; i <= homeMax; i++) {
            for (let j = 0; j <= awayMax; j++) {
                for (const activeLine of activeLines) {
                    const quickButtonCondition = checkQuickButtonCondition(activeLine.quickButton, activeLine.additionalButton, activeLine.line, i, j)
                    if (quickButtonCondition) {
                        newMatrix[i][j] = activeLine.value
                    }
                }
            }
        }
    } else {
        const homeMatrix = createEmptyMatrix(homeMax, awayMax)
        const awayMatrix = createEmptyMatrix(homeMax, awayMax)
        const totalsMatrix = createEmptyMatrix(homeMax, awayMax)
        const goalDifferenceMatrix = createEmptyMatrix(homeMax, awayMax)
        
        for (const activeLine of activeLines) {
            if (activeLine.quickButton === QuickButton.HomeTeam) {
                fillQuickMatrix(homeMatrix, activeLine.quickButton, activeLine.additionalButton, activeLine.line, activeLine.value, homeMax, awayMax)
            } else if (activeLine.quickButton === QuickButton.AwayTeam) {
                fillQuickMatrix(awayMatrix, activeLine.quickButton, activeLine.additionalButton, activeLine.line, activeLine.value, homeMax, awayMax)
            } else if (activeLine.quickButton === QuickButton.Totals) {
                fillQuickMatrix(totalsMatrix, activeLine.quickButton, activeLine.additionalButton, activeLine.line, activeLine.value, homeMax, awayMax)
            } else {
                fillQuickMatrix(goalDifferenceMatrix, activeLine.quickButton, activeLine.additionalButton, activeLine.line, activeLine.value, homeMax, awayMax)
            }
        }

        for (let i = 0; i <= homeMax; i++) {
            for (let j = 0; j <= awayMax; j++) {
                let newValue = MatrixValue.Selected
                
                if ((uniqueActiveQuickButtons.includes(QuickButton.HomeTeam) && homeMatrix[i][j] === MatrixValue.Insured) ||
                    (uniqueActiveQuickButtons.includes(QuickButton.AwayTeam) && awayMatrix[i][j] === MatrixValue.Insured) ||
                    (uniqueActiveQuickButtons.includes(QuickButton.Totals) && totalsMatrix[i][j] === MatrixValue.Insured) ||
                    (uniqueActiveQuickButtons.includes(QuickButton.GoalDifference) && goalDifferenceMatrix[i][j] === MatrixValue.Insured)) {
                        newValue = MatrixValue.Insured
                }

                if ((uniqueActiveQuickButtons.includes(QuickButton.HomeTeam) && homeMatrix[i][j] === MatrixValue.NotSelected) ||
                    (uniqueActiveQuickButtons.includes(QuickButton.AwayTeam) && awayMatrix[i][j] === MatrixValue.NotSelected) ||
                    (uniqueActiveQuickButtons.includes(QuickButton.Totals) && totalsMatrix[i][j] === MatrixValue.NotSelected) ||
                    (uniqueActiveQuickButtons.includes(QuickButton.GoalDifference) && goalDifferenceMatrix[i][j] === MatrixValue.NotSelected)) {
                        newValue = MatrixValue.NotSelected
                }

                newMatrix[i][j] = newValue
            }
        }
    }

    return newMatrix
}

export function createNewQuickMatrix(homeMax: number, awayMax: number, newQuickButton: NewQuickButton) {
    const newMatrix = createEmptyMatrix(homeMax, awayMax)

    for (let i = 0; i <= homeMax; i++) {
        for (let j = 0; j <= awayMax; j++) {
            const newQuickButtonCondition = checkNewQuickButtonCondition(newQuickButton, i, j)
            if (newQuickButtonCondition) {
                newMatrix[i][j] = MatrixValue.Selected
            }
        }
    }

    return newMatrix
}

export function fillSwipeMatrix(matrixWrapper: HTMLElement, touches: Touch[], selectedItems: MatrixValue[][], homeMax: number, awayMax: number) {
    const newItems: MatrixValue[][] = selectedItems
    const mobileGrid: number[][][] = createMobileGrid(matrixWrapper, homeMax, awayMax)

    for (let i = 0; i < mobileGrid.length; i++) {
        for (let j = 0; j < mobileGrid.length; j++) {
            const leftLimit: number = mobileGrid[i][j][0]
            const rightLimit: number = mobileGrid[i][j][1]
            const topLimit: number = mobileGrid[i][j][2]
            const bottomLimit: number = mobileGrid[i][j][3]
            const touchFound = touches.find(t => t.clientX >= leftLimit && t.clientX <= rightLimit && t.clientY >= topLimit && t.clientY <= bottomLimit)
            if (touchFound) {
                newItems[i][j] = MatrixValue.Selected
            }
        }
    }

    return newItems
}

export function getMatrixValues(selectedItems: MatrixValue[][], value: MatrixValue) {
    const matrixValues: {homeGoals: number, awayGoals: number}[] = []

    for (let i = 0; i < selectedItems.length; i++) {
        for (let j = 0; j < selectedItems.length; j++) {
            if (selectedItems[i][j] === value) {
                matrixValues.push({
                    homeGoals: i,
                    awayGoals: j
                })
            }
        }
    }

    return matrixValues
}

export function getQuickButtonLines(button: QuickButton, additionalButton: AdditionalButton, homeMax: number, awayMax: number) {
    switch (button) {
        case QuickButton.HomeTeam:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array(homeMax + 1).keys()).map(item => item.toString())
            }
            return Array.from(Array(homeMax).keys()).map(item => (item + 0.5).toString())
        case QuickButton.AwayTeam:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array(awayMax + 1).keys()).map(item => item.toString())
            }
            return Array.from(Array(awayMax).keys()).map(item => (item + 0.5).toString())
        case QuickButton.Totals:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array((homeMax + awayMax) + 1).keys()).map(item => item.toString())
            }
            return Array.from(Array(homeMax + awayMax).keys()).map(item => (item + 0.5).toString())
        case QuickButton.GoalDifference:
            if (additionalButton === AdditionalButton.Under) {
                return [...Array.from(Array(awayMax + 1).keys()).map(item => item.toString()), ...Array.from(Array(awayMax).keys()).map(item => `${item}+`)]
            }
            return [...Array.from(Array(homeMax + 1).keys()).map(item => item.toString()), ...Array.from(Array(homeMax).keys()).map(item => `${item}+`)]
        default:
            return []
    }
}