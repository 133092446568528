import React, { useEffect } from 'react'
import BetslipItemData from '../../BetslipItemData'
import FaIcon from '../../../../components/FaIcon'
import { BSSystemSingleItem, SSMarketOptions, BSStandardItem, BSBetrixItem } from '../../../../../state/stores/betslip/models'
import { disableBSSystemSingleItem, setBSSystemSingleBetweenOption, setBSSystemSingleRange, createBSSystemSingleRange, setBSSystemSingleMarket, roundOddsValue, setBSSystemSingleRangeValue, getBSSystemSingleRangeCount, setCharAt, makeSystemSingleRequest, getSystemSingleBetweenOptions } from '../../../../../utils'
import { MatrixValue } from '../../../../../state/enums'
import { SystemSingleData } from '../../../../../state/stores/betrix-combo/models'
import { store } from '../../../../../state'
import { SystemSingleMarkets } from '../../../../../assets'
import './style.scss'

interface Props {
    betrixItems: Array<BSBetrixItem>
    betslipItems: Array<BSSystemSingleItem>
    betweenOption: {
        name: string
        odds: number
    } | undefined
    data: SystemSingleData
    market: SSMarketOptions
    range: Array<MatrixValue>
    standardItems: Array<BSStandardItem>
}

const BetrixComboSystemSingle = ({ betrixItems, betslipItems, betweenOption, data, market, range, standardItems }: Props) => {
    useEffect(() => {
        const newDistinctMatches = getDistinctMatches(standardItems, betrixItems)
        if (newDistinctMatches.length !== betslipItems.length) {
            saveBSSystemSingleItems(newDistinctMatches)
            setBSSystemSingleBetweenOption(undefined)
            setBSSystemSingleRange(createBSSystemSingleRange(newDistinctMatches.length))
        }
    })

    const rangeSelectionsLength = range.filter(s => s !== MatrixValue.NotSelected).length
    const betweenOptions = (rangeSelectionsLength === 1) ? getSystemSingleBetweenOptions(data.between.odds, range.indexOf(MatrixValue.Selected)) : []
    const oddsAndMaxStake = getOddsAndMaxStake(data, betweenOption)
    const odds = (oddsAndMaxStake[0]) ? oddsAndMaxStake[0] : 0
    const maxStake = (oddsAndMaxStake[1]) ? oddsAndMaxStake[1] : 0
    
    return (
        <div className="BetrixComboSystemSingle">
            <div className="items-container">
                {betslipItems.map((item, index) => {
                    const { awayTeam, homeTeam, matchId, sportId } = item
                    const overlayClassName = (betslipItems[index] && betslipItems[index].disabled) ? 'active' : ''
                    const iconName = (betslipItems[index] && betslipItems[index].disabled) ? 'times' : 'check'

                    return (
                        <div
                            className="item-wrapper only-match"
                            key={index}
                        >
                            <BetslipItemData
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                marketName=""
                                oddsValue=""
                                outcomeText=""
                                showOnlyMatch={true}
                                sportId={sportId}
                            />
                            <FaIcon
                                icon={iconName}
                                type="solid"
                                onClick={() => handleItemDisable(betslipItems, matchId)}
                            />
                            <div className={`disabled-overlay ${overlayClassName}`} />
                        </div>
                    )
                })}
                <div className="ss-container">
                    <div className="ss-markets">
                        {Object.keys(SystemSingleMarkets).map(item =>
                            <button
                                key={item}
                                className={(market === +item) ? 'active' : ''}
                                onClick={() => setBSSystemSingleMarket(+item)}
                            >
                                {SystemSingleMarkets[item].name}
                            </button>
                        )}
                    </div>
                    <p className="ss-question">{SystemSingleMarkets[market].question}</p>
                    <div className="ss-range">
                        {range.map((item, index) =>
                            <span
                                key={index}
                                className={(item === MatrixValue.Selected) ? 'selected' : (item === MatrixValue.Insured) ? 'insured' : ''}
                                onClick={() => handleRangeValue(betslipItems, market, index)}
                            >
                                {index}
                            </span>
                        )}
                    </div>
                    {(betweenOptions.length > 0) &&
                        <div className="ss-other-options">
                            {betweenOptions.map((item, index) =>
                                <div
                                    key={index}
                                    className={(betweenOption && betweenOption.name === item.name && betweenOption.odds === item.odds) ? 'selected' : ''}
                                    onClick={() => setBSSystemSingleBetweenOption(item)}
                                >
                                    <span>{`${item.name}:`}</span>
                                    <span>{roundOddsValue(item.odds.toString(), 3)}</span>
                                </div>
                            )}
                        </div>
                    }
                    {(rangeSelectionsLength > 0 && odds >= 1.001) &&
                        <div className="ss-selection">
                            <p className="ss-label">{`Selection: ${getSelectionLabel(market, range, betweenOption)}`}</p>
                            <div className="ss-odds">
                                <span>Odds:</span>
                                <span>{odds}</span>
                            </div>
                            <div className="ss-max-stake">
                                <span>Max Stake:</span>
                                <span>{`${roundOddsValue(maxStake.toString(), 2)} €`}</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default BetrixComboSystemSingle

const getDistinctMatches = (betslipItems: Array<BSStandardItem>, betrixItems: Array<BSBetrixItem>): Array<BSSystemSingleItem> => {
    const distinctMatches: Array<BSSystemSingleItem> = []

    for (const item of betslipItems) {
        const itemFound = distinctMatches.find(d => d.matchId === item.matchId)
        if (!itemFound) {
            const { awayTeam, homeTeam, matchId, sportId } = item
            const newItem: BSSystemSingleItem = {
                awayTeam,
                disabled: false,
                homeTeam,
                matchId,
                sportId

            }
            distinctMatches.push(newItem)
        }
    }

    for (const item of betrixItems) {
        const itemFound = distinctMatches.find(d => d.matchId === item.matchId)
        if (!itemFound) {
            const { awayTeam, homeTeam, matchId, sportId } = item
            const newItem: BSSystemSingleItem = {
                awayTeam,
                disabled: false,
                homeTeam,
                matchId,
                sportId

            }
            distinctMatches.push(newItem)
        }
    }

    return distinctMatches
}

const getNumberOfDecimals = (value: number): number => {
    if (value) {
        const splittedValue = value.toString().split('.')
        const decimals = splittedValue[1]
        const numberOfDecimals = (decimals) ? decimals.length : 0

        return numberOfDecimals
    }

    return 0
}

const getOddsAndMaxStake = (systemSingleData: SystemSingleData, SSBetweenOption: { name: string, odds: number } | undefined): Array<number> => {
    if (SSBetweenOption) {
        const splittedName = SSBetweenOption.name.split('-')
        const i = +splittedName[0]
        const j = +splittedName[1]
        const oddsBetween = systemSingleData.between.odds[i][j]
        const maxStakeBetween = systemSingleData.between.maxStake[i][j]

        return [oddsBetween, maxStakeBetween]
    }

    const numberOfDecimals = getNumberOfDecimals(systemSingleData.Odds)
    const odds = (numberOfDecimals > 3) ? systemSingleData.odds : systemSingleData.Odds
    const maxStake = systemSingleData.maxStake

    return [odds, maxStake]
}

const getMarketLabel = (market: SSMarketOptions) => {
    switch(market) {
        case SSMarketOptions.HomeWins:
            return 'Home Wins'
        case SSMarketOptions.AwayWins:
            return 'Away Wins'
        case SSMarketOptions.Draws:
            return 'Draws'
        case SSMarketOptions.Goals:
            return 'matches with over 2.5 goals'
        default:
            return ''
    }
}

const getRangeLabel = (range: Array<MatrixValue>): string => {
    const selectedRange = range.filter(r => r === MatrixValue.Selected || r === MatrixValue.Insured)
    
    if (selectedRange.length === 1) {
        const goals = range.indexOf(selectedRange[0])
        return `Exactly ${goals}`
    } else {
        let rangeLabel = ''
        for (let i = 0; i < range.length; i++) {
            rangeLabel += (range[i] === MatrixValue.Selected) ? `${i}, ` : (range[i] === MatrixValue.Insured) ? `${i} (Insured), ` : ''
        }
        
        const firstCommaReplacement = rangeLabel.lastIndexOf(',')
        rangeLabel = setCharAt(rangeLabel, firstCommaReplacement, '')

        const secondCommaReplacement = rangeLabel.lastIndexOf(',')
        rangeLabel = setCharAt(rangeLabel, secondCommaReplacement, ' Or')
        
        return rangeLabel
    }
}

const getSelectionLabel = (market: SSMarketOptions, range: Array<MatrixValue>, betweenOption: { name: string, odds: number } | undefined): string => {
    const optionLabel = (betweenOption) ? `Between ${betweenOption.name.replace('-', 'And')}` : getRangeLabel(range)
    const marketLabel = getMarketLabel(market)
    const selectionLabel = `${optionLabel} ${marketLabel}`

    return selectionLabel
}

const handleItemDisable = (betslipItems: Array<BSSystemSingleItem>, matchId: string) => {
    const newItems = disableBSSystemSingleItem(betslipItems, matchId)
    const enabledMatches = newItems.filter(s => !s.disabled)
    setBSSystemSingleBetweenOption(undefined)
    setBSSystemSingleRange(createBSSystemSingleRange(enabledMatches.length))
}

const handleRangeValue = (betslipItems: Array<BSSystemSingleItem>, market: SSMarketOptions, index: number) => {
    setBSSystemSingleBetweenOption(undefined)

    const newRange = setBSSystemSingleRangeValue(index)
    const SSRangeCount = getBSSystemSingleRangeCount(newRange)
    const matchIds = betslipItems.map(b => b.matchId)
    if (SSRangeCount > 0) {
        makeSystemSingleRequest(market, newRange, matchIds, true)
    }
}

const saveBSSystemSingleItems = (items: Array<BSSystemSingleItem>): void => {
    store.update(main => {
        main.betslip.betrixCombo.systemSingle = items
        return main
    })
}