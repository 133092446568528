export enum BetTypeGroupId {
    ThreeWay = 1,
    OverUnder,
    HomeAway,
    DoubleChance,
    AsianHandicap,
    DrawNoBet,
    ToQualify,
    CorrectScore,
    HalfTimeFullTime,
    OddEven,
    Winner,
    EuropeanHandicap,
    BothTeamsToScore,
    WinMargin,
    ExactNumberOfGoals,
    NumberOfGoals,
    TeamsToScore,
    FirstLastToScore,
    HighestScoringHalf,
    TeamHighestScoringHalf,
    TeamExactNumberOfGoals,
    TimeOfFirstGoal,
    TeamOverUnder,
    MarketMaker,
    FirstTwoGoals,
    HighestScoringHalf2,
    TimeOfFirstGoal2,
    TimeOfFirstGoal3,
    HalfTimeFullTime2,
    HalfTimeFullTime3,
    TeamHighestScoringHalf2,
    TeamNumberOfGoals,
    WinMargin2,
    WinMargin3,
    WinMargin4,
    WinMargin5,
    WinMargin6,
    WinMargin7,
    WinMargin8,
    WinMargin9,
    WinMargin10,
    HalfTimeOrFullTime = 61,
    FirstHalfSecondHalf,
    FirstHalfOrSecondHalf,
    BetAgainst,
    TeamTimeOfFirstGoal,
    ExactNumberOfGoals2,
    ExactNumberOfGoals3,
    ExactNumberOfGoals4,
    ExactNumberOfGoals5,
    WinMargin11,
    WinMargin12,
    WinMargin13,
    WinMargin14,
    TeamExactNumberOfGoals2,
    TeamExactNumberOfGoals3,
    TeamExactNumberOfGoals4,
    TeamExactNumberOfGoals5,
    ExactNumberOfGoals6,
    ExactNumberOfGoals7,
    ToWinTheToss,
    TeamTotalRunsInMatch,
    TeamFirstOverTotalRuns
}

export const BETTYPE_BETRIX: number[][]= [
    [1458, 1459, 1460, 1461, 1462, 1463, 1464, 1465, 1466, 1467, 1468, 1469, 1874, 1901, 1930, 1961, 1994, 2029, 2066, 2105], // 0:x
    [1470, 370, 1471, 1472, 1473, 1474, 1475, 1476, 1477, 1478, 1479, 1480, 1873,  1900, 1929, 1960, 1993, 2028, 2065, 2104], // 1:x
    [1481, 1482, 371, 1483, 1484, 1485, 1486, 1487, 1488, 1489, 1490, 1491, 1872,  1899, 1928, 1959, 1992, 2027, 2064, 2103], // 2:x
    [1492, 1493, 1494, 372, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502, 1871,  1898, 1927, 1958, 1991, 2026, 2063, 2102], // 3:x
    [1503, 1504, 1505, 1506, 57, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1870,   1897, 1926, 1957, 1990, 2025, 2062, 2101], // 4:x
    [1514, 1515, 1516, 1517, 1518, 373, 1519, 1520, 1521, 1522, 1523, 1524, 1869,  1896, 1925, 1956, 1989, 2024, 2061, 2100], // 5:x
    [1525, 1526, 1527, 1528, 1529, 1530, 374, 1531, 1532, 1533, 1534, 1535, 1868,  1895, 1924, 1955, 1988, 2023, 2060, 2099], // 6:x
    [1536, 1537, 1538, 1539, 1540, 1541, 1542, 375, 1543, 1544, 1545, 1546, 1867,  1894, 1923, 1954, 1987, 2022, 2059, 2098], // 7:x
    [1547, 1548, 1549, 1550, 1551, 1552, 1553, 1554, 376, 1555, 1556, 1557, 1866,  1893, 1922, 1953, 1986, 2021, 2058, 2097], // 8:x
    [1558, 1559, 1560, 1561, 1562, 1563, 1564, 1565, 1566, 377, 1567, 1568, 1865,  1892, 1921, 1952, 1985, 2020, 2057, 2096], // 9:x
    [1569, 1570, 1571, 1572, 1573, 1574, 1575, 1576, 1577, 1578, 378, 1579, 1864,  1891, 1920, 1951, 1984, 2019, 2056, 2095], // 10:x
    [1580, 1581, 1582, 1583, 1584, 1585, 1586, 1587, 1588, 1589, 1590, 379, 1863,  1890, 1919, 1950, 1983, 2018, 2055, 2094], // 11:x
    [1850, 1851, 1852, 1853, 1854, 1855, 1856, 1857, 1858, 1859, 1860, 1861, 1862, 1889, 1918, 1949, 1982, 2017, 2054, 2093],// 12:x
    [1875, 1876, 1877, 1878, 1879, 1880, 1881, 1882, 1883, 1884, 1885, 1886, 1887, 1888, 1917, 1948, 1981, 2016, 2053, 2092],// 13:x
    [1902, 1903, 1904, 1905, 1906, 1907, 1908, 1909, 1910, 1911, 1912, 1913, 1914, 1915, 1916, 1947, 1980, 2015, 2052, 2091],// 14:x
    [1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938, 1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946, 1979, 2014, 2051, 2090],// 15:x
    [1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 2013, 2050, 2089],// 16:x
    [1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2049, 2088],// 17:x
    [2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2087],// 18:x
    [2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079, 2080, 2081, 2082, 2083, 2084, 2085, 2086]// 19:x
]

export const BETTYPE_CORRECT_SCORE: number[][] = [
    [121,124,129,136,145,156,169,184,202,221,242,265, 617, 644, 673, 704, 737, 772, 809, 848],
    [122,123,128,135,144,155,168,183,201,220,241,264, 616, 643, 672, 703, 736, 771, 808, 847],
    [125,126,127,134,143,154,167,182,200,219,240,263, 615, 642, 671, 702, 735, 770, 807, 846],
    [130,131,132,133,142,153,166,181,199,218,239,262, 614, 641, 670, 701, 734, 769, 806, 845],
    [137,138,139,140,141,152,165,180,198,217,238,261, 613, 640, 669, 700, 733, 768, 805, 844],
    [146,147,148,149,150,151,164,179,197,216,237,260, 612, 639, 668, 699, 732, 767, 804, 843],
    [157,158,159,160,161,162,163,178,196,215,236,259, 611, 638, 667, 698, 731, 766, 803, 842],
    [170,171,172,173,174,175,176,177,195,214,235,258, 610, 637, 666, 697, 730, 765, 802, 841],
    [186,187,188,189,190,191,192,193,194,213,234,257, 609, 636, 665, 696, 729, 764, 801, 840],
    [203,204,205,206,207,208,209,210,211,212,233,256, 608, 635, 664, 695, 728, 763, 800, 839],
    [222,223,224,225,226,227,228,229,230,231,232,255, 607, 634, 663, 694, 727, 762, 799, 838],
    [243,244,245,246,247,248,249,250,251,252,253,254, 606, 633, 662, 693, 726, 761, 798, 837],
    
    [593,594,595,596,597,598,599,600,601,602,603,604, 605, 632, 661, 692, 725, 760, 797, 836],
    [618,619,620,621,622,623,624,625,626,627,628,629, 630, 631, 660, 691, 724, 759, 796, 835],
    [645,646,647,648,649,650,651,652,653,654,655,656, 657, 658, 659, 690, 723, 758, 795, 834],
    [674,675,676,677,678,679,680,681,682,683,684,685, 686, 687, 688, 689, 722, 757, 794, 833],
    [705,706,707,708,709,710,711,712,713,714,715,716, 717, 718, 719, 720, 721, 756, 793, 832],
    [738,739,740,741,742,743,744,745,746,747,748,749, 750, 751, 752, 753, 754, 755, 792, 831],
    [773,774,775,776,777,778,779,780,781,782,783,784, 785, 786, 787, 788, 789, 790, 791, 830],
    [810,811,812,813,814,815,816,817,818,819,820,821, 822, 823, 824, 825, 826, 827, 828, 829]
]

export const BETTYPE_ANY_OTHER_DRAW: number[][]= [
    [1322, 1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1413, 1414, 1618, 1645, 1674, 1705, 1738, 1773, 1810, 1849], // 0:0, 0:1 ... 0:9
    [1332, 1314, 1333, 1334, 1335, 1336, 1337, 1338, 1339, 1340, 1415, 1416, 1617, 1644, 1673, 1704, 1737, 1772, 1809, 1848], // 1:x
    [1341, 1342, 1315, 1343, 1344, 1345, 1346, 1347, 1348, 1349, 1417, 1418, 1616, 1643, 1672, 1703, 1736, 1771, 1808, 1847], // 2:x
    [1350, 1351, 1352, 1316, 1353, 1354, 1355, 1356, 1357, 1358, 1419, 1420, 1615, 1642, 1671, 1702, 1735, 1770, 1807, 1846], // 3:x
    [1359, 1360, 1361, 1362, 28, 1363, 1364, 1365, 1366, 1367, 1421, 1422,   1614, 1641, 1670, 1701, 1734, 1769, 1806, 1845], // 4:x
    [1368, 1369, 1370, 1371, 1372, 1317, 1373, 1374, 1375, 1376, 1423, 1424, 1613, 1640, 1669, 1700, 1733, 1768, 1805, 1844], // 5:x
    [1377, 1378, 1379, 1380, 1381, 1382, 1318, 1383, 1384, 1385, 1425, 1426, 1612, 1639, 1668, 1699, 1732, 1767, 1804, 1843], // 6:x
    [1386, 1387, 1388, 1389, 1390, 1391, 1392, 1319, 1393, 1394, 1427, 1428, 1611, 1638, 1667, 1698, 1731, 1766, 1803, 1842], // 7:x
    [1395, 1396, 1397, 1398, 1399, 1400, 1401, 1402, 1320, 1403, 1429, 1430, 1610, 1637, 1666, 1697, 1730, 1765, 1802, 1841], // 8:x
    [1404, 1405, 1406, 1407, 1408, 1409, 1410, 1411, 1412, 1321, 1431, 1432, 1609, 1636, 1665, 1696, 1729, 1764, 1801, 1840], // 9:x
    [1433, 1434, 1435, 1436, 1437, 1438, 1439, 1440, 1441, 1442, 1443, 1444, 1608, 1635, 1664, 1695, 1728, 1763, 1800, 1839], // 10:x
    [1445, 1446, 1447, 1448, 1449, 1450, 1451, 1452, 1453, 1454, 1455, 1456, 1607, 1634, 1663, 1694, 1727, 1762, 1799, 1838], // 11:x
    [1594, 1595, 1596, 1597, 1598, 1599, 1600, 1601, 1602, 1603, 1604, 1605, 1606, 1633, 1662, 1693, 1726, 1761, 1798, 1837], // 12:x
    [1619, 1620, 1621, 1622, 1623, 1624, 1625, 1626, 1627, 1628, 1629, 1630, 1631, 1632, 1661, 1692, 1725, 1760, 1797, 1836], // 13:x
    [1646, 1647, 1648, 1649, 1650, 1651, 1652, 1653, 1654, 1655, 1656, 1657, 1658, 1659, 1660, 1691, 1724, 1759, 1796, 1835], // 14:x
    [1675, 1676, 1677, 1678, 1679, 1680, 1681, 1682, 1683, 1684, 1685, 1686, 1687, 1688, 1689, 1690, 1723, 1758, 1795, 1834], // 15:x
    [1706, 1707, 1708, 1709, 1710, 1711, 1712, 1713, 1714, 1715, 1716, 1717, 1718, 1719, 1720, 1721, 1722, 1757, 1794, 1833], // 16:x
    [1739, 1740, 1741, 1742, 1743, 1744, 1745, 1746, 1747, 1748, 1749, 1750, 1751, 1752, 1753, 1754, 1755, 1756, 1793, 1832], // 17:x
    [1774, 1775, 1776, 1777, 1778, 1779, 1780, 1781, 1782, 1783, 1784, 1785, 1786, 1787, 1788, 1789, 1790, 1791, 1792, 1831], // 18:x
    [1811, 1812, 1813, 1814, 1815, 1816, 1817, 1818, 1819, 1820, 1821, 1822, 1823, 1824, 1825, 1826, 1827, 1828, 1829, 1830] // 19:x
]

export const BETTYPE_ANY_OTHER_HOME: number[][]= [
    [2191, 2192, 2193, 2194, 2195, 2196, 2197, 2198, 2199, 2200, 2201, 2202, 2203, 2204, 2205, 2206, 2207, 2208, 2209, 2210],
    [2211, 2212, 2213, 2214, 2215, 2216, 2217, 2218, 2219, 2220, 2221, 2222, 2223, 2224, 2225, 2226, 2227, 2228, 2229, 2230],
    [2231, 2232, 2233, 2234, 2235, 2236, 2237, 2238, 2239, 2240, 2241, 2242, 2243, 2244, 2245, 2246, 2247, 2248, 2249, 2250],
    [2251, 2252, 2253, 2254, 2255, 2256, 2257, 2258, 2259, 2260, 2261, 2262, 2263, 2264, 2265, 2266, 2267, 2268, 2269, 2270],
    [2271, 2272, 2273, 2274, 2275, 2276, 2277, 2278, 2279, 2280, 2281, 2282, 2283, 2284, 2285, 2286, 2287, 2288, 2289, 2290],

    [2291, 2292, 2293, 2294, 2295, 2296, 2297, 2298, 2299, 2300, 2301, 2302, 2303, 2304, 2305, 2306, 2307, 2308, 2309, 2310],
    [2311, 2312, 2313, 2314, 2315, 2316, 2317, 2318, 2319, 2320, 2321, 2322, 2323, 2324, 2325, 2326, 2327, 2328, 2329, 2330],
    [2331, 2332, 2333, 2334, 2335, 2336, 2337, 2338, 2339, 2340, 2341, 2342, 2343, 2344, 2345, 2346, 2347, 2348, 2349, 2350],
    [2351, 2352, 2353, 2354, 2355, 2356, 2357, 2358, 2359, 2360, 2361, 2362, 2363, 2364, 2365, 2366, 2367, 2368, 2369, 2370],
    [2371, 2372, 2373, 2374, 2375, 2376, 2377, 2378, 2379, 2380, 2381, 2382, 2383, 2384, 2385, 2386, 2387, 2388, 2389, 2390],

    [2391, 2392, 2393, 2394, 2395, 2396, 2397, 2398, 2399, 2400, 2401, 2402, 2403, 2404, 2405, 2406, 2407, 2408, 2409, 2410],
    [2411, 2412, 2413, 2414, 2415, 2416, 2417, 2418, 2419, 2420, 2421, 2422, 2423, 2424, 2425, 2426, 2427, 2428, 2429, 2430],
    [2431, 2432, 2433, 2434, 2435, 2436, 2437, 2438, 2439, 2440, 2441, 2442, 2443, 2444, 2445, 2446, 2447, 2448, 2449, 2450],
    [2451, 2452, 2453, 2454, 2455, 2456, 2457, 2458, 2459, 2460, 2461, 2462, 2463, 2464, 2465, 2466, 2467, 2468, 2469, 2470],
    [2471, 2472, 2473, 2474, 2475, 2476, 2477, 2478, 2479, 2480, 2481, 2482, 2483, 2484, 2485, 2486, 2487, 2488, 2489, 2490],

    [2491, 2492, 2493, 2494, 2495, 2496, 2497, 2498, 2499, 2500, 2501, 2502, 2503, 2504, 2505, 2506, 2507, 2508, 2509, 2510],
    [2511, 2512, 2513, 2514, 2515, 2516, 2517, 2518, 2519, 2520, 2521, 2522, 2523, 2524, 2525, 2526, 2527, 2528, 2529, 2530],
    [2531, 2532, 2533, 2534, 2535, 2536, 2537, 2538, 2539, 2540, 2541, 2542, 2543, 2544, 2545, 2546, 2547, 2548, 2549, 2550],
    [2551, 2552, 2553, 2554, 2555, 2556, 2557, 2558, 2559, 2560, 2561, 2562, 2563, 2564, 2565, 2566, 2567, 2568, 2569, 2570],
    [2571, 2572, 2573, 2574, 2575, 2576, 2577, 2578, 2579, 2580, 2581, 2582, 2583, 2584, 2585, 2586, 2587, 2588, 2589, 2590]
]

export const BETTYPE_ANY_OTHER_AWAY: number[][]= [
    [2591, 2592, 2593, 2594, 2595, 2596, 2597, 2598, 2599, 2600, 2601, 2602, 2603, 2604, 2605, 2606, 2607, 2608, 2609, 2610],
    [2611, 2612, 2613, 2614, 2615, 2616, 2617, 2618, 2619, 2620, 2621, 2622, 2623, 2624, 2625, 2626, 2627, 2628, 2629, 2630],
    [2631, 2632, 2633, 2634, 2635, 2636, 2637, 2638, 2639, 2640, 2641, 2642, 2643, 2644, 2645, 2646, 2647, 2648, 2649, 2650],
    [2651, 2652, 2653, 2654, 2655, 2656, 2657, 2658, 2659, 2660, 2661, 2662, 2663, 2664, 2665, 2666, 2667, 2668, 2669, 2670],
    [2671, 2672, 2673, 2674, 2675, 2676, 2677, 2678, 2679, 2680, 2681, 2682, 2683, 2684, 2685, 2686, 2687, 2688, 2689, 2690],

    [2691, 2692, 2693, 2694, 2695, 2696, 2697, 2698, 2699, 2700, 2701, 2702, 2703, 2704, 2705, 2706, 2707, 2708, 2709, 2710],
    [2711, 2712, 2713, 2714, 2715, 2716, 2717, 2718, 2719, 2720, 2721, 2722, 2723, 2724, 2725, 2726, 2727, 2728, 2729, 2730],
    [2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738, 2739, 2740, 2741, 2742, 2743, 2744, 2745, 2746, 2747, 2748, 2749, 2750],
    [2751, 2752, 2753, 2754, 2755, 2756, 2757, 2758, 2759, 2760, 2761, 2762, 2763, 2764, 2765, 2766, 2767, 2768, 2769, 2770],
    [2771, 2772, 2773, 2774, 2775, 2776, 2777, 2778, 2779, 2780, 2781, 2782, 2783, 2784, 2785, 2786, 2787, 2788, 2789, 2790],

    [2791, 2792, 2793, 2794, 2795, 2796, 2797, 2798, 2799, 2800, 2801, 2802, 2803, 2804, 2805, 2806, 2807, 2808, 2809, 2810],
    [2811, 2812, 2813, 2814, 2815, 2816, 2817, 2818, 2819, 2820, 2821, 2822, 2823, 2824, 2825, 2826, 2827, 2828, 2829, 2830],
    [2831, 2832, 2833, 2834, 2835, 2836, 2837, 2838, 2839, 2840, 2841, 2842, 2843, 2844, 2845, 2846, 2847, 2848, 2849, 2850],
    [2851, 2852, 2853, 2854, 2855, 2856, 2857, 2858, 2859, 2860, 2861, 2862, 2863, 2864, 2865, 2866, 2867, 2868, 2869, 2870],
    [2871, 2872, 2873, 2874, 2875, 2876, 2877, 2878, 2879, 2880, 2881, 2882, 2883, 2884, 2885, 2886, 2887, 2888, 2889, 2890],

    [2891, 2892, 2893, 2894, 2895, 2896, 2897, 2898, 2899, 2900, 2901, 2902, 2903, 2904, 2905, 2906, 2907, 2908, 2909, 2910],
    [2911, 2912, 2913, 2914, 2915, 2916, 2917, 2918, 2919, 2920, 2921, 2922, 2923, 2924, 2925, 2926, 2927, 2928, 2929, 2930],
    [2931, 2932, 2933, 2934, 2935, 2936, 2937, 2938, 2939, 2940, 2941, 2942, 2943, 2944, 2945, 2946, 2947, 2948, 2949, 2950],
    [2951, 2952, 2953, 2954, 2955, 2956, 2957, 2958, 2959, 2960, 2961, 2962, 2963, 2964, 2965, 2966, 2967, 2968, 2969, 2970],
    [2971, 2972, 2973, 2974, 2975, 2976, 2977, 2978, 2979, 2980, 2981, 2982, 2983, 2984, 2985, 2986, 2987, 2988, 2989, 2990]
]

/*
export const BETTYPE_ANY_OTHER_DRAW: number[][]= [
    [3391, 3392, 3393, 2994, 2995, 2996, 2997, 2998, 2999, 3000, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 3009, 3010],
    [3011, 3012, 3013, 3014, 3015, 3016, 3017, 3018, 3019, 3020, 3021, 3022, 3023, 3024, 3025, 3026, 3027, 3028, 3029, 3030],
    [3031, 3032, 3033, 3034, 3035, 3036, 3037, 3038, 3039, 3040, 3041, 3042, 3043, 3044, 3045, 3046, 3047, 3048, 3049, 3050],
    [3051, 3052, 3053, 3054, 3055, 3056, 3057, 3058, 3059, 3060, 3061, 3062, 3063, 3064, 3065, 3066, 3067, 3068, 3069, 3070],
    [3071, 3072, 3073, 3074, 3075, 3076, 3077, 3078, 3079, 3080, 3081, 3082, 3083, 3084, 3085, 3086, 3087, 3088, 3089, 3090],

    [3091, 3092, 3093, 3094, 3095, 3096, 3097, 3098, 3099, 3100, 3101, 3102, 3103, 3104, 3105, 3106, 3107, 3108, 3109, 3110],
    [3111, 3112, 3113, 3114, 3115, 3116, 3117, 3118, 3119, 3120, 3121, 3122, 3123, 3124, 3125, 3126, 3127, 3128, 3129, 3130],
    [3131, 3132, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3140, 3141, 3142, 3143, 3144, 3145, 3146, 3147, 3148, 3149, 3150],
    [3151, 3152, 3153, 3154, 3155, 3156, 3157, 3158, 3159, 3160, 3161, 3162, 3163, 3164, 3165, 3166, 3167, 3168, 3169, 3170],
    [3171, 3172, 3173, 3174, 3175, 3176, 3177, 3178, 3179, 3180, 3181, 3182, 3183, 3184, 3185, 3186, 3187, 3188, 3189, 3190],

    [3191, 3192, 3193, 3194, 3195, 3196, 3197, 3198, 3199, 3200, 3201, 3202, 3203, 3204, 3205, 3206, 3207, 3208, 3209, 3210],
    [3211, 3212, 3213, 3214, 3215, 3216, 3217, 3218, 3219, 3220, 3221, 3222, 3223, 3224, 3225, 3226, 3227, 3228, 3229, 3230],
    [3231, 3232, 3233, 3234, 3235, 3236, 3237, 3238, 3239, 3240, 3241, 3242, 3243, 3244, 3245, 3246, 3247, 3248, 3249, 3250],
    [3251, 3252, 3253, 3254, 3255, 3256, 3257, 3258, 3259, 3260, 3261, 3262, 3263, 3264, 3265, 3266, 3267, 3268, 3269, 3270],
    [3271, 3272, 3273, 3274, 3275, 3276, 3277, 3278, 3279, 3280, 3281, 3282, 3283, 3284, 3285, 3286, 3287, 3288, 3289, 3290],

    [3291, 3292, 3293, 3294, 3295, 3296, 3297, 3298, 3299, 3300, 3301, 3302, 3303, 3304, 3305, 3306, 3307, 3308, 3309, 3310],
    [3311, 3312, 3313, 3314, 3315, 3316, 3317, 3318, 3319, 3320, 3321, 3322, 3323, 3324, 3325, 3326, 3327, 3328, 3329, 3330],
    [3331, 3332, 3333, 3334, 3335, 3336, 3337, 3338, 3339, 3340, 3341, 3342, 3343, 3344, 3345, 3346, 3347, 3348, 3349, 3350],
    [3351, 3352, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360, 3361, 3362, 3363, 3364, 3365, 3366, 3367, 3368, 3369, 3370],
    [3371, 3372, 3373, 3374, 3375, 3376, 3377, 3378, 3379, 3380, 3381, 3382, 3383, 3384, 3385, 3386, 3387, 3388, 3389, 3390]
]
*/

// export const _BETTYPE_ANY_OTHER_: number = 1457
export const _BETTYPE_ANY_OTHER_DRAW: number = 2991
export const _BETTYPE_ANY_OTHER_HOME: number = 2992
export const _BETTYPE_ANY_OTHER_AWAY: number = 2993

export enum BetTypeId {
    ExactNumberOfGoals = 3,
    NumberOfGoals = 4,
    TeamsToScore = 5,
    HighestScoringHalf = 7,
    THSHHome = 8,
    THSHAway,
    ThreeWay,
    HomeAway = 20,
    TENGHome,
    TENGAway,
    TimeOfFirstGoal = 25,
    FirstToScore,
    LastToScore,
    AnyOtherFourFour,
    EuropeanHandicapZero = 29,
    HalfTimeFullTime2 = 39,
    HalfTimeFullTime3,
    THSH2Home,
    THSH2Away,
    BothTeamsToScore,
    HalfTimeFullTime,
    MMGeneral,
    DoubleChance,
    DrawNoBet,
    FirstTwoGoals = 49,
    TimeOfFirstGoal2 = 53,
    TimeOfFirstGoal3,
    BetrixFourFour = 57,
    OverUnder05,
    OverUnder15,
    OverUnder25,
    OverUnder35,
    OverUnder45,
    OverUnder55,
    OverUnder65,
    OverUnder75,
    OverUnder85,
    OverUnder95,
    EuropeanHandicapMinusFive = 97,
    EuropeanHandicapMinusFour,
    EuropeanHandicapMinusThree,
    EuropeanHandicapMinusTwo,
    EuropeanHandicapMinusOne,
    EuropeanHandicapOne,
    EuropeanHandicapTwo,
    EuropeanHandicapThree,
    EuropeanHandicapFour,
    EuropeanHandicapFive,
    EuropeanHandicapSix,
    EuropeanHandicapMinusSix,
    EuropeanHandicapMinusSeven,
    EuropeanHandicapSeven,
    EuropeanHandicapMinusEight,
    EuropeanHandicapEight,
    EuropeanHandicapMinusNine,
    EuropeanHandicapNine,
    EuropeanHandicapMinusTen,
    EuropeanHandicapTen,
    EuropeanHandicapMinusEleven,
    EuropeanHandicapEleven,
    EuropeanHandicapMinusTwelve,
    EuropeanHandicapTwelve,
    ZeroZero = 121,
    OneZero,
    OneOne,
    ZeroOne,
    TwoZero,
    TwoOne,
    TwoTwo,
    OneTwo,
    ZeroTwo,
    ThreeZero,
    ThreeOne,
    ThreeTwo,
    ThreeThree,
    TwoThree,
    OneThree,
    ZeroThree,
    FourZero,
    FourOne,
    FourTwo,
    FourThree,
    FourFour,
    ThreeFour,
    TwoFour,
    OneFour,
    ZeroFour,
    FiveZero,
    FiveOne,
    FiveTwo,
    FiveThree,
    FiveFour,
    FiveFive,
    FourFive,
    ThreeFive,
    TwoFive,
    OneFive,
    ZeroFive,
    SixZero,
    SixOne,
    SixTwo,
    SixThree,
    SixFour,
    SixFive,
    SixSix,
    FiveSix,
    FourSix,
    ThreeSix,
    TwoSix,
    OneSix,
    ZeroSix,
    SevenZero,
    SevenOne,
    SevenTwo,
    SevenThree,
    SevenFour,
    SevenFive,
    SevenSix,
    SevenSeven,
    SixSeven,
    FiveSeven,
    FourSeven,
    ThreeSeven,
    TwoSeven,
    OneSeven,
    ZeroSeven,
    EightZero = 186,
    EightOne,
    EightTwo,
    EightThree,
    EightFour,
    EightFive,
    EightSix,
    EightSeven,
    EightEight,
    SevenEight,
    SixEight,
    FiveEight,
    FourEight,
    ThreeEight,
    TwoEight,
    OneEight,
    ZeroEight,
    NineZero,
    NineOne,
    NineTwo,
    NineThree,
    NineFour,
    NineFive,
    NineSix,
    NineSeven,
    NineEight,
    NineNine,
    EightNine,
    SevenNine,
    SixNine,
    FiveNine,
    FourNine,
    ThreeNine,
    TwoNine,
    OneNine,
    ZeroNine,
    TenZero,
    TenOne,
    TenTwo,
    TenThree,
    TenFour,
    TenFive,
    TenSix,
    TenSeven,
    TenEight,
    TenNine,
    TenTen,
    NineTen,
    EightTen,
    SevenTen,
    SixTen,
    FiveTen,
    FourTen,
    ThreeTen,
    TwoTen,
    OneTen,
    ZeroTen,
    ElevenZero,
    ElevenOne,
    ElevenTwo,
    ElevenThree,
    ElevenFour,
    ElevenFive,
    ElevenSix,
    ElevenSeven,
    ElevenEight,
    ElevenNine,
    ElevenTen,
    ElevenEleven,
    TenEleven,
    NineEleven,
    EightEleven,
    SevenEleven,
    SixEleven,
    FiveEleven,
    FourEleven,
    ThreeEleven,
    TwoEleven,
    OneEleven,
    ZeroEleven,
    TNGHome = 358,
    TNGAway,
    WinMargin2,
    WinMargin3,
    WinMargin4,
    WinMargin5,
    WinMargin6,
    WinMargin7,
    WinMargin8,
    WinMargin9 = 368,
    WinMargin10,
    BetrixOneOne,
    BetrixTwoTwo,
    BetrixThreeThree,
    BetrixFiveFive,
    BetrixSixSix,
    BetrixSevenSeven,
    BetrixEightEight,
    BetrixNineNine,
    BetrixTenTen,
    BetrixElevenEleven,
    TwelveZero = 593,
    TwelveOne,
    TwelveTwo,
    TwelveThree,
    TwelveFour,
    TwelveFive,
    TwelveSix,
    TwelveSeven,
    TwelveEight,
    TwelveNine,
    TwelveTen,
    TwelveEleven,
    TwelveTwelve,
    ElevenTwelve,
    TenTwelve,
    NineTwelve,
    EightTwelve,
    SevenTwelve,
    SixTwelve,
    FiveTwelve,
    FourTwelve,
    ThreeTwelve,
    TwoTwelve,
    OneTwelve,
    ZeroTwelve,
    ThirteenZero,
    ThirteenOne,
    ThirteenTwo,
    ThirteenThree,
    ThirteenFour,
    ThirteenFive,
    ThirteenSix,
    ThirteenSeven,
    ThirteenEight,
    ThirteenNine,
    ThirteenTen,
    ThirteenEleven,
    ThirteenTwelve,
    ThirteenThirteen,
    TwelveThirteen,
    ElevenThirteen,
    TenThirteen,
    NineThirteen,
    EightThirteen,
    SevenThirteen,
    SixThirteen,
    FiveThirteen,
    FourThirteen,
    ThreeThirteen,
    TwoThirteen,
    OneThirteen,
    ZeroThirteen,
    FourteenZero,
    FourteenOne,
    FourteenTwo,
    FourteenThree,
    FourteenFour,
    FourteenFive,
    FourteenSix,
    FourteenSeven,
    FourteenEight,
    FourteenNine,
    FourteenTen,
    FourteenEleven,
    FourteenTwelve,
    FourteenThirteen,
    FourteenFourteen,
    ThirteenFourteen,
    TwelveFourteen,
    ElevenFourteen,
    TenFourteen,
    NineFourteen,
    EightFourteen,
    SevenFourteen,
    SixFourteen,
    FiveFourteen,
    FourFourteen,
    ThreeFourteen,
    TwoFourteen,
    OneFourteen,
    ZeroFourteen,
    FifteenZero,
    FifteenOne,
    FifteenTwo,
    FifteenThree,
    FifteenFour,
    FifteenFive,
    FifteenSix,
    FifteenSeven,
    FifteenEight,
    FifteenNine,
    FifteenTen,
    FifteenEleven,
    FifteenTwelve,
    FifteenThirteen,
    FifteenFourteen,
    FifteenFifteen,
    FourteenFifteen,
    ThirteenFifteen,
    TwelveFifteen,
    ElevenFifteen,
    TenFifteen,
    NineFifteen,
    EightFifteen,
    SevenFifteen,
    SixFifteen,
    FiveFifteen,
    FourFifteen,
    ThreeFifteen,
    TwoFifteen,
    OneFifteen,
    ZeroFifteen,
    SixteenZero,
    SixteenOne,
    SixteenTwo,
    SixteenThree,
    SixteenFour,
    SixteenFive,
    SixteenSix,
    SixteenSeven,
    SixteenEight,
    SixteenNine,
    SixteenTen,
    SixteenEleven,
    SixteenTwelve,
    SixteenThirteen,
    SixteenFourteen,
    SixteenFifteen,
    SixteenSixteen,
    FifteenSixteen,
    FourteenSixteen,
    ThirteenSixteen,
    TwelveSixteen,
    ElevenSixteen,
    TenSixteen,
    NineSixteen,
    EightSixteen,
    SevenSixteen,
    SixSixteen,
    FiveSixteen,
    FourSixteen,
    ThreeSixteen,
    TwoSixteen,
    OneSixteen,
    ZeroSixteen,
    SeventeenZero,
    SeventeenOne,
    SeventeenTwo,
    SeventeenThree,
    SeventeenFour,
    SeventeenFive,
    SeventeenSix,
    SeventeenSeven,
    SeventeenEight,
    SeventeenNine,
    SeventeenTen,
    SeventeenEleven,
    SeventeenTwelve,
    SeventeenThirteen,
    SeventeenFourteen,
    SeventeenFifteen,
    SeventeenSixteen,
    SeventeenSeventeen,
    SixteenSeventeen,
    FifteenSeventeen,
    FourteenSeventeen,
    ThirteenSeventeen,
    TwelveSeventeen,
    ElevenSeventeen,
    TenSeventeen,
    NineSeventeen,
    EightSeventeen,
    SevenSeventeen,
    SixSeventeen,
    FiveSeventeen,
    FourSeventeen,
    ThreeSeventeen,
    TwoSeventeen,
    OneSeventeen,
    ZeroSeventeen,
    EighteenZero,
    EighteenOne,
    EighteenTwo,
    EighteenThree,
    EighteenFour,
    EighteenFive,
    EighteenSix,
    EighteenSeven,
    EighteenEight,
    EighteenNine,
    EighteenTen,
    EighteenEleven,
    EighteenTwelve,
    EighteenThirteen,
    EighteenFourteen,
    EighteenFifteen,
    EighteenSixteen,
    EighteenSeventeen,
    EighteenEighteen,
    SeventeenEighteen,
    SixteenEighteen,
    FifteenEighteen,
    FourteenEighteen,
    ThirteenEighteen,
    TwelveEighteen,
    ElevenEighteen,
    TenEighteen,
    NineEighteen,
    EightEighteen,
    SevenEighteen,
    SixEighteen,
    FiveEighteen,
    FourEighteen,
    ThreeEighteen,
    TwoEighteen,
    OneEighteen,
    ZeroEighteen,
    NineteenZero,
    NineteenOne,
    NineteenTwo,
    NineteenThree,
    NineteenFour,
    NineteenFive,
    NineteenSix,
    NineteenSeven,
    NineteenEight,
    NineteenNine,
    NineteenTen,
    NineteenEleven,
    NineteenTwelve,
    NineteenThirteen,
    NineteenFourteen,
    NineteenFifteen,
    NineteenSixteen,
    NineteenSeventeen,
    NineteenEighteen,
    NineteenNineteen,
    EighteenNineteen,
    SeventeenNineteen,
    SixteenNineteen,
    FifteenNineteen,
    FourteenNineteen,
    ThirteenNineteen,
    TwelveNineteen,
    ElevenNineteen,
    TenNineteen,
    NineNineteen,
    EightNineteen,
    SevenNineteen,
    SixNineteen,
    FiveNineteen,
    FourNineteen,
    ThreeNineteen,
    TwoNineteen,
    OneNineteen,
    ZeroNineteen,
    HalfTimeOrFullTime = 1310,
    FirstHalfSecondHalf,
    FirstHalfOrSecondHalf,
    BetAgainst,
    AnyOtherOneOne,
    AnyOtherTwoTwo,
    AnyOtherThreeThree,
    AnyOtherFiveFive,
    AnyOtherSixSix,
    AnyOtherSevenSeven,
    AnyOtherEightEight,
    AnyOtherNineNine,
    AnyOtherZeroZero,
    AnyOtherZeroOne,
    AnyOtherZeroTwo,
    AnyOtherZeroThree,
    AnyOtherZeroFour,
    AnyOtherZeroFive,
    AnyOtherZeroSix,
    AnyOtherZeroSeven,
    AnyOtherZeroEight,
    AnyOtherZeroNine,
    AnyOtherOneZero,
    AnyOtherOneTwo,
    AnyOtherOneThree,
    AnyOtherOneFour,
    AnyOtherOneFive,
    AnyOtherOneSix,
    AnyOtherOneSeven,
    AnyOtherOneEight,
    AnyOtherOneNine,
    AnyOtherTwoZero,
    AnyOtherTwoOne,
    AnyOtherTwoThree,
    AnyOtherTwoFour,
    AnyOtherTwoFive,
    AnyOtherTwoSix,
    AnyOtherTwoSeven,
    AnyOtherTwoEight,
    AnyOtherTwoNine,
    AnyOtherThreeZero,
    AnyOtherThreeOne,
    AnyOtherThreeTwo,
    AnyOtherThreeFour,
    AnyOtherThreeFive,
    AnyOtherThreeSix,
    AnyOtherThreeSeven,
    AnyOtherThreeEight,
    AnyOtherThreeNine,
    AnyOtherFourZero,
    AnyOtherFourOne,
    AnyOtherFourTwo,
    AnyOtherFourThree,
    AnyOtherFourFive,
    AnyOtherFourSix,
    AnyOtherFourSeven,
    AnyOtherFourEight,
    AnyOtherFourNine,
    AnyOtherFiveZero,
    AnyOtherFiveOne,
    AnyOtherFiveTwo,
    AnyOtherFiveThree,
    AnyOtherFiveFour,
    AnyOtherFiveSix,
    AnyOtherFiveSeven,
    AnyOtherFiveEight,
    AnyOtherFiveNine,
    AnyOtherSixZero,
    AnyOtherSixOne,
    AnyOtherSixTwo,
    AnyOtherSixThree,
    AnyOtherSixFour,
    AnyOtherSixFive,
    AnyOtherSixSeven,
    AnyOtherSixEight,
    AnyOtherSixNine,
    AnyOtherSevenZero,
    AnyOtherSevenOne,
    AnyOtherSevenTwo,
    AnyOtherSevenThree,
    AnyOtherSevenFour,
    AnyOtherSevenFive,
    AnyOtherSevenSix,
    AnyOtherSevenEight,
    AnyOtherSevenNine,
    AnyOtherEightZero,
    AnyOtherEightOne,
    AnyOtherEightTwo,
    AnyOtherEightThree,
    AnyOtherEightFour,
    AnyOtherEightFive,
    AnyOtherEightSix,
    AnyOtherEightSeven,
    AnyOtherEightNine,
    AnyOtherNineZero,
    AnyOtherNineOne,
    AnyOtherNineTwo,
    AnyOtherNineThree,
    AnyOtherNineFour,
    AnyOtherNineFive,
    AnyOtherNineSix,
    AnyOtherNineSeven,
    AnyOtherNineEight,
    AnyOtherZeroTen,
    AnyOtherZeroEleven,
    AnyOtherOneTen,
    AnyOtherOneEleven,
    AnyOtherTwoTen,
    AnyOtherTwoEleven,
    AnyOtherThreeTen,
    AnyOtherThreeEleven,
    AnyOtherFourTen,
    AnyOtherFourEleven,
    AnyOtherFiveTen,
    AnyOtherFiveEleven,
    AnyOtherSixTen,
    AnyOtherSixEleven,
    AnyOtherSevenTen,
    AnyOtherSevenEleven,
    AnyOtherEightTen,
    AnyOtherEightEleven,
    AnyOtherNineTen,
    AnyOtherNineEleven,
    AnyOtherTenZero,
    AnyOtherTenOne,
    AnyOtherTenTwo,
    AnyOtherTenThree,
    AnyOtherTenFour,
    AnyOtherTenFive,
    AnyOtherTenSix,
    AnyOtherTenSeven,
    AnyOtherTenEight,
    AnyOtherTenNine,
    AnyOtherTenTen,
    AnyOtherTenEleven,
    AnyOtherElevenZero,
    AnyOtherElevenOne,
    AnyOtherElevenTwo,
    AnyOtherElevenThree,
    AnyOtherElevenFour,
    AnyOtherElevenFive,
    AnyOtherElevenSix,
    AnyOtherElevenSeven,
    AnyOtherElevenEight,
    AnyOtherElevenNine,
    AnyOtherElevenTen,
    AnyOtherElevenEleven,
    AnyOtherUnknown,
    BetrixZeroZero,
    BetrixZeroOne,
    BetrixZeroTwo,
    BetrixZeroThree,
    BetrixZeroFour,
    BetrixZeroFive,
    BetrixZeroSix,
    BetrixZeroSeven,
    BetrixZeroEight,
    BetrixZeroNine,
    BetrixZeroTen,
    BetrixZeroEleven,
    BetrixOneZero,
    BetrixOneTwo,
    BetrixOneThree,
    BetrixOneFour,
    BetrixOneFive,
    BetrixOneSix,
    BetrixOneSeven,
    BetrixOneEight,
    BetrixOneNine,
    BetrixOneTen,
    BetrixOneEleven,
    BetrixTwoZero,
    BetrixTwoOne,
    BetrixTwoThree,
    BetrixTwoFour,
    BetrixTwoFive,
    BetrixTwoSix,
    BetrixTwoSeven,
    BetrixTwoEight,
    BetrixTwoNine,
    BetrixTwoTen,
    BetrixTwoEleven,
    BetrixThreeZero,
    BetrixThreeOne,
    BetrixThreeTwo,
    BetrixThreeFour,
    BetrixThreeFive,
    BetrixThreeSix,
    BetrixThreeSeven,
    BetrixThreeEight,
    BetrixThreeNine,
    BetrixThreeTen,
    BetrixThreeEleven,
    BetrixFourZero,
    BetrixFourOne,
    BetrixFourTwo,
    BetrixFourThree,
    BetrixFourFive,
    BetrixFourSix,
    BetrixFourSeven,
    BetrixFourEight,
    BetrixFourNine,
    BetrixFourTen,
    BetrixFourEleven,
    BetrixFiveZero,
    BetrixFiveOne,
    BetrixFiveTwo,
    BetrixFiveThree,
    BetrixFiveFour,
    BetrixFiveSix,
    BetrixFiveSeven,
    BetrixFiveEight,
    BetrixFiveNine,
    BetrixFiveTen,
    BetrixFiveEleven,
    BetrixSixZero,
    BetrixSixOne,
    BetrixSixTwo,
    BetrixSixThree,
    BetrixSixFour,
    BetrixSixFive,
    BetrixSixSeven,
    BetrixSixEight,
    BetrixSixNine,
    BetrixSixTen,
    BetrixSixEleven,
    BetrixSevenZero,
    BetrixSevenOne,
    BetrixSevenTwo,
    BetrixSevenThree,
    BetrixSevenFour,
    BetrixSevenFive,
    BetrixSevenSix,
    BetrixSevenEight,
    BetrixSevenNine,
    BetrixSevenTen,
    BetrixSevenEleven,
    BetrixEightZero,
    BetrixEightOne,
    BetrixEightTwo,
    BetrixEightThree,
    BetrixEightFour,
    BetrixEightFive,
    BetrixEightSix,
    BetrixEightSeven,
    BetrixEightNine,
    BetrixEightTen,
    BetrixEightEleven,
    BetrixNineZero,
    BetrixNineOne,
    BetrixNineTwo,
    BetrixNineThree,
    BetrixNineFour,
    BetrixNineFive,
    BetrixNineSix,
    BetrixNineSeven,
    BetrixNineEight,
    BetrixNineTen,
    BetrixNineEleven,
    BetrixTenZero,
    BetrixTenOne,
    BetrixTenTwo,
    BetrixTenThree,
    BetrixTenFour,
    BetrixTenFive,
    BetrixTenSix,
    BetrixTenSeven,
    BetrixTenEight,
    BetrixTenNine,
    BetrixTenEleven,
    BetrixElevenZero,
    BetrixElevenOne,
    BetrixElevenTwo,
    BetrixElevenThree,
    BetrixElevenFour,
    BetrixElevenFive,
    BetrixElevenSix,
    BetrixElevenSeven,
    BetrixElevenEight,
    BetrixElevenNine,
    BetrixElevenTen,
    HomeTimeOfFirstGoal,
    AwayTimeOfFirstGoal,
    TeamTimeOfFirstGoal,
    AnyOtherTwelveZero,
    AnyOtherTwelveOne,
    AnyOtherTwelveTwo,
    AnyOtherTwelveThree,
    AnyOtherTwelveFour,
    AnyOtherTwelveFive,
    AnyOtherTwelveSix,
    AnyOtherTwelveSeven,
    AnyOtherTwelveEight,
    AnyOtherTwelveNine,
    AnyOtherTwelveTen,
    AnyOtherTwelveEleven,
    AnyOtherTwelveTwelve,
    AnyOtherElevenTwelve,
    AnyOtherTenTwelve,
    AnyOtherNineTwelve,
    AnyOtherEightTwelve,
    AnyOtherSevenTwelve,
    AnyOtherSixTwelve,
    AnyOtherFiveTwelve,
    AnyOtherFourTwelve,
    AnyOtherThreeTwelve,
    AnyOtherTwoTwelve,
    AnyOtherOneTwelve,
    AnyOtherZeroTwelve,
    AnyOtherThirteenZero,
    AnyOtherThirteenOne,
    AnyOtherThirteenTwo,
    AnyOtherThirteenThree,
    AnyOtherThirteenFour,
    AnyOtherThirteenFive,
    AnyOtherThirteenSix,
    AnyOtherThirteenSeven,
    AnyOtherThirteenEight,
    AnyOtherThirteenNine,
    AnyOtherThirteenTen,
    AnyOtherThirteenEleven,
    AnyOtherThirteenTwelve,
    AnyOtherThirteenThirteen,
    AnyOtherTwelveThirteen,
    AnyOtherElevenThirteen,
    AnyOtherTenThirteen,
    AnyOtherNineThirteen,
    AnyOtherEightThirteen,
    AnyOtherSevenThirteen,
    AnyOtherSixThirteen,
    AnyOtherFiveThirteen,
    AnyOtherFourThirteen,
    AnyOtherThreeThirteen,
    AnyOtherTwoThirteen,
    AnyOtherOneThirteen,
    AnyOtherZeroThirteen,
    AnyOtherFourteenZero,
    AnyOtherFourteenOne,
    AnyOtherFourteenTwo,
    AnyOtherFourteenThree,
    AnyOtherFourteenFour,
    AnyOtherFourteenFive,
    AnyOtherFourteenSix,
    AnyOtherFourteenSeven,
    AnyOtherFourteenEight,
    AnyOtherFourteenNine,
    AnyOtherFourteenTen,
    AnyOtherFourteenEleven,
    AnyOtherFourteenTwelve,
    AnyOtherFourteenThirteen,
    AnyOtherFourteenFourteen,
    AnyOtherThirteenFourteen,
    AnyOtherTwelveFourteen,
    AnyOtherElevenFourteen,
    AnyOtherTenFourteen,
    AnyOtherNineFourteen,
    AnyOtherEightFourteen,
    AnyOtherSevenFourteen,
    AnyOtherSixFourteen,
    AnyOtherFiveFourteen,
    AnyOtherFourFourteen,
    AnyOtherThreeFourteen,
    AnyOtherTwoFourteen,
    AnyOtherOneFourteen,
    AnyOtherZeroFourteen,
    AnyOtherFifteenZero,
    AnyOtherFifteenOne,
    AnyOtherFifteenTwo,
    AnyOtherFifteenThree,
    AnyOtherFifteenFour,
    AnyOtherFifteenFive,
    AnyOtherFifteenSix,
    AnyOtherFifteenSeven,
    AnyOtherFifteenEight,
    AnyOtherFifteenNine,
    AnyOtherFifteenTen,
    AnyOtherFifteenEleven,
    AnyOtherFifteenTwelve,
    AnyOtherFifteenThirteen,
    AnyOtherFifteenFourteen,
    AnyOtherFifteenFifteen,
    AnyOtherFourteenFifteen,
    AnyOtherThirteenFifteen,
    AnyOtherTwelveFifteen,
    AnyOtherElevenFifteen,
    AnyOtherTenFifteen,
    AnyOtherNineFifteen,
    AnyOtherEightFifteen,
    AnyOtherSevenFifteen,
    AnyOtherSixFifteen,
    AnyOtherFiveFifteen,
    AnyOtherFourFifteen,
    AnyOtherThreeFifteen,
    AnyOtherTwoFifteen,
    AnyOtherOneFifteen,
    AnyOtherZeroFifteen,
    AnyOtherSixteenZero,
    AnyOtherSixteenOne,
    AnyOtherSixteenTwo,
    AnyOtherSixteenThree,
    AnyOtherSixteenFour,
    AnyOtherSixteenFive,
    AnyOtherSixteenSix,
    AnyOtherSixteenSeven,
    AnyOtherSixteenEight,
    AnyOtherSixteenNine,
    AnyOtherSixteenTen,
    AnyOtherSixteenEleven,
    AnyOtherSixteenTwelve,
    AnyOtherSixteenThirteen,
    AnyOtherSixteenFourteen,
    AnyOtherSixteenFifteen,
    AnyOtherSixteenSixteen,
    AnyOtherFifteenSixteen,
    AnyOtherFourteenSixteen,
    AnyOtherThirteenSixteen,
    AnyOtherTwelveSixteen,
    AnyOtherElevenSixteen,
    AnyOtherTenSixteen,
    AnyOtherNineSixteen,
    AnyOtherEightSixteen,
    AnyOtherSevenSixteen,
    AnyOtherSixSixteen,
    AnyOtherFiveSixteen,
    AnyOtherFourSixteen,
    AnyOtherThreeSixteen,
    AnyOtherTwoSixteen,
    AnyOtherOneSixteen,
    AnyOtherZeroSixteen,
    AnyOtherSeventeenZero,
    AnyOtherSeventeenOne,
    AnyOtherSeventeenTwo,
    AnyOtherSeventeenThree,
    AnyOtherSeventeenFour,
    AnyOtherSeventeenFive,
    AnyOtherSeventeenSix,
    AnyOtherSeventeenSeven,
    AnyOtherSeventeenEight,
    AnyOtherSeventeenNine,
    AnyOtherSeventeenTen,
    AnyOtherSeventeenEleven,
    AnyOtherSeventeenTwelve,
    AnyOtherSeventeenThirteen,
    AnyOtherSeventeenFourteen,
    AnyOtherSeventeenFifteen,
    AnyOtherSeventeenSixteen,
    AnyOtherSeventeenSeventeen,
    AnyOtherSixteenSeventeen,
    AnyOtherFifteenSeventeen,
    AnyOtherFourteenSeventeen,
    AnyOtherThirteenSeventeen,
    AnyOtherTwelveSeventeen,
    AnyOtherElevenSeventeen,
    AnyOtherTenSeventeen,
    AnyOtherNineSeventeen,
    AnyOtherEightSeventeen,
    AnyOtherSevenSeventeen,
    AnyOtherSixSeventeen,
    AnyOtherFiveSeventeen,
    AnyOtherFourSeventeen,
    AnyOtherThreeSeventeen,
    AnyOtherTwoSeventeen,
    AnyOtherOneSeventeen,
    AnyOtherZeroSeventeen,
    AnyOtherEighteenZero,
    AnyOtherEighteenOne,
    AnyOtherEighteenTwo,
    AnyOtherEighteenThree,
    AnyOtherEighteenFour,
    AnyOtherEighteenFive,
    AnyOtherEighteenSix,
    AnyOtherEighteenSeven,
    AnyOtherEighteenEight,
    AnyOtherEighteenNine,
    AnyOtherEighteenTen,
    AnyOtherEighteenEleven,
    AnyOtherEighteenTwelve,
    AnyOtherEighteenThirteen,
    AnyOtherEighteenFourteen,
    AnyOtherEighteenFifteen,
    AnyOtherEighteenSixteen,
    AnyOtherEighteenSeventeen,
    AnyOtherEighteenEighteen,
    AnyOtherSeventeenEighteen,
    AnyOtherSixteenEighteen,
    AnyOtherFifteenEighteen,
    AnyOtherFourteenEighteen,
    AnyOtherThirteenEighteen,
    AnyOtherTwelveEighteen,
    AnyOtherElevenEighteen,
    AnyOtherTenEighteen,
    AnyOtherNineEighteen,
    AnyOtherEightEighteen,
    AnyOtherSevenEighteen,
    AnyOtherSixEighteen,
    AnyOtherFiveEighteen,
    AnyOtherFourEighteen,
    AnyOtherThreeEighteen,
    AnyOtherTwoEighteen,
    AnyOtherOneEighteen,
    AnyOtherZeroEighteen,
    AnyOtherNineteenZero,
    AnyOtherNineteenOne,
    AnyOtherNineteenTwo,
    AnyOtherNineteenThree,
    AnyOtherNineteenFour,
    AnyOtherNineteenFive,
    AnyOtherNineteenSix,
    AnyOtherNineteenSeven,
    AnyOtherNineteenEight,
    AnyOtherNineteenNine,
    AnyOtherNineteenTen,
    AnyOtherNineteenEleven,
    AnyOtherNineteenTwelve,
    AnyOtherNineteenThirteen,
    AnyOtherNineteenFourteen,
    AnyOtherNineteenFifteen,
    AnyOtherNineteenSixteen,
    AnyOtherNineteenSeventeen,
    AnyOtherNineteenEighteen,
    AnyOtherNineteenNineteen,
    AnyOtherEighteenNineteen,
    AnyOtherSeventeenNineteen,
    AnyOtherSixteenNineteen,
    AnyOtherFifteenNineteen,
    AnyOtherFourteenNineteen,
    AnyOtherThirteenNineteen,
    AnyOtherTwelveNineteen,
    AnyOtherElevenNineteen,
    AnyOtherTenNineteen,
    AnyOtherNineNineteen,
    AnyOtherEightNineteen,
    AnyOtherSevenNineteen,
    AnyOtherSixNineteen,
    AnyOtherFiveNineteen,
    AnyOtherFourNineteen,
    AnyOtherThreeNineteen,
    AnyOtherTwoNineteen,
    AnyOtherOneNineteen,
    AnyOtherZeroNineteen,
    BetrixTwelveZero,
    BetrixTwelveOne,
    BetrixTwelveTwo,
    BetrixTwelveThree,
    BetrixTwelveFour,
    BetrixTwelveFive,
    BetrixTwelveSix,
    BetrixTwelveSeven,
    BetrixTwelveEight,
    BetrixTwelveNine,
    BetrixTwelveTen,
    BetrixTwelveEleven,
    BetrixTwelveTwelve,
    BetrixElevenTwelve,
    BetrixTenTwelve,
    BetrixNineTwelve,
    BetrixEightTwelve,
    BetrixSevenTwelve,
    BetrixSixTwelve,
    BetrixFiveTwelve,
    BetrixFourTwelve,
    BetrixThreeTwelve,
    BetrixTwoTwelve,
    BetrixOneTwelve,
    BetrixZeroTwelve,
    BetrixThirteenZero,
    BetrixThirteenOne,
    BetrixThirteenTwo,
    BetrixThirteenThree,
    BetrixThirteenFour,
    BetrixThirteenFive,
    BetrixThirteenSix,
    BetrixThirteenSeven,
    BetrixThirteenEight,
    BetrixThirteenNine,
    BetrixThirteenTen,
    BetrixThirteenEleven,
    BetrixThirteenTwelve,
    BetrixThirteenThirteen,
    BetrixTwelveThirteen,
    BetrixElevenThirteen,
    BetrixTenThirteen,
    BetrixNineThirteen,
    BetrixEightThirteen,
    BetrixSevenThirteen,
    BetrixSixThirteen,
    BetrixFiveThirteen,
    BetrixFourThirteen,
    BetrixThreeThirteen,
    BetrixTwoThirteen,
    BetrixOneThirteen,
    BetrixZeroThirteen,
    BetrixFourteenZero,
    BetrixFourteenOne,
    BetrixFourteenTwo,
    BetrixFourteenThree,
    BetrixFourteenFour,
    BetrixFourteenFive,
    BetrixFourteenSix,
    BetrixFourteenSeven,
    BetrixFourteenEight,
    BetrixFourteenNine,
    BetrixFourteenTen,
    BetrixFourteenEleven,
    BetrixFourteenTwelve,
    BetrixFourteenThirteen,
    BetrixFourteenFourteen,
    BetrixThirteenFourteen,
    BetrixTwelveFourteen,
    BetrixElevenFourteen,
    BetrixTenFourteen,
    BetrixNineFourteen,
    BetrixEightFourteen,
    BetrixSevenFourteen,
    BetrixSixFourteen,
    BetrixFiveFourteen,
    BetrixFourFourteen,
    BetrixThreeFourteen,
    BetrixTwoFourteen,
    BetrixOneFourteen,
    BetrixZeroFourteen,
    BetrixFifteenZero,
    BetrixFifteenOne,
    BetrixFifteenTwo,
    BetrixFifteenThree,
    BetrixFifteenFour,
    BetrixFifteenFive,
    BetrixFifteenSix,
    BetrixFifteenSeven,
    BetrixFifteenEight,
    BetrixFifteenNine,
    BetrixFifteenTen,
    BetrixFifteenEleven,
    BetrixFifteenTwelve,
    BetrixFifteenThirteen,
    BetrixFifteenFourteen,
    BetrixFifteenFifteen,
    BetrixFourteenFifteen,
    BetrixThirteenFifteen,
    BetrixTwelveFifteen,
    BetrixElevenFifteen,
    BetrixTenFifteen,
    BetrixNineFifteen,
    BetrixEightFifteen,
    BetrixSevenFifteen,
    BetrixSixFifteen,
    BetrixFiveFifteen,
    BetrixFourFifteen,
    BetrixThreeFifteen,
    BetrixTwoFifteen,
    BetrixOneFifteen,
    BetrixZeroFifteen,
    BetrixSixteenZero,
    BetrixSixteenOne,
    BetrixSixteenTwo,
    BetrixSixteenThree,
    BetrixSixteenFour,
    BetrixSixteenFive,
    BetrixSixteenSix,
    BetrixSixteenSeven,
    BetrixSixteenEight,
    BetrixSixteenNine,
    BetrixSixteenTen,
    BetrixSixteenEleven,
    BetrixSixteenTwelve,
    BetrixSixteenThirteen,
    BetrixSixteenFourteen,
    BetrixSixteenFifteen,
    BetrixSixteenSixteen,
    BetrixFifteenSixteen,
    BetrixFourteenSixteen,
    BetrixThirteenSixteen,
    BetrixTwelveSixteen,
    BetrixElevenSixteen,
    BetrixTenSixteen,
    BetrixNineSixteen,
    BetrixEightSixteen,
    BetrixSevenSixteen,
    BetrixSixSixteen,
    BetrixFiveSixteen,
    BetrixFourSixteen,
    BetrixThreeSixteen,
    BetrixTwoSixteen,
    BetrixOneSixteen,
    BetrixZeroSixteen,
    BetrixSeventeenZero,
    BetrixSeventeenOne,
    BetrixSeventeenTwo,
    BetrixSeventeenThree,
    BetrixSeventeenFour,
    BetrixSeventeenFive,
    BetrixSeventeenSix,
    BetrixSeventeenSeven,
    BetrixSeventeenEight,
    BetrixSeventeenNine,
    BetrixSeventeenTen,
    BetrixSeventeenEleven,
    BetrixSeventeenTwelve,
    BetrixSeventeenThirteen,
    BetrixSeventeenFourteen,
    BetrixSeventeenFifteen,
    BetrixSeventeenSixteen,
    BetrixSeventeenSeventeen,
    BetrixSixteenSeventeen,
    BetrixFifteenSeventeen,
    BetrixFourteenSeventeen,
    BetrixThirteenSeventeen,
    BetrixTwelveSeventeen,
    BetrixElevenSeventeen,
    BetrixTenSeventeen,
    BetrixNineSeventeen,
    BetrixEightSeventeen,
    BetrixSevenSeventeen,
    BetrixSixSeventeen,
    BetrixFiveSeventeen,
    BetrixFourSeventeen,
    BetrixThreeSeventeen,
    BetrixTwoSeventeen,
    BetrixOneSeventeen,
    BetrixZeroSeventeen,
    BetrixEighteenZero,
    BetrixEighteenOne,
    BetrixEighteenTwo,
    BetrixEighteenThree,
    BetrixEighteenFour,
    BetrixEighteenFive,
    BetrixEighteenSix,
    BetrixEighteenSeven,
    BetrixEighteenEight,
    BetrixEighteenNine,
    BetrixEighteenTen,
    BetrixEighteenEleven,
    BetrixEighteenTwelve,
    BetrixEighteenThirteen,
    BetrixEighteenFourteen,
    BetrixEighteenFifteen,
    BetrixEighteenSixteen,
    BetrixEighteenSeventeen,
    BetrixEighteenEighteen,
    BetrixSeventeenEighteen,
    BetrixSixteenEighteen,
    BetrixFifteenEighteen,
    BetrixFourteenEighteen,
    BetrixThirteenEighteen,
    BetrixTwelveEighteen,
    BetrixElevenEighteen,
    BetrixTenEighteen,
    BetrixNineEighteen,
    BetrixEightEighteen,
    BetrixSevenEighteen,
    BetrixSixEighteen,
    BetrixFiveEighteen,
    BetrixFourEighteen,
    BetrixThreeEighteen,
    BetrixTwoEighteen,
    BetrixOneEighteen,
    BetrixZeroEighteen,
    BetrixNineteenZero,
    BetrixNineteenOne,
    BetrixNineteenTwo,
    BetrixNineteenThree,
    BetrixNineteenFour,
    BetrixNineteenFive,
    BetrixNineteenSix,
    BetrixNineteenSeven,
    BetrixNineteenEight,
    BetrixNineteenNine,
    BetrixNineteenTen,
    BetrixNineteenEleven,
    BetrixNineteenTwelve,
    BetrixNineteenThirteen,
    BetrixNineteenFourteen,
    BetrixNineteenFifteen,
    BetrixNineteenSixteen,
    BetrixNineteenSeventeen,
    BetrixNineteenEighteen,
    BetrixNineteenNineteen,
    BetrixEighteenNineteen,
    BetrixSeventeenNineteen,
    BetrixSixteenNineteen,
    BetrixFifteenNineteen,
    BetrixFourteenNineteen,
    BetrixThirteenNineteen,
    BetrixTwelveNineteen,
    BetrixElevenNineteen,
    BetrixTenNineteen,
    BetrixNineNineteen,
    BetrixEightNineteen,
    BetrixSevenNineteen,
    BetrixSixNineteen,
    BetrixFiveNineteen,
    BetrixFourNineteen,
    BetrixThreeNineteen,
    BetrixTwoNineteen,
    BetrixOneNineteen,
    BetrixZeroNineteen,
    ExactNumberOfGoals2,
    ExactNumberOfGoals3,
    ExactNumberOfGoals4,
    ExactNumberOfGoals5,
    WinMargin11,
    WinMargin12,
    WinMargin13,
    WinMargin14,
    TENG2Home,
    TENG2Away,
    TENG3Home,
    TENG3Away,
    TENG4Home,
    TENG4Away,
    TENG5Home,
    TENG5Away,
    ExactNumberOfGoals6,
    ExactNumberOfGoals7,
    TENGNull,
    TENG3Null,
    TENG4Null,
    TENG5Null,
    AnyOtherHomeZeroZero = 2191,
    AnyOtherHomeZeroOne,
    AnyOtherHomeZeroTwo,
    AnyOtherHomeZeroThree,
    AnyOtherHomeZeroFour,
    AnyOtherHomeZeroFive,
    AnyOtherHomeZeroSix,
    AnyOtherHomeZeroSeven,
    AnyOtherHomeZeroEight,
    AnyOtherHomeZeroNine,
    AnyOtherHomeZeroTen,
    AnyOtherHomeZeroEleven,
    AnyOtherHomeZeroTwelve,
    AnyOtherHomeZeroThirteen,
    AnyOtherHomeZeroFourteen,
    AnyOtherHomeZeroFifteen,
    AnyOtherHomeZeroSixteen,
    AnyOtherHomeZeroSeventeen,
    AnyOtherHomeZeroEighteen,
    AnyOtherHomeZeroNineteen,
    AnyOtherHomeOneZero,
    AnyOtherHomeOneOne,
    AnyOtherHomeOneTwo,
    AnyOtherHomeOneThree,
    AnyOtherHomeOneFour,
    AnyOtherHomeOneFive,
    AnyOtherHomeOneSix,
    AnyOtherHomeOneSeven,
    AnyOtherHomeOneEight,
    AnyOtherHomeOneNine,
    AnyOtherHomeOneTen,
    AnyOtherHomeOneEleven,
    AnyOtherHomeOneTwelve,
    AnyOtherHomeOneThirteen,
    AnyOtherHomeOneFourteen,
    AnyOtherHomeOneFifteen,
    AnyOtherHomeOneSixteen,
    AnyOtherHomeOneSeventeen,
    AnyOtherHomeOneEighteen,
    AnyOtherHomeOneNineteen,
    AnyOtherHomeTwoZero,
    AnyOtherHomeTwoOne,
    AnyOtherHomeTwoTwo,
    AnyOtherHomeTwoThree,
    AnyOtherHomeTwoFour,
    AnyOtherHomeTwoFive,
    AnyOtherHomeTwoSix,
    AnyOtherHomeTwoSeven,
    AnyOtherHomeTwoEight,
    AnyOtherHomeTwoNine,
    AnyOtherHomeTwoTen,
    AnyOtherHomeTwoEleven,
    AnyOtherHomeTwoTwelve,
    AnyOtherHomeTwoThirteen,
    AnyOtherHomeTwoFourteen,
    AnyOtherHomeTwoFifteen,
    AnyOtherHomeTwoSixteen,
    AnyOtherHomeTwoSeventeen,
    AnyOtherHomeTwoEighteen,
    AnyOtherHomeTwoNineteen,
    AnyOtherHomeThreeZero,
    AnyOtherHomeThreeOne,
    AnyOtherHomeThreeTwo,
    AnyOtherHomeThreeThree,
    AnyOtherHomeThreeFour,
    AnyOtherHomeThreeFive,
    AnyOtherHomeThreeSix,
    AnyOtherHomeThreeSeven,
    AnyOtherHomeThreeEight,
    AnyOtherHomeThreeNine,
    AnyOtherHomeThreeTen,
    AnyOtherHomeThreeEleven,
    AnyOtherHomeThreeTwelve,
    AnyOtherHomeThreeThirteen,
    AnyOtherHomeThreeFourteen,
    AnyOtherHomeThreeFifteen,
    AnyOtherHomeThreeSixteen,
    AnyOtherHomeThreeSeventeen,
    AnyOtherHomeThreeEighteen,
    AnyOtherHomeThreeNineteen,
    AnyOtherHomeFourZero,
    AnyOtherHomeFourOne,
    AnyOtherHomeFourTwo,
    AnyOtherHomeFourThree,
    AnyOtherHomeFourFour,
    AnyOtherHomeFourFive,
    AnyOtherHomeFourSix,
    AnyOtherHomeFourSeven,
    AnyOtherHomeFourEight,
    AnyOtherHomeFourNine,
    AnyOtherHomeFourTen,
    AnyOtherHomeFourEleven,
    AnyOtherHomeFourTwelve,
    AnyOtherHomeFourThirteen,
    AnyOtherHomeFourFourteen,
    AnyOtherHomeFourFifteen,
    AnyOtherHomeFourSixteen,
    AnyOtherHomeFourSeventeen,
    AnyOtherHomeFourEighteen,
    AnyOtherHomeFourNineteen,
    AnyOtherHomeFiveZero,
    AnyOtherHomeFiveOne,
    AnyOtherHomeFiveTwo,
    AnyOtherHomeFiveThree,
    AnyOtherHomeFiveFour,
    AnyOtherHomeFiveFive,
    AnyOtherHomeFiveSix,
    AnyOtherHomeFiveSeven,
    AnyOtherHomeFiveEight,
    AnyOtherHomeFiveNine,
    AnyOtherHomeFiveTen,
    AnyOtherHomeFiveEleven,
    AnyOtherHomeFiveTwelve,
    AnyOtherHomeFiveThirteen,
    AnyOtherHomeFiveFourteen,
    AnyOtherHomeFiveFifteen,
    AnyOtherHomeFiveSixteen,
    AnyOtherHomeFiveSeventeen,
    AnyOtherHomeFiveEighteen,
    AnyOtherHomeFiveNineteen,
    AnyOtherHomeSixZero,
    AnyOtherHomeSixOne,
    AnyOtherHomeSixTwo,
    AnyOtherHomeSixThree,
    AnyOtherHomeSixFour,
    AnyOtherHomeSixFive,
    AnyOtherHomeSixSix,
    AnyOtherHomeSixSeven,
    AnyOtherHomeSixEight,
    AnyOtherHomeSixNine,
    AnyOtherHomeSixTen,
    AnyOtherHomeSixEleven,
    AnyOtherHomeSixTwelve,
    AnyOtherHomeSixThirteen,
    AnyOtherHomeSixFourteen,
    AnyOtherHomeSixFifteen,
    AnyOtherHomeSixSixteen,
    AnyOtherHomeSixSeventeen,
    AnyOtherHomeSixEighteen,
    AnyOtherHomeSixNineteen,
    AnyOtherHomeSevenZero,
    AnyOtherHomeSevenOne,
    AnyOtherHomeSevenTwo,
    AnyOtherHomeSevenThree,
    AnyOtherHomeSevenFour,
    AnyOtherHomeSevenFive,
    AnyOtherHomeSevenSix,
    AnyOtherHomeSevenSeven,
    AnyOtherHomeSevenEight,
    AnyOtherHomeSevenNine,
    AnyOtherHomeSevenTen,
    AnyOtherHomeSevenEleven,
    AnyOtherHomeSevenTwelve,
    AnyOtherHomeSevenThirteen,
    AnyOtherHomeSevenFourteen,
    AnyOtherHomeSevenFifteen,
    AnyOtherHomeSevenSixteen,
    AnyOtherHomeSevenSeventeen,
    AnyOtherHomeSevenEighteen,
    AnyOtherHomeSevenNineteen,
    AnyOtherHomeEightZero,
    AnyOtherHomeEightOne,
    AnyOtherHomeEightTwo,
    AnyOtherHomeEightThree,
    AnyOtherHomeEightFour,
    AnyOtherHomeEightFive,
    AnyOtherHomeEightSix,
    AnyOtherHomeEightSeven,
    AnyOtherHomeEightEight,
    AnyOtherHomeEightNine,
    AnyOtherHomeEightTen,
    AnyOtherHomeEightEleven,
    AnyOtherHomeEightTwelve,
    AnyOtherHomeEightThirteen,
    AnyOtherHomeEightFourteen,
    AnyOtherHomeEightFifteen,
    AnyOtherHomeEightSixteen,
    AnyOtherHomeEightSeventeen,
    AnyOtherHomeEightEighteen,
    AnyOtherHomeEightNineteen,
    AnyOtherHomeNineZero,
    AnyOtherHomeNineOne,
    AnyOtherHomeNineTwo,
    AnyOtherHomeNineThree,
    AnyOtherHomeNineFour,
    AnyOtherHomeNineFive,
    AnyOtherHomeNineSix,
    AnyOtherHomeNineSeven,
    AnyOtherHomeNineEight,
    AnyOtherHomeNineNine,
    AnyOtherHomeNineTen,
    AnyOtherHomeNineEleven,
    AnyOtherHomeNineTwelve,
    AnyOtherHomeNineThirteen,
    AnyOtherHomeNineFourteen,
    AnyOtherHomeNineFifteen,
    AnyOtherHomeNineSixteen,
    AnyOtherHomeNineSeventeen,
    AnyOtherHomeNineEighteen,
    AnyOtherHomeNineNineteen,
    AnyOtherHomeTenZero,
    AnyOtherHomeTenOne,
    AnyOtherHomeTenTwo,
    AnyOtherHomeTenThree,
    AnyOtherHomeTenFour,
    AnyOtherHomeTenFive,
    AnyOtherHomeTenSix,
    AnyOtherHomeTenSeven,
    AnyOtherHomeTenEight,
    AnyOtherHomeTenNine,
    AnyOtherHomeTenTen,
    AnyOtherHomeTenEleven,
    AnyOtherHomeTenTwelve,
    AnyOtherHomeTenThirteen,
    AnyOtherHomeTenFourteen,
    AnyOtherHomeTenFifteen,
    AnyOtherHomeTenSixteen,
    AnyOtherHomeTenSeventeen,
    AnyOtherHomeTenEighteen,
    AnyOtherHomeTenNineteen,
    AnyOtherHomeElevenZero,
    AnyOtherHomeElevenOne,
    AnyOtherHomeElevenTwo,
    AnyOtherHomeElevenThree,
    AnyOtherHomeElevenFour,
    AnyOtherHomeElevenFive,
    AnyOtherHomeElevenSix,
    AnyOtherHomeElevenSeven,
    AnyOtherHomeElevenEight,
    AnyOtherHomeElevenNine,
    AnyOtherHomeElevenTen,
    AnyOtherHomeElevenEleven,
    AnyOtherHomeElevenTwelve,
    AnyOtherHomeElevenThirteen,
    AnyOtherHomeElevenFourteen,
    AnyOtherHomeElevenFifteen,
    AnyOtherHomeElevenSixteen,
    AnyOtherHomeElevenSeventeen,
    AnyOtherHomeElevenEighteen,
    AnyOtherHomeElevenNineteen,
    AnyOtherHomeTwelveZero,
    AnyOtherHomeTwelveOne,
    AnyOtherHomeTwelveTwo,
    AnyOtherHomeTwelveThree,
    AnyOtherHomeTwelveFour,
    AnyOtherHomeTwelveFive,
    AnyOtherHomeTwelveSix,
    AnyOtherHomeTwelveSeven,
    AnyOtherHomeTwelveEight,
    AnyOtherHomeTwelveNine,
    AnyOtherHomeTwelveTen,
    AnyOtherHomeTwelveEleven,
    AnyOtherHomeTwelveTwelve,
    AnyOtherHomeTwelveThirteen,
    AnyOtherHomeTwelveFourteen,
    AnyOtherHomeTwelveFifteen,
    AnyOtherHomeTwelveSixteen,
    AnyOtherHomeTwelveSeventeen,
    AnyOtherHomeTwelveEighteen,
    AnyOtherHomeTwelveNineteen,
    AnyOtherHomeThirteenZero,
    AnyOtherHomeThirteenOne,
    AnyOtherHomeThirteenTwo,
    AnyOtherHomeThirteenThree,
    AnyOtherHomeThirteenFour,
    AnyOtherHomeThirteenFive,
    AnyOtherHomeThirteenSix,
    AnyOtherHomeThirteenSeven,
    AnyOtherHomeThirteenEight,
    AnyOtherHomeThirteenNine,
    AnyOtherHomeThirteenTen,
    AnyOtherHomeThirteenEleven,
    AnyOtherHomeThirteenTwelve,
    AnyOtherHomeThirteenThirteen,
    AnyOtherHomeThirteenFourteen,
    AnyOtherHomeThirteenFifteen,
    AnyOtherHomeThirteenSixteen,
    AnyOtherHomeThirteenSeventeen,
    AnyOtherHomeThirteenEighteen,
    AnyOtherHomeThirteenNineteen,
    AnyOtherHomeFourteenZero,
    AnyOtherHomeFourteenOne,
    AnyOtherHomeFourteenTwo,
    AnyOtherHomeFourteenThree,
    AnyOtherHomeFourteenFour,
    AnyOtherHomeFourteenFive,
    AnyOtherHomeFourteenSix,
    AnyOtherHomeFourteenSeven,
    AnyOtherHomeFourteenEight,
    AnyOtherHomeFourteenNine,
    AnyOtherHomeFourteenTen,
    AnyOtherHomeFourteenEleven,
    AnyOtherHomeFourteenTwelve,
    AnyOtherHomeFourteenThirteen,
    AnyOtherHomeFourteenFourteen,
    AnyOtherHomeFourteenFifteen,
    AnyOtherHomeFourteenSixteen,
    AnyOtherHomeFourteenSeventeen,
    AnyOtherHomeFourteenEighteen,
    AnyOtherHomeFourteenNineteen,
    AnyOtherHomeFifteenZero,
    AnyOtherHomeFifteenOne,
    AnyOtherHomeFifteenTwo,
    AnyOtherHomeFifteenThree,
    AnyOtherHomeFifteenFour,
    AnyOtherHomeFifteenFive,
    AnyOtherHomeFifteenSix,
    AnyOtherHomeFifteenSeven,
    AnyOtherHomeFifteenEight,
    AnyOtherHomeFifteenNine,
    AnyOtherHomeFifteenTen,
    AnyOtherHomeFifteenEleven,
    AnyOtherHomeFifteenTwelve,
    AnyOtherHomeFifteenThirteen,
    AnyOtherHomeFifteenFourteen,
    AnyOtherHomeFifteenFifteen,
    AnyOtherHomeFifteenSixteen,
    AnyOtherHomeFifteenSeventeen,
    AnyOtherHomeFifteenEighteen,
    AnyOtherHomeFifteenNineteen,
    AnyOtherHomeSixteenZero,
    AnyOtherHomeSixteenOne,
    AnyOtherHomeSixteenTwo,
    AnyOtherHomeSixteenThree,
    AnyOtherHomeSixteenFour,
    AnyOtherHomeSixteenFive,
    AnyOtherHomeSixteenSix,
    AnyOtherHomeSixteenSeven,
    AnyOtherHomeSixteenEight,
    AnyOtherHomeSixteenNine,
    AnyOtherHomeSixteenTen,
    AnyOtherHomeSixteenEleven,
    AnyOtherHomeSixteenTwelve,
    AnyOtherHomeSixteenThirteen,
    AnyOtherHomeSixteenFourteen,
    AnyOtherHomeSixteenFifteen,
    AnyOtherHomeSixteenSixteen,
    AnyOtherHomeSixteenSeventeen,
    AnyOtherHomeSixteenEighteen,
    AnyOtherHomeSixteenNineteen,
    AnyOtherHomeSeventeenZero,
    AnyOtherHomeSeventeenOne,
    AnyOtherHomeSeventeenTwo,
    AnyOtherHomeSeventeenThree,
    AnyOtherHomeSeventeenFour,
    AnyOtherHomeSeventeenFive,
    AnyOtherHomeSeventeenSix,
    AnyOtherHomeSeventeenSeven,
    AnyOtherHomeSeventeenEight,
    AnyOtherHomeSeventeenNine,
    AnyOtherHomeSeventeenTen,
    AnyOtherHomeSeventeenEleven,
    AnyOtherHomeSeventeenTwelve,
    AnyOtherHomeSeventeenThirteen,
    AnyOtherHomeSeventeenFourteen,
    AnyOtherHomeSeventeenFifteen,
    AnyOtherHomeSeventeenSixteen,
    AnyOtherHomeSeventeenSeventeen,
    AnyOtherHomeSeventeenEighteen,
    AnyOtherHomeSeventeenNineteen,
    AnyOtherHomeEighteenZero,
    AnyOtherHomeEighteenOne,
    AnyOtherHomeEighteenTwo,
    AnyOtherHomeEighteenThree,
    AnyOtherHomeEighteenFour,
    AnyOtherHomeEighteenFive,
    AnyOtherHomeEighteenSix,
    AnyOtherHomeEighteenSeven,
    AnyOtherHomeEighteenEight,
    AnyOtherHomeEighteenNine,
    AnyOtherHomeEighteenTen,
    AnyOtherHomeEighteenEleven,
    AnyOtherHomeEighteenTwelve,
    AnyOtherHomeEighteenThirteen,
    AnyOtherHomeEighteenFourteen,
    AnyOtherHomeEighteenFifteen,
    AnyOtherHomeEighteenSixteen,
    AnyOtherHomeEighteenSeventeen,
    AnyOtherHomeEighteenEighteen,
    AnyOtherHomeEighteenNineteen,
    AnyOtherHomeNineeenZero,
    AnyOtherHomeNineeenOne,
    AnyOtherHomeNineeenTwo,
    AnyOtherHomeNineeenThree,
    AnyOtherHomeNineeenFour,
    AnyOtherHomeNineeenFive,
    AnyOtherHomeNineeenSix,
    AnyOtherHomeNineeenSeven,
    AnyOtherHomeNineeenEight,
    AnyOtherHomeNineeenNine,
    AnyOtherHomeNineeenTen,
    AnyOtherHomeNineeenEleven,
    AnyOtherHomeNineeenTwelve,
    AnyOtherHomeNineeenThirteen,
    AnyOtherHomeNineeenFourteen,
    AnyOtherHomeNineeenFifteen,
    AnyOtherHomeNineeenSixteen,
    AnyOtherHomeNineeenSeventeen,
    AnyOtherHomeNineeenEighteen,
    AnyOtherHomeNineeenNineteen,
    AnyOtherAwayZeroZero,
    AnyOtherAwayZeroOne,
    AnyOtherAwayZeroTwo,
    AnyOtherAwayZeroThree,
    AnyOtherAwayZeroFour,
    AnyOtherAwayZeroFive,
    AnyOtherAwayZeroSix,
    AnyOtherAwayZeroSeven,
    AnyOtherAwayZeroEight,
    AnyOtherAwayZeroNine,
    AnyOtherAwayZeroTen,
    AnyOtherAwayZeroEleven,
    AnyOtherAwayZeroTwelve,
    AnyOtherAwayZeroThirteen,
    AnyOtherAwayZeroFourteen,
    AnyOtherAwayZeroFifteen,
    AnyOtherAwayZeroSixteen,
    AnyOtherAwayZeroSeventeen,
    AnyOtherAwayZeroEighteen,
    AnyOtherAwayZeroNineteen,
    AnyOtherAwayOneZero,
    AnyOtherAwayOneOne,
    AnyOtherAwayOneTwo,
    AnyOtherAwayOneThree,
    AnyOtherAwayOneFour,
    AnyOtherAwayOneFive,
    AnyOtherAwayOneSix,
    AnyOtherAwayOneSeven,
    AnyOtherAwayOneEight,
    AnyOtherAwayOneNine,
    AnyOtherAwayOneTen,
    AnyOtherAwayOneEleven,
    AnyOtherAwayOneTwelve,
    AnyOtherAwayOneThirteen,
    AnyOtherAwayOneFourteen,
    AnyOtherAwayOneFifteen,
    AnyOtherAwayOneSixteen,
    AnyOtherAwayOneSeventeen,
    AnyOtherAwayOneEighteen,
    AnyOtherAwayOneNineteen,
    AnyOtherAwayTwoZero,
    AnyOtherAwayTwoOne,
    AnyOtherAwayTwoTwo,
    AnyOtherAwayTwoThree,
    AnyOtherAwayTwoFour,
    AnyOtherAwayTwoFive,
    AnyOtherAwayTwoSix,
    AnyOtherAwayTwoSeven,
    AnyOtherAwayTwoEight,
    AnyOtherAwayTwoNine,
    AnyOtherAwayTwoTen,
    AnyOtherAwayTwoEleven,
    AnyOtherAwayTwoTwelve,
    AnyOtherAwayTwoThirteen,
    AnyOtherAwayTwoFourteen,
    AnyOtherAwayTwoFifteen,
    AnyOtherAwayTwoSixteen,
    AnyOtherAwayTwoSeventeen,
    AnyOtherAwayTwoEighteen,
    AnyOtherAwayTwoNineteen,
    AnyOtherAwayThreeZero,
    AnyOtherAwayThreeOne,
    AnyOtherAwayThreeTwo,
    AnyOtherAwayThreeThree,
    AnyOtherAwayThreeFour,
    AnyOtherAwayThreeFive,
    AnyOtherAwayThreeSix,
    AnyOtherAwayThreeSeven,
    AnyOtherAwayThreeEight,
    AnyOtherAwayThreeNine,
    AnyOtherAwayThreeTen,
    AnyOtherAwayThreeEleven,
    AnyOtherAwayThreeTwelve,
    AnyOtherAwayThreeThirteen,
    AnyOtherAwayThreeFourteen,
    AnyOtherAwayThreeFifteen,
    AnyOtherAwayThreeSixteen,
    AnyOtherAwayThreeSeventeen,
    AnyOtherAwayThreeEighteen,
    AnyOtherAwayThreeNineteen,
    AnyOtherAwayFourZero,
    AnyOtherAwayFourOne,
    AnyOtherAwayFourTwo,
    AnyOtherAwayFourThree,
    AnyOtherAwayFourFour,
    AnyOtherAwayFourFive,
    AnyOtherAwayFourSix,
    AnyOtherAwayFourSeven,
    AnyOtherAwayFourEight,
    AnyOtherAwayFourNine,
    AnyOtherAwayFourTen,
    AnyOtherAwayFourEleven,
    AnyOtherAwayFourTwelve,
    AnyOtherAwayFourThirteen,
    AnyOtherAwayFourFourteen,
    AnyOtherAwayFourFifteen,
    AnyOtherAwayFourSixteen,
    AnyOtherAwayFourSeventeen,
    AnyOtherAwayFourEighteen,
    AnyOtherAwayFourNineteen,
    AnyOtherAwayFiveZero,
    AnyOtherAwayFiveOne,
    AnyOtherAwayFiveTwo,
    AnyOtherAwayFiveThree,
    AnyOtherAwayFiveFour,
    AnyOtherAwayFiveFive,
    AnyOtherAwayFiveSix,
    AnyOtherAwayFiveSeven,
    AnyOtherAwayFiveEight,
    AnyOtherAwayFiveNine,
    AnyOtherAwayFiveTen,
    AnyOtherAwayFiveEleven,
    AnyOtherAwayFiveTwelve,
    AnyOtherAwayFiveThirteen,
    AnyOtherAwayFiveFourteen,
    AnyOtherAwayFiveFifteen,
    AnyOtherAwayFiveSixteen,
    AnyOtherAwayFiveSeventeen,
    AnyOtherAwayFiveEighteen,
    AnyOtherAwayFiveNineteen,
    AnyOtherAwaySixZero,
    AnyOtherAwaySixOne,
    AnyOtherAwaySixTwo,
    AnyOtherAwaySixThree,
    AnyOtherAwaySixFour,
    AnyOtherAwaySixFive,
    AnyOtherAwaySixSix,
    AnyOtherAwaySixSeven,
    AnyOtherAwaySixEight,
    AnyOtherAwaySixNine,
    AnyOtherAwaySixTen,
    AnyOtherAwaySixEleven,
    AnyOtherAwaySixTwelve,
    AnyOtherAwaySixThirteen,
    AnyOtherAwaySixFourteen,
    AnyOtherAwaySixFifteen,
    AnyOtherAwaySixSixteen,
    AnyOtherAwaySixSeventeen,
    AnyOtherAwaySixEighteen,
    AnyOtherAwaySixNineteen,
    AnyOtherAwaySevenZero,
    AnyOtherAwaySevenOne,
    AnyOtherAwaySevenTwo,
    AnyOtherAwaySevenThree,
    AnyOtherAwaySevenFour,
    AnyOtherAwaySevenFive,
    AnyOtherAwaySevenSix,
    AnyOtherAwaySevenSeven,
    AnyOtherAwaySevenEight,
    AnyOtherAwaySevenNine,
    AnyOtherAwaySevenTen,
    AnyOtherAwaySevenEleven,
    AnyOtherAwaySevenTwelve,
    AnyOtherAwaySevenThirteen,
    AnyOtherAwaySevenFourteen,
    AnyOtherAwaySevenFifteen,
    AnyOtherAwaySevenSixteen,
    AnyOtherAwaySevenSeventeen,
    AnyOtherAwaySevenEighteen,
    AnyOtherAwaySevenNineteen,
    AnyOtherAwayEightZero,
    AnyOtherAwayEightOne,
    AnyOtherAwayEightTwo,
    AnyOtherAwayEightThree,
    AnyOtherAwayEightFour,
    AnyOtherAwayEightFive,
    AnyOtherAwayEightSix,
    AnyOtherAwayEightSeven,
    AnyOtherAwayEightEight,
    AnyOtherAwayEightNine,
    AnyOtherAwayEightTen,
    AnyOtherAwayEightEleven,
    AnyOtherAwayEightTwelve,
    AnyOtherAwayEightThirteen,
    AnyOtherAwayEightFourteen,
    AnyOtherAwayEightFifteen,
    AnyOtherAwayEightSixteen,
    AnyOtherAwayEightSeventeen,
    AnyOtherAwayEightEighteen,
    AnyOtherAwayEightNineteen,
    AnyOtherAwayNineZero,
    AnyOtherAwayNineOne,
    AnyOtherAwayNineTwo,
    AnyOtherAwayNineThree,
    AnyOtherAwayNineFour,
    AnyOtherAwayNineFive,
    AnyOtherAwayNineSix,
    AnyOtherAwayNineSeven,
    AnyOtherAwayNineEight,
    AnyOtherAwayNineNine,
    AnyOtherAwayNineTen,
    AnyOtherAwayNineEleven,
    AnyOtherAwayNineTwelve,
    AnyOtherAwayNineThirteen,
    AnyOtherAwayNineFourteen,
    AnyOtherAwayNineFifteen,
    AnyOtherAwayNineSixteen,
    AnyOtherAwayNineSeventeen,
    AnyOtherAwayNineEighteen,
    AnyOtherAwayNineNineteen,
    AnyOtherAwayTenZero,
    AnyOtherAwayTenOne,
    AnyOtherAwayTenTwo,
    AnyOtherAwayTenThree,
    AnyOtherAwayTenFour,
    AnyOtherAwayTenFive,
    AnyOtherAwayTenSix,
    AnyOtherAwayTenSeven,
    AnyOtherAwayTenEight,
    AnyOtherAwayTenNine,
    AnyOtherAwayTenTen,
    AnyOtherAwayTenEleven,
    AnyOtherAwayTenTwelve,
    AnyOtherAwayTenThirteen,
    AnyOtherAwayTenFourteen,
    AnyOtherAwayTenFifteen,
    AnyOtherAwayTenSixteen,
    AnyOtherAwayTenSeventeen,
    AnyOtherAwayTenEighteen,
    AnyOtherAwayTenNineteen,
    AnyOtherAwayElevenZero,
    AnyOtherAwayElevenOne,
    AnyOtherAwayElevenTwo,
    AnyOtherAwayElevenThree,
    AnyOtherAwayElevenFour,
    AnyOtherAwayElevenFive,
    AnyOtherAwayElevenSix,
    AnyOtherAwayElevenSeven,
    AnyOtherAwayElevenEight,
    AnyOtherAwayElevenNine,
    AnyOtherAwayElevenTen,
    AnyOtherAwayElevenEleven,
    AnyOtherAwayElevenTwelve,
    AnyOtherAwayElevenThirteen,
    AnyOtherAwayElevenFourteen,
    AnyOtherAwayElevenFifteen,
    AnyOtherAwayElevenSixteen,
    AnyOtherAwayElevenSeventeen,
    AnyOtherAwayElevenEighteen,
    AnyOtherAwayElevenNineteen,
    AnyOtherAwayTwelveZero,
    AnyOtherAwayTwelveOne,
    AnyOtherAwayTwelveTwo,
    AnyOtherAwayTwelveThree,
    AnyOtherAwayTwelveFour,
    AnyOtherAwayTwelveFive,
    AnyOtherAwayTwelveSix,
    AnyOtherAwayTwelveSeven,
    AnyOtherAwayTwelveEight,
    AnyOtherAwayTwelveNine,
    AnyOtherAwayTwelveTen,
    AnyOtherAwayTwelveEleven,
    AnyOtherAwayTwelveTwelve,
    AnyOtherAwayTwelveThirteen,
    AnyOtherAwayTwelveFourteen,
    AnyOtherAwayTwelveFifteen,
    AnyOtherAwayTwelveSixteen,
    AnyOtherAwayTwelveSeventeen,
    AnyOtherAwayTwelveEighteen,
    AnyOtherAwayTwelveNineteen,
    AnyOtherAwayThirteenZero,
    AnyOtherAwayThirteenOne,
    AnyOtherAwayThirteenTwo,
    AnyOtherAwayThirteenThree,
    AnyOtherAwayThirteenFour,
    AnyOtherAwayThirteenFive,
    AnyOtherAwayThirteenSix,
    AnyOtherAwayThirteenSeven,
    AnyOtherAwayThirteenEight,
    AnyOtherAwayThirteenNine,
    AnyOtherAwayThirteenTen,
    AnyOtherAwayThirteenEleven,
    AnyOtherAwayThirteenTwelve,
    AnyOtherAwayThirteenThirteen,
    AnyOtherAwayThirteenFourteen,
    AnyOtherAwayThirteenFifteen,
    AnyOtherAwayThirteenSixteen,
    AnyOtherAwayThirteenSeventeen,
    AnyOtherAwayThirteenEighteen,
    AnyOtherAwayThirteenNineteen,
    AnyOtherAwayFourteenZero,
    AnyOtherAwayFourteenOne,
    AnyOtherAwayFourteenTwo,
    AnyOtherAwayFourteenThree,
    AnyOtherAwayFourteenFour,
    AnyOtherAwayFourteenFive,
    AnyOtherAwayFourteenSix,
    AnyOtherAwayFourteenSeven,
    AnyOtherAwayFourteenEight,
    AnyOtherAwayFourteenNine,
    AnyOtherAwayFourteenTen,
    AnyOtherAwayFourteenEleven,
    AnyOtherAwayFourteenTwelve,
    AnyOtherAwayFourteenThirteen,
    AnyOtherAwayFourteenFourteen,
    AnyOtherAwayFourteenFifteen,
    AnyOtherAwayFourteenSixteen,
    AnyOtherAwayFourteenSeventeen,
    AnyOtherAwayFourteenEighteen,
    AnyOtherAwayFourteenNineteen,
    AnyOtherAwayFifteenZero,
    AnyOtherAwayFifteenOne,
    AnyOtherAwayFifteenTwo,
    AnyOtherAwayFifteenThree,
    AnyOtherAwayFifteenFour,
    AnyOtherAwayFifteenFive,
    AnyOtherAwayFifteenSix,
    AnyOtherAwayFifteenSeven,
    AnyOtherAwayFifteenEight,
    AnyOtherAwayFifteenNine,
    AnyOtherAwayFifteenTen,
    AnyOtherAwayFifteenEleven,
    AnyOtherAwayFifteenTwelve,
    AnyOtherAwayFifteenThirteen,
    AnyOtherAwayFifteenFourteen,
    AnyOtherAwayFifteenFifteen,
    AnyOtherAwayFifteenSixteen,
    AnyOtherAwayFifteenSeventeen,
    AnyOtherAwayFifteenEighteen,
    AnyOtherAwayFifteenNineteen,
    AnyOtherAwaySixteenZero,
    AnyOtherAwaySixteenOne,
    AnyOtherAwaySixteenTwo,
    AnyOtherAwaySixteenThree,
    AnyOtherAwaySixteenFour,
    AnyOtherAwaySixteenFive,
    AnyOtherAwaySixteenSix,
    AnyOtherAwaySixteenSeven,
    AnyOtherAwaySixteenEight,
    AnyOtherAwaySixteenNine,
    AnyOtherAwaySixteenTen,
    AnyOtherAwaySixteenEleven,
    AnyOtherAwaySixteenTwelve,
    AnyOtherAwaySixteenThirteen,
    AnyOtherAwaySixteenFourteen,
    AnyOtherAwaySixteenFifteen,
    AnyOtherAwaySixteenSixteen,
    AnyOtherAwaySixteenSeventeen,
    AnyOtherAwaySixteenEighteen,
    AnyOtherAwaySixteenNineteen,
    AnyOtherAwaySeventeenZero,
    AnyOtherAwaySeventeenOne,
    AnyOtherAwaySeventeenTwo,
    AnyOtherAwaySeventeenThree,
    AnyOtherAwaySeventeenFour,
    AnyOtherAwaySeventeenFive,
    AnyOtherAwaySeventeenSix,
    AnyOtherAwaySeventeenSeven,
    AnyOtherAwaySeventeenEight,
    AnyOtherAwaySeventeenNine,
    AnyOtherAwaySeventeenTen,
    AnyOtherAwaySeventeenEleven,
    AnyOtherAwaySeventeenTwelve,
    AnyOtherAwaySeventeenThirteen,
    AnyOtherAwaySeventeenFourteen,
    AnyOtherAwaySeventeenFifteen,
    AnyOtherAwaySeventeenSixteen,
    AnyOtherAwaySeventeenSeventeen,
    AnyOtherAwaySeventeenEighteen,
    AnyOtherAwaySeventeenNineteen,
    AnyOtherAwayEighteenZero,
    AnyOtherAwayEighteenOne,
    AnyOtherAwayEighteenTwo,
    AnyOtherAwayEighteenThree,
    AnyOtherAwayEighteenFour,
    AnyOtherAwayEighteenFive,
    AnyOtherAwayEighteenSix,
    AnyOtherAwayEighteenSeven,
    AnyOtherAwayEighteenEight,
    AnyOtherAwayEighteenNine,
    AnyOtherAwayEighteenTen,
    AnyOtherAwayEighteenEleven,
    AnyOtherAwayEighteenTwelve,
    AnyOtherAwayEighteenThirteen,
    AnyOtherAwayEighteenFourteen,
    AnyOtherAwayEighteenFifteen,
    AnyOtherAwayEighteenSixteen,
    AnyOtherAwayEighteenSeventeen,
    AnyOtherAwayEighteenEighteen,
    AnyOtherAwayEighteenNineteen,
    AnyOtherAwayNineeenZero,
    AnyOtherAwayNineeenOne,
    AnyOtherAwayNineeenTwo,
    AnyOtherAwayNineeenThree,
    AnyOtherAwayNineeenFour,
    AnyOtherAwayNineeenFive,
    AnyOtherAwayNineeenSix,
    AnyOtherAwayNineeenSeven,
    AnyOtherAwayNineeenEight,
    AnyOtherAwayNineeenNine,
    AnyOtherAwayNineeenTen,
    AnyOtherAwayNineeenEleven,
    AnyOtherAwayNineeenTwelve,
    AnyOtherAwayNineeenThirteen,
    AnyOtherAwayNineeenFourteen,
    AnyOtherAwayNineeenFifteen,
    AnyOtherAwayNineeenSixteen,
    AnyOtherAwayNineeenSeventeen,
    AnyOtherAwayNineeenEighteen,
    AnyOtherAwayNineeenNineteen,
    AnyOtherUnknownDraw,
    AnyOtherUnknownHome,
    AnyOtherUnknownAway,
}

export enum MatchStatus {
    NotStartedYet = 1,
    InProgress,
    Finished,
    Canceled
}

export enum MatrixValue {
    NotSelected,
    Selected,
    Insured
}

export enum OutcomeId {
    Winner = 0,
    HomeWin = 1,
    Draw,
    AwayWin,
    BTTSYes,
    BTTSNo,
    Over,
    Under,
    EHHome,
    EHDraw,
    EHAway,
    CorrectScore,
    BetAgainstAway,
    ENGZero = 22,
    ENGOne,
    ENGTwo,
    ENGThree,
    ENGFour,
    ENGFive,
    ENGSixPlus,
    ZeroOne = 30,
    TwoThree,
    FourPlus,
    ScoreBoth,
    ScoreHome,
    ScoreAway,
    ScoreNone,
    FLTSHome,
    FLTSAway,
    FLTSNone,
    HSHFirst,
    HSHSecond,
    HSHTie,
    THSHFirst,
    THSHSecond,
    THSHTie,
    TENGZero,
    TENGOne,
    TENGTwo,
    TENGThreePlus,
    TFG010,
    TFG1120,
    TFG2130,
    TFG3140,
    TFG4150,
    TFG5160,
    TFG6170,
    TFG7180,
    TFG81Plus,
    OverHome = 59,
    UnderHome,
    OverAway,
    UnderAway,
    BetrixYes,
    BetrixNo,
    FTGBoth,
    FTGHome,
    FTGAway,
    FTGUnder,
    HomeOrDraw,
    HomeOrAway,
    DrawOrAway,
    HAHome,
    HAAWay,
    AHHome,
    AHAway,
    DNBHome,
    DNBAway,
    Odd = 80,
    Even = 81,
    TFG2015 = 88,
    TFG21630,
    TFG23145,
    TFG24660,
    TFG26175,
    TFG276Plus,
    TFG3030,
    TFG33160,
    TFG361Plus,
    BetAgainstDraw = 97,
    BetAgainstHome,
    THSH2FirstYes,
    THSH2FirstNo,
    THSH2SecondYes,
    THSH2SecondNo,
    THSH2TieYes,
    THSH2TieNo,
    TNGZeroOne,
    TNGTwoThree,
    TNGFourPlus,
    WM2ThreePlus,
    WM2Two,
    WM2One,
    WM2Zero,
    WM2MinusOne,
    WM2MinusTwo,
    WM2MinusThreePlus,
    WM7FourPlus = 135,
    WM7Three,
    WM7Two,
    WM7One,
    WM7Zero,
    WM7MinusOne,
    WM7MinusTwoPlus,
    WM8TwoPlus,
    WM8One,
    WM8Zero,
    WM8MinusOne,
    WM8MinusTwo,
    WM8MinusThree,
    WM8MinusFourPlus,
    WM9FivePlus,
    WM9Four,
    WM9Three,
    WM9Two,
    WM9One,
    WM9Zero,
    WM9MinusOnePlus,
    WM10OnePlus,
    WM10Zero,
    WM10MinusOne,
    WM10MinusTwo,
    WM10MinusThree,
    WM10MinusFour,
    WM10MinusFivePlus,
    BetrixOut = 163,
    BetrixYesNo,
    BetrixYesOut,
    BetrixNoOut,
    BetrixYesNO,
    BetrixYesOUT,
    BetrixNoYES,
    BetrixNoOUT,
    BetrixOutYES,
    BetrixOutNO,
    HTOrFTHome = 228,
    HTOrFTDraw,
    HTOrFTAway,
    FHSHHomeHome,
    FHSHDrawHome,
    FHSHAwayHome,
    FHSHHomeDraw,
    FHSHDrawDraw,
    FHSHAwayDraw,
    FHSHHomeAway,
    FHSHDrawAway,
    FHSHAwayAway,
    FHOrSHHome,
    FHOrSHDraw,
    FHOrSHAway,
    HTFT2HomeHome = 243,
    HTFT2DrawOrAwayHome,
    HTFT2HomeDrawOrAway,
    HTFT2DrawOrAwayDrawOrAway,
    HTFT3HomeOrDrawHomeOrDraw,
    HTFT3AwayHomeOrDraw,
    HTFT3HomeOrDrawAway,
    HTFT3AwayAway,
    HTFTHomeHome,
    HTFTDrawHome,
    HTFTAwayHome,
    HTFTHomeDraw,
    HTFTDrawDraw,
    HTFTAwayDraw,
    HTFTHomeAway,
    HTFTDrawAway,
    HTFTAwayAway,
    TFGNone,
    TFG2None,
    TFG3None,
    TTFG030,
    TTFG3160,
    TTFG61Plus,
    TTFGNone,
    ENG2ZeroOne,
    ENG2Two,
    ENG2Three,
    ENG2Four,
    ENG2Five,
    ENG2Six,
    ENG2SevenPlus,
    ENG3ZeroTwo,
    ENG3Three,
    ENG3Four,
    ENG3Five,
    ENG3Six,
    ENG3Seven,
    ENG3EightPlus,
    ENG4ZeroThree,
    ENG4Four,
    ENG4Five,
    ENG4Six,
    ENG4Seven,
    ENG4Eight,
    ENG4NinePlus,
    ENG5ZeroFour,
    ENG5Five,
    ENG5Six,
    ENG5Seven,
    ENG5Eight,
    ENG5Nine,
    ENG5TenPlus,
    WM11SixPlus,
    WM11Five,
    WM11Four,
    WM11Three,
    WM11Two,
    WM11One,
    WM11ZeroMinus,
    WM12ZeroPlus,
    WM12MinusOne,
    WM12MinusTwo,
    WM12MinusThree,
    WM12MinusFour,
    WM12MinusFive,
    WM12MinusSixPlus,
    WM13SevenPlus,
    WM13Six,
    WM13Five,
    WM13Four,
    WM13Three,
    WM13Two,
    WM13OnePlus,
    WM14MinusOnePlus,
    WM14MinusTwo,
    WM14MinusThree,
    WM14MinusFour,
    WM14MinusFive,
    WM14MinusSix,
    WM14MinusSevenPlus,
    TENG2ZeroOne,
    TENG2Two,
    TENG2Three,
    TENG2FourPlus,
    TENG3ZeroTwo,
    TENG3Three,
    TENG3Four,
    TENG3FivePlus,
    TENG4ZeroThree,
    TENG4Four,
    TENG4Five,
    TENG4SixPlus,
    TENG5ZeroFour,
    TENG5Five,
    TENG5Six,
    TENG5SevenPlus,
    ENG6ZeroFive,
    ENG6Six,
    ENG6Seven,
    ENG6Eight,
    ENG6Nine,
    ENG6Ten,
    ENG6ElevenPlus,
    ENG7ZeroSix,
    ENG7Seven,
    ENG7Eight,
    ENG7Nine,
    ENG7Ten,
    ENG7Eleven,
    ENG7TwelvePlus,
    TWTHome,
    TWTAway,
    TTRMOverHome,
    TTRMUnderHome,
    TTRMOverAway,
    TTRMUnderAway,
    TFOTROverHome,
    TFOTRUnderHome,
    TFOTROverAway,
    TFOTRUnderAway,
}

export enum SportId {
    Undefined,
    Soccer,
    Tennis,
    Basketball,
    Hockey,
    'American Football',
    Baseball,
    Handball,
    'Rugby Union',
    Floorball,
    Bandy,
    Futsal,
    Volleyball,
    Cricket,
    Darts,
    Snooker,
    Boxing,
    'Beach Volleyball',
    'Aussie Rules',
    'Rugby League',
    'Ski Jumping',
    Badminton,
    Waterpolo,
    Cycling,
    'Horse Racing',
    Greyhounds,
    'Beach Soccer',
    Golf,
    MMA,
    Chess,
    Pesapallo,
    Motorsport,
    'Table Tennis',
    Pool,
    Wrestling,
    'Bull Riding',
    Esports
}

export enum ScopeId {
    CUSTOM = 0,
    FTOT, // full time with ot
    FT, // full time
    H1, // halves
    H2,
    P1, // periods
    P2,
    P3,
    Q1, // quarters
    Q2,
    Q3 = 10,
    Q4,
    S1, // sets
    S2,
    S3,
    S4,
    S5,
    I1, // innings
    I2,
    I3,
    I4 = 20,
    I5,
    I6,
    I7,
    I8,
    I9,
    NS, // next set
    CS, // current set
    NG, // next game
    CG, // current game
    IP = 30, // in play
    ET, // extra time
    ET1,
    ET2,
    PEN, // penalties
    J1, // jump 1
    J2, // jump 2
    J3, // jump 3
    J4 // jump 4
}

export enum ScopeName {
    CUSTOM = 0,
    'Full time with overtime', // full time with ot
    'Full time', // full time
    '1st half', // halves
    '2nd half',
    '1st period', // periods
    '2nd period',
    '3rd period',
    '1st quarter', // quarters
    '2nd quarter',
    '3rd quarter' = 10,
    '4th quarter',
    '1st set', // sets
    '2nd set',
    '3rd set',
    '4th set',
    '5th set',
    '1st inning', // innings
    '2nd inning',
    '3rd inning',
    '4th inning' = 20,
    '5th inning',
    '6th inning',
    '7th inning',
    '8th inning',
    '9th inning',
    'Next set', // next set
    'Current set', // current set
    'Next game', // next game
    'Current game', // current game
    'In play' = 30, // in play
    'Extra time',
    '1st Extra time',
    '2nd Extra time',
    'Penalties',
    'Jump 1',
    'Jump 2',
    'Jump 3',
    'Jump 4',
}