import React from 'react'
import Base from '../../../views/components/Base'
import dataServer from '../../../network/rest'
import DateMatches from '../../components/DateMatches'
import LeagueTableTitle from '../../components/LeagueTableTitle'
import MainTitle from '../../components/MainTitle'
import { capitalize, isMobile, getLeagueName, getRegionName, fillDatesWithMatches } from '../../../utils'
import config from '../../../configjson'
import { League, Odds, MatchesByDate, MainMarket, Match, QuickLeague } from '../../../state/stores/feed/models'
import { RouteChildrenProps } from 'react-router'
import { SportId, BetTypeGroupId, ScopeId } from '../../../state/enums'
import { store } from '../../../state'
import './style.scss'
import {betrixDefaultLocale} from '../../../assets'

interface Props extends RouteChildrenProps {
    leagues: League[]
    mainMarkets: MainMarket[]
    matches: Match[]
    odds: Odds[]
    quickLeagues: QuickLeague[]
    scopeId: ScopeId
}

interface State {
    dateMatches: MatchesByDate
    leagueId: number
    leagueName: string
    regionName: string
    requestSent: boolean
    sportId: number
}

class SportLeague extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            dateMatches: {},
            leagueId: 0,
            leagueName: '',
            regionName: '',
            requestSent: false,
            sportId: 1
        }
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            window.location.reload()
        }
    }
    static getDerivedStateFromProps(props: Props, state: State) {
        const { leagues, match, matches } = props
        const { requestSent } = state

        if (!requestSent && leagues.length && matches.length) {
            const league = match && match.params && match.params['league']
            const region = match && match.params && match.params['region']
            const sport = props.match && props.match.params && props.match.params['sport']

            const capitalizedSport: string = capitalize(sport)
            const sportid: SportId = SportId[capitalizedSport]
            const leagueName: string = getLeagueName(league)
            const regionName: string = getRegionName(region)
            const leagueFound = leagues.find(l => l.region.toLowerCase() === regionName.toLowerCase() && l.name.toLowerCase() === leagueName.toLowerCase())
            if (leagueFound) {
                dataServer.request('get', 'get-odds', {
                    bookmakerid: 0,
                    id: config.configuration_id,
                    leagueid: leagueFound.id,
                    sportid
                })

                const newState: State = {
                    dateMatches: fillDatesWithMatches(matches),
                    leagueId: leagueFound.id,
                    leagueName: leagueFound.name,
                    regionName: leagueFound.region,
                    requestSent: true,
                    sportId: +sportid
                }

                return newState
            } else {
                const newState: State = {
                    dateMatches: {},
                    leagueId: 0,
                    leagueName: '',
                    regionName: '',
                    requestSent: true,
                    sportId: +sportid
                }

                return newState
            }
        }

        return null
    }
    render() {
        const { leagues, mainMarkets, matches, odds, scopeId} = this.props
        const { dateMatches, leagueId, leagueName, regionName, sportId } = this.state

        const matchDates: string[] = Object.keys(dateMatches)
        const noMatches: boolean = !matchDates.length || !odds.length
        const markets: string[] = getMarkets(mainMarkets, odds, sportId)

        return (
            <Base>
                <div className="sport-league">
                    <MainTitle
                        locale = {betrixDefaultLocale}
                        allLeagues={leagues}
                        allMatches={matches}
                        league={leagueName}
                        region={regionName}
                        sportId={sportId}
                    />
                    {(!noMatches) ?
                        <>
                            <LeagueTableTitle
                                leagueName={leagueName}
                                regionName={regionName}
                                markets={markets}
                                sportId={sportId}
                            />
                            {matchDates.map(date => {
                                const dateWithMatches: Match[] = dateMatches[date]
                                const leagueMatches: Match[] = dateWithMatches.filter(d => d.leagueid === leagueId)

                                if (!leagueMatches.length) {
                                    return null
                                }

                                return (
                                    <DateMatches
                                        key={date}
                                        date={date}
                                        markets={markets}
                                        matches={leagueMatches}
                                        odds={odds}
                                        region={regionName}
                                        sportId={sportId}
                                        scopeId={scopeId}
                                    />
                                )
                            })}
                        </>
                        :
                        <div className="no-matches">
                            <span>No matches available</span>
                        </div>
                    }
                </div>
            </Base>
        )
    }
}

export default store.subscribe(main => ({
    leagues: main.feed.leagues,
    mainMarkets: main.feed.mainMarkets,
    matches: main.feed.matches,
    odds: main.feed.odds,
    quickLeagues: main.feed.quickLeagues,
}))(SportLeague)

const getMarkets = (mainMarkets: MainMarket[], odds: Odds[], sportId: SportId): string[] => {
    if (isMobile()) {
        const mainSportMarket = mainMarkets.find(m => m.sportid === sportId)
        if (mainSportMarket) {
            const newMarkets: string[] = [BetTypeGroupId[mainSportMarket.bettypegroup]]
            return newMarkets
        }
    }

    let markets: string[] = (odds && odds[0]) ? Object.keys(odds[0].markets) : []
    for (const item of odds) {
        const itemMarkets: string[] = Object.keys(item.markets)
        if (itemMarkets.length > markets.length) {
            markets = itemMarkets
        }
    }

    return markets
}