import React from 'react'
import Base from '../../components/Base'
import BetslipHistoryRange from '../../components/BetslipHistoryRange'
import BetslipHistoryTable from '../../components/BetslipHistoryTable'
import { BetslipHistoryStandard, BetslipHistoryItem } from '../../../state/stores/betslip/models'
import { store } from '../../../state'
import './style.scss'

interface Props {
    cashout: string
    historyItems: BetslipHistoryItem[]
    historySubitems: BetslipHistoryItem[]
    historyStandard: BetslipHistoryStandard[]
}

class BetslipHistory extends React.Component<Props, {}> {
    render() {
        const { cashout, historyItems, historySubitems, historyStandard } = this.props
        
        return (
            <Base>
                <div className="betslip-history">
                    <h3>BETSLIP HISTORY</h3>
                    <BetslipHistoryRange/>
                    <BetslipHistoryTable
                        cashout={cashout}
                        historyItems={historyItems}
                        historySubitems={historySubitems}
                        historyStandard={historyStandard}
                    />
                </div>
            </Base>
        )
    }
}

export default store.subscribe(main => ({
    cashout: main.betslip.cashout,
    historyItems: main.betslip.history.items,
    historySubitems: main.betslip.history.subitems,
    historyStandard: main.betslip.history.standard
}))(BetslipHistory)