import React from 'react'
import FaIcon from '../FaIcon'
import Spinner from '../Spinner'
import { closePopup } from '../../../utils'
import { store } from '../../../state'
import './style.scss'

interface Props {
    content: string | JSX.Element
}

const Popup = ({ content }: Props) => {
    const popupContent: JSX.Element = getContent(content)

    return (
        <div className="popup">
            {(content !== '') ?
                <div className="popup-inner">
                    <FaIcon
                        icon="times"
                        type="solid"
                        className="close-popup"
                        onClick={closePopup}
                    />
                    {popupContent}
                </div>
            :
                <Spinner />
            }
        </div>
    )
}

export default store.subscribe(main => ({
    content: main.popup.content
}))(Popup)

const getContent = (content: string | JSX.Element): JSX.Element => {
    if (typeof content === 'string') {
        return (
            <span className="text-content">{content}</span>
        )
    }

    return content
}