import React from 'react'
import LeagueList from '../LeagueList'
import { getLeagueMatchesLength, getSportFromPathname, capitalize } from '../../../utils'
import { getFlagElement } from '../../../helpers'
import { Regions, Match, League, QuickLeague } from '../../../state/stores/feed/models'
import './style.scss'
import { SportId } from '../../../state/enums'

interface Props {
    matches: Match[]
    quickLeagues: QuickLeague[]
    regions: Regions
}

const RegionsList = ({ matches, quickLeagues, regions }: Props) => {
    const regionNames: string[] = Object.keys(regions)

    return (
        <div className="regions-list">
            {regionNames.map(region => {
                const flagElement: JSX.Element = getFlagElement(region)
                const leagues: League[] = regions[region]
                const sport: string = capitalize(getSportFromPathname())
                const sportLeagues: League[] = leagues.filter(l => l.sportid === SportId[sport])
                const leagueMatchesLength: number[] = getLeagueMatchesLength(sportLeagues, matches)
                const hasRegionLeagues: boolean = leagueMatchesLength.some(v => v > 0)
                
                if (!hasRegionLeagues) {
                    return null
                }
                
                return (
                    <div key={region} className="region">
                        <div className="region-title">
                            {flagElement}
                            <h3>{region}</h3>
                        </div>
                        <LeagueList
                            leagues={sportLeagues}
                            leagueMatchesLength={leagueMatchesLength}
                            quickLeagues={quickLeagues}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default RegionsList