import React, { useState } from 'react'
import FaIcon from '../../FaIcon'
import StakeWrapper from '../StakeWrapper'
import BetslipItemData from '../BetslipItemData'
import { BSStandardItem } from '../../../../state/stores/betslip/models'
import { BetTypeGroupId, BetTypeId } from '../../../../state/enums'
import { getOutcomeTextBetslip, getMarketNameAndOptions, roundProduct, getTeamNameFromOutcome } from '../../../../utils'
import './style.scss'
import {getScopeName} from '../../Betrix/TopRow'

interface Props {
    activeStakeInput: number
    item: BSStandardItem
    sameMatches: Array<string>
    singleBets: {[oddsId: number]: string}
    handleSingleBet: (index: number, stake: string) => void
    removeBetslipItem: (index: number, matchId: string, typeProperty: string) => void
    setActiveStakeInput: (stake: number) => void
    min_bet: number
    max_bet: number
    currency: string
}

const StandardItem = ({ activeStakeInput, handleSingleBet, item, removeBetslipItem, sameMatches, setActiveStakeInput, singleBets, min_bet, max_bet, currency }: Props) => {
    const [extended, handleExtended] = useState(false)
    
    const { awayTeam, betTypeGroupId, betTypeId, homeTeam, matchId, MMBetTypeId, MMSelections, oddsValue, outcomeId, outcomeValue, sportId, scopeId } = item

    const handleStake = (stake: string): void => {
        const newStake: string = stake.replace(/,/g, '.')
        handleSingleBet(item.index, newStake)
    }

    const stakeToShow: string = (singleBets[item.index]) ? singleBets[item.index] : ''
    const betValue: number = +stakeToShow * parseFloat(oddsValue)
    const betValueRounded: number = roundProduct(betValue)
    const teamName: string = getTeamNameFromOutcome(awayTeam, homeTeam, outcomeId)
    const marketName = getMarketNameAndOptions(BetTypeGroupId[betTypeGroupId], sportId, { teamName }).name
    const isSameMatchOnBetslip = sameMatches.find(s => s === matchId)
    const sameMatchClassName: string = (isSameMatchOnBetslip) ? 'same-match' : ''
    const outcomeValueText: string = (betTypeId === BetTypeId.MMGeneral) ? 'Multi Selection' : (betTypeGroupId === BetTypeGroupId.CorrectScore) ? getCorrectScoreText(betTypeId) : getOutcomeTextBetslip(awayTeam, homeTeam, outcomeId, outcomeValue)
    const iconName: string = (extended) ? 'chevron-up' : 'chevron-down'
    const extendedClassName: string = (extended) ? 'extended' : ''
    const betTypeIdForRemoving = (MMBetTypeId) ? MMBetTypeId : betTypeId
    const typeProperty = (betTypeGroupId === BetTypeGroupId.CorrectScore) ? BetTypeGroupId[betTypeGroupId] : BetTypeId[betTypeIdForRemoving]
    return parseFloat(oddsValue) === 1 ? <div/> : (
        <div className={`betslip-item ${sameMatchClassName}`}>
            <FaIcon
                icon="times"
                type="solid"
                className="remove-item"
                onClick={() => removeBetslipItem(item.index, matchId, typeProperty)}
            />
            <BetslipItemData
                awayTeam={awayTeam}
                homeTeam={homeTeam}
                extendedClassName={extendedClassName}
                iconName={(MMSelections) ? iconName : ''}
                marketName={marketName + ' (' + getScopeName(scopeId) + ')'}
                MMSelections={MMSelections}
                oddsValue={oddsValue}
                outcomeText={outcomeValueText}
                sportId={sportId}
                handleExtended={() => handleExtended(!extended)}
            />
            <StakeWrapper
                activeStakeInput={activeStakeInput}
                betValue={betValueRounded}
                handleStake={handleStake}
                oddsIndex={item.index}
                setActiveStakeInput={setActiveStakeInput}
                stake={stakeToShow}
                currency = {currency}
                min_bet = {min_bet}
                max_bet = {max_bet}
            />
            {isSameMatchOnBetslip &&
                <span className="same-match-text">Same match on betslip</span>
            }
        </div>
    )
}

export default StandardItem

const getCorrectScoreText = (betTypeId: BetTypeId) => {
    switch (betTypeId) {
        case BetTypeId.ZeroZero:
            return '0:0'
        case BetTypeId.ZeroOne:
            return '0:1'
        case BetTypeId.ZeroTwo:
            return '0:2'
        case BetTypeId.ZeroThree:
            return '0:3'
        case BetTypeId.ZeroFour:
            return '0:4'
        case BetTypeId.OneZero:
            return '1:0'
        case BetTypeId.OneOne:
            return '1:1'
        case BetTypeId.OneTwo:
            return '1:2'
        case BetTypeId.OneThree:
            return '1:3'
        case BetTypeId.OneFour:
            return '1:4'
        case BetTypeId.TwoZero:
            return '2:0'
        case BetTypeId.TwoOne:
            return '2:1'
        case BetTypeId.TwoTwo:
            return '2:2'
        case BetTypeId.TwoThree:
            return '2:3'
        case BetTypeId.TwoFour:
            return '2:4'
        case BetTypeId.ThreeZero:
            return '3:0'
        case BetTypeId.ThreeOne:
            return '3:1'
        case BetTypeId.ThreeTwo:
            return '3:2'
        case BetTypeId.ThreeThree:
            return '3:3'
        case BetTypeId.ThreeFour:
            return '3:4'
        case BetTypeId.FourZero:
            return '4:0'
        case BetTypeId.FourOne:
            return '4:1'
        case BetTypeId.FourTwo:
            return '4:2'
        case BetTypeId.FourThree:
            return '4:3'
        case BetTypeId.FourFour:
            return '4:4'
        case BetTypeId.AnyOtherUnknownHome:
            return 'Any other result (Home win)'
        case BetTypeId.AnyOtherUnknownDraw:
            return 'Any other result (Draw)'
        case BetTypeId.AnyOtherUnknownAway:
            return 'Any other result (Away win)'
        default:
            return ''
    }
}