import React from 'react'
import { getMarketNameAndOptions } from '../../../utils'
import { SportId } from '../../../state/enums'

interface Props {
    markets: string[]
    sportId: SportId
}

const MarketOptions = ({ markets, sportId }: Props) => (
    <div className="columns-equal-width">
        {markets.map(market => {
            const marketData = getMarketNameAndOptions(market, sportId)
            const options: string[] = marketData.options
            return (
                <div key={market} className="market-options">
                    {options.map(option =>
                        <span key={option}>{option}</span>    
                    )}
                </div>
            )
        })}
    </div>
)

export default MarketOptions