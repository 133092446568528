import React from 'react'
import browserHistory from '../history';
import routes from '../routes';
import { Route, Router, Switch } from 'react-router-dom'
import { hot } from 'react-hot-loader'
import './styles/app.scss'

class App extends React.Component {
    render() {
        return (
            <Router history={browserHistory}>
                <Switch>
                    {routes.map(route =>
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            render={(props: any) => {
                                return React.createElement(route.component, {
                                    ...props
                                });
                            }}
                        />)
                    }
                </Switch>
            </Router>
        );
    }
}

declare const module: object
export default hot(module)(App)