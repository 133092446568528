import React, { useState } from 'react'
import FaIcon from '../../FaIcon'
import StakeWrapper from '../StakeWrapper'
import BetslipItemData from '../BetslipItemData'
import { BSBetrixItem, BSBetrixSubitem } from '../../../../state/stores/betslip/models'
import { roundProduct, sortByProperty, getOutcomeText, isSportWinner } from '../../../../utils'
import { ScopeId } from '../../../../state/enums'
import {getScopeName} from '../../Betrix/TopRow'

interface Props {
    activeStakeInput: number
    item: BSBetrixItem
    sameMatches: Array<string>
    singleBets: {[oddsId: number]: string}
    showMarketMaker: boolean
    handleSingleBet: (key: number, stake: string) => void
    removeBetslipItem: (index: number, matchId: string) => void
    setActiveStakeInput: (stake: number) => void
    min_bet: number
    max_bet: number
    currency: string
    stage: string
    league: string
}

const BetrixItem = ({ stage, league, activeStakeInput, item, sameMatches, showMarketMaker, singleBets, handleSingleBet, removeBetslipItem, setActiveStakeInput, min_bet, max_bet, currency }: Props) => {
    const [extended, handleExtended] = useState(false)

    const { awayTeam, homeTeam, matchId, oddsValue, sportId, subitems, outcomeId, scopeId } = item

    const handleStake = (stake: string): void => {
        const newStake: string = stake.replace(/,/g, '.')
        handleSingleBet(item.index, newStake)
    }

    const stakeToShow: string = (singleBets[item.index]) ? singleBets[item.index] : ''
    const betValue: number = +stakeToShow * parseFloat(oddsValue)
    const betValueRounded: number = roundProduct(betValue)
    const sortedSubitems: BSBetrixSubitem[] = (subitems.length > 1) ? sortByProperty(subitems, 'insured') : []

    const isSameMatchOnBetslip = sameMatches.find(s => s === matchId)
    const sameMatchClassName: string = (isSameMatchOnBetslip) ? 'same-match' : ''
    
    const multipleScoresSelected = subitems.length > 1
    const isAnyOtherResultDraw: boolean = (subitems.length) ? (subitems[0] && subitems[0].homeGoals === -1 && subitems[0].awayGoals === -1) : false
    const isAnyOtherResultHome: boolean = (subitems.length) ? (subitems[0] && subitems[0].homeGoals === -2 && subitems[0].awayGoals === -2) : false
    const isAnyOtherResultAway: boolean = (subitems.length) ? (subitems[0] && subitems[0].homeGoals === -3 && subitems[0].awayGoals === -3) : false

    let outcomeText: string = (multipleScoresSelected || !subitems.length) ? getOutcomeText(awayTeam, homeTeam, outcomeId, null, { showMarketMaker: true }) : getCorrectScoreText(subitems, isAnyOtherResultHome, isAnyOtherResultDraw, isAnyOtherResultAway)

    const scope: ScopeId = (multipleScoresSelected || !subitems.length) ? scopeId : getCorrectScoreScope(subitems, isAnyOtherResultHome, isAnyOtherResultDraw, isAnyOtherResultAway)

    const iconName: string = (multipleScoresSelected) ? ((extended) ? 'chevron-up' : 'chevron-down') : ''
    const extendedClassName: string = (multipleScoresSelected && extended) ? 'extended' : ''

    if (isSportWinner(sportId)) {
        if (subitems.length === 1) {
            outcomeText = subitems[0].desc + (subitems[0].Desc ? ' (' + subitems[0].Desc + ')' : '')
        }
    }
    return (parseFloat(oddsValue) === 1 ? <div/> :
        <div className={`betslip-item ${sameMatchClassName}`}>
            <FaIcon
                icon="times"
                type="solid"
                className="remove-item"
                onClick={() => removeBetslipItem(item.index, matchId)}
            />
            <BetslipItemData
                awayTeam={awayTeam}
                extendedClassName={extendedClassName}
                homeTeam={homeTeam}
                iconName={iconName}
                isAnyOtherResultHome={isAnyOtherResultHome}
                isAnyOtherResultDraw={isAnyOtherResultDraw}
                isAnyOtherResultAway={isAnyOtherResultAway}
                marketName={(isSportWinner(sportId) ? 'Winner' : 'Correct Score') + ' (' + getScopeName(scope) + ')'}
                oddsValue={oddsValue}
                outcomeText={outcomeText}
                sportId={sportId}
                subitems={sortedSubitems}
                handleExtended={() => multipleScoresSelected && handleExtended(!extended)}
                stage = {stage}
                league = {league}
            />
            <StakeWrapper
                activeStakeInput={activeStakeInput}
                betValue={betValueRounded}
                handleStake={handleStake}
                oddsIndex={0}
                setActiveStakeInput={setActiveStakeInput}
                stake={stakeToShow}
                currency = {currency}
                min_bet = {min_bet}
                max_bet = {max_bet}
            />
            {isSameMatchOnBetslip &&
                <span className="same-match-text">Same match on betslip</span>
            }
        </div>
    )
}

export default BetrixItem

const getCorrectScoreText = (subitems: Array<BSBetrixSubitem>, isAnyOtherResultHome: boolean, isAnyOtherResultDraw: boolean, isAnyOtherResultAway: boolean): string => {
    if (isAnyOtherResultHome) {
        return 'Any other result (Home win)'
    } else if (isAnyOtherResultDraw) {
        return 'Any other result (Draw)'
    } else if (isAnyOtherResultAway) {
        return 'Any other result (Away win)'
    } else {
        return `${subitems[0].homeGoals} : ${subitems[0].awayGoals}`
    }
}

const getCorrectScoreScope = (subitems: Array<BSBetrixSubitem>, isAnyOtherResultHome: boolean, isAnyOtherResultDraw: boolean, isAnyOtherResultAway: boolean): ScopeId => {
    return subitems[0].scopeId
}