import React from 'react'
import BetrixItem from './BetrixItem'
import dataServer from '../../../network/rest'
import FaIcon from '../FaIcon'
import Link from '../Link'
import MobileKeyboard from '../MobileKeyboard'
import StandardItem from './StandardItem'
import StakeWrapper from './StakeWrapper'
import BetrixComboMode from './BetrixComboMode'
import { BetTypeId, OutcomeId, ScopeName, /*_BETTYPE_ANY_OTHER_,*/ BETTYPE_ANY_OTHER_AWAY, BETTYPE_ANY_OTHER_HOME, BETTYPE_ANY_OTHER_DRAW, BETTYPE_BETRIX, BETTYPE_CORRECT_SCORE, _BETTYPE_ANY_OTHER_DRAW, _BETTYPE_ANY_OTHER_HOME, _BETTYPE_ANY_OTHER_AWAY} from '../../../state/enums'
import { Betslip as BetslipModel, BSStandardItem, BetslipInsertOutput, BetslipInsertBetslip, BetslipInsertBetslipItem, BetslipInsert, BSBetrixItem, BetslipInsertBetslipSubitem, BSBetrixSubitem, BetslipMode, BetslipSubmode, SelectionInternal, Selection, BetslipInsertBetslipItemOutput, MultiSelection, OutcomeName } from '../../../state/stores/betslip/models'
import { roundOddsValue, /* openPopup, */roundProduct, emptyBetslip, isMobile, removeBSStandardItem, getGoalsText, removeBSBetrixItem, changeBetslipMode, changeBetslipSubmode } from '../../../utils'
import { subscribe, store } from '../../../state'
import { BetrixCombo } from '../../../state/stores/betrix-combo/models'
import './style.scss'
interface Props {
    betrixCombo: BetrixCombo
    betslip: BetslipModel
    mobileBetslipOpened: boolean
    mode: BetslipMode
    showMarketMaker: boolean
    submode: BetslipSubmode
    handleMobileBetslip: () => void
    account_id: string
    tenant_id: number
    min_bet: number
    max_bet: number
    currency: string
    fullThreshold: number
    periodThreshold: number
    stage: string
    league: string
    token: string
    homeFT: number
    awayFT: number
    homeP1: number
    awayP1: number
    homeP2: number
    awayP2: number
    homeP3: number
    awayP3: number
}

interface State {
    activeStakeInput: number
    multiBetStake: string
    singleBets: {[oddsId: number]: string}
}

class Betslip extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            activeStakeInput: -1,
            multiBetStake: '',
            singleBets: {}
        }
    }
    closeBetslip = (): void => {
        this.props.handleMobileBetslip()
        this.closeMobileKeyboard()
    }
    closeMobileKeyboard = (): void => {
        this.setActiveStakeInput(-1)
    }
    componentDidUpdate() {
        const { betrix, standard } = this.props.betslip
        const { multiBetStake } = this.state
        const sameMatches = getSameMatches(betrix, standard)
        const multiBetAvailable: boolean = checkMultibetAvailability(betrix, standard, sameMatches)
        const itemsLength: number = betrix.length + standard.length
        
        if (multiBetStake !== '' && (!multiBetAvailable || !itemsLength)) {
            this.setState({
                multiBetStake: ''
            })
        }
    }
    emptyBetslip = () => {
        if (isMobile()) {
            this.setState({
                activeStakeInput: -1,
                multiBetStake: '',
                singleBets: {}
            }, () => {
                this.props.handleMobileBetslip()
                this.closeMobileKeyboard()
                setTimeout(() => emptyBetslip(), 500)
            })
        } else {
            this.setState({
                activeStakeInput: -1,
                multiBetStake: '',
                singleBets: {}
            }, () => emptyBetslip())
        }
    }
    handleMultiBetStake = (multiBetStake: string): void => {
        const newStake: string = multiBetStake.replace(/,/g, '.')
        this.setState({ multiBetStake: newStake })
    }
    handleSingleBet = (index: number, stake: string): void => {
        if (stake === '') {
            delete this.state.singleBets[index]
        } else {
            this.state.singleBets[index] = stake
        }

        this.setState(this.state)
    }
    handleMobileStake = (stake: string): void => {
        const { activeStakeInput } = this.state
        if (activeStakeInput === 0) {
            this.handleMultiBetStake(stake)
        } else {
            this.handleSingleBet(activeStakeInput, stake)
        }
    }
    
    insertBetslip = (currency: string, token: string, fullThreshold: number, periodThreshold: number, homeFT: number, awayFT: number, homeP1: number, awayP1: number, homeP2: number, awayP2: number, homeP3: number, awayP3: number, tenant_id: number, account_id: string, betPlacementAvailable: boolean, totalOdds: number, totalStake: number) => {
        if (betPlacementAvailable) {
            const { betslip, handleMobileBetslip } = this.props
            const { multiBetStake, singleBets } = this.state
            const betslipInsertBetslip: BetslipInsertBetslip = {
                account_id,
                tenant_id,
                tot_stake: totalStake
            }
            if (multiBetStake !== '') {
                addHeadOddsAndStake(betslipInsertBetslip, multiBetStake, totalOdds)
            }

            const betslipInsertBetslipItems: BetslipInsertBetslipItem[] = addBetslipItems(betslip.betrix, betslip.standard, singleBets)
            const betslipInsert: BetslipInsert = {
                betslip: betslipInsertBetslip,
                betslipItems: betslipInsertBetslipItems,
                betrixMaxScoreHome: fullThreshold,
                betrixMaxScoreAway: fullThreshold,
                betrixMaxScoreP1Home: periodThreshold,
                betrixMaxScoreP1Away: periodThreshold,
                betrixMaxScoreP2Home: periodThreshold,
                betrixMaxScoreP2Away: periodThreshold,
            }
            makeRequest(currency, token, betslipInsert, handleMobileBetslip)
        }
    }
    removeBetslipBetrixItem = (index: number, matchId: string) => {
        const singleBets = this.state.singleBets
        const singleBetsKeys: string[] = Object.keys(singleBets)
        
        const keyFound = singleBetsKeys.find(s => +s === index)
        if (keyFound) {
            delete singleBets[index]
        }
        
        this.setState({
            singleBets
        }, () => removeBSBetrixItem(index, matchId))
    }
    removeBetslipStandardItem = (index: number, matchId: string, typeProperty: string) => {
        const singleBets = this.state.singleBets
        const singleBetsKeys: string[] = Object.keys(singleBets)
        
        const keyFound = singleBetsKeys.find(s => +s === index)
        if (keyFound) {
            delete singleBets[index]
        }

        this.setState({
            activeStakeInput: -1,
            singleBets
        }, () => removeBSStandardItem(index, matchId, typeProperty))

        store.update(main => {
            main.iframeresponse.psbodds = []
            main.iframeresponse.orOddsInsured = []
            return main
        })
    }
    setActiveStakeInput = (activeStakeInput: number) => {
        const { standard } = this.props.betslip
        const betslipWrapper = document.getElementById('betslip-wrapper')
        
        if (activeStakeInput === 0) {
            if (betslipWrapper) {
                const scroll: number = standard.length * 100
                setTimeout(() => {
                    betslipWrapper.scrollTo(0, scroll)
                }, 500)
            }
        }
        
        if (activeStakeInput > 0) {
            const itemFound = standard.find(s => +`${s.matchId}${s.betTypeId}${s.outcomeId}` === activeStakeInput)
            if (itemFound && betslipWrapper) {
                const index: number = standard.indexOf(itemFound)
                const scroll: number = index * 100
                setTimeout(() => {
                    betslipWrapper.scrollTo(0, scroll)
                }, 500)
            }
        }
        
        this.setState({ activeStakeInput })
    }
    render() {
        const { homeFT, awayFT, homeP1, awayP1, homeP2, awayP2, homeP3, awayP3, token, stage, league, betslip, betrixCombo, handleMobileBetslip, mobileBetslipOpened, mode, showMarketMaker, submode, account_id, tenant_id, min_bet, max_bet, currency, fullThreshold, periodThreshold} = this.props

        const { activeStakeInput, multiBetStake, singleBets } = this.state
        const { betrix, standard } = betslip
        const totalOdds: number = multiplyOddsValues(betrix, standard)
        const betValue: number = +multiBetStake * totalOdds
        const betValueRounded: number = roundProduct(betValue)
        const totalStake: number = calculateTotalStake(multiBetStake, singleBets)
        const sameMatches: string[] = getSameMatches(betrix, standard)
        const multiBetAvailable: boolean = checkMultibetAvailability(betrix, standard, sameMatches)
        const betPlacementAvailable: boolean = checkBetPlacementAvailability(multiBetAvailable, multiBetStake, singleBets)
        const buttonClassName: string = (!betPlacementAvailable) || totalStake < min_bet || totalStake > max_bet ? 'disabled' : ''
        const openedClassName: string = (mobileBetslipOpened) ? 'opened' : ''
        const activeMobileStake: string = getActiveMobileStake(activeStakeInput, multiBetStake, singleBets)
        const mobileKeyboardOpened: boolean = activeStakeInput !== -1
        const keyboardOpenedClassName: string = (mobileKeyboardOpened) ? 'keyboard-opened' : ''

        return (
            <div className={`betslip ${openedClassName}`}>
                <div
                    className="betslip-overlay"
                    onClick={handleMobileBetslip}
                />
                <div
                    className={`betslip-wrapper ${keyboardOpenedClassName}`}
                    id="betslip-wrapper"
                >
                    <div className="betslip-title">
                        <h3>Betslip</h3>
                        <FaIcon
                            icon="times"
                            type="solid"
                            className="betslip-close"
                            onClick={this.closeBetslip}
                        />
                        <Link to="/betslip-history" className="betslip-history-icon">
                            <FaIcon
                                icon="history"
                                type="solid"
                            />  
                        </Link>
                    </div>
                    {(betrix.length || standard.length) ?
                        <>
                            <div className="betslip-mode">
                                <button
                                    className={(mode === BetslipMode.Standard) ? 'active' : ''}
                                    onClick={() => changeBetslipMode(BetslipMode.Standard)}
                                >
                                    Standard
                                </button>
                                <button
                                    className={(mode === BetslipMode.BetrixCombo) ? 'active' : ''}
                                    onClick={() => changeBetslipMode(BetslipMode.BetrixCombo)}
                                >
                                    Betrix Combo
                                </button>
                            </div>
                            <div className="clear-betslip">
                                <span onClick={this.emptyBetslip}>Clear Betslip</span>
                            </div>
                            {(mode === BetslipMode.Standard) ?
                                <div className="standard-mode">
                                    <div className="betslip-items" id="betslip-items">
                                        {betrix.map((item, index) =>
                                            <BetrixItem
                                                key={index}
                                                activeStakeInput={activeStakeInput}
                                                handleSingleBet={this.handleSingleBet}
                                                item={item}
                                                removeBetslipItem={this.removeBetslipBetrixItem}
                                                sameMatches={sameMatches}
                                                setActiveStakeInput={this.setActiveStakeInput}
                                                showMarketMaker={showMarketMaker}
                                                singleBets={singleBets}
                                                currency = {currency}
                                                min_bet = {min_bet}
                                                max_bet = {max_bet}
                                                stage = {stage}
                                                league = {league}
                                            />
                                        )}
                                        {standard.map((item, index) =>
                                            <StandardItem
                                                key={index}
                                                activeStakeInput={activeStakeInput}
                                                item={item}
                                                handleSingleBet={this.handleSingleBet}
                                                removeBetslipItem={this.removeBetslipStandardItem}
                                                sameMatches={sameMatches}
                                                setActiveStakeInput={this.setActiveStakeInput}
                                                singleBets={singleBets}
                                                currency = {currency}
                                                min_bet = {min_bet}
                                                max_bet = {max_bet}
                                            />
                                        )}
                                        {multiBetAvailable &&
                                            <div className="odds-and-stake">
                                                <div className="odds-wrapper">
                                                    <span>Multi Bet</span>
                                                    <span>{roundOddsValue(totalOdds.toString(), 3)}</span>
                                                </div>
                                                <StakeWrapper
                                                    activeStakeInput={activeStakeInput}
                                                    betValue={betValueRounded}
                                                    handleStake={this.handleMultiBetStake}
                                                    oddsIndex={0}
                                                    setActiveStakeInput={this.setActiveStakeInput}
                                                    stake={multiBetStake}
                                                    currency = {currency}
                                                    min_bet = {min_bet}
                                                    max_bet = {min_bet}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="place-bet-wrapper">
                                        <button
                                            className={`place-bet ${buttonClassName}`}
                                            onClick={() => this.insertBetslip(currency, token, fullThreshold, periodThreshold, homeFT, awayFT, homeP1, awayP1, homeP2, awayP2, homeP3, awayP3, tenant_id, account_id, betPlacementAvailable, totalOdds, totalStake)}
                                        >
                                            <span>{`${currency} ${totalStake.toString()}`}</span>
                                            <span>Place Bet</span>
                                        </button>
                                    </div>
                                </div>
                            :
                                <>
                                    <div className="submode-wrapper">
                                        <button
                                            className={(submode === BetslipSubmode.MultiSystems) ? 'active' : ''}
                                            onClick={() => changeBetslipSubmode(BetslipSubmode.MultiSystems)}
                                        >
                                            MULTI SYSTEMS
                                        </button>
                                        <button
                                            className={(submode === BetslipSubmode.SystemSingle) ? 'active' : ''}
                                            onClick={() => changeBetslipSubmode(BetslipSubmode.SystemSingle)}
                                        >
                                            SYSTEM SINGLE
                                        </button>
                                    </div>
                                    <BetrixComboMode
                                        betrixCombo={betrixCombo}
                                        betrixItems={betrix}
                                        betslip={betslip.betrixCombo}
                                        standardItems={standard}
                                        submode={submode}
                                    />
                                </>
                            }
                        </>
                    :
                        <span className="empty-betslip">Your betslip is empty. Please add a selection to place a bet.</span>
                    }
                </div>
                <MobileKeyboard
                    closeKeyboard={this.closeMobileKeyboard}
                    handleStake={this.handleMobileStake}
                    keyboardOpened={mobileKeyboardOpened}
                    stake={activeMobileStake}
                />
            </div>
        )
    }
}

export default subscribe(main => ({
    account_id: main.iframeresponse.account_id,
    tenant_id: main.iframeresponse.tenant_id,
    min_bet: main.iframeresponse.min_bet,
    max_bet: main.iframeresponse.max_bet,
    currency: main.iframeresponse.currency,
    fullThreshold: main.iframeresponse.fullThreshold,
    periodThreshold: main.iframeresponse.periodThreshold,
    homeFT: main.iframeresponse.xh,
    awayFT: main.iframeresponse.xa,
    homeP1: main.iframeresponse.xh1,
    awayP1: main.iframeresponse.xa1,
    homeP2: main.iframeresponse.xh2,
    awayP2: main.iframeresponse.xa2,
    homeP3: main.iframeresponse.xh3,
    awayP3: main.iframeresponse.xa3,

    betrixCombo: main.betrixCombo,
    betslip: main.betslip,
    mode: main.betslip.mode,
    showMarketMaker: main.settingsSoccer.showMarketMakerInBetrix,
    submode: main.betslip.submode,
    league: main.iframeresponse.league,
    stage: main.iframeresponse.stage
}))(Betslip)

const addBetrixSubitems = (subitems: BSBetrixSubitem[], oddsValue: string): BetslipInsertBetslipSubitem[] => {
    const betrixSubitems: BetslipInsertBetslipSubitem[] = []

    for (const item of subitems) {
        const { awayGoals, homeGoals, insured } = item
        const betodds: number = (insured) ? 1 : +oddsValue
        const homeGoalsText: string = getGoalsText(homeGoals)
        const awayGoalsText: string = getGoalsText(awayGoals)

        const goalsText: string = homeGoals === -1 && awayGoals === -1 ? 'AnyOtherUnknownDraw' : homeGoals === -2 && awayGoals === -2 ? 'AnyOtherUnknownHome' : homeGoals === -3 && awayGoals === -3 ? 'AnyOtherUnknownAway' : `${homeGoalsText}${awayGoalsText}`
        const bettype: BetTypeId = BetTypeId[goalsText]
        const newSubitem: BetslipInsertBetslipSubitem = {
            betodds,
            betoutcome: OutcomeId.CorrectScore,
            bettype,
            insurance: insured
        }
        betrixSubitems.push(newSubitem)
    }

    return betrixSubitems
}

const addBetslipItems = (betrixItems: BSBetrixItem[], standardItems: BSStandardItem[], singleBets: {[oddsId: number]: string}): BetslipInsertBetslipItem[] => {
    const betslipInsertBetslipItems: BetslipInsertBetslipItem[] = []
    for (const item of betrixItems) {
        const { betTypeId, index, matchId, oddsValue, subitems, outcomeId, scopeId } = item
        const betslipSubItems: BetslipInsertBetslipSubitem[] = addBetrixSubitems(subitems, oddsValue)
        const singleBetStake: string = singleBets[index]
        const stake: number = (singleBetStake) ? +singleBetStake : 0
        const betslipItem: BetslipInsertBetslipItem = {
            betodds: +oddsValue,
            betoutcome: outcomeId,
            betslipSubItems,
            bettype: betTypeId,
            match_id: matchId,
            scopeId,
            stake
        }
        betslipInsertBetslipItems.push(betslipItem)
    }

    for (const item of standardItems) {
        const { betTypeId, index, matchId, MMBetTypeId, MMSelections, oddsValue, outcomeId, scopeId } = item
        const singleBetStake: string = singleBets[index]
        const stake: number = (singleBetStake) ? +singleBetStake : 0
        const betslipItem: BetslipInsertBetslipItem = {
            betodds: +oddsValue,
            betoutcome: outcomeId,
            bettype: betTypeId,
            match_id: matchId,
            stake,
            scopeId
        }
        if (MMSelections && MMBetTypeId) {
            const selection = MMSelections.map(m => Object.assign({}, {outcomeid: m.outcomeId, insurance: m.insured, bettypeid: m.betTypeId}))
            betslipItem.selection = selection
            betslipItem.bettype2 = MMBetTypeId
        } else {
            // just 1 item
            betslipItem.selection = [{outcomeid: item.outcomeId, insurance: false, bettypeid: item.betTypeId}]
            betslipItem.bettype2 = betTypeId

        }
        betslipInsertBetslipItems.push(betslipItem)
    }

    return betslipInsertBetslipItems
}

const addHeadOddsAndStake = (betslipInsertBetslip: BetslipInsertBetslip, multiBetStake: string, totalOdds: number): void => {
    betslipInsertBetslip.head_odds = totalOdds
    betslipInsertBetslip.head_stake = +multiBetStake
}

const calculateTotalStake = (multiBetStake: string, singleBets: {[matchId: string]: string}): number => {
    let totalStake: number = 0

    const singleBetsMatchIds: string[] = Object.keys(singleBets)
    for (const matchId of singleBetsMatchIds) {
        totalStake += +singleBets[matchId]
    }

    totalStake += +multiBetStake

    return totalStake
}

const getSameMatches = (betrixItems: Array<BSBetrixItem>, standardItems: Array<BSStandardItem>): Array<string> => { 
    const sameMatches: Array<string> = []
    
    const betrixIds: string[] = betrixItems.map(b => b.matchId)
    const standardIds: string[] = standardItems.map(b => b.matchId)
    const matchIds: string[] = [...betrixIds, ...standardIds]

    for (const id of matchIds) {
        const sameIds = matchIds.filter(b => +b === +id)
        if (sameIds.length > 1) {
            const idFound = sameMatches.find(s => s === id)
            if (!idFound) {
                sameMatches.push(id)
            }
        }
    }
    
    return sameMatches
}

const checkBetPlacementAvailability = (multiBetAvailable: boolean, multiBetStake: string, singleBets: any): boolean => {
    const singleBetsStakes: string[] = Object.values(singleBets)
    const singleBetsValid: boolean = checkSingleBetsStakesValidity(singleBetsStakes)

    if (multiBetAvailable && (+multiBetStake > 0 || (singleBetsStakes.length && singleBetsValid))) {
        return true
    }

    if (!multiBetAvailable && singleBetsStakes.length && singleBetsValid) {
        return true
    }

    return false
}

const checkMultibetAvailability = (betrixItems: Array<BSBetrixItem>, standardItems: Array<BSStandardItem>, sameMatches: Array<string>): boolean => {
    const itemsLength: number = betrixItems.length + standardItems.length
    const multiBetAvailable: boolean = itemsLength > 1 && !sameMatches.length
    
    return multiBetAvailable
}

const checkSingleBetsStakesValidity = (singleBetsStakes: string[]): boolean => {
    for (const stake of singleBetsStakes) {
        if (isNaN(+stake) || +stake <= 0) {
            return false
        }
    }

    return true
}

const getActiveMobileStake = (activeStakeInput: number, multiBetStake: string, singleBets: {[oddsId: number]: string}): string => {
    if (activeStakeInput === 0) {
        return multiBetStake
    }

    const activeMobileStake: string = (singleBets[activeStakeInput]) ? singleBets[activeStakeInput] : ''
    return activeMobileStake
}

const getSelection = (item: SelectionInternal[], sub: BetslipInsertBetslipSubitem[]): Selection[] => {
    const returned: Selection[] = []
    if (item) {
        for (const Item of item) {
            returned.push({insurance: Item.insurance, outcome: getOutcome(Item.outcomeid, Item.bettypeid)})
        }
    } else if (sub) {
        for (const Item of sub) {
            returned.push({insurance: Item.insurance, outcome: getOutcome(Item.betoutcome, Item.bettype)})
        }
    }

    return returned
}

const getOutcome = (outcomeid: OutcomeId, bettypeid: BetTypeId): OutcomeName => {
    if (outcomeid === OutcomeId.HomeWin) {return '1'}
    if (outcomeid === OutcomeId.Draw) {return 'X'}
    if (outcomeid === OutcomeId.AwayWin) {return '2'}

    if (outcomeid === OutcomeId.EHHome) {return '1'}
    if (outcomeid === OutcomeId.EHDraw) {return 'X'}
    if (outcomeid === OutcomeId.EHAway) {return '2'}

    if (outcomeid === OutcomeId.TENGZero) {return '0'}
    if (outcomeid === OutcomeId.TENGOne) {return '1'}
    if (outcomeid === OutcomeId.TENGTwo) {return '2'}
    if (outcomeid === OutcomeId.TENGThreePlus) {return '3+'}
    if (outcomeid === OutcomeId.TENG2ZeroOne) {return '0-1'}
    if (outcomeid === OutcomeId.TENG2Two) {return '2'}
    if (outcomeid === OutcomeId.TENG2Three) {return '3'}
    if (outcomeid === OutcomeId.TENG2FourPlus) {return '4+'}
    if (outcomeid === OutcomeId.TENG3ZeroTwo) {return '0-2'}
    if (outcomeid === OutcomeId.TENG3Three) {return '3'}
    if (outcomeid === OutcomeId.TENG3Four) {return '4'}
    if (outcomeid === OutcomeId.TENG3FivePlus) {return '5+'}
    if (outcomeid === OutcomeId.TENG4ZeroThree) {return '0-3'}
    if (outcomeid === OutcomeId.TENG4Four) {return '4'}
    if (outcomeid === OutcomeId.TENG4Five) {return '5'}
    if (outcomeid === OutcomeId.TENG4SixPlus) {return '6+'}
    if (outcomeid === OutcomeId.TENG5ZeroFour) {return '0-4'}
    if (outcomeid === OutcomeId.TENG5Five) {return '5'}
    if (outcomeid === OutcomeId.TENG5Six) {return '6'}
    if (outcomeid === OutcomeId.TENG5SevenPlus) {return '7+'}

    if (outcomeid === OutcomeId.THSHFirst) {return 'First'}
    if (outcomeid === OutcomeId.THSHSecond) {return 'Second'}
    if (outcomeid === OutcomeId.THSHTie) {return 'Tie'}

    if (outcomeid === OutcomeId.THSH2FirstYes) {return 'First & Yes'}
    if (outcomeid === OutcomeId.THSH2FirstNo) {return 'First & No'}
    if (outcomeid === OutcomeId.THSH2SecondYes) {return 'Second & Yes'}
    if (outcomeid === OutcomeId.THSH2SecondNo) {return 'Second & No'}
    if (outcomeid === OutcomeId.THSH2TieYes) {return 'Tie & Yes'}
    if (outcomeid === OutcomeId.THSH2TieNo) {return 'Tie & No'}

    if (outcomeid === OutcomeId.TNGZeroOne) {return '0-1'}
    if (outcomeid === OutcomeId.TNGTwoThree) {return '2-3'}
    if (outcomeid === OutcomeId.TNGFourPlus) {return '4+'}

    if (outcomeid === OutcomeId.TTFG030) {return '0-30'}
    if (outcomeid === OutcomeId.TTFG3160) {return '31-60'}
    if (outcomeid === OutcomeId.TTFG61Plus) {return '61+'}
    if (outcomeid === OutcomeId.TTFGNone) {return 'None'}

    if (outcomeid === OutcomeId.ENGZero) {return '0'}
    if (outcomeid === OutcomeId.ENGOne) {return '1'}
    if (outcomeid === OutcomeId.ENGTwo) {return '2'}
    if (outcomeid === OutcomeId.ENGThree) {return '3'}
    if (outcomeid === OutcomeId.ENGFour) {return '4'}
    if (outcomeid === OutcomeId.ENGFive) {return '5'}
    if (outcomeid === OutcomeId.ENGSixPlus) {return '6+'}

    if (outcomeid === OutcomeId.ENG2ZeroOne) {return '0-1'}
    if (outcomeid === OutcomeId.ENG2Two) {return '2'}
    if (outcomeid === OutcomeId.ENG2Three) {return '3'}
    if (outcomeid === OutcomeId.ENG2Four) {return '4'}
    if (outcomeid === OutcomeId.ENG2Five) {return '5'}
    if (outcomeid === OutcomeId.ENG2Six) {return '6'}
    if (outcomeid === OutcomeId.ENG2SevenPlus) {return '7+'}

    if (outcomeid === OutcomeId.ENG3ZeroTwo) {return '0-2'}
    if (outcomeid === OutcomeId.ENG3Three) {return '3'}
    if (outcomeid === OutcomeId.ENG3Four) {return '4'}
    if (outcomeid === OutcomeId.ENG3Five) {return '5'}
    if (outcomeid === OutcomeId.ENG3Six) {return '6'}
    if (outcomeid === OutcomeId.ENG3Seven) {return '7'}
    if (outcomeid === OutcomeId.ENG3EightPlus) {return '8+'}

    if (outcomeid === OutcomeId.ENG4ZeroThree) {return '0-3'}
    if (outcomeid === OutcomeId.ENG4Four) {return '4'}
    if (outcomeid === OutcomeId.ENG4Five) {return '5'}
    if (outcomeid === OutcomeId.ENG4Six) {return '6'}
    if (outcomeid === OutcomeId.ENG4Seven) {return '7'}
    if (outcomeid === OutcomeId.ENG4Eight) {return '8'}
    if (outcomeid === OutcomeId.ENG4NinePlus) {return '9+'}

    if (outcomeid === OutcomeId.ENG5ZeroFour) {return '0-4'}
    if (outcomeid === OutcomeId.ENG5Five) {return '5'}
    if (outcomeid === OutcomeId.ENG5Six) {return '6'}
    if (outcomeid === OutcomeId.ENG5Seven) {return '7'}
    if (outcomeid === OutcomeId.ENG5Eight) {return '8'}
    if (outcomeid === OutcomeId.ENG5Nine) {return '9'}
    if (outcomeid === OutcomeId.ENG5TenPlus) {return '10+'}

    if (outcomeid === OutcomeId.ENG6ZeroFive) {return '0-5'}
    if (outcomeid === OutcomeId.ENG6Six) {return '6'}
    if (outcomeid === OutcomeId.ENG6Seven) {return '7'}
    if (outcomeid === OutcomeId.ENG6Eight) {return '8'}
    if (outcomeid === OutcomeId.ENG6Nine) {return '9'}
    if (outcomeid === OutcomeId.ENG6Ten) {return '10'}
    if (outcomeid === OutcomeId.ENG6ElevenPlus) {return '11+'}

    if (outcomeid === OutcomeId.ENG7ZeroSix) {return '0-6'}
    if (outcomeid === OutcomeId.ENG7Seven) {return '7'}
    if (outcomeid === OutcomeId.ENG7Eight) {return '8'}
    if (outcomeid === OutcomeId.ENG7Nine) {return '9'}
    if (outcomeid === OutcomeId.ENG7Ten) {return '10'}
    if (outcomeid === OutcomeId.ENG7Eleven) {return '11'}
    if (outcomeid === OutcomeId.ENG7TwelvePlus) {return '12+'}

    if (outcomeid === OutcomeId.FHSHHomeHome) {return '1/1'}
    if (outcomeid === OutcomeId.FHSHDrawHome) {return 'X/1'}
    if (outcomeid === OutcomeId.FHSHAwayHome) {return '2/1'}
    if (outcomeid === OutcomeId.FHSHHomeDraw) {return '1/X'}
    if (outcomeid === OutcomeId.FHSHDrawDraw) {return 'X/X'}
    if (outcomeid === OutcomeId.FHSHAwayDraw) {return '2/X'}
    if (outcomeid === OutcomeId.FHSHHomeAway) {return '1/2'}
    if (outcomeid === OutcomeId.FHSHDrawAway) {return 'X/2'}
    if (outcomeid === OutcomeId.FHSHAwayAway) {return '2/2'}

    if (outcomeid === OutcomeId.FTGBoth) {return 'Both'}
    if (outcomeid === OutcomeId.FTGHome) {return 'Home'}
    if (outcomeid === OutcomeId.FTGAway) {return 'Away'}
    if (outcomeid === OutcomeId.FTGUnder) {return 'Under 2 goals'}

    if (outcomeid === OutcomeId.HTFTHomeHome) {return '1/1'}
    if (outcomeid === OutcomeId.HTFTDrawHome) {return 'X/1'}
    if (outcomeid === OutcomeId.HTFTAwayHome) {return '2/1'}
    if (outcomeid === OutcomeId.HTFTHomeDraw) {return '1/X'}
    if (outcomeid === OutcomeId.HTFTDrawDraw) {return 'X/X'}
    if (outcomeid === OutcomeId.HTFTAwayDraw) {return '2/X'}
    if (outcomeid === OutcomeId.HTFTHomeAway) {return '1/2'}
    if (outcomeid === OutcomeId.HTFTDrawAway) {return 'X/2'}
    if (outcomeid === OutcomeId.HTFTAwayAway) {return '2/2'}

    if (outcomeid === OutcomeId.HTFT2HomeHome) {return '1/1'}
    if (outcomeid === OutcomeId.HTFT2DrawOrAwayHome) {return 'X2/1'}
    if (outcomeid === OutcomeId.HTFT2HomeDrawOrAway) {return '1/X2'}
    if (outcomeid === OutcomeId.HTFT2DrawOrAwayDrawOrAway) {return 'X2/X2'}

    if (outcomeid === OutcomeId.HTFT3HomeOrDrawHomeOrDraw) {return '1X/1X'}
    if (outcomeid === OutcomeId.HTFT3AwayHomeOrDraw) {return '2/1X'}
    if (outcomeid === OutcomeId.HTFT3HomeOrDrawAway) {return '1X/2'}
    if (outcomeid === OutcomeId.HTFT3AwayAway) {return '2/2'}

    if (outcomeid === OutcomeId.HSHFirst) {return 'First'}
    if (outcomeid === OutcomeId.HSHSecond) {return 'Second'}
    if (outcomeid === OutcomeId.HSHTie) {return 'Tie'}

    if (outcomeid === OutcomeId.ZeroOne) {return '0-1'}
    if (outcomeid === OutcomeId.TwoThree) {return '2-3'}
    if (outcomeid === OutcomeId.FourPlus) {return '4+'}

    if (outcomeid === OutcomeId.FLTSHome) {return 'Home'}
    if (outcomeid === OutcomeId.FLTSAway) {return 'Away'}
    if (outcomeid === OutcomeId.FLTSNone) {return 'None'}

    if (outcomeid === OutcomeId.ScoreBoth) {return 'Both'}
    if (outcomeid === OutcomeId.ScoreHome) {return 'Home'}
    if (outcomeid === OutcomeId.ScoreAway) {return 'Away'}
    if (outcomeid === OutcomeId.ScoreNone) {return 'None'}

    if (outcomeid === OutcomeId.TFG010) {return '0-10'}
    if (outcomeid === OutcomeId.TFG1120) {return '11-20'}
    if (outcomeid === OutcomeId.TFG2130) {return '21-30'}
    if (outcomeid === OutcomeId.TFG3140) {return '31-40'}
    if (outcomeid === OutcomeId.TFG4150) {return '41-50'}
    if (outcomeid === OutcomeId.TFG5160) {return '51-60'}
    if (outcomeid === OutcomeId.TFG6170) {return '61-70'}
    if (outcomeid === OutcomeId.TFG7180) {return '71-80'}
    if (outcomeid === OutcomeId.TFG81Plus) {return '81+'}
    if (outcomeid === OutcomeId.TFGNone) {return 'None'}

    if (outcomeid === OutcomeId.TFG2015) {return '0-15'}
    if (outcomeid === OutcomeId.TFG21630) {return '16-30'}
    if (outcomeid === OutcomeId.TFG23145) {return '31-45'}
    if (outcomeid === OutcomeId.TFG24660) {return '46-60'}
    if (outcomeid === OutcomeId.TFG26175) {return '61-75'}
    if (outcomeid === OutcomeId.TFG276Plus) {return '76+'}
    if (outcomeid === OutcomeId.TFG2None) {return 'None'}

    if (outcomeid === OutcomeId.TFG3030) {return '0-30'}
    if (outcomeid === OutcomeId.TFG33160) {return '31-60'}
    if (outcomeid === OutcomeId.TFG361Plus) {return '61+'}
    if (outcomeid === OutcomeId.TFG3None) {return 'None'}

    if (outcomeid === OutcomeId.WM2ThreePlus) {return '3+'}
    if (outcomeid === OutcomeId.WM2Two) {return '2'}
    if (outcomeid === OutcomeId.WM2One) {return '1'}
    if (outcomeid === OutcomeId.WM2Zero) {return '0'}
    if (outcomeid === OutcomeId.WM2MinusOne) {return '-1'}
    if (outcomeid === OutcomeId.WM2MinusTwo) {return '-2'}
    if (outcomeid === OutcomeId.WM2MinusThreePlus) {return '-3+'}

    if (outcomeid === OutcomeId.WM7FourPlus) {return '4+'}
    if (outcomeid === OutcomeId.WM7Three) {return '3'}
    if (outcomeid === OutcomeId.WM7Two) {return '2'}
    if (outcomeid === OutcomeId.WM7One) {return '1'}
    if (outcomeid === OutcomeId.WM7Zero) {return '0'}
    if (outcomeid === OutcomeId.WM7MinusOne) {return '-1'}
    if (outcomeid === OutcomeId.WM7MinusTwoPlus) {return '-2+'}

    if (outcomeid === OutcomeId.WM8TwoPlus) {return '2+'}
    if (outcomeid === OutcomeId.WM8One) {return '1'}
    if (outcomeid === OutcomeId.WM8Zero) {return '0'}
    if (outcomeid === OutcomeId.WM8MinusOne) {return '-1'}
    if (outcomeid === OutcomeId.WM8MinusTwo) {return '-2'}
    if (outcomeid === OutcomeId.WM8MinusThree) {return '-3'}
    if (outcomeid === OutcomeId.WM8MinusFourPlus) {return '-4+'}

    if (outcomeid === OutcomeId.WM9FivePlus) {return '5+'}
    if (outcomeid === OutcomeId.WM9Four) {return '4'}
    if (outcomeid === OutcomeId.WM9Three) {return '3'}
    if (outcomeid === OutcomeId.WM9Two) {return '2'}
    if (outcomeid === OutcomeId.WM9One) {return '1'}
    if (outcomeid === OutcomeId.WM9Zero) {return '0'}
    if (outcomeid === OutcomeId.WM9MinusOnePlus) {return '-1+'}

    if (outcomeid === OutcomeId.WM10OnePlus) {return '1+'}
    if (outcomeid === OutcomeId.WM10Zero) {return '0'}
    if (outcomeid === OutcomeId.WM10MinusOne) {return '-1'}
    if (outcomeid === OutcomeId.WM10MinusTwo) {return '-2'}
    if (outcomeid === OutcomeId.WM10MinusThree) {return '-3'}
    if (outcomeid === OutcomeId.WM10MinusFour) {return '-4'}
    if (outcomeid === OutcomeId.WM10MinusFivePlus) {return '-5+'}

    if (outcomeid === OutcomeId.WM11SixPlus) {return '6+'}
    if (outcomeid === OutcomeId.WM11Five) {return '5'}
    if (outcomeid === OutcomeId.WM11Four) {return '4'}
    if (outcomeid === OutcomeId.WM11Three) {return '3'}
    if (outcomeid === OutcomeId.WM11Two) {return '2'}
    if (outcomeid === OutcomeId.WM11One) {return '1'}
    if (outcomeid === OutcomeId.WM11ZeroMinus) {return '0-'}

    if (outcomeid === OutcomeId.WM12ZeroPlus) {return '0+'}
    if (outcomeid === OutcomeId.WM12MinusOne) {return '-1'}
    if (outcomeid === OutcomeId.WM12MinusTwo) {return '-2'}
    if (outcomeid === OutcomeId.WM12MinusThree) {return '-3'}
    if (outcomeid === OutcomeId.WM12MinusFour) {return '-4'}
    if (outcomeid === OutcomeId.WM12MinusFive) {return '-5'}
    if (outcomeid === OutcomeId.WM12MinusSixPlus) {return '-6+'}

    if (outcomeid === OutcomeId.WM13SevenPlus) {return '7+'}
    if (outcomeid === OutcomeId.WM13Six) {return '6'}
    if (outcomeid === OutcomeId.WM13Five) {return '5'}
    if (outcomeid === OutcomeId.WM13Four) {return '4'}
    if (outcomeid === OutcomeId.WM13Three) {return '3'}
    if (outcomeid === OutcomeId.WM13Two) {return '2'}
    if (outcomeid === OutcomeId.WM13OnePlus) {return '1-'}

    if (outcomeid === OutcomeId.WM14MinusOnePlus) {return '-1+'}
    if (outcomeid === OutcomeId.WM14MinusTwo) {return '-2'}
    if (outcomeid === OutcomeId.WM14MinusThree) {return '-3'}
    if (outcomeid === OutcomeId.WM14MinusFour) {return '-4'}
    if (outcomeid === OutcomeId.WM14MinusFive) {return '-5'}
    if (outcomeid === OutcomeId.WM14MinusSix) {return '-6'}
    if (outcomeid === OutcomeId.WM14MinusSevenPlus) {return '-7+'}

    if (outcomeid === OutcomeId.CorrectScore || outcomeid === OutcomeId.BetrixYes) {
        if (bettypeid === BetTypeId.AnyOtherUnknown) {
            return 'Any other result'
        }

        if (bettypeid === BetTypeId.AnyOtherUnknownDraw) {
            return 'Any other result (Draw)'
        }

        if (bettypeid === BetTypeId.AnyOtherUnknownHome) {
            return 'Any other result (Home)'
        }

        if (bettypeid === BetTypeId.AnyOtherUnknownAway) {
            return 'Any other result (Away)'
        }

        // either in a table of betrixes BETTYPE_BETRIX
        for (let i: number = 0; i < BETTYPE_BETRIX.length; i++) {
            for (let j: number = 0; j < BETTYPE_BETRIX[i].length; j++) {
                if (bettypeid === BETTYPE_BETRIX[i][j]) {
                    return 'Betrix'
                }
            }
        }

        // either in a table of correct score
        for (let i: number = 0; i < BETTYPE_CORRECT_SCORE.length; i++) {
            for (let j: number = 0; j < BETTYPE_CORRECT_SCORE[i].length; j++) {
                if (bettypeid === BETTYPE_CORRECT_SCORE[i][j]) {
                    return i + ':' + j
                }
            }
        }
        for (let i: number = 0; i < BETTYPE_ANY_OTHER_DRAW.length; i++) {
            for (let j: number = 0; j < BETTYPE_ANY_OTHER_DRAW[i].length; j++) {
                if (bettypeid === BETTYPE_ANY_OTHER_DRAW[i][j]) {
                    return 'Any other result (Draw)'
                }
            }
        }

        for (let i: number = 0; i < BETTYPE_ANY_OTHER_HOME.length; i++) {
            for (let j: number = 0; j < BETTYPE_ANY_OTHER_HOME[i].length; j++) {
                if (bettypeid === BETTYPE_ANY_OTHER_HOME[i][j]) {
                    return 'Any other result (Home)'
                }
            }
        }

        for (let i: number = 0; i < BETTYPE_ANY_OTHER_AWAY.length; i++) {
            for (let j: number = 0; j < BETTYPE_ANY_OTHER_AWAY[i].length; j++) {
                if (bettypeid === BETTYPE_ANY_OTHER_AWAY[i][j]) {
                    return 'Any other result (Away)'
                }
            }
        }

        // either in a table of any other results
    }
    // console.log ('2', outcomeid, bettypeid)
    return 'Error'
}


const getBetType = (bettypeid: BetTypeId): MultiSelection => {
    if (bettypeid === BetTypeId.ThreeWay) {return '1X2'}
    if (bettypeid === BetTypeId.EuropeanHandicapNine) {return 'European Handicap 9'}
    if (bettypeid === BetTypeId.EuropeanHandicapEight) {return 'European Handicap 8'}
    if (bettypeid === BetTypeId.EuropeanHandicapSeven) {return 'European Handicap 7'}
    if (bettypeid === BetTypeId.EuropeanHandicapSix) {return 'European Handicap 6'}
    if (bettypeid === BetTypeId.EuropeanHandicapFive) {return 'European Handicap 5'}
    if (bettypeid === BetTypeId.EuropeanHandicapFour) {return 'European Handicap 4'}
    if (bettypeid === BetTypeId.EuropeanHandicapThree) {return 'European Handicap 3'}
    if (bettypeid === BetTypeId.EuropeanHandicapTwo) {return 'European Handicap 2'}
    if (bettypeid === BetTypeId.EuropeanHandicapOne) {return 'European Handicap 1'}
    if (bettypeid === BetTypeId.EuropeanHandicapZero) {return 'European Handicap 0'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusNine) {return 'European Handicap -9'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusEight) {return 'European Handicap -8'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusSeven) {return 'European Handicap -7'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusSix) {return 'European Handicap -6'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusFive) {return 'European Handicap -5'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusFour) {return 'European Handicap -4'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusThree) {return 'European Handicap -3'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusTwo) {return 'European Handicap -2'}
    if (bettypeid === BetTypeId.EuropeanHandicapMinusOne) {return 'European Handicap -1'}
    if (bettypeid === BetTypeId.WinMargin2) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin3) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin4) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin5) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin6) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin7) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin8) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin9) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin10) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin11) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin12) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin13) {return 'Win Margin'}
    if (bettypeid === BetTypeId.WinMargin14) {return 'Win Margin'}
    if (bettypeid === BetTypeId.HalfTimeFullTime) {return 'Half Time/Full Time'}
    if (bettypeid === BetTypeId.HalfTimeFullTime2) {return 'Half Time/Full Time'}
    if (bettypeid === BetTypeId.HalfTimeFullTime3) {return 'Half Time/Full Time'}
    if (bettypeid === BetTypeId.FirstHalfSecondHalf) {return 'First Half/Second Half'}
    if (bettypeid === BetTypeId.HighestScoringHalf) {return 'Highest Scoring Half'}
    if (bettypeid === BetTypeId.TimeOfFirstGoal) {return 'Time of First Goal'}
    if (bettypeid === BetTypeId.TimeOfFirstGoal2) {return 'Time of First Goal'}
    if (bettypeid === BetTypeId.TimeOfFirstGoal3) {return 'Time of First Goal'}
    if (bettypeid === BetTypeId.FirstTwoGoals) {return 'First Two Goals'}
    if (bettypeid === BetTypeId.ExactNumberOfGoals) {return 'Exact Number of Goals'}
    if (bettypeid === BetTypeId.ExactNumberOfGoals2) {return 'Exact Number of Goals'}
    if (bettypeid === BetTypeId.ExactNumberOfGoals3) {return 'Exact Number of Goals'}
    if (bettypeid === BetTypeId.ExactNumberOfGoals4) {return 'Exact Number of Goals'}
    if (bettypeid === BetTypeId.ExactNumberOfGoals5) {return 'Exact Number of Goals'}
    if (bettypeid === BetTypeId.ExactNumberOfGoals6) {return 'Exact Number of Goals'}
    if (bettypeid === BetTypeId.ExactNumberOfGoals7) {return 'Exact Number of Goals'}
    if (bettypeid === BetTypeId.NumberOfGoals) {return 'Number of Goals'}
    if (bettypeid === BetTypeId.FirstToScore) {return 'Team First to Score'}
    if (bettypeid === BetTypeId.TeamsToScore) {return 'Teams to Score'}

    if (bettypeid === BetTypeId.TNGHome) {return 'Home Number of Goals'}
    if (bettypeid === BetTypeId.TENGHome) {return 'Home Exact Number of Goals'}
    if (bettypeid === BetTypeId.TENG2Home) {return 'Home Exact Number of Goals'}
    if (bettypeid === BetTypeId.TENG3Home) {return 'Home Exact Number of Goals'}
    if (bettypeid === BetTypeId.TENG4Home) {return 'Home Exact Number of Goals'}
    if (bettypeid === BetTypeId.TENG5Home) {return 'Home Exact Number of Goals'}
    if (bettypeid === BetTypeId.HomeTimeOfFirstGoal) {return 'Home Time of First Goal'}
    if (bettypeid === BetTypeId.THSHHome) {return 'Home Highest Scoring Half'}
    if (bettypeid === BetTypeId.THSH2Home) {return 'Home Highest Scoring Half'}

    if (bettypeid === BetTypeId.TNGAway) {return 'Away Number of Goals'}
    if (bettypeid === BetTypeId.TENGAway) {return 'Away Exact Number of Goals'}
    if (bettypeid === BetTypeId.TENG2Away) {return 'Away Exact Number of Goals'}
    if (bettypeid === BetTypeId.TENG3Away) {return 'Away Exact Number of Goals'}
    if (bettypeid === BetTypeId.TENG4Away) {return 'Away Exact Number of Goals'}
    if (bettypeid === BetTypeId.TENG5Away) {return 'Away Exact Number of Goals'}
    if (bettypeid === BetTypeId.AwayTimeOfFirstGoal) {return 'Away Time of First Goal'}
    if (bettypeid === BetTypeId.THSHAway) {return 'Away Highest Scoring Half'}
    if (bettypeid === BetTypeId.THSH2Away) {return 'Away Highest Scoring Half'}
    // bettypefound in correct scores
    if (bettypeid >= 121 && bettypeid <= 184 || bettypeid >= 186 && bettypeid <= 265 || bettypeid >= 593 && bettypeid <= 848) {
        return 'Correct Score'
    }

    // bettypefound in anyother results
    if (bettypeid === 28 || bettypeid === 1457 || bettypeid >= 1314 && bettypeid <= 1456 || bettypeid >= 1594 && bettypeid <= 1849) {
        return 'Correct Score'
    }

    if (bettypeid === 57 || bettypeid >= 370 && bettypeid <= 379 || bettypeid >= 1458 && bettypeid <= 1590 || bettypeid >= 1850 && bettypeid <= 2105) {
        return 'Correct Score'
    }

    if (bettypeid >= BETTYPE_ANY_OTHER_HOME[0][0] && bettypeid <= BETTYPE_ANY_OTHER_HOME[BETTYPE_ANY_OTHER_HOME.length - 1][BETTYPE_ANY_OTHER_HOME[0].length - 1]) {
        return 'Correct Score'
    }

    if (bettypeid >= BETTYPE_ANY_OTHER_AWAY[0][0] && bettypeid <= BETTYPE_ANY_OTHER_AWAY[BETTYPE_ANY_OTHER_AWAY.length - 1][BETTYPE_ANY_OTHER_AWAY[0].length - 1]) {
        return 'Correct Score'
    }

    if (bettypeid === BETTYPE_ANY_OTHER_DRAW || bettypeid === _BETTYPE_ANY_OTHER_HOME || bettypeid === _BETTYPE_ANY_OTHER_AWAY) {
        return 'Correct Score'
    }

    // console.log ('1', 'bettypeid', bettypeid)
    return 'Error'
}

const getBetslipItems = (betslipItems: BetslipInsertBetslipItem[]): BetslipInsertBetslipItemOutput[] => {
    const returned: BetslipInsertBetslipItemOutput[] = []
    if (betslipItems) {
        for (const item of betslipItems) {
            returned.push({
                odds: item.betodds,
                marketName: getBetType(item.bettype2 ? item.bettype2 : item.bettype),
                matchId: item.match_id,
                stake: item.stake,
                selection: getSelection(item.selection, item.betslipSubItems),
                scope: ScopeName[item.scopeId]
            })
        }
    }
    return returned
}

const makeRequest = (currency: string, token: string, betslipInsert: BetslipInsert, handleMobileBetslip: () => void): void => {

    const betslipInsertOutput: BetslipInsertOutput = {
        betrixMaxScoreHome: betslipInsert.betrixMaxScoreHome,
        betrixMaxScoreP1Home: betslipInsert.betrixMaxScoreP1Home,
        betrixMaxScoreP2Home: betslipInsert.betrixMaxScoreP2Home,
        betrixMaxScoreP3Home: betslipInsert.betrixMaxScoreP3Home,
        betrixMaxScoreAway: betslipInsert.betrixMaxScoreAway,
        betrixMaxScoreP1Away: betslipInsert.betrixMaxScoreP1Away,
        betrixMaxScoreP2Away: betslipInsert.betrixMaxScoreP2Away,
        betrixMaxScoreP3Away: betslipInsert.betrixMaxScoreP3Away,

        accountId: betslipInsert.betslip.account_id,
        tenantId: betslipInsert.betslip.tenant_id,
        betslipItems: getBetslipItems(betslipInsert.betslipItems),
        currency
    }

    if (betslipInsertOutput.betrixMaxScoreP3Home === undefined) {delete(betslipInsertOutput.betrixMaxScoreP3Home)}
    if (betslipInsertOutput.betrixMaxScoreP3Away === undefined) {delete(betslipInsertOutput.betrixMaxScoreP3Away)}

    console.log ('BETSLIP OUTPUT EXAMPLE!')
    console.log ('Betslip object view')
    console.log (betslipInsertOutput)
    console.log ('Betslip object after JSON.stringify)')
    console.log (JSON.stringify(betslipInsertOutput, null, 2))
    dataServer.request('post', 'encrypt', {}, {t: token, b: betslipInsertOutput}, (Token) => {
        if (Token.resolved) {
            console.log ('Betslip object encryption & signature successful - resulting JSON Web Token below')
            console.log (Token.token)
            // dataServer.request('get', 'get-decrypt2', {t: Token.token}, {}, (check) => {console.log (check)})
        } else {
            console.log (Token.message)
        }
    })
    handleMobileBetslip()
}

const multiplyOddsValues = (betrix: Array<BSBetrixItem>, standard: Array<BSStandardItem>): number => {
    const oddsValues: string[] = [
        ...betrix.map(b => b.oddsValue),
        ...standard.map(s => s.oddsValue)
    ]
   
    let multipliedOddsValues: number = 1
    
    for (const oddsValue of oddsValues) {
        multipliedOddsValues *= +oddsValue
    }

    const roundedProduct = roundProduct(multipliedOddsValues)
    return roundedProduct
}