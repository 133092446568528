import React from 'react'
import clsx from 'clsx'
import OddsItem from '../../OddsItem'
import { getMarketNameAndOptions, fillValuesWithOdds, getClosestValue, getOutcomeText, isMobile, sortBySize, GetNameFromMarketGroup } from '../../../../utils'
import { MarketMaker } from '../../../../state/stores/marketmaker/models'
import { MarketOdds } from '../../../../state/stores/feed/models'
import { SportId, OutcomeId, BetTypeGroupId, ScopeId } from '../../../../state/enums'
import { MarketGroupItemElement } from '../../../../state/stores/settings/models'
import MarketScope from '../../MarketScope'
import { scopes } from '../../../../assets'

interface Props {
    token: string
    awayTeam: string
    homeTeam: string
    market: string
    marketMaker: MarketMaker
    odds: MarketOdds[]
    odds1: MarketOdds[]
    odds2: MarketOdds[]
    odds3: MarketOdds[]
    sportId: SportId
    hasNormalValues?: boolean
    marketGroupData: MarketGroupItemElement
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    Odds: number[]
    bettypegroupid: BetTypeGroupId
}

interface State {
    activeScope: ScopeId
    oddsChanged: boolean
    otherOptionsOpened: boolean
}

class MarketBoxWithValue extends React.Component<Props, State> {
    private closestOdds: MarketOdds[] = []
    private closestValue = ''
    private sortedValues: string[] = []
    private valuesWithOdds: {[value: string]: MarketOdds[]} = {}
    constructor(props: Props) {
        super(props)

        this.state = {
            activeScope: ScopeId.FT,
            oddsChanged: false,
            otherOptionsOpened: false
        }
        const valuesWithOdds = fillValuesWithOdds(props.odds, props.marketGroupData)
        const closestValue: string = getClosestValue(valuesWithOdds)
        const sortedValues: string[] = Object.keys(valuesWithOdds).sort((a: string, b: string) => sortBySize(+a, +b))
        this.closestOdds = (closestValue !== '') ? valuesWithOdds[closestValue] : []
        this.closestValue = closestValue
        this.sortedValues = sortedValues
        this.valuesWithOdds = valuesWithOdds
    }
    componentDidUpdate(prevProps: Props) {
        const { activeScope, oddsChanged } = this.state
        const oddsKey =
            activeScope === ScopeId.FT ?
                'odds'
            : activeScope === ScopeId.P1 || activeScope === ScopeId.H1 || activeScope === ScopeId.J1 ?
                'odds1'
            : activeScope === ScopeId.P2 || activeScope === ScopeId.H2 ?
                'odds2'
            : activeScope === ScopeId.P3 ?
                'odds3'
            : ''
        const areOddsDifferent: boolean = checkDifferentOdds(prevProps[oddsKey], this.props[oddsKey])
        if (!oddsChanged && areOddsDifferent) {
            const valuesWithOdds = fillValuesWithOdds(this.props[oddsKey])
            const closestValue: string = getClosestValue(valuesWithOdds)
            const sortedValues: string[] = Object.keys(valuesWithOdds).sort((a: string, b: string) => sortBySize(+a, +b))
            this.closestOdds = (closestValue !== '') ? valuesWithOdds[closestValue] : []
            this.closestValue = closestValue
            this.sortedValues = sortedValues
            this.valuesWithOdds = valuesWithOdds

            this.setState({
                oddsChanged: true
            })
        }
    }
    handleOtherOptionsOpened = () => {
        const { otherOptionsOpened } = this.state
        this.setState({
            otherOptionsOpened: !otherOptionsOpened
        })
    }
    handleChangeScope = (value: ScopeId) => {
        this.setState({ activeScope: value })
    }
    render() {
        const { token, bettypegroupid, Odds, minOdds, maxOdds, minCSOdds, maxCSOdds, marginParameter, margin, awayTeam, /*hasNormalValues, */homeTeam, market, marketGroupData, marketMaker, sportId } = this.props
        const { activeScope, otherOptionsOpened } = this.state
        
        const teamName: string = getTeamName(awayTeam, homeTeam, this.valuesWithOdds)
        const marketName: string =
            (marketGroupData && marketGroupData.name) ?
                GetNameFromMarketGroup(marketGroupData.name, homeTeam, awayTeam)
            :
/*
                (BetTypeGroupId[market] === BetTypeGroupId.OverUnder && !hasNormalValues) ?
                    'Asian Total Goals'
                : (BetTypeGroupId[market] === BetTypeGroupId.AsianHandicap && hasNormalValues) ?
                    'Handicap'
                :
*/
                getMarketNameAndOptions(market, sportId, { teamName }).name

        const sportScopes = scopes[sportId]
        const oddsKey =
            activeScope === ScopeId.FT ?
                'odds'
            : activeScope === ScopeId.P1 || activeScope === ScopeId.H1 || activeScope === ScopeId.J1 ?
                'odds1'
            : activeScope === ScopeId.P2 || activeScope === ScopeId.H2 ?
                'odds2'
            : activeScope === ScopeId.P3 ?
                'odds3'
            : ''

        const valuesWithOdds = fillValuesWithOdds(this.props[oddsKey], marketGroupData)
        const closestValue: string = getClosestValue(valuesWithOdds)
        const sortedValues: string[] = Object.keys(valuesWithOdds).sort((a: string, b: string) => sortBySize(+a, +b))
        this.closestOdds = (closestValue !== '') ? valuesWithOdds[closestValue] : []
        this.closestValue = closestValue
        this.sortedValues = sortedValues
        this.valuesWithOdds = valuesWithOdds
        return (
            <div className="market-box">
                <h3 className="market-name thin" style = {{position: 'relative'}}>
                    <div>{marketName}</div>
                    <MarketScope
                        Odds={Odds}
                        activeScope={activeScope}
                        scopes={sportScopes}
                        handleChangeScope={this.handleChangeScope}
                    />
                </h3>
                <div className = "market-menu">
                    <div className = "blank">
                        line
                    </div>
                    <div className = "values">
                        <div>{bettypegroupid === BetTypeGroupId.AsianHandicap ? homeTeam : 'Over'}</div>
                        <div>{bettypegroupid === BetTypeGroupId.AsianHandicap ? awayTeam : 'Under'}</div>
                    </div>
                </div>
                <div className="value-wrapper first">
                    <div
                        className={this.sortedValues.length === 1 ? 'value-item value-item-no-click' : 'value-item'}
                        onClick={this.handleOtherOptionsOpened}
                    >
                        <span className="value">{this.closestValue}</span>
                        <span className="arrow-down"/>
                    </div>
                    <div className="odds-wrapper">
                        {this.closestOdds.map((item, index) => {
                            const { outcomeid, outcomename, value } = item
                            const selectionText: string = (isMobile()) ? outcomename : getOutcomeText(awayTeam, homeTeam, outcomeid, value)
                            return (
                                <OddsItem token = {token} psbOdds = {false}
                                    margin = {margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={index}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className="single-odds align-center"
                                    item={item}
                                    marketMaker={false}
                                    marketMakerData={marketMaker}
                                    optionsLength={this.closestOdds.length}
                                    options={this.closestOdds}
                                    selectionText={selectionText}
                                    sportId={sportId}
                                    scopeId = {activeScope}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className={clsx('other-options', otherOptionsOpened && 'opened')}>
                    {this.sortedValues.filter(s => s !== this.closestValue).map(itemValue =>
                        <div key={itemValue} className="value-wrapper">
                            <div className="value-item">
                                <span className="value">{itemValue}</span>
                            </div>
                            <div className="odds-wrapper">
                                {this.valuesWithOdds[itemValue].map((item, index) => {
                                    const { outcomeid, outcomename, value } = item
                                    const selectionText: string = (isMobile()) ? outcomename : getOutcomeText(awayTeam, homeTeam, outcomeid, value)
                                    return (
                                        <OddsItem token = {token} psbOdds = {false}
                                            margin = {margin}
                                            marginParameter = {marginParameter}
                                            minOdds={minOdds}
                                            maxOdds={maxOdds}
                                            minCSOdds={minCSOdds}
                                            maxCSOdds={maxCSOdds}
                                            key={index + '_'}
                                            awayTeam={awayTeam}
                                            homeTeam={homeTeam}
                                            className="single-odds align-center"
                                            item={item}
                                            marketMaker={false}
                                            marketMakerData={marketMaker}
                                            optionsLength={this.valuesWithOdds[itemValue].length}
                                            options={this.valuesWithOdds[itemValue]}
                                            selectionText={selectionText}
                                            sportId={sportId}
                                            scopeId = {activeScope}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default MarketBoxWithValue

const checkDifferentOdds = (previousOdds: MarketOdds[], currentOdds: MarketOdds[]): boolean => {
    if (currentOdds.length !== previousOdds.length) {
        return true
    }

    if (previousOdds[0] && currentOdds[0] && previousOdds[0].matchid !== currentOdds[0].matchid) {
        return true
    }

    if (previousOdds[0] && currentOdds[0] && previousOdds[0].probability !== currentOdds[0].probability) {
        return true
    }

    return false
}

const getTeamName = (awayTeam: string, homeTeam: string, valuesWithOdds: {[value: string]: MarketOdds[]}): string => {
    const values: string[] = Object.keys(valuesWithOdds)
    const firstValue: string = values[0]

    if (firstValue && valuesWithOdds[firstValue]) {
        const firstOdds: MarketOdds = valuesWithOdds[firstValue][0]
        if (firstOdds && firstOdds.bettypegroupid === BetTypeGroupId.TeamOverUnder) {
            if (firstOdds.outcomeid === OutcomeId.OverHome || firstOdds.outcomeid === OutcomeId.UnderHome) {
                return homeTeam
            }
            return awayTeam
        }
    }
    
    return ''
}