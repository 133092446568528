import { RESTRoutes, REST_Routes, availableRESTRoutes } from './routes'
import query from '../../utils/query';
import { store } from '../../state'
import { Main } from '../../state/models'
import config from '../../configjson'
export default class Rest {
    private id: string;
    constructor(id: string) {
        this.id = id;
    }

    public request(type: 'get' | 'post' | 'put', name: availableRESTRoutes, queries?: object, body?: any, callback?: (response: any) => void) {
        const R = config.data_server_url.indexOf('/sportmodel') === -1 ? REST_Routes : RESTRoutes
        const route = R.find(r => r.name === name);
        if (!route) {
            throw new Error('Requested route does not exist.');
        }

        if (route.hasLoader) {
            store.update((main: Main) => {
                main.loaders.rest.push(route.name)
                return main
            })
        }

        const url: string = (queries) ? `${this.id}/${route.url}${query.create(queries)}` : `${this.id}/${route.url}`
        if (type === 'post') {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }).then(response => response.json()).then((data) => {
                route.mapper(data);
                store.update((main: Main) => {
                    const newRoutes = main.loaders.rest.filter(i => i !== route.name)
                    main.loaders.rest = newRoutes
                    return main
                })
                if (callback) {
                    callback(data)
                }
            });
        } else if (type === 'put') {
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            }).then(response => response.json()).then((data) => {
                route.mapper(data);
                store.update((main: Main) => {
                    const newRoutes = main.loaders.rest.filter(i => i !== route.name)
                    main.loaders.rest = newRoutes
                    return main
                })
                if (callback) {
                    callback(data)
                }
            });
        } else {
            fetch(url).then(response => response.json()).then(data => {
                route.mapper(data);
                store.update((main: Main) => {
                    const newRoutes = main.loaders.rest.filter(i => i !== route.name)
                    main.loaders.rest = newRoutes
                    return main
                })
                if (callback) {
                    callback(data)
                }
            });
        }
    }

}