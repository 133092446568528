import React from 'react'
import BetResult from './bet-result'
import BetslipSubitems from '../BetslipSubitems'
import Cashout from './cashout'
import Combo from './combo'
import dataServer from '../../../../network/rest'
import FaIcon from '../../FaIcon'
import ShowCashout from './show-cashout'
import { BetslipHistoryItem } from '../../../../state/stores/betslip/models'
import { BetTypeId } from '../../../../state/enums'
import { getSportIcon, getMatchDate, getBetText, capitalize, roundProduct, getOutcomeText } from '../../../../utils'
import { store } from '../../../../state'
import config from '../../../../configjson'
import './style.scss'

interface Props {
    cashout: string
    comboOdds: string
    comboPayout: string
    comboStake: string
    isSettled: boolean
    items: BetslipHistoryItem[]
    subitems: BetslipHistoryItem[]
    setBetslipUuid: (betslipUuid: string) => void
}

interface State {
    cashoutIndex: number
    cashoutPercentage: string
    subitemUuid: string
}

class BetslipItems extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            cashoutIndex: -1,
            cashoutPercentage: '100',
            subitemUuid: ''
        }
    }
    handleCashoutIndex = (index: number, item: BetslipHistoryItem): void => {
        const cashoutIndex: number = (index === this.state.cashoutIndex) ? -1 : index
        this.setState({ cashoutIndex })
        store.update(main => {
            main.betslip.cashout = ''
            return main
        })
        getCashout(item)
    }
    handleCashoutPercentage = (cashoutPercentage: string): void => {
        this.setState({ cashoutPercentage })
    }
    handleSubitemIndex = (betslipUuid: string, subitemUuid: string): void => {
        if (subitemUuid === this.state.subitemUuid) {
            this.setState({ subitemUuid: '' })
        } else {
            store.update(main => {
                main.betslip.history.subitems = []
                return main
            })
            this.setState({ subitemUuid })
            getBetslipSubitems(betslipUuid, subitemUuid)
        }
    }
    render() {
        const { cashout, comboOdds, comboPayout, comboStake, isSettled, items, setBetslipUuid, subitems } = this.props
        const { cashoutIndex, cashoutPercentage, subitemUuid } = this.state

        return (
            <div className="bet-betslip-items">
                {items.map((item, index) => {
                    const { away, awayscore, betodds, betoutcome, betslip_uuid, bettype, bettype2, home, homescore, issettled, league, payout, plannedstartdate, plannedstarttime, region, result, sportid, stake, uuid, wintype } = item
                    const status: string = (issettled) ? 'finished' : 'active'
                    const isStakeEmpty: boolean = checkStakeEmpty(stake)
                    const realStake: string = (!isStakeEmpty) ? stake.replace('$', '') : ''
                    const stakeToShow: string = (!isStakeEmpty) ? `€${realStake}` : ''
                    const oddsToShow: string = (betodds !== '0') ? betodds : ''
                    const possiblePayout: string = `€${roundProduct(+realStake * +betodds)}`
                    const isMarketMakerBet: boolean = bettype === BetTypeId.MMGeneral
                    const isBetrixBet = BetTypeId[bettype].includes('Betrix')
                    const showCashout: boolean = cashoutIndex === index && !isBetrixBet && !isMarketMakerBet
                    const isCashoutEmpty: boolean = cashout === ''
                    const betText: string = getBetText(bettype)
                    const outcomeText: string = (bettype === BetTypeId.MMGeneral) ? getBetText(bettype2) : getOutcomeText(away, home, betoutcome, null)
                    const showSubitems: boolean = subitemUuid === uuid

                    return (
                        <React.Fragment key={index}>
                            <div className="single-item">
                                <div className="sport">
                                    <img src={getSportIcon(sportid)}/>
                                </div>
                                <div className="region-league">
                                    <span title={region}>{region}</span>
                                    <span title={league}>{league}</span>
                                </div>
                                <div className="match">
                                    <span>{`${home} - ${away}`}</span>
                                    <span>{`${getMatchDate(plannedstartdate, plannedstarttime, true)}`}</span>
                                </div>
                                <div className="bet">
                                    <span title={betText}>{betText}</span>
                                    <span title={outcomeText}>{outcomeText}</span>
                                </div>
                                <div className="status">
                                    <FaIcon
                                        className={`status ${status}`}
                                        icon="circle"
                                        title={capitalize(status)}
                                        type="solid"
                                    />
                                </div>
                                {(!isStakeEmpty) &&
                                    <div className="stake">{stakeToShow}</div>
                                }
                                <div className="odds">{oddsToShow}</div>
                                <BetResult
                                    awayScore={awayscore}
                                    hasResult={result}
                                    homeScore={homescore}
                                    wintype={wintype}
                                />
                                {issettled && !isStakeEmpty && payout ?
                                    <div className="payout">
                                        <FaIcon
                                            icon="coins"
                                            type="solid"
                                        />
                                        <span>{payout.replace('$', '€')}</span>
                                    </div>
                                : (!isStakeEmpty && !isBetrixBet && !isMarketMakerBet) ?
                                    <ShowCashout
                                        index={index}
                                        item={item}
                                        possiblePayout={possiblePayout}
                                        setCashoutIndex={this.handleCashoutIndex}
                                    />
                                : 
                                    null
                                }
                                {(isBetrixBet || isMarketMakerBet) &&
                                    <FaIcon
                                        icon="search-plus"
                                        onClick={() => this.handleSubitemIndex(betslip_uuid, uuid)}
                                        title="Show more"
                                        type="solid"
                                    />
                                }
                                {showCashout && !isCashoutEmpty &&
                                    <Cashout
                                        betslipUuid={betslip_uuid}
                                        betslipItemUuid={uuid}
                                        cashout={cashout}
                                        cashoutPercentage={cashoutPercentage}
                                        setBetslipUuid={setBetslipUuid}
                                        setCashoutPercentage={this.handleCashoutPercentage}
                                        stake={realStake}
                                    />
                                }
                            </div>
                            {showSubitems &&
                                <BetslipSubitems
                                    sportId = {sportid}
                                    betTypeId={bettype}
                                    subitems={subitems}
                                />
                            }
                        </React.Fragment>
                    )
                })}
                {comboOdds && comboStake &&
                    <Combo
                        comboOdds={comboOdds}
                        comboPayout={comboPayout}
                        comboStake={comboStake}
                        isSettled={isSettled}
                    />
                }
            </div>
        )
    }
}

export default BetslipItems

const checkStakeEmpty = (stake: string): boolean => {
    const isStakeEmpty: boolean = stake === '$0.00'
    return isStakeEmpty
}

const getBetslipSubitems = (betslip_uuid: string, item_uuid: string): void => {
    dataServer.request('get', 'get-betslip-subitems', {
        tenant: config.tenant_id,
        betslip_uuid,
        item_uuid
    })
}

const getCashout = (item: BetslipHistoryItem): void => {
    const { betodds, betoutcome, bettype, match_id, matchscope_id, stake } = item
    const realStake: number = +stake.replace('$', '')

    dataServer.request('get', 'get-cashout', {
        stake: realStake,
        islive: false,
        odds: +betodds,
        match_id,
        matchscope_id,
        bettype_id: bettype,
        outcome_id: betoutcome
    })
}