import { BetTypeGroupId, SportId, ScopeId, OutcomeId  } from '../state/enums'
import { QuickLeague } from '../state/stores/feed/models'
import { SSMarketOptions } from '../state/stores/betslip/models'
export const betrixSoccerMaxGoals: number = 4 // export const betrixDefaultMaxGoals: number = 4
export const betrixHockeyMaxGoals: number = 6
export const betrixHalfSoccerMaxGoals: number = 3
export const betrixPeriodHockeyMaxGoals: number = 3
export const betrixSkiJumpingX: number = 6
export const betrixSkiJumpingY: number = 5
export const betrixCyclingX: number = 11
export const betrixCyclingY: number = 11

export const scopes: ScopeId[][] = [[ScopeId.FT], // 0 nothing
    [ScopeId.FT, ScopeId.H1, ScopeId.H2], // 1 soccer
    [ScopeId.FT], // 2 ?
    [ScopeId.FT], // 3 ?
    [ScopeId.FT, ScopeId.P1, ScopeId.P2, ScopeId.P3], // 4 ice hockey
    [ScopeId.FT],// 5
    [ScopeId.FT],// 6
    [ScopeId.FT],// 7
    [ScopeId.FT],// 8
    [ScopeId.FT],// 9
    [ScopeId.FT],// 10
    [ScopeId.FT],// 11
    [ScopeId.FT],// 12
    [ScopeId.FT],// 13
    [ScopeId.FT],// 14
    [ScopeId.FT],// 15
    [ScopeId.FT],// 16
    [ScopeId.FT],// 17
    [ScopeId.FT],// 18
    [ScopeId.FT],// 19
    [ScopeId.FT, ScopeId.J1],// 20 SKI JUMPING
    [ScopeId.FT],// 21
    [ScopeId.FT],// 22
    [ScopeId.FT],// 23
    [ScopeId.FT],// 24
    [ScopeId.FT],// 25
    [ScopeId.FT],// 26
    [ScopeId.FT],// 27
    [ScopeId.FT],// 28
    [ScopeId.FT],// 29
    [ScopeId.FT],// 30
    [ScopeId.FT],// 31
    [ScopeId.FT],// 32
    [ScopeId.FT],// 33
    [ScopeId.FT],// 34
    [ScopeId.FT],// 35
    [ScopeId.FT] // 36 esports
]

export const isBetrixFlexible = true
// export const betrixDefaultMinBet: number = 0.01
// export const betrixDefaultCurrency: string = 'EUR'
// export const betrixDefaultCurrencySymbol: string = '€'
export const betrixDefaultLocale: string = 'en_US' // English

export const dayInWeek = {
    en_US: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
}

export const monthInYear = {
    en_US: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
}

export const defaultQuickLeagues: QuickLeague[] = [
    { league: 'Premier League', region: 'England', sportId: SportId.Soccer },
    { league: 'Champions League', region: 'Europe', sportId: SportId.Soccer },
    { league: 'Europa League', region: 'Europe', sportId: SportId.Soccer },
    { league: 'NBA', region: 'USA', sportId: SportId.Basketball },
    { league: 'NHL', region: 'USA', sportId: SportId.Hockey }
]

export const nonMarketMakerMarkets: BetTypeGroupId[] = [
    BetTypeGroupId.OverUnder,
]
export const marketMakerMarkets: BetTypeGroupId[] = [
    BetTypeGroupId.CorrectScore,
    BetTypeGroupId.ThreeWay,
    BetTypeGroupId.EuropeanHandicap,
    BetTypeGroupId.WinMargin2,
    BetTypeGroupId.WinMargin7,
    BetTypeGroupId.WinMargin8,
    BetTypeGroupId.WinMargin9,
    BetTypeGroupId.WinMargin10,
    BetTypeGroupId.WinMargin11,
    BetTypeGroupId.WinMargin12,
    BetTypeGroupId.WinMargin13,
    BetTypeGroupId.WinMargin14,
    BetTypeGroupId.NumberOfGoals,
    BetTypeGroupId.TeamNumberOfGoals,
    BetTypeGroupId.ExactNumberOfGoals,
    BetTypeGroupId.ExactNumberOfGoals2,
    BetTypeGroupId.ExactNumberOfGoals3,
    BetTypeGroupId.ExactNumberOfGoals4,
    BetTypeGroupId.ExactNumberOfGoals5,
    BetTypeGroupId.ExactNumberOfGoals6,
    BetTypeGroupId.ExactNumberOfGoals7,
    BetTypeGroupId.TeamExactNumberOfGoals,
    BetTypeGroupId.TeamExactNumberOfGoals2,
    BetTypeGroupId.TeamExactNumberOfGoals3,
    BetTypeGroupId.TeamExactNumberOfGoals4,
    BetTypeGroupId.TeamExactNumberOfGoals5,
    BetTypeGroupId.HalfTimeFullTime,
    BetTypeGroupId.HalfTimeFullTime2,
    BetTypeGroupId.HalfTimeFullTime3,
    BetTypeGroupId.HighestScoringHalf,
    BetTypeGroupId.TeamHighestScoringHalf,
    BetTypeGroupId.FirstTwoGoals,
    BetTypeGroupId.FirstHalfSecondHalf,
    BetTypeGroupId.TimeOfFirstGoal,
    BetTypeGroupId.TimeOfFirstGoal2,
    BetTypeGroupId.TimeOfFirstGoal3,
    BetTypeGroupId.TeamHighestScoringHalf2,
    BetTypeGroupId.TeamsToScore,
    BetTypeGroupId.FirstLastToScore,
    BetTypeGroupId.TeamTimeOfFirstGoal
]
export const OddscraftLogo = require('./Oddscraft_Logo.png').default

/*
export const AlbaniaFlag = require('./albania-flag.png').default
export const AlgeriaFlag = require('./algeria-flag.png').default
export const ArgentinaFlag = require('./argentina-flag.png').default
export const ArubaFlag = require('./aruba-flag.png').default
export const AustraliaFlag = require('./australia-flag.png').default
export const AustriaFlag = require('./austria-flag.png').default
export const AzerbaijanFlag = require('./azerbaijan-flag.png').default

export const BangladeshFlag = require('./bangladesh-flag.png').default
export const BasketballBanner1 = require('./basketball-banner-1.jpg').default
export const BasketballBanner2 = require('./basketball-banner-2.jpg').default
export const BasketballBanner3 = require('./basketball-banner-3.jpg').default
export const BasketballIcon = require('./basketball-icon.svg').default
export const BelarusFlag = require('./belarus-flag.png').default
export const BasketballIconGreen = require('./basketball-icon-green.svg').default
export const BelgiumFlag = require('./belgium-flag.png').default

export const BoliviaFlag = require('./bolivia-flag.png').default
export const BosniaAndHerzegovinaFlag = require('./bosnia-and-herzegovina-flag.png').default
export const BrazilFlag = require('./brazil-flag.png').default
export const BulgariaFlag = require('./bulgaria-flag.png').default
export const BurkinaFasoFlag = require('./burkina-faso-flag.png').default
export const BurundiFlag = require('./burundi-flag.png').default
export const CameroonFlag = require('./cameroon-flag.png').default
export const CanadaFlag = require('./canada-flag.png').default
export const ChileFlag = require('./chile-flag.png').default
export const ChinaFlag = require('./china-flag.png').default
export const ColombiaFlag = require('./colombia-flag.png').default
export const CostaRicaFlag = require('./costa-rica-flag.png').default
export const CotedivoireFlag = require('./cotedivoire-flag.png').default
export const CroatiaFlag = require('./croatia-flag.png').default
export const CyprusFlag = require('./cyprus-flag.png').default
export const CzechRepublicFlag = require('./czech-republic-flag.png').default
export const DenmarkFlag = require('./denmark-flag.png').default
export const EcuadorFlag = require('./ecuador-flag.png').default
export const EgyptFlag = require('./egypt-flag.png').default
export const EthiopiaFlag = require('./ethiopia-flag.png').default
export const EnglandFlag = require('./england-flag.png').default
export const EstoniaFlag = require('./estonia-flag.png').default
export const EuropeFlag = require('./europe-flag.png').default
export const FinlandFlag = require('./finland-flag.png').default
export const FranceFlag = require('./france-flag.png').default

export const GeorgiaFlag = require('./georgia-flag.png').default
export const GermanyFlag = require('./germany-flag.png').default
export const GibraltarFlag = require('./gibraltar-flag.png').default
export const GreeceFlag = require('./greece-flag.png').default
export const HockeyBanner1 = require('./hockey-banner-1.jpg').default
export const HockeyBanner2 = require('./hockey-banner-2.jpg').default
export const HockeyBanner3 = require('./hockey-banner-3.jpg').default
export const HockeyIcon = require('./hockey-icon.svg').default
export const HockeyIconGreen = require('./hockey-icon-green.svg').default
export const HungaryFlag = require('./hungary-flag.png').default
export const IcelandFlag = require('./iceland-flag.png').default
export const IndiaFlag = require('./india-flag.png').default
export const IranFlag = require('./iran-flag.png').default
export const IrelandFlag = require('./ireland-flag.png').default
export const IsraelFlag = require('./israel-flag.png').default
export const ItalyFlag = require('./italy-flag.png').default
export const JapanFlag = require('./japan-flag.png').default
export const KazakhstanFlag = require('./kazahstan-flag.png').default
export const KenyaFlag = require('./kenya-flag.png').default
export const KosovoFlag = require('./kosovo-flag.png').default
export const KuwaitFlag = require('./kuwait-flag.png').default
export const LithuaniaFlag = require('./lithuania-flag.png').default
export const LuxembourgFlag = require('./luxembourg-flag.png').default
// export const MacaoFlag = require('./macau-flag.png').default
// export const MacauFlag = require('./macau-flag.png').default
export const MaltaFlag = require('./malta-flag.png').default
export const MexicoFlag = require('./mexico-flag.png').default
export const MontenegroFlag = require('./montenegro-flag.png').default
export const MoroccoFlag = require('./morocco-flag.png').default
export const NetherlandsFlag = require('./netherlands-flag.png').default
export const NewZealandFlag = require('./new-zealand-flag.png').default
export const NigeriaFlag = require('./nigeria-flag.png').default
export const NorthMacedoniaFlag = require('./north-macedonia-flag.png').default
export const NorthernIrelandFlag = require('./northern-ireland-flag.png').default
export const NorwayFlag = require('./norway-flag.png').default
export const OmanFlag = require('./oman-flag.png').default
export const PolandFlag = require('./poland-flag.png').default
export const PortugalFlag = require('./portugal-flag.png').default
export const QatarFlag = require('./qatar-flag.png').default
export const RomaniaFlag = require('./romania-flag.png').default
export const RussiaFlag = require('./russia-flag.png').default
export const SaudiArabiaFlag = require('./saudi-arabia-flag.png').default
export const SenegalFlag = require('./senegal-flag.png').default
export const ScotlandFlag = require('./scotland-flag.png').default
export const SerbiaFlag = require('./serbia-flag.png').default
export const SingaporeFlag = require('./singapore-flag.png').default
export const SoccerBanner1 = require('./soccer-banner-1.jpg').default
export const SoccerBanner2 = require('./soccer-banner-2.jpg').default
export const SoccerBanner3 = require('./soccer-banner-3.jpg').default
export const SoccerIcon = require('./soccer-icon.svg').default
export const SoccerIconGreen = require('./soccer-icon-green.svg').default
export const SlovakiaFlag = require('./slovakia-flag.png').default
export const SloveniaFlag = require('./slovenia-flag.png').default
export const SouthAfricaFlag = require('./south-africa-flag.png').default
export const SouthKoreaFlag = require('./south-korea-flag.png').default
export const SpainFlag = require('./spain-flag.png').default
export const SwedenFlag = require('./sweden-flag.png').default
export const SwitzerlandFlag = require('./switzerland-flag.png').default
export const TaiwanFlag = require('./taiwan-flag.png').default
export const TennisIcon = require('./tennis-icon.svg').default
export const TennisIconGreen = require('./tennis-icon-green.svg').default
export const TunisiaFlag = require('./tunisia-flag.png').default
export const TurkeyFlag = require('./turkey-flag.png').default
export const UgandaFlag = require('./uganda-flag.png').default
export const UkraineFlag = require('./ukraine-flag.png').default
export const UnitedArabEmiratesFlag = require('./united-arab-emirates-flag.png').default
export const UnitedKingdomFlag = require('./united-kingdom-flag.png').default
export const UruguayFlag = require('./uruguay-flag.png').default
export const USAFlag = require('./usa-flag.png').default
export const UzbekistanFlag = require('./uzbekistan-flag.png').default
export const VenezuelaFlag = require('./venezuela-flag.png').default
export const WalesFlag = require('./wales-flag.png').default
*/

export const AlbaniaFlag = require('./albania-flag.png').default
export const AndorraFlag = require('./andorra-flag.png').default
export const AngolaFlag = require('./angola-flag.png').default
export const AlgeriaFlag = require('./algeria-flag.png').default
export const ArgentinaFlag = require('./argentina-flag.png').default
export const ArmeniaFlag = require('./armenia-flag.png').default
export const ArubaFlag = require('./aruba-flag.png').default
export const AustraliaFlag = require('./australia-flag.png').default
export const AustriaFlag = require('./austria-flag.png').default
export const AzerbaijanFlag = require('./azerbaijan-flag.png').default
export const BahrainFlag = require('./bahrain-flag.png').default
export const BangladeshFlag = require('./bangladesh-flag.png').default
export const BelarusFlag = require('./belarus-flag.png').default
export const BelgiumFlag = require('./belgium-flag.png').default
export const BoliviaFlag = require('./bolivia-flag.png').default
export const BosniaandHerzegovinaFlag = require('./bosnia-and-herzegovina-flag.png').default
export const BrazilFlag = require('./brazil-flag.png').default
export const BulgariaFlag = require('./bulgaria-flag.png').default
export const BurkinaFasoFlag = require('./burkina-faso-flag.png').default
export const BurundiFlag = require('./burundi-flag.png').default
export const CameroonFlag = require('./cameroon-flag.png').default
export const CanadaFlag = require('./canada-flag.png').default
export const ChileFlag = require('./chile-flag.png').default
export const ChinaFlag = require('./china-flag.png').default
export const CISFlag = require('./cis-flag.png').default
export const ColombiaFlag = require('./colombia-flag.png').default
export const CostaRicaFlag = require('./costa-rica-flag.png').default
export const CotedivoireFlag = require('./cotedivoire-flag.png').default
export const CroatiaFlag = require('./croatia-flag.png').default
export const CyprusFlag = require('./cyprus-flag.png').default
export const CzechRepublicFlag = require('./czech-republic-flag.png').default
export const DenmarkFlag = require('./denmark-flag.png').default
export const EcuadorFlag = require('./ecuador-flag.png').default
export const EgyptFlag = require('./egypt-flag.png').default
export const EthiopiaFlag = require('./ethiopia-flag.png').default
export const EnglandFlag = require('./england-flag.png').default
export const EstoniaFlag = require('./estonia-flag.png').default
export const EuropeFlag = require('./europe-flag.png').default
export const FaroeIslandsFlag = require('./faroe-islands-flag.png').default
export const FinlandFlag = require('./finland-flag.png').default
export const FranceFlag = require('./france-flag.png').default
export const GambiaFlag = require('./gambia-flag.png').default
export const GeorgiaFlag = require('./georgia-flag.png').default
export const GermanyFlag = require('./germany-flag.png').default
export const GhanaFlag = require('./ghana-flag.png').default
export const GibraltarFlag = require('./gibraltar-flag.png').default
export const GreeceFlag = require('./greece-flag.png').default
export const HondurasFlag = require('./honduras-flag.png').default
export const HongKongFlag = require('./hongkong-flag.png').default
export const HungaryFlag = require('./hungary-flag.png').default
export const IcelandFlag = require('./iceland-flag.png').default
export const IndiaFlag = require('./india-flag.png').default
export const IranFlag = require('./iran-flag.png').default
export const IraqFlag = require('./iraq-flag.png').default
export const IrelandFlag = require('./ireland-flag.png').default
export const IsraelFlag = require('./israel-flag.png').default
export const ItalyFlag = require('./italy-flag.png').default
export const IvoryCoastFlag = require('./cotedivoire-flag.png').default
export const JapanFlag = require('./japan-flag.png').default
export const KazakhstanFlag = require('./kazakhstan-flag.png').default
export const KenyaFlag = require('./kenya-flag.png').default
export const KosovoFlag = require('./kosovo-flag.png').default
export const KuwaitFlag = require('./kuwait-flag.png').default
export const LatviaFlag = require('./latvia-flag.png').default
export const LithuaniaFlag = require('./lithuania-flag.png').default
export const LiechtensteinFlag = require('./liechtenstein-flag.png').default
export const LuxembourgFlag = require('./luxembourg-flag.png').default
export const MaltaFlag = require('./malta-flag.png').default
export const MauritaniaFlag = require('./mauritania-flag.png').default
export const MexicoFlag = require('./mexico-flag.png').default
export const MoldovaFlag = require('./moldova-flag.png').default
export const MonacoFlag = require('./monaco-flag.png').default
export const MontenegroFlag = require('./montenegro-flag.png').default
export const MoroccoFlag = require('./morocco-flag.png').default
export const MyanmarFlag = require('./myanmar-flag.png').default
export const NetherlandsFlag = require('./netherlands-flag.png').default
export const NewZealandFlag = require('./new-zealand-flag.png').default
export const NicaraguaFlag = require('./nicaragua-flag.png').default
export const NigeriaFlag = require('./nigeria-flag.png').default
export const NorthMacedoniaFlag = require('./north-macedonia-flag.png').default
export const NorthernIrelandFlag = require('./northernireland-flag.png').default
export const NorwayFlag = require('./norway-flag.png').default
export const OmanFlag = require('./oman-flag.png').default
export const PalestineFlag = require('./palestine-flag.png').default
export const ParaguayFlag = require('./paraguay-flag.png').default
export const PolandFlag = require('./poland-flag.png').default
export const PortugalFlag = require('./portugal-flag.png').default
export const QatarFlag = require('./qatar-flag.png').default
export const RomaniaFlag = require('./romania-flag.png').default
export const RussiaFlag = require('./russia-flag.png').default
export const SanMarinoFlag = require('./sanmarino-flag.png').default
export const SaudiArabiaFlag = require('./saudi-arabia-flag.png').default
export const SenegalFlag = require('./senegal-flag.png').default
export const ScotlandFlag = require('./scotland-flag.png').default
export const SerbiaFlag = require('./serbia-flag.png').default
export const SingaporeFlag = require('./singapore-flag.png').default
export const SlovakiaFlag = require('./slovakia-flag.png').default
export const SloveniaFlag = require('./slovenia-flag.png').default
export const SouthAfricaFlag = require('./south-africa-flag.png').default
export const SouthKoreaFlag = require('./south-korea-flag.png').default
export const SpainFlag = require('./spain-flag.png').default
export const SwedenFlag = require('./sweden-flag.png').default
export const SwitzerlandFlag = require('./switzerland-flag.png').default
export const SyriaFlag = require('./syria-flag.png').default
export const TaiwanFlag = require('./taiwan-flag.png').default
export const TajikistanFlag = require('./tajikistan-flag.png').default
export const TanzaniaFlag = require('./tanzania-flag.png').default
export const ThailandFlag = require('./thailand-flag.png').default
export const TunisiaFlag = require('./tunisia-flag.png').default
export const TurkeyFlag = require('./turkey-flag.png').default
export const TurkmenistanFlag = require('./turkmenistan-flag.png').default
export const UgandaFlag = require('./uganda-flag.png').default
export const UkraineFlag = require('./ukraine-flag.png').default
export const UnitedArabEmiratesFlag = require('./united-arab-emirates-flag.png').default
export const UAEFlag = require('./united-arab-emirates-flag.png').default
export const UnitedKingdomFlag = require('./united-kingdom-flag.png').default
export const UruguayFlag = require('./uruguay-flag.png').default
export const USAFlag = require('./usa-flag.png').default
export const VenezuelaFlag = require('./venezuela-flag.png').default
export const VietnamFlag = require('./vietnam-flag.png').default
export const WalesFlag = require('./wales-flag.png').default

export const AfghanistanFlag = require('./afghanistan-flag.png').default
export const AntiguaandBarbudaFlag = require('./antiguaandbarbuda-flag.png').default
export const BahamasFlag = require('./bahamas-flag.png').default
export const BarbadosFlag = require('./barbados-flag.png').default
export const BasqueCountryFlag = require('./basque-flag.png').default
export const BelizeFlag = require('./belize-flag.png').default
export const BeninFlag = require('./benin-flag.png').default
export const BhutanFlag = require('./bhutan-flag.png').default
export const BotswanaFlag = require('./botswana-flag.png').default
export const BruneiFlag = require('./brunei-flag.png').default
export const CambodiaFlag = require('./cambodia-flag.png').default
export const CapeVerdeFlag = require('./cape-verde-flag.png').default
export const CentralAfricanRepublicFlag = require('./centralafricanrepublic-flag.png').default
export const CentralAfricaFlag = require('./centralafricanrepublic-flag.png').default
export const ChadFlag = require('./chad-flag.png').default
export const ComorosFlag = require('./comoros-flag.png').default
export const CubaFlag = require('./cuba-flag.png').default
export const DRCongoFlag = require('./democraticrepublicofthecongo-flag.png').default
export const CongoFlag = require('./democraticrepublicofthecongo-flag.png').default
export const DemocracticRepublicOfTheCongoFlag = require('./democraticrepublicofthecongo-flag.png').default
export const DjiboutiFlag = require('./djibouti-flag.png').default
export const DominicaFlag = require('./dominica-flag.png').default
export const DominicanRepublicFlag = require('./dominicanrepublic-flag.png').default
export const ElSalvadorFlag = require('./elsalvador-flag.png').default
export const EquatorialGuineaFlag = require('./equatorialguinea-flag.png').default
export const EritreaFlag = require('./eritrea-flag.png').default
export const EswatiniFlag = require('./eswatini-flag.png').default
export const FijiFlag = require('./fiji-flag.png').default
export const GabonFlag = require('./gabon-flag.png').default
export const GrenadaFlag = require('./grenada-flag.png').default
export const GuatemalaFlag = require('./guatemala-flag.png').default
export const GuineaBissauFlag = require('./guineabissau-flag.png').default
export const GuineaFlag = require('./guinea-flag.png').default
export const GuyanaFlag = require('./guyana-flag.png').default
export const HaitiFlag = require('./haiti-flag.png').default
export const IndonesiaFlag = require('./indonesia-flag.png').default
export const JamaicaFlag = require('./jamaica-flag.png').default
export const JordanFlag = require('./jordan-flag.png').default
export const KiribatiFlag = require('./kiribati-flag.png').default
export const KyrgyzstanFlag = require('./kyrgyzstan-flag.png').default
export const LaosFlag = require('./laos-flag.png').default
export const LebanonFlag = require('./lebanon-flag.png').default
export const LesothoFlag = require('./lesotho-flag.png').default
export const LiberiaFlag = require('./liberia-flag.png').default
export const LibyaFlag = require('./libya-flag.png').default
export const MadagascarFlag = require('./madagascar-flag.png').default
export const MalawiFlag = require('./malawi-flag.png').default
export const MalaysiaFlag = require('./malaysia-flag.png').default
export const MaldivesFlag = require('./maldives-flag.png').default
export const MaliFlag = require('./mali-flag.png').default
export const MarshallIslandsFlag = require('./marshallislands-flag.png').default
export const MauritiusFlag = require('./mauritius-flag.png').default
export const MicronesiaFlag = require('./micronesia-flag.png').default
export const MongoliaFlag = require('./mongolia-flag.png').default
export const MozambiqueFlag = require('./mozambique-flag.png').default
export const NamibiaFlag = require('./namibia-flag.png').default
export const NauruFlag = require('./nauru-flag.png').default
export const NepalFlag = require('./nepal-flag.png').default
export const NigerFlag = require('./niger-flag.png').default
export const NorthKoreaFlag = require('./northkorea-flag.png').default
export const PakistanFlag = require('./pakistan-flag.png').default
export const PalauFlag = require('./palau-flag.png').default
export const PanamaFlag = require('./panama-flag.png').default
export const PapuaNewGuineaFlag = require('./papuanewguinea-flag.png').default
export const PeruFlag = require('./peru-flag.png').default
export const PhilippinesFlag = require('./philippines-flag.png').default
export const RepublicoftheCongoFlag = require('./republicofthecongo-flag.png').default
export const RwandaFlag = require('./rwanda-flag.png').default
export const SaintKittsandNevisFlag = require('./saintkittsandnevis-flag.png').default
export const SaintVincentandtheGrenadinesFlag = require('./saintvincentandthegrenadines-flag.png').default
export const SamoaFlag = require('./samoa-flag.png').default
export const SaoTomeandPrincipeFlag = require('./saotomeandprincipe-flag.png').default
export const SeychellesFlag = require('./seychelles-flag.png').default
export const SierraLeoneFlag = require('./sierraleone-flag.png').default
export const SolomonIslandsFlag = require('./solomonislands-flag.png').default
export const SomaliaFlag = require('./somalia-flag.png').default
export const SriLankaFlag = require('./srilanka-flag.png').default
export const SudanFlag = require('./sudan-flag.png').default
export const SurinameFlag = require('./suriname-flag.png').default
export const TimorLesteFlag = require('./timor-leste-flag.png').default
export const TogoFlag = require('./togo-flag.png').default
export const TongaFlag = require('./tonga-flag.png').default
export const TrinidadandTobagoFlag = require('./trinidadandtobago-flag.png').default
export const TuvaluFlag = require('./tuvalu-flag.png').default
export const UzbekistanFlag = require('./uzbekistan-flag.png').default
export const VanuatuFlag = require('./vanuatu-flag.png').default
export const VaticanFlag = require('./vaticancity-flag.png').default
export const YemenFlag = require('./yemen-flag.png').default
export const ZambiaFlag = require('./zambia-flag.png').default
export const ZimbabweFlag = require('./zimbabwe-flag.png').default

export const AbkhaziaFlag = require('./abkhazia-flag.png').default
export const ArtsakhFlag = require('./artsakh-flag.png').default
export const NorthernCyprusFlag = require('./northerncyprus-flag.png').default
export const SomalilandFlag = require('./somaliland-flag.png').default
export const SouthOssetiaFlag = require('./southossetia-flag.png').default
export const TransnistriaFlag = require('./transnistria-flag.png').default
export const WesternSaharaFlag = require('./sahrawi-flag.png').default
export const SahrawiFlag = require('./sahrawi-flag.png').default
export const CaymanIslandsFlag = require('./caymanislands-flag.png').default
export const SaintLuciaFlag = require('./saintlucia-flag.png').default
export const BermudaFlag = require('./bermuda-flag.png').default
export const GuamFlag = require('./guam-flag.png').default
export const MacaoFlag = require('./macau-flag.png').default
export const MacauFlag = require('./macau-flag.png').default
export const MontserratFlag = require('./montserrat-flag.png').default
export const NorthernMarianaIslandsFlag = require('./northernmarianaislands-flag.png').default
export const PuertoRicoFlag = require('./puertorico-flag.png').default
export const TurksandCaicosIslandsFlag = require('./turksandcaicosislands-flag.png').default
export const BritishVirginIslandsFlag = require('./britishvirginislands-flag.png').default
export const UnitedStatesVirginIslandsFlag = require('./unitedstatesvirginislands-flag.png').default
export const NetherlandsAntillesFlag = require('./netherlandsantilles-flag.png').default
export const AmericanSamoaFlag = require('./americansamoa-flag.png').default
export const AnguillaFlag = require('./anguilla-flag.png').default
export const CookIslandsFlag = require('./cookislands-flag.png').default
export const NewCaledoniaFlag = require('./newcaledonia-flag.png').default
export const ZanzibarFlag = require('./zanzibar-flag.png').default
export const NiueFlag = require('./niue-flag.png').default
export const FrenchPolynesiaFlag = require('./frenchpolynesia-flag.png').default
export const CuracaoFlag = require('./curacao-flag.png').default
export const IsleofManFlag = require('./isleofman-flag.png').default
export const SouthSudanFlag = require('./southsudan-flag.png').default
export const JerseyFlag = require('./jersey-flag.png').default
export const GreenlandFlag = require('./greenland-flag.png').default
export const GuernseyFlag = require('./guernsey-flag.png').default
export const CataloniaFlag = require('./catalonia-flag.png').default
export const BonaireFlag = require('./bonaire-flag.png').default

export const GuadeloupeFlag = require('./france-flag.png').default
export const TahitiFlag = require('./frenchpolynesia-flag.png').default
export const ReunionFlag = require('./france-flag.png').default
export const MartiniqueFlag = require('./france-flag.png').default
export const FrenchGuianaFlag = require('./france-flag.png').default
export const SaintMartinFlag = require('./france-flag.png').default
export const SintMaartenFlag = require('./netherlands-flag.png').default

export const BackspaceIconRed = require('./backspace-icon-red.svg').default
export const BetslipIcon = require('./betslip-icon.svg').default
export const GamanzaLogo = require('./gamanza-logo.svg').default
export const FootballPitch = require('./football-pitch.png').default
export const aorSoccer = require('./aor-soccer.png').default

export const aorHockey = ''
export const SkiJumpingHill = ''
export const FootballPitchV2 = ''
export const IceHockeyRink = ''
export const CyclingRoad = ''

// export const aorHockey = require('./aor-hockey.png').default
// export const FootballPitchV2 = require('./footballpitch.png').default
// export const IceHockeyRink = require('./hockey-rink.png').default
// export const CyclingRoad = require('./climb40.jpg').default
// export const SkiJumpingHill = require('./skijumping.jpg').default


export const yellowCard = require ('./y.png').default
export const redCard = require ('./r.png').default
export const yellowRedCard = require ('./yr.png').default

export const goal = require('./ball.svg').default
export const substIn = require('./arrow-green.svg').default
export const substOut = require('./red-arrow.svg').default

export const wgr = require ('./wide-green-blackboard.jpg').default
export const Var = require ('./var.png').default
export const flags = require ('./flags.png').default
export const whistle = require ('./whistle.png').default

export const wideMarkets: BetTypeGroupId[] = [
    BetTypeGroupId.ExactNumberOfGoals,
    BetTypeGroupId.ExactNumberOfGoals2,
    BetTypeGroupId.ExactNumberOfGoals3,
    BetTypeGroupId.ExactNumberOfGoals4,
    BetTypeGroupId.ExactNumberOfGoals5,
    BetTypeGroupId.ExactNumberOfGoals6,
    BetTypeGroupId.ExactNumberOfGoals7,
    BetTypeGroupId.WinMargin2,
    BetTypeGroupId.WinMargin7,
    BetTypeGroupId.WinMargin8,
    BetTypeGroupId.WinMargin9,
    BetTypeGroupId.WinMargin10,
    BetTypeGroupId.WinMargin11,
    BetTypeGroupId.WinMargin12,
    BetTypeGroupId.WinMargin13,
    BetTypeGroupId.WinMargin14,
    BetTypeGroupId.HalfTimeFullTime,
    BetTypeGroupId.FirstHalfSecondHalf,
    BetTypeGroupId.TimeOfFirstGoal,
    BetTypeGroupId.TimeOfFirstGoal2,
    BetTypeGroupId.TeamHighestScoringHalf2,
    BetTypeGroupId.CorrectScore,
]

export const SystemSingleMarkets = {
    [SSMarketOptions.HomeWins]: {
        name: 'Home wins',
        question: 'How many home wins?',
        inputBetId: '10',
        inputType: '0'

    },
    [SSMarketOptions.AwayWins]: {
        name: 'Away wins',
        question: 'How many away wins?',
        inputBetId: '10',
        inputType: '1'
    },
    [SSMarketOptions.Draws]: {
        name: 'Draws',
        question: 'How many draws?',
        inputBetId: '10',
        inputType: '2'
    },
    [SSMarketOptions.Goals]: {
        name: 'Goals',
        question: 'How many matches ends with over 2.5 goals?',
        inputBetId: '60',
        inputType: 'Over'
    }
}

export const marketsWithDescriptions: {[market: string]: {[outcomeId: string]: string}} = {
    [BetTypeGroupId[BetTypeGroupId.HalfTimeFullTime2]]: {
        [OutcomeId.HTFT2HomeHome]: 'Home team has to lead at half time and win the match at the end',
        [OutcomeId.HTFT2DrawOrAwayHome]: 'Home team has to lose or draw at half time but win the match at the end',
        [OutcomeId.HTFT2HomeDrawOrAway]: 'Home team has to lead at half time but lose or draw at the end',
        [OutcomeId.HTFT2DrawOrAwayDrawOrAway]: 'Home team has to lose or draw at half time and lose or draw the match at the end'
    },
    [BetTypeGroupId[BetTypeGroupId.HalfTimeFullTime3]]: {
        [OutcomeId.HTFT3HomeOrDrawHomeOrDraw]: 'Home team has to lead or draw at half time and win or draw the match at the end',
        [OutcomeId.HTFT3AwayHomeOrDraw]: 'Home team has to lose at half time but win or draw the match at the end',
        [OutcomeId.HTFT3HomeOrDrawAway]: 'Home team has to lead or draw at half time but lose at the end',
        [OutcomeId.HTFT3AwayAway]: 'Home team has to lose at half time and lose the match at the end'
    }
}