import WS from '@gamingenius/websocket-browser'
import WSRoutes from './routes'
import { LiveServerWS } from './types'

export const liveServerId: string = 'localhost'
export const liveServerPort: number = 9095
export const liveServerSecret: string = 'KVjHtLLx2U3Bd8vt'

const liveServer: LiveServerWS = new WS({
    name: 'Live Server',
    host: liveServerId,
    secure: false,
    connect: false,
    port: liveServerPort,
    capture: {
        message: (path, data) => {
            const route = WSRoutes.find(r => r.name === path)
            if (route) {
                route.mapper(data)
            } else {
                console.warn('Could not find WebSocket route. Please check src/network/websocket/routes.ts')
            }
        }
    }
})

export default liveServer