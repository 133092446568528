import React from 'react'
import { BetslipHistoryItem } from '../../../../state/stores/betslip/models'
import { createBetslipSubitemsMatrix, getOutcomeText, capitalize } from '../../../../utils'
import { MatrixValue, BetTypeId, SportId } from '../../../../state/enums'
import './style.scss'

interface Props {
    betTypeId: BetTypeId
    subitems: BetslipHistoryItem[]
    sportId: SportId
}

const BetslipSubitems = ({ betTypeId, subitems, sportId }: Props) => {
    if (betTypeId === BetTypeId.MMGeneral) {
        return (
            <div className="bet-betslip-subitems market-maker">
                {subitems.map(item => {
                    const { away, betodds, betoutcome, home, uuid } = item
                    const outcomeText: string = getOutcomeText(away, home, betoutcome, null)
                    const className: string = (betodds === '1') ? 'insured' : 'selected'
                    
                    return (
                        <div
                            className={className}
                            key={uuid}
                        >
                            <span>{outcomeText}</span>
                            <span>{`(${capitalize(className)})`}</span>
                        </div>
                    )
                })}
            </div>
        )
    }

    const matrix: MatrixValue[][] = createBetslipSubitemsMatrix(subitems,sportId)
    return (
        <div className="bet-betslip-subitems">
            <div className="matrix-wrapper">
                {matrix.map((item1, i) =>
                    <div
                        key={i}
                        className="matrix-row"
                    >
                        {item1.map((item2, j) => {
                            const className: string = (item2 === MatrixValue.Selected) ? 'selected' : (item2 === MatrixValue.Insured) ? 'insured' : '' 
                            return (
                                <div
                                    key={j}
                                    className={className}
                                >
                                    {`${i}:${j}`}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default BetslipSubitems