import React from 'react'
import clsx from 'clsx'
import { MatrixValue, BetTypeId } from '../../../state/enums'
import { getGoalsText } from '../../../strings'
import { betrixSoccerMaxGoals, FootballPitch } from '../../../assets'
import './style.scss'

interface Props {
    data: any[]
    dataWithBetType?: boolean
}

function BetrixScoreMatrix({ data, dataWithBetType }: Props) {
    const matrix = createMatrix(data, betrixSoccerMaxGoals, betrixSoccerMaxGoals, dataWithBetType)
    
    return (
        <div className="BetrixScoreMatrix">
            <img src={FootballPitch}/>
            {matrix.map((item1, i) =>
                <div
                    key={i}
                    className="matrix-row"
                >
                    {item1.map((item2, j) => (
                        <div
                            key={j}
                            className={clsx(item2 === MatrixValue.Selected && 'selected', item2 === MatrixValue.Insured && 'insured')}
                        >
                            {`${i}:${j}`}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default BetrixScoreMatrix

const createMatrix = (data: any[], homeGoals: number, awayGoals: number, dataWithBetType?: boolean) => {
    const betslipSubitemsMatrix: MatrixValue[][] = []

    for (let i = 0; i <= homeGoals; i++) {
        betslipSubitemsMatrix[i] = new Array(awayGoals + 1).fill(MatrixValue.NotSelected)
        for (let j = 0; j <= awayGoals; j++) {
            const homeGoalsText = getGoalsText(i)
            const awayGoalsText = getGoalsText(j)
            const goalsText = `${homeGoalsText}${awayGoalsText}`
            const betType = BetTypeId[goalsText]
            const goalsItemFound = (dataWithBetType) ? data.find(d => d.bettype === betType) : data.find(d => d.homeGoals === i && d.awayGoals === j)

            if (goalsItemFound) {
                if ((goalsItemFound.betodds && goalsItemFound.betodds === '1') || goalsItemFound.insured || goalsItemFound.insurance) {
                    betslipSubitemsMatrix[i][j] = MatrixValue.Insured
                } else {
                    betslipSubitemsMatrix[i][j] = MatrixValue.Selected
                }
            }
        }
    }

    return betslipSubitemsMatrix
}