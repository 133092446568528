import React, { useState } from 'react'
import BetslipItems from './BetslipItems'
import dataServer from '../../../network/rest'
import FaIcon from '../FaIcon'
import Table from '../Table'
import { BetslipHistoryStandard, BetslipHistoryItem } from '../../../state/stores/betslip/models'
import { getDateString, getDateFormat, getTimeFormat, capitalize } from '../../../utils'
import { store } from '../../../state'
import config from '../../../configjson'
import './style.scss'

const headerItems: string[] = ['#', 'Date', 'Stake', 'Status', '']

interface Props {
    cashout: string
    historyItems: BetslipHistoryItem[]
    historySubitems: BetslipHistoryItem[]
    historyStandard: BetslipHistoryStandard[]
}

const BetslipHistoryTable = ({ cashout, historyItems, historySubitems, historyStandard }: Props) => {
    const [betslipUuid, setBetslipUuid] = useState('')
    
    function handleBetslipUuid(newUuid: string) {
        if (betslipUuid === newUuid) {
            setBetslipUuid('')
        } else {
            store.update(main => {
                main.betslip.history.items = []
                return main
            })
            setBetslipUuid(newUuid)
            makeRequest(newUuid)
        }
    }

    return (
        <div className="betslip-history-table">
            <Table
                data={historyStandard}
                headerItems={headerItems}
                noDataMessage={getNoDataMessage()}
                proportions={['30%', '20%', '20%', '15%', '15%']}
                rowMapper={(item: BetslipHistoryStandard, index: number, proportions: string[]) => {
                    const { head_odds, head_payout, head_stake, insertedat, issettled, tot_stake, uuid } = item
                    const status: string = (issettled) ? 'finished' : 'active'
                
                    const insertedDate: Date = new Date(insertedat)
                    const dateFormat: string = getDateFormat(insertedDate)
                    const date: string = getDateString(dateFormat)
                    const time: string = getTimeFormat(insertedDate)
                    const stake: string = tot_stake.replace('$', '€')
                    const showBetslipItems: boolean = betslipUuid === uuid
                
                    return (
                        <React.Fragment key={index}>
                            <div className="table-row">
                                <div style={{ width: proportions[0] }}>{uuid}</div>
                                <div style={{ width: proportions[1] }}>{`${date}, ${time}`}</div>
                                <div style={{ width: proportions[2] }}>{stake}</div>
                                <div style={{ width: proportions[3] }}>
                                    <FaIcon
                                        className={`status ${status}`}
                                        icon="circle"
                                        title={capitalize(status)}
                                        type="solid"
                                    />
                                </div>
                                <div style={{ width: proportions[4] }}>
                                    <FaIcon
                                        className="search"
                                        onClick={() => handleBetslipUuid(uuid)}
                                        icon="search-plus"
                                        title="Show more"
                                        type="solid"
                                    />
                                </div>
                            </div>
                            {showBetslipItems &&
                                <BetslipItems
                                    cashout={cashout}
                                    comboOdds={head_odds}
                                    comboPayout={head_payout}
                                    comboStake={head_stake}
                                    isSettled={issettled}
                                    items={historyItems}
                                    subitems={historySubitems}
                                    setBetslipUuid={handleBetslipUuid}
                                />
                            }
                        </React.Fragment>
                    )
                }}
            />
        </div>
    )
}

export default BetslipHistoryTable

const getNoDataMessage = (): JSX.Element => {
    return (
        <span className="no-history-message">There are no betslips for selected date period.</span>
    )
}

const makeRequest = (betslip_uuid: string) => {
    dataServer.request('get', 'get-betslip-items', {
        tenant: config.tenant_id,
        betslip_uuid
    })
}