import React, { useState } from 'react'
import dataServer from '../../../../network/rest'
import FaIcon from '../../FaIcon'
import { roundOddsValue, openPopup } from '../../../../utils'
import config from '../../../../configjson'
const minValue: number = 1
const maxValue: number = 100

interface Props {
    betslipUuid: string
    betslipItemUuid: string
    cashout: string
    cashoutPercentage: string
    stake: string
    setBetslipUuid: (newUuid: string) => void
    setCashoutPercentage: (percentage: string) => void
}

const Cashout = ({ betslipUuid, betslipItemUuid, cashout, cashoutPercentage, setBetslipUuid, setCashoutPercentage, stake }: Props) => {
    const [customCashout, setCustomCashout] = useState('')

    const customCashoutEntered: boolean = customCashout !== ''
    const percentageToShow: string = (customCashoutEntered) ? roundOddsValue(((+customCashout / +cashout) * 100).toString(), 2) : cashoutPercentage
    const cashoutValue: string = (customCashoutEntered) ? customCashout : (+cashout * (+cashoutPercentage / 100)).toString()
    const cashoutToShow: string = roundOddsValue(cashoutValue, 2)
    const disabledClassName: string = (customCashoutEntered) ? 'disabled' : ''

    function handleCustomCashout(value: string) {
        const newCashout: string = (+value > +cashout) ? cashout : value
        setCustomCashout(newCashout)
    }

    function insertCashout() {
        const stakeValue: number = (+percentageToShow / 100) * +stake
        const stakeToInsert: string = roundOddsValue(stakeValue.toString(), 2)
        setBetslipUuid('')
        openPopup()
        
        dataServer.request('post', 'insert-cashout', {}, {
            account_id: 1,
            tenant: config.tenant_id,
            id: betslipUuid,
            item: betslipItemUuid,
            stake: +cashoutToShow,
            stakeToCashout: +stakeToInsert
        })
    }    

    return (
        <div className="cashout-wrapper">
            <input
                className={`input-range ${disabledClassName}`}
                type="range"
                min={minValue}
                max={maxValue}
                value={cashoutPercentage}
                onChange={(event) => setCashoutPercentage(event.target.value)}
            />
            <div className={`cashout-labels ${disabledClassName}`}>
                <span>{`${minValue}%`}</span>
                <span>{`${maxValue}%`}</span>
            </div>
            <div className="custom-cashout">
                <span>Custom:</span>
                <span>€</span>
                <div>
                    <input
                        type="number"
                        value={customCashout}
                        onChange={(event) => handleCustomCashout(event.target.value)}
                    />
                </div>
            </div>
            <div className="cashout-value">
                <FaIcon
                    icon="coins"
                    type="solid"
                />
                <span>{`€${cashoutToShow}`}</span>
                <span>{`(${percentageToShow}%)`}</span>
            </div>
            <button
                className="cashout-button"
                onClick={insertCashout}
            >
                CASHOUT
            </button>
        </div>
    )
}

export default Cashout