import React from 'react'
import Base from '../../../views/components/Base'
import RegionsList from '../../components/RegionsList'
import { Regions, Match, QuickLeague } from '../../../state/stores/feed/models'
import { RouteChildrenProps } from 'react-router'
import { store } from '../../../state'

interface Props extends RouteChildrenProps {
    matches: Match[]
    quickLeagues: QuickLeague[]
    regions: Regions
}

class SportMain extends React.Component<Props, {}> {
    render() {
        const { matches, quickLeagues, regions } = this.props

        return (
            <Base>
                <div className="sport-main">
                    <RegionsList
                        matches={matches}
                        quickLeagues={quickLeagues}
                        regions={regions}
                    />
                </div>
            </Base>
        )
    }
}

export default store.subscribe(main => ({
    matches: main.feed.matches,
    quickLeagues: main.feed.quickLeagues,
    regions: main.feed.regions
}))(SportMain)