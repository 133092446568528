import React from 'react';
import BetrixComboMultiSystems from './MultiSystems';
import BetrixComboSystemSingle from './SystemSingle';
import { BSStandardItem, BetslipBetrixCombo, BetslipSubmode, BSBetrixItem } from '../../../../state/stores/betslip/models';
import { BetrixCombo } from '../../../../state/stores/betrix-combo/models';
import './style.scss';

interface Props {
    betrixCombo: BetrixCombo
    betrixItems: Array<BSBetrixItem>
    betslip: BetslipBetrixCombo
    standardItems: Array<BSStandardItem>
    submode: BetslipSubmode
}

const BetrixComboMode = ({ betrixCombo, betrixItems, betslip, standardItems, submode }: Props) => {
    const { MSPayout, multiSystems, SSBetweenOption, SSMarket, SSRange, systemSingle } = betslip

    return (
        <div className="BetrixComboMode">
            {(submode === BetslipSubmode.MultiSystems) ?
                <BetrixComboMultiSystems
                    betrixItems={betrixItems}
                    betslipItems={multiSystems}
                    data={betslip.MSData}
                    payout={MSPayout}
                    settings={betrixCombo.settings.betslip}
                    standardItems={standardItems}
                />
            :
                <BetrixComboSystemSingle
                    betrixItems={betrixItems}
                    betslipItems={systemSingle}
                    betweenOption={SSBetweenOption}
                    data={betslip.SSData}
                    market={SSMarket}
                    range={SSRange}
                    standardItems={standardItems}
                />
            }
        </div>
    )
}

export default BetrixComboMode