import * as React from 'react';
import { EventEmitter } from 'events';
import { Main } from './models';

export default class Store {
    private values
    private context
    private emitter    
    constructor(values) {
        this.values = values
        this.context = React.createContext(values)
        this.emitter = new EventEmitter();
    }
    provide = () => {
        const store = this;
        return class extends React.Component {
            constructor(props) {
                super(props);
                this.state = store.values;

                store.emitter.on('event', (newState) => {
                    // console.log('newState in emitter', newState)
                    store.values = {
                        ...this.state,
                        ...newState
                    }
                    this.setState({
                        ...this.state,
                        ...newState
                    })
                })
            }
            render() {
                return (
                    <store.context.Provider value={this.state}>
                        {this.props.children}
                    </store.context.Provider>
                )
            }
        }
    }
    subscribe = (mapper: (state: Main) => any) => (Component: any) => (props: any): any => {
        return (
            <this.context.Consumer>
                {values => {
                    const mappedValues = mapper(values)
                    return <Component {...props} {...mappedValues}>{props.children}</Component>
                }}
            </this.context.Consumer>
        )
    }
    update = (mapper: (state: Main) => any) => {
        // const newState = mapper(clone(this.values));
        const newState = mapper(this.values);
        // TODO: write checks for newState

        this.emitter.emit('event', newState)

    }
    get = (mapper: (state: Main) => any) => {
        return mapper(this.values)
    }
}

// move somewhere else
export function clone(obj: object) {
    if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj || React.isValidElement(obj)) {
        return obj;
    }
    const temp = obj.constructor();
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            obj['isActiveClone'] = null;
            temp[key] = clone(obj[key]);
            delete obj['isActiveClone'];
        }
    }
    return temp;
}