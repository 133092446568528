import React from 'react'
import MarketBoxWithValue from './WithValue'
import MarketBoxWithValueMM from './WithValue/market-maker'
import NormalMarketBox from './Normal'
import NormalMarketBoxMM from './Normal/market-maker'
import { checkMarketWithValue } from '../../../utils'
import { MarketMaker } from '../../../state/stores/marketmaker/models'
import { Odds, MarketOdds } from '../../../state/stores/feed/models'
import { SportId, BetTypeGroupId, OutcomeId, BetTypeId, ScopeId } from '../../../state/enums'
import { wideMarkets, marketMakerMarkets } from '../../../assets'
import './style.scss'

interface Props {
    hideBetAgainst: boolean
    awayTeam: string
    homeTeam: string
    markets: Array<string>
    marketMaker: MarketMaker
    odds: Odds
    odds1: Odds
    odds2: Odds
    odds3: Odds
    sportId: SportId
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    scopeId: ScopeId
    betAgainst: () => void
    token: string
}

const MarketBoxes = ({ hideBetAgainst, token, betAgainst, odds1, odds2, odds3, scopeId, minOdds, maxOdds, minCSOdds, maxCSOdds, marginParameter, margin, awayTeam, homeTeam, markets, marketMaker, odds, sportId }: Props) => (
    <div className="markets-wrapper">
        {markets.map(market => {
            const marketName = BetTypeGroupId[market]

            if (market === BetTypeGroupId[BetTypeGroupId.TeamOverUnder]) {
                const splittedOdds: MarketOdds[][] = splitTeamOverUnderOdds(odds.markets[market])
                return (
                    splittedOdds.map((item, i) =>
                        <MarketBoxWithValue
                            token = {token}
                            marketGroupData={undefined}
                            key={i}
                            Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                                odds && odds.markets1 && odds.markets1[marketName] ? odds.markets1[marketName].length : 0,
                                odds && odds.markets2 && odds.markets2[marketName] ? odds.markets2[marketName].length : 0,
                                odds && odds.markets3 && odds.markets3[marketName] ? odds.markets3[marketName].length : 0
                            ]}
                            awayTeam={awayTeam}
                            homeTeam={homeTeam}
                            market={marketName}
                            marketMaker={marketMaker}
                            odds={[]}
                            odds1={[]}
                            odds2={[]}
                            odds3={[]}
                            sportId={sportId}
                            margin={margin}
                            marginParameter={marginParameter}
                            minOdds={minOdds}
                            maxOdds={maxOdds}
                            minCSOdds={minCSOdds}
                            maxCSOdds={maxCSOdds}
                            bettypegroupid={BetTypeGroupId[market]}
                        />
                    )
                )
            }
            
            const isMarketWithValue: boolean = checkMarketWithValue(market)
            const hasMarketMaker: boolean = marketMakerMarkets.includes(BetTypeGroupId[market])

            if (isMarketWithValue) {
                if (market === BetTypeGroupId[BetTypeGroupId.AsianHandicap] || market === BetTypeGroupId[BetTypeGroupId.OverUnder]) {
                    return (
                        <React.Fragment key={market}>
                            <MarketBoxWithValue
                                token = {token}
                                marketGroupData={undefined}
                                Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                                    odds && odds.markets1 && odds.markets1[marketName] ? odds.markets1[marketName].length : 0,
                                    odds && odds.markets2 && odds.markets2[marketName] ? odds.markets2[marketName].length : 0,
                                    odds && odds.markets3 && odds.markets3[marketName] ? odds.markets3[marketName].length : 0
                                ]}
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                market={marketName}
                                marketMaker={marketMaker}
                                odds={[]}
                                odds1={[]}
                                odds2={[]}
                                odds3={[]}
                                sportId={sportId}
                                margin={margin}
                                marginParameter={marginParameter}
                                minOdds={minOdds}
                                maxOdds={maxOdds}
                                minCSOdds={minCSOdds}
                                maxCSOdds={maxCSOdds}
                                bettypegroupid={BetTypeGroupId[market]}
                            />
                            <MarketBoxWithValue
                                token = {token}
                                marketGroupData={undefined}
                                Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                                    odds && odds.markets1 && odds.markets1[marketName] ? odds.markets1[marketName].length : 0,
                                    odds && odds.markets2 && odds.markets2[marketName] ? odds.markets2[marketName].length : 0,
                                    odds && odds.markets3 && odds.markets3[marketName] ? odds.markets3[marketName].length : 0
                                ]}
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                market={marketName}
                                marketMaker={marketMaker}
                                odds={[]}
                                odds1={[]}
                                odds2={[]}
                                odds3={[]}
                                sportId={sportId}
                                margin={margin}
                                marginParameter={marginParameter}
                                minOdds={minOdds}
                                maxOdds={maxOdds}
                                minCSOdds={minCSOdds}
                                maxCSOdds={maxCSOdds}
                                bettypegroupid={BetTypeGroupId[market]}
                        />
                        </React.Fragment>
                    )
                }
                
                if (hasMarketMaker) {
                    return (
                        <MarketBoxWithValueMM
                            hideBetAgainst = {hideBetAgainst}
                            token = {token}
                            betAgainst={betAgainst}
                            marketGroupData={undefined}
                            Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                                odds && odds.markets1 && odds.markets1[marketName] ? odds.markets1[marketName].length : 0,
                                odds && odds.markets2 && odds.markets2[marketName] ? odds.markets2[marketName].length : 0,
                                odds && odds.markets3 && odds.markets3[marketName] ? odds.markets3[marketName].length : 0
                            ]}
                            margin = {margin}
                            marginParameter={marginParameter}
                            minOdds={minOdds}
                            maxOdds={maxOdds}
                            minCSOdds={minCSOdds}
                            maxCSOdds={maxCSOdds}
                            key={market}
                            awayTeam={awayTeam}
                            homeTeam={homeTeam}
                            market={market}
                            marketMaker={marketMaker}
                            odds={odds.markets[market]}
                            odds1={odds.markets1[marketName]}
                            odds2={odds.markets2[marketName]}
                            odds3={odds.markets3[marketName]}
                            sportId={sportId}
                        />
                    )
                }

                return (
                    <MarketBoxWithValue
                        token = {token}
                        marketGroupData={undefined}
                        Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                            odds && odds.markets1 && odds.markets1[marketName] ? odds.markets1[marketName].length : 0,
                            odds && odds.markets2 && odds.markets2[marketName] ? odds.markets2[marketName].length : 0,
                            odds && odds.markets3 && odds.markets3[marketName] ? odds.markets3[marketName].length : 0
                        ]}
                        awayTeam={awayTeam}
                        homeTeam={homeTeam}
                        market={marketName}
                        marketMaker={marketMaker}
                        odds={[]}
                        odds1={[]}
                        odds2={[]}
                        odds3={[]}
                        sportId={sportId}
                        margin={margin}
                        marginParameter={marginParameter}
                        minOdds={minOdds}
                        maxOdds={maxOdds}
                        minCSOdds={minCSOdds}
                        maxCSOdds={maxCSOdds}
                        bettypegroupid={BetTypeGroupId[market]}
                    />
                )
            }
            
            const isWide = wideMarkets.includes(BetTypeGroupId[market])
            if (market === BetTypeGroupId[BetTypeGroupId.TeamNumberOfGoals] ||
                market === BetTypeGroupId[BetTypeGroupId.TeamExactNumberOfGoals] ||
                market === BetTypeGroupId[BetTypeGroupId.TeamHighestScoringHalf] ||
                market === BetTypeGroupId[BetTypeGroupId.TeamHighestScoringHalf2]) {
                const splittedOdds: MarketOdds[][] =
                    scopeId === ScopeId.FT ? splitTeamOverUnderOdds(odds.markets[market]) :
                    scopeId === ScopeId.P1 || scopeId === ScopeId.H1 ? splitTeamOverUnderOdds(odds.markets1[market]) :
                    scopeId === ScopeId.P2 || scopeId === ScopeId.H2 ? splitTeamOverUnderOdds(odds.markets2[market]) :
                    scopeId === ScopeId.P3 ? splitTeamOverUnderOdds(odds.markets3[market]) : []

                if (hasMarketMaker) {
                    return (
                        splittedOdds.map((item, i) =>
                            <NormalMarketBoxMM
                                hideBetAgainst = {hideBetAgainst}
                                token = {token}
                                betAgainst={betAgainst}
                                marketGroupData0={undefined}
                                marketGroupData1={undefined}
                                marketGroupData2={undefined}
                                marketGroupData3={undefined}
                                Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                                    odds && odds.markets1 && odds.markets1[marketName] ? odds.markets1[marketName].length : 0,
                                    odds && odds.markets2 && odds.markets2[marketName] ? odds.markets2[marketName].length : 0,
                                    odds && odds.markets3 && odds.markets3[marketName] ? odds.markets3[marketName].length : 0
                                ]}
                                marginParameter={marginParameter}
                                margin={margin}
                                minOdds={minOdds}
                                maxOdds={maxOdds}
                                minCSOdds={minCSOdds}
                                maxCSOdds={maxCSOdds}
                                key={i}
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                isWide={isWide}
                                market={market}
                                marketMaker={marketMaker}
                                odds={item}
                                odds1={[]}
                                odds2={[]}
                                odds3={[]}
                                sportId={sportId}
                            />
                        )
                    )
                }

                return (
                    splittedOdds.map((item, i) =>
                        <NormalMarketBox
                            token = {token}
                            marketGroupData={undefined}
                            margin = {0}
                            marginParameter = {0}
                            minOdds={0}
                            maxOdds={0}
                            minCSOdds={0}
                            maxCSOdds={0}
                            key={i}
                            awayTeam={awayTeam}
                            homeTeam={homeTeam}
                            isWide={isWide}
                            market={market}
                            marketMaker={marketMaker}
                            odds={item}
                            sportId={sportId}
                            scopeId={scopeId}
                        />
                    )
                )
            }
            
            if (hasMarketMaker) {
                return (
                    <NormalMarketBoxMM
                        hideBetAgainst = {hideBetAgainst}
                        token = {token}
                        betAgainst={betAgainst}
                        marketGroupData0={undefined}
                        marketGroupData1={undefined}
                        marketGroupData2={undefined}
                        marketGroupData3={undefined}
                        Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                            odds && odds.markets1 && odds.markets1[marketName] ? odds.markets1[marketName].length : 0,
                            odds && odds.markets2 && odds.markets2[marketName] ? odds.markets2[marketName].length : 0,
                            odds && odds.markets3 && odds.markets3[marketName] ? odds.markets3[marketName].length : 0
                        ]}
                        margin={margin}
                        marginParameter={marginParameter}
                        minOdds={minOdds}
                        maxOdds={maxOdds}
                        minCSOdds={minCSOdds}
                        maxCSOdds={maxCSOdds}
                        key={market}
                        awayTeam={awayTeam}
                        homeTeam={homeTeam}
                        isWide={isWide}
                        market={market}
                        marketMaker={marketMaker}
                        odds={odds.markets[market]}
                        odds1={[]}
                        odds2={[]}
                        odds3={[]}
                        sportId={sportId}
                    />
                )
            }
            
            return (
                <NormalMarketBox
                    token = {token}
                    marketGroupData={undefined}
                    margin = {0}
                    marginParameter = {0}
                    minOdds={0}
                    maxOdds={0}
                    minCSOdds={0}
                    maxCSOdds={0}
                    key={market}
                    awayTeam={awayTeam}
                    homeTeam={homeTeam}
                    isWide={isWide}
                    market={market}
                    marketMaker={marketMaker}
                    odds={odds.markets[market]}
                    sportId={sportId}
                    scopeId={scopeId}
                />
            )
        })}
    </div>
)

export default MarketBoxes

const splitTeamOverUnderOdds = (odds: MarketOdds[]): MarketOdds[][] => {
    const homeOdds: MarketOdds[] = []
    const awayOdds: MarketOdds[] = []

    for (const item of odds) {
        if (item.outcomeid === OutcomeId.OverHome ||
            item.outcomeid === OutcomeId.UnderHome ||
            item.bettypeid === BetTypeId.TNGHome ||
            item.bettypeid === BetTypeId.TENGHome ||
            item.bettypeid === BetTypeId.THSHHome ||
            item.bettypeid === BetTypeId.THSH2Home) {
            homeOdds.push(item)
        } else {
            awayOdds.push(item)
        }
    }

    return [homeOdds, awayOdds]
}