import React, { useState } from 'react'
import dataServer from '../../../network/rest'
import ReactDatePicker from '../DatePicker'
import { getDateFormat } from '../../../utils'
import config from '../../../configjson'
import './style.scss'

const BetslipHistoryRange = () => {
    const dateFrom = handleDatePicker()
    const dateTo = handleDatePicker()
    const buttonDisabled: boolean = (!dateFrom.value || !dateTo.value)
    
    return (
        <div className="betslip-history-range">
            <ReactDatePicker {...dateFrom}/>
            <ReactDatePicker {...dateTo}/>
            <button
                disabled={buttonDisabled}
                onClick={() => makeRequest(dateFrom.value, dateTo.value)}
            >
                GET
            </button>
        </div>
    )
}

export default BetslipHistoryRange

const handleDatePicker = (): {value: Date, onChange: (value: Date) => void} => {
    const [value, onChange] = useState(new Date())

    return {
        value,
        onChange
    }
}

const makeRequest = (dateFrom: Date | undefined, dateTo: Date | undefined) => {
    if (dateFrom && dateTo) {
        const date_from: string = getDateFormat(dateFrom)
        const date_to: string = getDateFormat(dateTo)

        dataServer.request('get', 'get-betslips', {
            tenant: config.tenant_id,
            account_id: 1,
            date_from,
            date_to
        })
    }
}