import React from 'react'
import FaIcon from '../../FaIcon'
import clsx from 'clsx'
import { setPopupContent, isSportWinner, roundOddsValue } from '../../../../utils'
import './style.scss'
import { ScopeId, ScopeName, SportId } from '../../../../state/enums'
import MarketScope from '../../MarketScope'
import BetrixInfoPopup from '../../Popup/BetrixInfo'

interface Props {
    intersectionOdds2: string
    showBetSuggestions: boolean
    suggestedMarketsOpened: boolean
    handleSuggestedMarketsOpened: (suggestedMarketsOpened: boolean) => void
    scopeId: ScopeId
    scopes: ScopeId[]
    handleChangeScope: (scopeId: ScopeId) => void
    Odds: number[]
    sportId: SportId
    betAgainst: () => void
    hideBetAgainst: boolean
}

export function getScopeName(scopeId: ScopeId): string {
    return ScopeName[scopeId]
}

function BetrixTopRow({ hideBetAgainst, betAgainst, intersectionOdds2, sportId, Odds, scopes, scopeId,  showBetSuggestions, suggestedMarketsOpened, handleSuggestedMarketsOpened, handleChangeScope }: Props) {    
    return (
        <div className={clsx('BetrixTopRow', showBetSuggestions && 'suggestions-shown')}>
            <h3 className="market-name">
                {isSportWinner(sportId) ? 'Winner' : 'Correct Score'}
                <MarketScope
                    Odds={Odds}
                    activeScope={scopeId}
                    scopes={scopes}
                    handleChangeScope={handleChangeScope}
                />
            </h3>
            <FaIcon
                icon="info-circle"
                type="solid"
                onClick={() => setPopupContent(<BetrixInfoPopup/>)} // betrixInfoContent
            />
            {/*showBetSuggestions &&
                <button
                    className={suggestedMarketsOpened ? 'hide-suggested-markets' : 'show-suggested-markets'}
                    onClick={() => handleSuggestedMarketsOpened(!suggestedMarketsOpened)}
                >
                    {suggestedMarketsOpened ?
                        <span>Hide bet suggestions</span>
                        :
                        <span>Show bet suggestions</span>
                    }
                </button>
            */}
            {/*(intersectionOdds) ?
                <div className="multi-selection-label">
                    <span>Your Multi selection:</span>
                    <span>{intersectionOdds}</span>
                </div>
            : null*/}
            {(intersectionOdds2 && parseFloat(intersectionOdds2) !== 1) && !hideBetAgainst ?
                <button onClick = {() => betAgainst()} className="invert-button">
                    <span>Bet against: </span>
                    <span>{roundOddsValue(intersectionOdds2, 3)}</span>
                </button>
            : null}
        </div>
    )
}

export default BetrixTopRow