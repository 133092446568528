import * as Assets from './assets';
import dataServer from './network/rest';
import { MarketGroupItemElement, Settings } from './state/stores/settings/models'
import { BSStandardItem, BSBetrixItem, BetslipHistoryItem, Betslip, BSMultiSystemsItem, BetslipMode, BetslipSubmode, BSSystemSingleItem, SSMarketOptions, BSBetrixSubitem } from './state/stores/betslip/models';
import { CorrectScoreOdds, CorrectScoreOddsResult, BetrixSelectionOdds, Doublette} from './state/stores/betrix/models';
import { OutcomeId, SportId, BetTypeGroupId, BetTypeId, MatrixValue, BETTYPE_CORRECT_SCORE, ScopeId, BETTYPE_ANY_OTHER_HOME, BETTYPE_ANY_OTHER_AWAY/*, _BETTYPE_ANY_OTHER_DRAW, _BETTYPE_ANY_OTHER_HOME, _BETTYPE_ANY_OTHER_AWAY*/ } from './state/enums';
import { Regions, League, Odds, MarketOdds, Match, MatchesByDate, MatchMarket, MainMarket, HeaderMatch, HeaderMatchData, QuickLeague } from './state/stores/feed/models';
import { MarketMaker, MarketMakerData, MarketMakerSelection } from './state/stores/marketmaker/models';
import { store } from './state';
import { Platform } from './state/singles';
import { QuickButton, AdditionalButton } from './views/components/Betrix';
import { MultiSystemsInputDataOdds, MultiSystemsInput, SystemSingleBetId, SystemSingleInput, SystemSingleType } from './network/rest/models';
import { MultiSystemsData, BetrixComboSettingsItem } from './state/stores/betrix-combo/models';
const {c} = require('./assets/c.json')

export function getOutcomeTextBetslip(awayTeam: string, homeTeam: string, outcomeId: OutcomeId, outcomeValue: string | null, additionalData?: any): string {
    switch (outcomeId) {
        case OutcomeId.TFOTROverAway:
            return `Over ${outcomeValue}`
        case OutcomeId.TTRMOverAway:
            return `Over ${outcomeValue}`
        case OutcomeId.TFOTRUnderAway:
            return `Under ${outcomeValue}`
        case OutcomeId.TTRMUnderAway:
            return `Under ${outcomeValue}`

        case OutcomeId.TFOTROverHome:
            return `Over ${outcomeValue}`
        case OutcomeId.TTRMOverHome:
            return `Over ${outcomeValue}`
        case OutcomeId.TFOTRUnderHome:
            return `Under ${outcomeValue}`
        case OutcomeId.TTRMUnderHome:
            return `Under ${outcomeValue}`

        case OutcomeId.HomeWin:
        case OutcomeId.HAHome:
        case OutcomeId.DNBHome:
        case OutcomeId.FLTSHome:
        case OutcomeId.FTGHome:
        case OutcomeId.HTOrFTHome:
        case OutcomeId.FHOrSHHome:
            return (isMobile()) ? 'Home' : homeTeam
        case OutcomeId.Draw:
        case OutcomeId.HTOrFTDraw:
        case OutcomeId.FHOrSHDraw:
            return 'Draw'
        case OutcomeId.AwayWin:
        case OutcomeId.HAAWay:
        case OutcomeId.DNBAway:
        case OutcomeId.FLTSAway:
        case OutcomeId.FTGAway:
        case OutcomeId.HTOrFTAway:
        case OutcomeId.FHOrSHAway:
            return (isMobile()) ? 'Away' : awayTeam
        case OutcomeId.BTTSYes:
            return 'Yes'
        case OutcomeId.BTTSNo:
            return 'No'
        case OutcomeId.Over:
        case OutcomeId.OverHome:
        case OutcomeId.OverAway:
            return `Over ${outcomeValue}`
        case OutcomeId.Under:
        case OutcomeId.UnderHome:
        case OutcomeId.UnderAway:
            return `Under ${outcomeValue}`
        case OutcomeId.HomeOrDraw:
            return (isMobile()) ? '1X' : 'Home or Draw'
        case OutcomeId.HomeOrAway:
            return (isMobile()) ? '12' : 'Home or Away'
        case OutcomeId.DrawOrAway:
            return (isMobile()) ? 'X2' : 'Draw or Away'
        case OutcomeId.AHHome:
        case OutcomeId.EHHome:
            return (isMobile()) ? `Home (${outcomeValue})` : `${homeTeam} ${outcomeValue}`
        case OutcomeId.AHAway:
        case OutcomeId.EHAway:
            return (isMobile()) ? `Away (${outcomeValue})` : `${awayTeam} ${outcomeValue}`
        case OutcomeId.EHDraw:
            return (isMobile()) ? `Draw (${outcomeValue})` : `Draw (${homeTeam} ${outcomeValue})`
        case OutcomeId.Odd:
            return 'Odd'
        case OutcomeId.Even:
            return 'Even'
        case OutcomeId.BetrixYes:
            if (additionalData && additionalData.showMarketMaker) {
                return 'Selected'
            }
            return 'Multi Selection'
        case OutcomeId.BetrixNo:
            return 'Not Selected'
        case OutcomeId.BetrixOut:
            return 'Any Other Result'
        case OutcomeId.BetrixYesNo:
            return 'Selected and Not Selected'
        case OutcomeId.BetrixYesOut:
            return 'Selected and Any Other Result'
        case OutcomeId.BetrixNoOut:
            return 'Not Selected and Any Other Result'
        case OutcomeId.BetrixYesNO:
            return 'Selected and (Not Selected)'
        case OutcomeId.BetrixYesOUT:
            return 'Selected and (Any Other Result)'
        case OutcomeId.BetrixNoYES:
            return 'Not Selected and (Selected)'
        case OutcomeId.BetrixNoOUT:
            return 'Not Selected and (Any Other Result)'
        case OutcomeId.BetrixOutYES:
            return 'Any Other Result and (Selected)'
        case OutcomeId.BetrixOutNO:
            return 'Any Other Result and (Not Selected)'
        case OutcomeId.WM13SevenPlus:
            return (isMobile()) ? `Home 7+` : `${homeTeam} 7+`
        case OutcomeId.WM11SixPlus:
            return (isMobile()) ? `Home 6+` : `${homeTeam} 6+`
        case OutcomeId.WM9FivePlus:
            return (isMobile()) ? `Home 5+` : `${homeTeam} 5+`
        case OutcomeId.WM13Six:
            return (isMobile()) ? `Home 6` : `${homeTeam} 6`
        case OutcomeId.WM11Five:
        case OutcomeId.WM13Five:
            return (isMobile()) ? `Home 5` : `${homeTeam} 5`
        case OutcomeId.WM7FourPlus:
            return (isMobile()) ? 'Home 4+' : `${homeTeam} 4+`
        case OutcomeId.WM9Four:
        case OutcomeId.WM11Four:
        case OutcomeId.WM13Four:
            return (isMobile()) ? `Home 4` : `${homeTeam} 4`
        case OutcomeId.WM2ThreePlus:
            return (isMobile()) ? `Home 3+` : `${homeTeam} 3+`
        case OutcomeId.WM7Three:
        case OutcomeId.WM9Three:
        case OutcomeId.WM11Three:
        case OutcomeId.WM13Three:
            return (isMobile()) ? `Home 3` : `${homeTeam} 3`
        case OutcomeId.WM8TwoPlus:
            return (isMobile()) ? `Home 2+` : `${homeTeam} 2+`
        case OutcomeId.WM2Two:
        case OutcomeId.WM7Two:
        case OutcomeId.WM9Two:
        case OutcomeId.WM11Two:
        case OutcomeId.WM13Two:
            return (isMobile()) ? `Home 2` : `${homeTeam} 2`
        case OutcomeId.WM10OnePlus:
        case OutcomeId.WM13OnePlus:
            return (isMobile()) ? `Home 1+` : `${homeTeam} 1+`
        case OutcomeId.WM2One:
        case OutcomeId.WM7One:
        case OutcomeId.WM8One:
        case OutcomeId.WM9One:
        case OutcomeId.WM11One:
            return (isMobile()) ? `Home 1` : `${homeTeam} 1`
        case OutcomeId.WM2Zero:
        case OutcomeId.WM7Zero:
        case OutcomeId.WM8Zero:
        case OutcomeId.WM9Zero:
        case OutcomeId.WM10Zero:
            return 'Draw (0)'
        case OutcomeId.WM11ZeroMinus:
            return (isMobile()) ? 'Draw or Away' : `Draw (0) or ${awayTeam}`
        case OutcomeId.WM12ZeroPlus:
            return (isMobile()) ? 'Draw or Home' : `Draw (0) or ${homeTeam}`
        case OutcomeId.WM2MinusOne:
        case OutcomeId.WM7MinusOne:
        case OutcomeId.WM8MinusOne:
        case OutcomeId.WM10MinusOne:
        case OutcomeId.WM12MinusOne:
            return (isMobile()) ? 'Away 1' : `${awayTeam} 1`
        case OutcomeId.WM9MinusOnePlus:
        case OutcomeId.WM14MinusOnePlus:
            return (isMobile()) ? 'Away 1+' : `${awayTeam} 1+`
        case OutcomeId.WM2MinusTwo:
        case OutcomeId.WM8MinusTwo:
        case OutcomeId.WM10MinusTwo:
        case OutcomeId.WM12MinusTwo:
        case OutcomeId.WM14MinusTwo:
            return (isMobile()) ? 'Away 2' : `${awayTeam} 2`
        case OutcomeId.WM7MinusTwoPlus:
            return (isMobile()) ? 'Away 2+' : `${awayTeam} 2+`
        case OutcomeId.WM8MinusThree:
        case OutcomeId.WM10MinusThree:
        case OutcomeId.WM12MinusThree:
        case OutcomeId.WM14MinusThree:
            return (isMobile()) ? 'Away 3' : `${awayTeam} 3`
        case OutcomeId.WM2MinusThreePlus:
            return (isMobile()) ? 'Away 3+' : `${awayTeam} 3+`
        case OutcomeId.WM10MinusFour:
        case OutcomeId.WM12MinusFour:
        case OutcomeId.WM14MinusFour:
            return (isMobile()) ? 'Away 4' : `${awayTeam} 4`
        case OutcomeId.WM8MinusFourPlus:
            return (isMobile()) ? 'Away 4+' : `${awayTeam} 4+`
        case OutcomeId.WM12MinusFive:
        case OutcomeId.WM14MinusFive:
            return (isMobile()) ? 'Away 5' : `${awayTeam} 5`
        case OutcomeId.WM10MinusFivePlus:
            return (isMobile()) ? 'Away 5+' : `${awayTeam} 5+`
        case OutcomeId.WM14MinusSix:
            return (isMobile()) ? 'Away 6' : `${awayTeam} 6`
        case OutcomeId.WM12MinusSixPlus:
            return (isMobile()) ? 'Away 6' : `${awayTeam} 6+`
        case OutcomeId.WM14MinusSevenPlus:
            return (isMobile()) ? 'Away 7+' : `${awayTeam} 7`
        case OutcomeId.ENGZero:
        case OutcomeId.TENGZero:
            return 'No goals'
        case OutcomeId.ENG2ZeroOne:
        case OutcomeId.TENG2ZeroOne:
            return '0 - 1 goal'
        case OutcomeId.ENG3ZeroTwo:
        case OutcomeId.TENG3ZeroTwo:
            return '0 - 2 goals'
        case OutcomeId.ENG4ZeroThree:
        case OutcomeId.TENG4ZeroThree:
            return '0 - 3 goals'
        case OutcomeId.ENG5ZeroFour:
        case OutcomeId.TENG5ZeroFour:
            return '0 - 4 goals'
        case OutcomeId.ENG6ZeroFive:
            return '0 - 5 goals'
        case OutcomeId.ENG7ZeroSix:
            return '0 - 6 goals'
        case OutcomeId.ENGOne:
        case OutcomeId.TENGOne:
            return '1 goal'
        case OutcomeId.ENGTwo:
        case OutcomeId.ENG2Two:
        case OutcomeId.TENGTwo:
        case OutcomeId.TENG2Two:
            return '2 goals'
        case OutcomeId.ENGThree:
        case OutcomeId.ENG2Three:
        case OutcomeId.ENG3Three:
        case OutcomeId.TENG2Three:
        case OutcomeId.TENG3Three:
            return '3 goals'
        case OutcomeId.TENGThreePlus:
            return '3+ goals'
        case OutcomeId.ENGFour:
        case OutcomeId.ENG2Four:
        case OutcomeId.ENG3Four:
        case OutcomeId.ENG4Four:
        case OutcomeId.TENG3Four:
        case OutcomeId.TENG4Four:
            return '4 goals'
        case OutcomeId.TENG2FourPlus:
            return '4+ goals'
        case OutcomeId.ENGFive:
        case OutcomeId.ENG2Five:
        case OutcomeId.ENG3Five:
        case OutcomeId.ENG4Five:
        case OutcomeId.ENG5Five:
        case OutcomeId.TENG4Five:
        case OutcomeId.TENG5Five:
            return '5 goals'
        case OutcomeId.TENG3FivePlus:
            return '5+ goals'
        case OutcomeId.ENG2Six:
        case OutcomeId.ENG3Six:
        case OutcomeId.ENG4Six:
        case OutcomeId.ENG5Six:
        case OutcomeId.ENG6Seven:
        case OutcomeId.TENG5Six:
            return '6 goals'
        case OutcomeId.ENGSixPlus:
        case OutcomeId.TENG4SixPlus:
            return '6+ goals'
        case OutcomeId.ENG3Seven:
        case OutcomeId.ENG4Seven:
        case OutcomeId.ENG5Seven:
        case OutcomeId.ENG6Seven:
        case OutcomeId.ENG7Seven:
            return '7 goals'
        case OutcomeId.ENG2SevenPlus:
        case OutcomeId.TENG5SevenPlus:
            return '7+ goals'
        case OutcomeId.ENG4Eight:
        case OutcomeId.ENG5Eight:
        case OutcomeId.ENG6Eight:
        case OutcomeId.ENG7Eight:
            return '8 goals'
        case OutcomeId.ENG3EightPlus:
            return '8+ goals'
        case OutcomeId.ENG5Nine:
        case OutcomeId.ENG6Nine:
        case OutcomeId.ENG7Nine:
            return '9 goals'
        case OutcomeId.ENG4NinePlus:
            return '9+ goals'
        case OutcomeId.ENG6Ten:
        case OutcomeId.ENG7Ten:
            return '10 goals'
        case OutcomeId.ENG5TenPlus:
            return '10+ goals'
        case OutcomeId.ENG7Eleven:
            return '11 goals'
        case OutcomeId.ENG6ElevenPlus:
            return '11+ goals'
        case OutcomeId.ENG7TwelvePlus:
            return '12+ goals'
        case OutcomeId.ZeroOne:
        case OutcomeId.TNGZeroOne:
            return '0 or 1'
        case OutcomeId.TwoThree:
        case OutcomeId.TNGTwoThree:
            return '2 or 3'
        case OutcomeId.FourPlus:
        case OutcomeId.TNGFourPlus:
            return '4+'
        case OutcomeId.ScoreBoth:
        case OutcomeId.FTGBoth:
            return 'Both'
        case OutcomeId.ScoreHome:
            return 'Home only'
        case OutcomeId.ScoreAway:
            return 'Away only'
        case OutcomeId.ScoreNone:
        case OutcomeId.FLTSNone:
        case OutcomeId.TFGNone:
        case OutcomeId.TFG2None:
        case OutcomeId.TFG3None:
        case OutcomeId.TTFGNone:
            return 'None'
        case OutcomeId.HTFTHomeHome:
        case OutcomeId.FHSHHomeHome:
        case OutcomeId.HTFT2HomeHome:
            return (isMobile()) ? '1/1' : `${homeTeam}/${homeTeam}`
        case OutcomeId.HTFTDrawHome:
        case OutcomeId.FHSHDrawHome:
            return (isMobile()) ? 'X/2' : `Draw/${homeTeam}`
        case OutcomeId.HTFTAwayHome:
        case OutcomeId.FHSHAwayHome:
            return (isMobile()) ? '2/1' : `${awayTeam}/${homeTeam}`
        case OutcomeId.HTFTHomeDraw:
        case OutcomeId.FHSHHomeDraw:
            return (isMobile()) ? '1/X' : `${homeTeam}/Draw`
        case OutcomeId.HTFTDrawDraw:
        case OutcomeId.FHSHDrawDraw:
            return (isMobile()) ? 'X/X' : 'Draw/Draw'
        case OutcomeId.HTFTAwayDraw:
        case OutcomeId.FHSHAwayDraw:
            return (isMobile()) ? '2/X' : `${awayTeam}/Draw`
        case OutcomeId.HTFTHomeAway:
        case OutcomeId.FHSHHomeAway:
            return (isMobile()) ? '1/2' : `${homeTeam}/${awayTeam}`
        case OutcomeId.HTFTDrawAway:
        case OutcomeId.FHSHDrawAway:
                return (isMobile()) ? 'X/2' : `Draw/${awayTeam}`
        case OutcomeId.HTFTAwayAway:
        case OutcomeId.FHSHAwayAway:
        case OutcomeId.HTFT3AwayAway:
            return (isMobile()) ? '2/2' : `${awayTeam}/${awayTeam}`
        case OutcomeId.HSHFirst:
        case OutcomeId.THSHFirst:
            return (isMobile()) ? '1st' : 'First'
        case OutcomeId.HSHSecond:
        case OutcomeId.THSHSecond:
            return (isMobile()) ? '2nd' : 'Second'
        case OutcomeId.HSHTie:
        case OutcomeId.THSHTie:
            return 'Tie'
        case OutcomeId.FTGUnder:
            return 'Under 2 goals'
        case OutcomeId.HTFT2DrawOrAwayHome:
            return (isMobile()) ? 'X or 2/1' : `Draw or ${awayTeam}/${homeTeam}`
        case OutcomeId.HTFT2HomeDrawOrAway:
            return (isMobile()) ? '1/X or 2' : `${homeTeam}/Draw or ${awayTeam}`
        case OutcomeId.HTFT2DrawOrAwayDrawOrAway:
            return (isMobile()) ? 'X or 2/X or 2' : `Draw or ${awayTeam}/Draw or ${awayTeam}`
        case OutcomeId.HTFT3HomeOrDrawHomeOrDraw:
            return (isMobile()) ? '1 or X/1 or X' : `${homeTeam} or Draw/${homeTeam} or Draw`
        case OutcomeId.HTFT3AwayHomeOrDraw:
            return (isMobile()) ? '2/1 or X' : `${awayTeam}/${homeTeam} or Draw`
        case OutcomeId.HTFT3HomeOrDrawAway:
            return (isMobile()) ? '1 or X/2' : `${homeTeam} or Draw/${awayTeam}`
        case OutcomeId.BetAgainstAway:
            return (isMobile()) ? 'Against 2' : `Against ${awayTeam}`;
        case OutcomeId.BetAgainstDraw:
            return (isMobile()) ? 'Against X' : `Against Draw`;
        case OutcomeId.BetAgainstHome:
            return (isMobile()) ? 'Against 1' : `Against ${homeTeam}`;
        case OutcomeId.TFG010:
            return '0\' - 10\''
        case OutcomeId.TFG1120:
            return '11\' - 20\''
        case OutcomeId.TFG2130:
            return '21\' - 30\''
        case OutcomeId.TFG3140:
            return '31\' - 40\''
        case OutcomeId.TFG4150:
            return '41\' - 50\''
        case OutcomeId.TFG5160:
            return '51\' - 60\''
        case OutcomeId.TFG6170:
            return '61\' - 70\''
        case OutcomeId.TFG7180:
            return '71\' - 80\''
        case OutcomeId.TFG81Plus:
            return '81\'+'
        case OutcomeId.TFG2015:
            return '0\' - 15\''
        case OutcomeId.TFG21630:
            return '16\' - 30\''
        case OutcomeId.TFG23145:
            return '31\' - 45\''
        case OutcomeId.TFG24660:
            return '46\' - 60\''
        case OutcomeId.TFG26175:
            return '61\' - 75\''
        case OutcomeId.TFG276Plus:
            return '76\'+'
        case OutcomeId.TFG3030:
        case OutcomeId.TTFG030:
            return '0\' - 30\''
        case OutcomeId.TFG33160:
        case OutcomeId.TTFG3160:
            return '31\' - 60\''
        case OutcomeId.TFG361Plus:
        case OutcomeId.TTFG61Plus:
            return '61+\''
        case OutcomeId.THSH2FirstYes:
            return (isMobile()) ? '1st & Yes' : 'First & Yes'
        case OutcomeId.THSH2FirstNo:
            return (isMobile()) ? '1st & No' : 'First & No'
        case OutcomeId.THSH2SecondYes:
            return (isMobile()) ? '2nd & Yes' : '2nd & Yes'
        case OutcomeId.THSH2SecondNo:
            return (isMobile()) ? '2nd & No' : '2nd & No'
        case OutcomeId.THSH2TieYes:
            return 'Tie & Yes'
        case OutcomeId.THSH2TieNo:
            return 'Tie & No'
        default:
            return ''
    }
}

export function getNameFromMarketGroup2(marketName: string, homeTeam: string, awayTeam: string) {
    const marketNameFromGroup = marketName.replace('{home}', homeTeam).replace('{away}', awayTeam)
    return marketNameFromGroup
}

export function isNormalHandicapValue(value: string): boolean {
    const splittedValue: string[] = value.split('.')
    const normalHandicapValue: boolean = (splittedValue[1]) ? (splittedValue[1] === '5') : false

    return normalHandicapValue
}

export function getHandicapOdds(type: string, odds: MarketOdds[]): MarketOdds[] {
    if (type === 'Normal') {
        const filteredOdds: MarketOdds[] = odds.filter(o => o.value && isNormalHandicapValue(o.value))
        return filteredOdds
    }

    if (type === 'Asian') {
        const filteredOdds: MarketOdds[] = odds.filter(o => o.value && !isNormalHandicapValue(o.value))
        return filteredOdds
    }

    return odds
}

export function isSportWinner(sportId: SportId): boolean {
    const sportWinners: SportId[] = [SportId.Cycling, SportId['Ski Jumping']]
    for (const sportid of sportWinners) {
        if (sportid === sportId) {
            return true
        }
    }
    return false
}

function addMarket(item: MarketOdds, markets: MatchMarket): void {
    const marketNames: string[] = Object.keys(markets)

    const marketFound = marketNames.find(m => +BetTypeGroupId[m] === +item.bettypegroupid)
    if (marketFound) {
        markets[marketFound].push(item)
    } else {
        const newMarket = {
            [BetTypeGroupId[item.bettypegroupid]]: [item]
        }
        Object.assign(markets, newMarket)
    }
}

function changeHandicapsSigns(markets: MatchMarket): MatchMarket {
    const marketNames: string[] = Object.keys(markets)
    const marketsIncludeAH: boolean = marketNames.includes(BetTypeGroupId[BetTypeGroupId.AsianHandicap])
    if (marketsIncludeAH) {
        const AHMarkets: MarketOdds[] = markets[BetTypeGroupId[BetTypeGroupId.AsianHandicap]]
        for (let i = 0; i < AHMarkets.length; i++) {
            const homeMarket: MarketOdds = AHMarkets[i]
            const awayMarket: MarketOdds = AHMarkets[i + 1]
            if (homeMarket.value && awayMarket && awayMarket.bettypeid === homeMarket.bettypeid) {
                const homeMarketValue: number = +homeMarket.value
                if (homeMarketValue > 0) {
                    homeMarket.value = `+${Math.abs(homeMarketValue)}`
                    awayMarket.value = `-${Math.abs(homeMarketValue)}`
                }
                if (homeMarketValue < 0) {
                    homeMarket.value = `-${Math.abs(homeMarketValue)}`
                    awayMarket.value = `+${Math.abs(homeMarketValue)}`
                }
            }
        }
    }

    const marketsIncludeEH: boolean = marketNames.includes(BetTypeGroupId[BetTypeGroupId.EuropeanHandicap])
    if (marketsIncludeEH) {
        const EHMarkets: MarketOdds[] = markets[BetTypeGroupId[BetTypeGroupId.EuropeanHandicap]]
        for (let i = 0; i < EHMarkets.length; i++) {
            const homeMarket: MarketOdds = EHMarkets[i]
            const drawMarket: MarketOdds = EHMarkets[i + 1]
            const awayMarket: MarketOdds = EHMarkets[i + 2]
            if (homeMarket.value && drawMarket && drawMarket.bettypeid === homeMarket.bettypeid && awayMarket && awayMarket.bettypeid === homeMarket.bettypeid) {
                const homeMarketValue: number = +homeMarket.value
                if (homeMarketValue > 0) {
                    homeMarket.value = `+${Math.abs(homeMarketValue)}`
                    drawMarket.value = `+${Math.abs(homeMarketValue)}`
                    awayMarket.value = `-${Math.abs(homeMarketValue)}`
                }
                if (homeMarketValue < 0) {
                    homeMarket.value = `-${Math.abs(homeMarketValue)}`
                    drawMarket.value = `-${Math.abs(homeMarketValue)}`
                    awayMarket.value = `+${Math.abs(homeMarketValue)}`
                }
            }
        }
    }

    return markets
}

function checkEmptyOdds(odds: MarketOdds[]): boolean {
    for (const item of odds) {
        const { oddsvalue } = item
        if (!oddsvalue) {
            return true
        }
        const oddsValue: number = parseFloat(oddsvalue)
        if (oddsValue === 1) {
            return true
        }
    }

    return false
}

function makeMarketMakerRequest(token: string, selections: Array<MarketMakerSelection>, matchId: string, typeProperty: string, margin: number, marginParameter: number, oddsMin: number, oddsMax: number, options: MarketOdds[]): void {
    const data = getDataForMMRequest(selections, margin, marginParameter, oddsMin, oddsMax, options)
    data['t'] = token
    dataServer.request('post', 'insert-market-maker-odds', undefined, data, (response: any) => {
        if (response && response.odds && response.probability) {
            store.update(main => {
                main.marketmaker[matchId][typeProperty].odds = response.odds.toString()
                main.marketmaker[matchId][typeProperty].probability = response.probability
                main.marketmaker[matchId][typeProperty].invOdds = response.invOdds.toString()
                main.marketmaker[matchId][typeProperty].invProbability = response.invProbability
                return main
            })
        }
    })
}

export function makeMultiSystemsRequest(betslipItems: Array<BSMultiSystemsItem>, forBetslip?: boolean): void {
    const MSData = betslipItems.filter(m => !m.disabled)
    const MSDataForInput = MSData.map(m => {
        return {
            bettypegroupid: m.bettypegroupid,
            odds: m.odds,
            selection: m.selection
        }
    })
    const multiSystemsInput: MultiSystemsInput = { data: MSDataForInput }
    const route = (forBetslip) ? 'insert-multi-systems-betslip' : 'insert-multi-systems'
    dataServer.request('post', route, undefined, multiSystemsInput)
}

export function getDataForMMRequest(selections: Array<MarketMakerSelection>, margin: number, marginParameter: number, oddsMin: number, oddsMax: number, options: MarketOdds[]): any {
    const dataForMMRequest: any = { selection: [], options, margin, marginParameter, oddsMin, oddsMax }
    for (const item of selections) {
        const { insured, probability, outcomeId } = item
        dataForMMRequest.selection.push({
            insurance: insured,
            probability,
            outcomeid: outcomeId
        })
    }

    return dataForMMRequest
}

export function addHeaderOdds(bettypegroupid: BetTypeGroupId, bettypeid: BetTypeId, matchid: string, probability: string, oddsvalue: string, outcomeid: number, outcomename: string): MarketOdds {
    const newOdds: MarketOdds = {
        bettypegroupid,
        bettypeid,
        islive: false,
        matchid,
        name: outcomename,
        probability,
        oddsvalue,
        oldoddsvalue: oddsvalue,
        // psboddsvalue: oddsvalue,
        outcomeid,
        outcomename,
        value: null
    }

    return newOdds
}

export function addOddsToMatches(matches: Match[], odds: Odds[]): Match[] {
    const matchesWithOdds: Match[] = []

    for (const match of matches) {
        const oddsFound = odds.find(o => +o.matchId === +match.id)
        if (oddsFound) {
            const matchOdds = oddsFound.markets
            const matchWithOdds: Match = match
            matchWithOdds.odds = matchOdds
            matchesWithOdds.push(matchWithOdds)
        }
    }

    return matchesWithOdds
}

export function capitalize(text: string): string {
    const capitalizedText: string = text.charAt(0).toUpperCase() + text.slice(1)

    return capitalizedText
}

export function getNameFromBetType(bettypeid: number): string {
    if (bettypeid === BetTypeId.AnyOtherUnknownDraw) {
        return 'Any other result (Draw)'
    }

    if (bettypeid === BetTypeId.AnyOtherUnknownHome) {
        return 'Any other result (Home win)'
    }

    if (bettypeid === BetTypeId.AnyOtherUnknownAway) {
        return 'Any other result (Away win)'
    }

    for (let i: number = 0; i < BETTYPE_CORRECT_SCORE.length; i++) {
        for (let j: number = 0; j < BETTYPE_CORRECT_SCORE[i].length; j++) {
            if (BETTYPE_CORRECT_SCORE[i][j] === bettypeid) {
                return i + ':' + j
            }
        }
    }
    if (bettypeid >= BETTYPE_ANY_OTHER_HOME[0][0] && BETTYPE_ANY_OTHER_HOME[BETTYPE_ANY_OTHER_HOME.length - 1][BETTYPE_ANY_OTHER_HOME[0].length - 1]) {
        return 'Any other result (Home win)'
    }

    if (bettypeid >= BETTYPE_ANY_OTHER_AWAY[0][0] && BETTYPE_ANY_OTHER_AWAY[BETTYPE_ANY_OTHER_AWAY.length - 1][BETTYPE_ANY_OTHER_AWAY[0].length - 1]) {
        return 'Any other result (Away win)'
    }

    return 'Any other result (Draw)'
}

export function checkStandardItemOnBetslip(matchId: string, betTypeId: BetTypeId, outcomeId: OutcomeId): boolean {
    const betslip: BSStandardItem[] = store.get(main => main.betslip.standard)
    const itemFound = betslip.find(b => b.matchId === matchId && +b.betTypeId === +betTypeId && +b.outcomeId === +outcomeId)
    if (itemFound) {
        return true
    }

    return false
}

export function checkMarketWithValue(market: string): boolean {
    const isBoxWithRange: boolean =
        BetTypeGroupId[market] === BetTypeGroupId.OverUnder ||
        BetTypeGroupId[market] === BetTypeGroupId.AsianHandicap ||
        BetTypeGroupId[market] === BetTypeGroupId.EuropeanHandicap ||
        BetTypeGroupId[market] === BetTypeGroupId.TeamOverUnder

    return isBoxWithRange
}

export function closePopup(): void {
    store.update(main => {
        main.popup.isOpened = false
        main.popup.content = ''
        return main
    })
}

export function createBetslipSubitemsMatrix(data: BetslipHistoryItem[], sportId: SportId): MatrixValue[][] {
    const betslipSubitemsMatrix: MatrixValue[][] = []
    const betrixDefaultMaxGoals: number = sportId === SportId.Hockey ? Assets.betrixHockeyMaxGoals : Assets.betrixSoccerMaxGoals
    for (let i = 0; i <= betrixDefaultMaxGoals; i++) {
        betslipSubitemsMatrix[i] = new Array(betrixDefaultMaxGoals + 1).fill(MatrixValue.NotSelected)
        for (let j = 0; j <= betrixDefaultMaxGoals; j++) {
            const homeGoalsText: string = getGoalsText(i)
            const awayGoalsText: string = getGoalsText(j)
            const goalsText: string = `${homeGoalsText}${awayGoalsText}`
            const betType: BetTypeId = BetTypeId[goalsText]
            const historyItemFound = data.find(s => s.bettype === betType)

            if (historyItemFound) {
                if (historyItemFound.betodds === '1') {
                    betslipSubitemsMatrix[i][j] = MatrixValue.Insured
                } else {
                    betslipSubitemsMatrix[i][j] = MatrixValue.Selected
                }
            }
        }
    }

    return betslipSubitemsMatrix
}

export function emptyBetslip(): void {
    store.update(main => {
        main.betslip.betrix = []
        main.betslip.index = -1
        main.betslip.standard = []
        return main
    })
}

export function emptyMarketMaker(): void {
    store.update(main => {
        main.marketmaker = {}
        return main
    })
}

export function fillBetrixSelectionOdds(data: any): BetrixSelectionOdds {
    let anyOther = 0
    let back = 0
    let lay = 0

    let anyOtherProbability = 0
    let backProbability = 0
    let layProbability = 0

    let backlay = 0
    let backany = 0
    let layany = 0

    let backLAY = 0
    let backANY = 0

    let layBACK = 0
    let layANY = 0

    let anyBACK = 0
    let anyLAY = 0

    if (data.output && data.output.result) {
        const resultData = data.output.result
        
        if (resultData.anyOther && resultData.anyOther.odds) {
            anyOther = resultData.anyOther.odds
        }

        if (resultData.anyOther && resultData.anyOther.probability) {
            anyOtherProbability = resultData.anyOther.probability
        }
        
        if (resultData.back && resultData.back.odds) {
            back = resultData.back.odds
        }

        if (resultData.back && resultData.back.probability) {
            backProbability = resultData.back.probability
        }
        
        if (resultData.lay && resultData.lay.odds) {
            lay = resultData.lay.odds
        }

        if (resultData.lay && resultData.lay.probability) {
            layProbability = resultData.lay.probability
        }

        if (resultData.backlay && resultData.backlay.odds) {
            backlay = resultData.backlay.odds
        }

        if (resultData.backany && resultData.backany.odds) {
            backany = resultData.backany.odds
        }

        if (resultData.layany && resultData.layany.odds) {
            layany = resultData.layany.odds
        }

        if (resultData.backLAY && resultData.backLAY.odds) {
            backLAY = resultData.backLAY.odds
        }

        if (resultData.backANY && resultData.backANY.odds) {
            backANY = resultData.backANY.odds
        }

        if (resultData.layBACK && resultData.layBACK.odds) {
            layBACK = resultData.layBACK.odds
        }

        if (resultData.layANY && resultData.layANY.odds) {
            layANY = resultData.layANY.odds
        }

        if (resultData.anyBACK && resultData.anyBACK.odds) {
            anyBACK = resultData.anyBACK.odds
        }

        if (resultData.anyLAY && resultData.anyLAY.odds) {
            anyLAY = resultData.anyLAY.odds
        }
    }

    return { anyOther, back, lay, anyOtherProbability, backProbability, layProbability, backlay, backany, layany, backLAY, backANY, layBACK, layANY, anyBACK, anyLAY }
}

export function fillCorrectScoreOdds(data: any): CorrectScoreOdds[] {
    const correctScoreOdds: CorrectScoreOdds[] = []

    if (data.output && data.output.result && data.output.result.results) {
        for (const item of data.output.result.results) {
            if (item.homeGoals >= 0) {
                const correctScoreOddsResult: CorrectScoreOddsResult[] = []
                for (const result of item.result) {
                    const newResult: CorrectScoreOddsResult = {
                        awayGoals: result.awayGoals,
                        option: result.option,
                        oddsValue: (result.odd) ? result.odd.toString() : '1'
                    }
                    correctScoreOddsResult.push(newResult)
                }
                const newOdds: CorrectScoreOdds = {
                    homeGoals: item.homeGoals,
                    result: correctScoreOddsResult
                }
                correctScoreOdds.push(newOdds)
            }
        }
    }

    return correctScoreOdds
}

export function fillDatesWithMatches(data: Match[]): MatchesByDate {
    const datesWithMatches: MatchesByDate = {}

    const sortedByDate: Match[] = sortByProperty(data, 'plannedstartdate')
    for (const match of sortedByDate) {
        const dates: string[] = Object.keys(datesWithMatches)
        const dateFound = dates.find(d => d === match.plannedstartdate)
        if (dateFound) {
            datesWithMatches[dateFound].push(match)
        } else {
            const newDate = {
                [match.plannedstartdate]: [match]
            }
            Object.assign(datesWithMatches, newDate)
        }
    }

    return datesWithMatches
}

export function fillMainMarket(data: MarketOdds[]): Odds[] {
    const odds: Odds[] = []

    let markets: MatchMarket = {}
    let markets1: MatchMarket = {}
    let markets2: MatchMarket = {}
    let markets3: MatchMarket = {}
    const filteredData: MarketOdds[] = data.filter(d => d.sportid && +d.bettypegroupid === getMainSportMarket(+d.sportid))
    const sortedData: MarketOdds[] = sortByProperty(filteredData, 'matchid')

    for (let i = 0; i < sortedData.length; i++) {
        const currentItem: MarketOdds = sortedData[i]
        const nextItem: MarketOdds = sortedData[i + 1]
        addMarket(currentItem, markets)
        if (i === (sortedData.length - 1) || (nextItem && nextItem.matchid !== currentItem.matchid)) {
            const newItem: Odds = {
                matchId: currentItem.matchid,
                markets,
                markets1,
                markets2,
                markets3
            }
            odds.push(newItem)
            markets = {}
        }
    }
    return odds
}

export function fillMainMarkets(data: Array<MarketOdds>, currentOdds: Array<Odds>): Array<Odds> {
    const odds: Array<Odds> = currentOdds
    const sortedData: Array<MarketOdds> = sortByProperty(data, 'matchid')
    
    let matchMarkets: MatchMarket = {}
    let matchMarkets1: MatchMarket = {}
    let matchMarkets2: MatchMarket = {}
    let matchMarkets3: MatchMarket = {}

    for (let i = 0; i < sortedData.length; i++) {
        const currentItem: MarketOdds = sortedData[i]
        const nextItem: MarketOdds = sortedData[i + 1]
        addMarket(currentItem, matchMarkets)
        if (i === (sortedData.length - 1) || (nextItem && nextItem.matchid !== currentItem.matchid)) {
            const markets: MatchMarket = changeHandicapsSigns(matchMarkets)
            const markets1: MatchMarket = changeHandicapsSigns(matchMarkets1)
            const markets2: MatchMarket = changeHandicapsSigns(matchMarkets2)
            const markets3: MatchMarket = changeHandicapsSigns(matchMarkets3)
            const matchFound = odds.find(o => o.matchId === currentItem.matchid)
            if (matchFound) {
                const marketKeys = Object.keys(markets)
                for (const market of marketKeys) {
                    const marketFound = Object.keys(matchFound.markets).find(m => m === market)
                    if (marketFound) {
                        if (markets[marketFound].length > matchFound.markets[marketFound].length) {
                            matchFound.markets[marketFound] = markets[marketFound]
                        }
                    } else {
                        matchFound.markets[market] = markets[market]
                    }
                }

                const marketKeys1 = Object.keys(markets1)
                for (const market of marketKeys1) {
                    const marketFound = Object.keys(matchFound.markets1).find(m => m === market)
                    if (marketFound) {
                        if (markets1[marketFound].length > matchFound.markets1[marketFound].length) {
                            matchFound.markets1[marketFound] = markets1[marketFound]
                        }
                    } else {
                        matchFound.markets1[market] = markets1[market]
                    }
                }

            } else {
                const newItem: Odds = {
                    matchId: currentItem.matchid,
                    markets,
                    markets1,
                    markets2,
                    markets3
                }
                odds.push(newItem)
            }
            matchMarkets = {}
        }
    }

    return odds
}

export function fillRegionsWithLeagues(leagues: League[]): Regions {
    const regionsWithLeagues: Regions = {}

    for (const league of leagues) {
        const regions: string[] = Object.keys(regionsWithLeagues)
        const regionFound = regions.find(r => r === league.region)
        if (regionFound) {
            regionsWithLeagues[regionFound].push(league)
        } else {
            const newRegion = {
                [league.region]: [league]
            }
            Object.assign(regionsWithLeagues, newRegion)
        }
    }

    const regionNames: string[] = Object.keys(regionsWithLeagues).sort((a, b) => sortBySize(a, b))
    const sortedRegionsWithLeagues: Regions = {}
    for (const name of regionNames) {
        const sortedLeagues: League[] = sortByProperty(regionsWithLeagues[name], 'id')
        const newRegion = {
            [name]: sortedLeagues
        }
        Object.assign(sortedRegionsWithLeagues, newRegion)
    }

    return sortedRegionsWithLeagues
}

export function fillValuesWithOdds(odds: Array<MarketOdds>, marketGroupData?: MarketGroupItemElement): { [value: string]: Array<MarketOdds> } {

    const valuesWithOdds: { [value: string]: Array<MarketOdds> } = {}
    const sortedOdds = sortByProperty(odds, 'bettypeid')
    let valueOdds: Array<MarketOdds> = []
    for (let i = (sortedOdds.length - 1); i >= 0; i--) {
        const currentItem: MarketOdds = sortedOdds[i]
        const nextItem: MarketOdds = sortedOdds[i - 1]
        
        if (marketGroupData) {
            const outcomeFound = marketGroupData.outcomes.find(o => o.name && o.id === currentItem.outcomeid)
            if (outcomeFound) {
                valueOdds.push(currentItem)
            }
        } else {
            valueOdds.push(currentItem)
        }

        if (i === 0 || (nextItem && nextItem.bettypeid !== currentItem.bettypeid)) {
            const emptyOdds: boolean = checkEmptyOdds(valueOdds)
            if (emptyOdds || !valueOdds.length) {
                valueOdds = []
                continue
            }
            const itemValue: string = currentItem.value as string
            const newValue: string = itemValue.replace('+', '')
            const newItem = {
                [newValue]: valueOdds.reverse()
            }
            Object.assign(valuesWithOdds, newItem)
            valueOdds = []
        }
    }

    if (marketGroupData && marketGroupData.bettypegroupid === BetTypeGroupId.OverUnder) {
        const ValuesWithOdds: { [value: string]: Array<MarketOdds> } = {}
        const obj: string[] = Object.keys(valuesWithOdds)
        for (let i: number = 0; i < obj.length; i++) {
            if (marketGroupData.nopush && obj[i].endsWith('.5')) {
                ValuesWithOdds[obj[i]] = valuesWithOdds[obj[i]]
            } else if (!marketGroupData.nopush && !obj[i].endsWith('.5')) {
                ValuesWithOdds[obj[i]] = valuesWithOdds[obj[i]]
            }

        }
        return ValuesWithOdds
    }

    return valuesWithOdds
}

function getBSMultiSystemsBetrixItems(betslipItems: Array<BSBetrixItem>): Array<BSMultiSystemsItem> {
    const newItems: Array<BSMultiSystemsItem> = []

    for (const item of betslipItems) {
        const { allOdds, matchId, outcomeId, subitems } = item
        
        const odds: Array<MultiSystemsInputDataOdds> = allOdds.map(o => {
            return {
                name: '',
                odds: +o.value,
                outcome: convertBetrixOutcome(o.outcomeId),
                probability: o.probability
            }
        })

        const selection = getMSSelectionBetrix(odds, outcomeId)
        const newItem = createNewMultiSystemsItem(BetTypeGroupId.ThreeWay, matchId, odds, selection, outcomeId, undefined, undefined, subitems)
        newItems.push(newItem)
    }

    return newItems
}

export function createNewMultiSystemsItem(bettypegroupid: BetTypeGroupId, matchId: string, odds: Array<MultiSystemsInputDataOdds>, selection: Array<number>, outcomeId: OutcomeId, betTypeId?: BetTypeId, MMBetTypeId?: BetTypeId, subitems?: Array<BSBetrixSubitem>): BSMultiSystemsItem {
    const newItem: BSMultiSystemsItem = {
        bettypegroupid,
        betTypeId,
        disabled: false,
        matchId,
        MMBetTypeId,
        odds,
        opened: false,
        outcomeId,
        selection,
        subitems
    }

    return newItem
}

export function getBetText(betTypeId: BetTypeId): string {
    if (betTypeId === BetTypeId.ThreeWay) {
        return '3-Way'
    }
    
    if (betTypeId === BetTypeId.HomeAway) {
        return 'Home/Away'
    }
        
    if (betTypeId === BetTypeId.BothTeamsToScore) {
        return 'Both to Score'
    }
    
    if (betTypeId === BetTypeId.MMGeneral) {
        return 'Betrix Market'
    }

    if (betTypeId === BetTypeId.DoubleChance) {
        return 'Double Chance'
    }

    if (betTypeId === BetTypeId.DrawNoBet) {
        return 'Draw No Bet'
    }

    if (BetTypeId[betTypeId].includes('OverUnder')) {
        return 'Total Goals'
    }

    if (BetTypeId[betTypeId] && BetTypeId[betTypeId].includes('Betrix')) {
        return 'Multi Selection'
    }
    
    return ''
}

export function getBetTypeOddsAndProbabilities(betTypeId: BetTypeId): any[] {
    const oddsAndProbabilites: any[] = []
    
    const odds: Odds[] = store.get(main => main.feed.odds)
    const data: MarketOdds[] = odds[0].markets[BetTypeId[betTypeId]]
    const filteredData: MarketOdds[] = data.filter(d => d.bettypeid === betTypeId)
    const sortedData: MarketOdds[] = sortByProperty(filteredData, 'outcomeid')
    
    for (const item of sortedData) {
        const newItem: any = {
            odds: item.oddsvalue,
            probability: item.probability ? parseFloat(item.probability) : 0
        }
        oddsAndProbabilites.push(newItem)
    }

    return oddsAndProbabilites
}

export function getClosestValue(data: { [value: string]: MarketOdds[] }): string {
    const values: string[] = Object.keys(data)
    const filteredValues: string[] = values.filter(v => v !== '0')

    if (filteredValues.length) {
        const firstValue: string = filteredValues[0]

        let mainValue = firstValue
        let mainValueOddsSum = data[firstValue].map(v => +v.oddsvalue).reduce((a, b) => a + b, 0)

        for (const value of filteredValues) {
            const valueOdds: MarketOdds[] = data[value]
            const oddsSum: number = valueOdds.map(v => +v.oddsvalue).reduce((a, b) => a + b, 0)
            if (oddsSum < mainValueOddsSum) {
                mainValue = value
                mainValueOddsSum = oddsSum
            }
        }

        return mainValue
    }

    return ''
}

export function getDayOfWeek(day: number): string {
    switch (day) {
        case 0:
            return 'Sunday'
        case 1:
            return 'Monday'
        case 2:
            return 'Tuesday'
        case 3:
            return 'Wednesday'
        case 4:
            return 'Thursday'
        case 5:
            return 'Friday'
        case 6:
            return 'Saturday'
        default:
            return ''
    }
}

export function getDateFormat(date: Date): string {
    const day: number = date.getDate()
    const month: number = date.getMonth() + 1
    const year: number = date.getFullYear()

    const dayString: string = (day < 10) ? `0${day}` : day.toString()
    const monthString: string = (month < 10) ? `0${month}` : month.toString()

    const dateFormat: string = `${year}-${monthString}-${dayString}`

    return dateFormat
}

export function getDateString(date: string): string {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + 1)
    const matchDate = new Date(date)

    const todayDay: number = today.getDate()
    const tomorrowDay: number = tomorrow.getDate()
    const matchDateDay: number = matchDate.getDate()

    const todayMonth: number = today.getMonth()
    const tomorrowMonth: number = tomorrow.getMonth()
    const matchDateMonth: number = matchDate.getMonth()

    const todayYear: number = today.getFullYear()
    const tomorrowYear: number = tomorrow.getFullYear()
    const matchDateYear: number = matchDate.getFullYear()

    if (matchDateYear === todayYear && matchDateMonth === todayMonth && matchDateDay === todayDay) {
        return 'Today'
    }

    if (matchDateYear === tomorrowYear && matchDateMonth === tomorrowMonth && matchDateDay === tomorrowDay) {
        return 'Tomorrow'
    }

    return getMatchDate(date, '00:00')
}

export function getGoalsText(goals: number): string {
    switch(goals) {
        case 0:
            return 'Zero'
        case 1:
            return 'One'
        case 2:
            return 'Two'
        case 3:
            return 'Three'
        case 4:
            return 'Four'
        case 5:
            return 'Five'
        case 6:
            return 'Six'
        case 7:
            return 'Seven'
        case 8:
            return 'Eight'
        case 9:
            return 'Nine'
        case 10:
            return 'Ten'
        case 11:
            return 'Eleven'
        case 12:
            return 'Twelve'
        case 13:
            return 'Thirteen'
        case 14:
            return 'Fourteen'
        case 15:
            return 'Fifteen'
        case 16:
            return 'Sixteen'
        case 17:
            return 'Seventeen'
        case 18:
            return 'Eighteen'
        case 19:
            return 'Nineteen'
        case 20:
            return 'Twenty'
        default:
            return ''
    }
}

export function getHeaderMatchBanner(index: number, sportId: SportId): any {
    const sport: string = SportId[sportId]
    const assetName: string = `${sport}Banner${index + 1}`

    return Assets[assetName]
}

export function getLeagueLink(league: string, region: string, sport: string): string {
    const leagueNameForLink: string = league.replace(/\s+/g, '-').toLowerCase()
    const regionNameForLink: string = region.replace(/\s+/g, '-').toLowerCase()
    const link: string = `/${sport.toLowerCase()}/${regionNameForLink}/${leagueNameForLink}`

    return link
}

export function getLeagueMatchesLength(leagues: League[], matches: Match[]): number[] {
    const leagueMatchesLength: number[] = []

    for (const league of leagues) {
        const leagueMatches: Match[] = matches.filter(m => m.leagueid === league.id)
        leagueMatchesLength.push(leagueMatches.length)
    }
    
    return leagueMatchesLength
}

export function getLeagueName(league: string): string {
    if (league === 'a-league' || league === 'i-league' || league === 'w-league') {
        return league
    }

    let leagueName: string = ''

    const splittedLeague: string[] = league.split('-')
    for (const item of splittedLeague) {
        leagueName += `${item} `
    }

    const trimmedLeagueName: string = leagueName.trim()

    return trimmedLeagueName
}

export function getMainSportMarket(sportId: SportId): BetTypeGroupId {
    const mainMarkets: MainMarket[] = store.get(main => main.feed.mainMarkets)
    const mainSportMarket = mainMarkets.find(m => m.sportid === sportId)
    if (mainSportMarket) {
        return mainSportMarket.bettypegroup
    }

    return BetTypeGroupId.ThreeWay
}

export function getMarketNameAndOptions(market: string, sportId: SportId, additionalData?: any): { name: string, options: string[] } {
    const marketType: BetTypeGroupId = BetTypeGroupId[market]
    const sh = store.get(main => main.settingsHockey)
    const ss = store.get(main => main.settingsSoccer)

    const settings: Settings = sportId === SportId.Hockey ? sh : ss
    if (settings && settings.marketGroups) {
        for (const item of settings.marketGroups) {
            if (item.marketGroupItem) {
                for (const Item of item.marketGroupItem) {
                    if (Item && Item.items) {
                        for (const ITEM of Item.items) {
                            if (ITEM.bettypegroupid === marketType) {
                                const options: string[] = []
                                for (const ITM of ITEM.outcomes) {
                                    options.push(ITM.name)
                                }
                                return {name: ITEM.name, options}
                            }
                        }
                    }

                }
            }
        }
    }

    return {name: '', options: []}
}

export function getMatchDate(date: string, time: string, showTime?: boolean): string {
    const JSDate: Date = new Date(`${date}T${time}Z`)

    const matchDayOfWeek: number = JSDate.getDay()
    const matchDateFormat: string = getDateFormat(JSDate)

    const splittedDateFormat: string[] = matchDateFormat.split('-')
    const matchDay: string = splittedDateFormat[2]
    const matchMonth: string = splittedDateFormat[1]

    const dayOfWeek: string = getDayOfWeek(matchDayOfWeek)
    const slicedDayOfWeek: string = (isMobile()) ? dayOfWeek.slice(0, 3) : dayOfWeek
    const month: string = getMonth(matchMonth)

    let matchDate: string = `${slicedDayOfWeek}, ${month} ${matchDay}`

    if (showTime) {
        const timeString: string = getTimeFormat(JSDate)
        matchDate += `, ${timeString}`
    }

    return matchDate
}

export function getMatchLink(matchId: string, league: string, region: string, sportId: SportId): string {
    const sport: string = SportId[sportId].toLowerCase()
    const leagueLink: string = getLeagueLink(league, region, sport)
    const pathname: string = `${leagueLink}/${matchId}`

    return pathname
}

export function getMatchTime(date: string, time: string): string {
    const JSDate: Date = new Date(`${date}T${time}Z`)

    const matchHours: number = JSDate.getHours()
    const matchMinutes: number = JSDate.getMinutes()

    const hours: string = (matchHours < 10) ? `0${matchHours}` : matchHours.toString()
    const minutes: string = (matchMinutes < 10) ? `0${matchMinutes}` : matchMinutes.toString()

    const matchTime: string = `${hours}:${minutes}`

    return matchTime
}

export function getMonth(month: string): string {
    switch (month) {
        case '01':
            return 'Jan'
        case '02':
            return 'Feb'
        case '03':
            return 'Mar'
        case '04':
            return 'Apr'
        case '05':
            return 'May'
        case '06':
            return 'Jun'
        case '07':
            return 'Jul'
        case '08':
            return 'Aug'
        case '09':
            return 'Sep'
        case '10':
            return 'Oct'
        case '11':
            return 'Nov'
        case '12':
            return 'Dec'
        default:
            return ''
    }
}

export function getOutcomeTextFull(awayTeam: string, homeTeam: string, outcomeId: OutcomeId, outcomeValue: string | null, additionalData?: any): string {
    switch (outcomeId) {
        case OutcomeId.TFOTROverAway:
            return `Over ${outcomeValue}`
        case OutcomeId.TTRMOverAway:
            return `Over ${outcomeValue}`
        case OutcomeId.TFOTRUnderAway:
            return `Under ${outcomeValue}`
        case OutcomeId.TTRMUnderAway:
            return `Under ${outcomeValue}`

        case OutcomeId.TFOTROverHome:
            return `Over ${outcomeValue}`
        case OutcomeId.TTRMOverHome:
            return `Over ${outcomeValue}`
        case OutcomeId.TFOTRUnderHome:
            return `Under ${outcomeValue}`
        case OutcomeId.TTRMUnderHome:
            return `Under ${outcomeValue}`
        case OutcomeId.Over:
        case OutcomeId.OverHome:
        case OutcomeId.OverAway:
            return `Over ${outcomeValue}`
        case OutcomeId.Under:
        case OutcomeId.UnderHome:
        case OutcomeId.UnderAway:
            return `Under ${outcomeValue}`

        case OutcomeId.AHHome:
        case OutcomeId.EHHome:
            return (isMobile()) ? `Home (${outcomeValue})` : `${homeTeam} ${outcomeValue}`
        
        case OutcomeId.AHAway:
        case OutcomeId.EHAway:
            return (isMobile()) ? `Away (${outcomeValue})` : `${awayTeam} ${outcomeValue}`
        case OutcomeId.EHDraw:
            return (isMobile()) ? `Draw (${outcomeValue})` : `Draw (${homeTeam} ${outcomeValue})`
    }
    return getOutcomeText(awayTeam, homeTeam, outcomeId, outcomeValue, additionalData)
}

export function getOutcomeText(awayTeam: string, homeTeam: string, outcomeId: OutcomeId, outcomeValue: string | null, additionalData?: any): string {
    switch (outcomeId) {
        case OutcomeId.TFOTROverAway:
        case OutcomeId.TTRMOverAway:
        case OutcomeId.TFOTRUnderAway:
        case OutcomeId.TTRMUnderAway:
        case OutcomeId.TFOTROverHome:
        case OutcomeId.TTRMOverHome:
        case OutcomeId.TFOTRUnderHome:
        case OutcomeId.TTRMUnderHome:
            return ''
        case OutcomeId.HomeWin:
        case OutcomeId.HAHome:
        case OutcomeId.DNBHome:
        case OutcomeId.FLTSHome:
        case OutcomeId.FTGHome:
        case OutcomeId.HTOrFTHome:
        case OutcomeId.FHOrSHHome:
            return (isMobile()) ? 'Home' : homeTeam
        case OutcomeId.Draw:
        case OutcomeId.HTOrFTDraw:
        case OutcomeId.FHOrSHDraw:
            return 'Draw'
        case OutcomeId.AwayWin:
        case OutcomeId.HAAWay:
        case OutcomeId.DNBAway:
        case OutcomeId.FLTSAway:
        case OutcomeId.FTGAway:
        case OutcomeId.HTOrFTAway:
        case OutcomeId.FHOrSHAway:
            return (isMobile()) ? 'Away' : awayTeam
        case OutcomeId.BTTSYes:
            return 'Yes'
        case OutcomeId.BTTSNo:
            return 'No'
        case OutcomeId.Over:
        case OutcomeId.OverHome:
        case OutcomeId.OverAway:
            return ''
        case OutcomeId.Under:
        case OutcomeId.UnderHome:
        case OutcomeId.UnderAway:
            return ''
        case OutcomeId.HomeOrDraw:
            return (isMobile()) ? '1X' : 'Home or Draw'
        case OutcomeId.HomeOrAway:
            return (isMobile()) ? '12' : 'Home or Away'
        case OutcomeId.DrawOrAway:
            return (isMobile()) ? 'X2' : 'Draw or Away'
        case OutcomeId.AHHome:
            return ''
        case OutcomeId.AHAway:
            return ''
        case OutcomeId.EHHome:
        case OutcomeId.EHAway:
        case OutcomeId.EHDraw:
            return ''
        case OutcomeId.Odd:
            return 'Odd'
        case OutcomeId.Even:
            return 'Even'
        case OutcomeId.BetrixYes:
            if (additionalData && additionalData.showMarketMaker) {
                return 'Selected'
            }
            return 'Multi Selection'
        case OutcomeId.BetrixNo:
            return 'Not Selected'
        case OutcomeId.BetrixOut:
            return 'Any Other Result'
        case OutcomeId.BetrixYesNo:
            return 'Selected and Not Selected'
        case OutcomeId.BetrixYesOut:
            return 'Selected and Any Other Result'
        case OutcomeId.BetrixNoOut:
            return 'Not Selected and Any Other Result'
        case OutcomeId.BetrixYesNO:
            return 'Selected and (Not Selected)'
        case OutcomeId.BetrixYesOUT:
            return 'Selected and (Any Other Result)'
        case OutcomeId.BetrixNoYES:
            return 'Not Selected and (Selected)'
        case OutcomeId.BetrixNoOUT:
            return 'Not Selected and (Any Other Result)'
        case OutcomeId.BetrixOutYES:
            return 'Any Other Result and (Selected)'
        case OutcomeId.BetrixOutNO:
            return 'Any Other Result and (Not Selected)'
        case OutcomeId.WM13SevenPlus:
            return (isMobile()) ? `Home 7+` : `${homeTeam} 7+`
        case OutcomeId.WM11SixPlus:
            return (isMobile()) ? `Home 6+` : `${homeTeam} 6+`
        case OutcomeId.WM9FivePlus:
            return (isMobile()) ? `Home 5+` : `${homeTeam} 5+`
        case OutcomeId.WM13Six:
            return (isMobile()) ? `Home 6` : `${homeTeam} 6`
        case OutcomeId.WM11Five:
        case OutcomeId.WM13Five:
            return (isMobile()) ? `Home 5` : `${homeTeam} 5`
        case OutcomeId.WM7FourPlus:
            return (isMobile()) ? 'Home 4+' : `${homeTeam} 4+`
        case OutcomeId.WM9Four:
        case OutcomeId.WM11Four:
        case OutcomeId.WM13Four:
            return (isMobile()) ? `Home 4` : `${homeTeam} 4`
        case OutcomeId.WM2ThreePlus:
            return (isMobile()) ? `Home 3+` : `${homeTeam} 3+`
        case OutcomeId.WM7Three:
        case OutcomeId.WM9Three:
        case OutcomeId.WM11Three:
        case OutcomeId.WM13Three:
            return (isMobile()) ? `Home 3` : `${homeTeam} 3`
        case OutcomeId.WM8TwoPlus:
            return (isMobile()) ? `Home 2+` : `${homeTeam} 2+`
        case OutcomeId.WM2Two:
        case OutcomeId.WM7Two:
        case OutcomeId.WM9Two:
        case OutcomeId.WM11Two:
        case OutcomeId.WM13Two:
            return (isMobile()) ? `Home 2` : `${homeTeam} 2`
        case OutcomeId.WM10OnePlus:
        case OutcomeId.WM13OnePlus:
            return (isMobile()) ? `Home 1+` : `${homeTeam} 1+`
        case OutcomeId.WM2One:
        case OutcomeId.WM7One:
        case OutcomeId.WM8One:
        case OutcomeId.WM9One:
        case OutcomeId.WM11One:
            return (isMobile()) ? `Home 1` : `${homeTeam} 1`
        case OutcomeId.WM2Zero:
        case OutcomeId.WM7Zero:
        case OutcomeId.WM8Zero:
        case OutcomeId.WM9Zero:
        case OutcomeId.WM10Zero:
            return 'Draw (0)'
        case OutcomeId.WM11ZeroMinus:
            return (isMobile()) ? 'Draw or Away' : `Draw (0) or ${awayTeam}`
        case OutcomeId.WM12ZeroPlus:
            return (isMobile()) ? 'Draw or Home' : `Draw (0) or ${homeTeam}`
        case OutcomeId.WM2MinusOne:
        case OutcomeId.WM7MinusOne:
        case OutcomeId.WM8MinusOne:
        case OutcomeId.WM10MinusOne:
        case OutcomeId.WM12MinusOne:
            return (isMobile()) ? 'Away 1' : `${awayTeam} 1`
        case OutcomeId.WM9MinusOnePlus:
        case OutcomeId.WM14MinusOnePlus:
            return (isMobile()) ? 'Away 1+' : `${awayTeam} 1+`
        case OutcomeId.WM2MinusTwo:
        case OutcomeId.WM8MinusTwo:
        case OutcomeId.WM10MinusTwo:
        case OutcomeId.WM12MinusTwo:
        case OutcomeId.WM14MinusTwo:
            return (isMobile()) ? 'Away 2' : `${awayTeam} 2`
        case OutcomeId.WM7MinusTwoPlus:
            return (isMobile()) ? 'Away 2+' : `${awayTeam} 2+`
        case OutcomeId.WM8MinusThree:
        case OutcomeId.WM10MinusThree:
        case OutcomeId.WM12MinusThree:
        case OutcomeId.WM14MinusThree:
            return (isMobile()) ? 'Away 3' : `${awayTeam} 3`
        case OutcomeId.WM2MinusThreePlus:
            return (isMobile()) ? 'Away 3+' : `${awayTeam} 3+`
        case OutcomeId.WM10MinusFour:
        case OutcomeId.WM12MinusFour:
        case OutcomeId.WM14MinusFour:
            return (isMobile()) ? 'Away 4' : `${awayTeam} 4`
        case OutcomeId.WM8MinusFourPlus:
            return (isMobile()) ? 'Away 4+' : `${awayTeam} 4+`
        case OutcomeId.WM12MinusFive:
        case OutcomeId.WM14MinusFive:
            return (isMobile()) ? 'Away 5' : `${awayTeam} 5`
        case OutcomeId.WM10MinusFivePlus:
            return (isMobile()) ? 'Away 5+' : `${awayTeam} 5+`
        case OutcomeId.WM14MinusSix:
            return (isMobile()) ? 'Away 6' : `${awayTeam} 6`
        case OutcomeId.WM12MinusSixPlus:
            return (isMobile()) ? 'Away 6' : `${awayTeam} 6+`
        case OutcomeId.WM14MinusSevenPlus:
            return (isMobile()) ? 'Away 7+' : `${awayTeam} 7`
        case OutcomeId.ENGZero:
        case OutcomeId.TENGZero:
            return 'No goals'
        case OutcomeId.ENG2ZeroOne:
        case OutcomeId.TENG2ZeroOne:
            return '0 - 1 goal'
        case OutcomeId.ENG3ZeroTwo:
        case OutcomeId.TENG3ZeroTwo:
            return '0 - 2 goals'
        case OutcomeId.ENG4ZeroThree:
        case OutcomeId.TENG4ZeroThree:
            return '0 - 3 goals'
        case OutcomeId.ENG5ZeroFour:
        case OutcomeId.TENG5ZeroFour:
            return '0 - 4 goals'
        case OutcomeId.ENG6ZeroFive:
            return '0 - 5 goals'
        case OutcomeId.ENG7ZeroSix:
            return '0 - 6 goals'
        case OutcomeId.ENGOne:
        case OutcomeId.TENGOne:
            return '1 goal'
        case OutcomeId.ENGTwo:
        case OutcomeId.ENG2Two:
        case OutcomeId.TENGTwo:
        case OutcomeId.TENG2Two:
            return '2 goals'
        case OutcomeId.ENGThree:
        case OutcomeId.ENG2Three:
        case OutcomeId.ENG3Three:
        case OutcomeId.TENG2Three:
        case OutcomeId.TENG3Three:
            return '3 goals'
        case OutcomeId.TENGThreePlus:
            return '3+ goals'
        case OutcomeId.ENGFour:
        case OutcomeId.ENG2Four:
        case OutcomeId.ENG3Four:
        case OutcomeId.ENG4Four:
        case OutcomeId.TENG3Four:
        case OutcomeId.TENG4Four:
            return '4 goals'
        case OutcomeId.TENG2FourPlus:
            return '4+ goals'
        case OutcomeId.ENGFive:
        case OutcomeId.ENG2Five:
        case OutcomeId.ENG3Five:
        case OutcomeId.ENG4Five:
        case OutcomeId.ENG5Five:
        case OutcomeId.TENG4Five:
        case OutcomeId.TENG5Five:
            return '5 goals'
        case OutcomeId.TENG3FivePlus:
            return '5+ goals'
        case OutcomeId.ENG2Six:
        case OutcomeId.ENG3Six:
        case OutcomeId.ENG4Six:
        case OutcomeId.ENG5Six:
        case OutcomeId.ENG6Seven:
        case OutcomeId.TENG5Six:
            return '6 goals'
        case OutcomeId.ENGSixPlus:
        case OutcomeId.TENG4SixPlus:
            return '6+ goals'
        case OutcomeId.ENG3Seven:
        case OutcomeId.ENG4Seven:
        case OutcomeId.ENG5Seven:
        case OutcomeId.ENG6Seven:
        case OutcomeId.ENG7Seven:
            return '7 goals'
        case OutcomeId.ENG2SevenPlus:
        case OutcomeId.TENG5SevenPlus:
            return '7+ goals'
        case OutcomeId.ENG4Eight:
        case OutcomeId.ENG5Eight:
        case OutcomeId.ENG6Eight:
        case OutcomeId.ENG7Eight:
            return '8 goals'
        case OutcomeId.ENG3EightPlus:
            return '8+ goals'
        case OutcomeId.ENG5Nine:
        case OutcomeId.ENG6Nine:
        case OutcomeId.ENG7Nine:
            return '9 goals'
        case OutcomeId.ENG4NinePlus:
            return '9+ goals'
        case OutcomeId.ENG6Ten:
        case OutcomeId.ENG7Ten:
            return '10 goals'
        case OutcomeId.ENG5TenPlus:
            return '10+ goals'
        case OutcomeId.ENG7Eleven:
            return '11 goals'
        case OutcomeId.ENG6ElevenPlus:
            return '11+ goals'
        case OutcomeId.ENG7TwelvePlus:
            return '12+ goals'
        case OutcomeId.ZeroOne:
        case OutcomeId.TNGZeroOne:
            return '0 or 1'
        case OutcomeId.TwoThree:
        case OutcomeId.TNGTwoThree:
            return '2 or 3'
        case OutcomeId.FourPlus:
        case OutcomeId.TNGFourPlus:
            return '4+'
        case OutcomeId.ScoreBoth:
        case OutcomeId.FTGBoth:
            return 'Both'
        case OutcomeId.ScoreHome:
            return 'Home only'
        case OutcomeId.ScoreAway:
            return 'Away only'
        case OutcomeId.ScoreNone:
        case OutcomeId.FLTSNone:
        case OutcomeId.TFGNone:
        case OutcomeId.TFG2None:
        case OutcomeId.TFG3None:
        case OutcomeId.TTFGNone:
            return 'None'
        case OutcomeId.HTFTHomeHome:
        case OutcomeId.FHSHHomeHome:
        case OutcomeId.HTFT2HomeHome:
            return (isMobile()) ? '1/1' : `${homeTeam}/${homeTeam}`
        case OutcomeId.HTFTDrawHome:
        case OutcomeId.FHSHDrawHome:
            return (isMobile()) ? 'X/2' : `Draw/${homeTeam}`
        case OutcomeId.HTFTAwayHome:
        case OutcomeId.FHSHAwayHome:
            return (isMobile()) ? '2/1' : `${awayTeam}/${homeTeam}`
        case OutcomeId.HTFTHomeDraw:
        case OutcomeId.FHSHHomeDraw:
            return (isMobile()) ? '1/X' : `${homeTeam}/Draw`
        case OutcomeId.HTFTDrawDraw:
        case OutcomeId.FHSHDrawDraw:
            return (isMobile()) ? 'X/X' : 'Draw/Draw'
        case OutcomeId.HTFTAwayDraw:
        case OutcomeId.FHSHAwayDraw:
            return (isMobile()) ? '2/X' : `${awayTeam}/Draw`
        case OutcomeId.HTFTHomeAway:
        case OutcomeId.FHSHHomeAway:
            return (isMobile()) ? '1/2' : `${homeTeam}/${awayTeam}`
        case OutcomeId.HTFTDrawAway:
        case OutcomeId.FHSHDrawAway:
                return (isMobile()) ? 'X/2' : `Draw/${awayTeam}`
        case OutcomeId.HTFTAwayAway:
        case OutcomeId.FHSHAwayAway:
        case OutcomeId.HTFT3AwayAway:
            return (isMobile()) ? '2/2' : `${awayTeam}/${awayTeam}`
        case OutcomeId.HSHFirst:
        case OutcomeId.THSHFirst:
            return (isMobile()) ? '1st' : 'First'
        case OutcomeId.HSHSecond:
        case OutcomeId.THSHSecond:
            return (isMobile()) ? '2nd' : 'Second'
        case OutcomeId.HSHTie:
        case OutcomeId.THSHTie:
            return 'Tie'
        case OutcomeId.FTGUnder:
            return 'Under 2 goals'
        case OutcomeId.HTFT2DrawOrAwayHome:
            return (isMobile()) ? 'X or 2/1' : `Draw or ${awayTeam}/${homeTeam}`
        case OutcomeId.HTFT2HomeDrawOrAway:
            return (isMobile()) ? '1/X or 2' : `${homeTeam}/Draw or ${awayTeam}`
        case OutcomeId.HTFT2DrawOrAwayDrawOrAway:
            return (isMobile()) ? 'X or 2/X or 2' : `Draw or ${awayTeam}/Draw or ${awayTeam}`
        case OutcomeId.HTFT3HomeOrDrawHomeOrDraw:
            return (isMobile()) ? '1 or X/1 or X' : `${homeTeam} or Draw/${homeTeam} or Draw`
        case OutcomeId.HTFT3AwayHomeOrDraw:
            return (isMobile()) ? '2/1 or X' : `${awayTeam}/${homeTeam} or Draw`
        case OutcomeId.HTFT3HomeOrDrawAway:
            return (isMobile()) ? '1 or X/2' : `${homeTeam} or Draw/${awayTeam}`
        case OutcomeId.BetAgainstAway:
            return (isMobile()) ? 'Against 2' : `Against ${awayTeam}`;
        case OutcomeId.BetAgainstDraw:
            return (isMobile()) ? 'Against X' : `Against Draw`;
        case OutcomeId.BetAgainstHome:
            return (isMobile()) ? 'Against 1' : `Against ${homeTeam}`;
        case OutcomeId.TFG010:
            return '0\' - 10\''
        case OutcomeId.TFG1120:
            return '11\' - 20\''
        case OutcomeId.TFG2130:
            return '21\' - 30\''
        case OutcomeId.TFG3140:
            return '31\' - 40\''
        case OutcomeId.TFG4150:
            return '41\' - 50\''
        case OutcomeId.TFG5160:
            return '51\' - 60\''
        case OutcomeId.TFG6170:
            return '61\' - 70\''
        case OutcomeId.TFG7180:
            return '71\' - 80\''
        case OutcomeId.TFG81Plus:
            return '81\'+'
        case OutcomeId.TFG2015:
            return '0\' - 15\''
        case OutcomeId.TFG21630:
            return '16\' - 30\''
        case OutcomeId.TFG23145:
            return '31\' - 45\''
        case OutcomeId.TFG24660:
            return '46\' - 60\''
        case OutcomeId.TFG26175:
            return '61\' - 75\''
        case OutcomeId.TFG276Plus:
            return '76\'+'
        case OutcomeId.TFG3030:
        case OutcomeId.TTFG030:
            return '0\' - 30\''
        case OutcomeId.TFG33160:
        case OutcomeId.TTFG3160:
            return '31\' - 60\''
        case OutcomeId.TFG361Plus:
        case OutcomeId.TTFG61Plus:
            return '61+\''
        case OutcomeId.THSH2FirstYes:
            return (isMobile()) ? '1st & Yes' : 'First & Yes'
        case OutcomeId.THSH2FirstNo:
            return (isMobile()) ? '1st & No' : 'First & No'
        case OutcomeId.THSH2SecondYes:
            return (isMobile()) ? '2nd & Yes' : '2nd & Yes'
        case OutcomeId.THSH2SecondNo:
            return (isMobile()) ? '2nd & No' : '2nd & No'
        case OutcomeId.THSH2TieYes:
            return 'Tie & Yes'
        case OutcomeId.THSH2TieNo:
            return 'Tie & No'
        default:
            return ''
    }
}

export function getOutcomeTextMarketMaker(awayTeam: string, homeTeam: string, outcomeId: OutcomeId, outcomeValue: string | null): string {
    switch (outcomeId) {
        case OutcomeId.BetrixYes:
            return `${homeTeam}`
        case OutcomeId.BetrixNo:
            return 'Draw'
        case OutcomeId.BetrixOut:
            return `${awayTeam}`
        case OutcomeId.BetrixYesNo:
            return `${homeTeam} or Draw`
        case OutcomeId.BetrixYesOut:
            return `${homeTeam} or ${awayTeam}`
        case OutcomeId.BetrixNoOut:
            return `${awayTeam} or Draw`
        case OutcomeId.BetrixYesNO:
            return `${homeTeam} or Money back on Draw`
        case OutcomeId.BetrixYesOUT:
            return `${homeTeam} or Money back on ${awayTeam}`
        case OutcomeId.BetrixNoYES:
            return `Draw or Money back on ${homeTeam}`
        case OutcomeId.BetrixNoOUT:
            return `Draw or Money back on ${awayTeam}`
        case OutcomeId.BetrixOutYES:
            return `${awayTeam} or Money back on ${homeTeam}`
        case OutcomeId.BetrixOutNO:
            return `${awayTeam} or Money back on Draw`
        default:
            return ''
    }
}

export function getPlatform(): Platform {
    const platform: Platform = (window.innerWidth > 991) ? 'desktop' : 'mobile'

    return platform
}

export function getRegionFlag(region: string): {flag: string, name: string} {
    if (region.length === 2) {
        for (const item of c) {
            if (item.a === region) {
                region = item.n
                break
            }
        }
    }

    const regionName: string = region.replace(' ', '')
    const assetName: string = `${regionName}Flag`
    if (region === 'Côte d\'Ivoire') {
        return Assets.CotedivoireFlag
    }
    if (region === 'Korea, Republic of') {
        return Assets.SouthKoreaFlag
    }

    return {flag: Assets[assetName], name: region}
}

export function getRegionName(region: string): string {
    let regionName: string = ''

    const splittedRegion: string[] = region.split('-')
    for (const item of splittedRegion) {
        regionName += `${item} `
    }

    const trimmedRegionName: string = regionName.trim()

    return trimmedRegionName
}

export function getResponseMessage(response: any): string {
    if (response && response.message) {
        return response.message
    }

    return 'Something went wrong'
}

export function getQuickButtonLines(button: QuickButton, additionalButton: AdditionalButton, betrixMaxGoalsHome: number, betrixMaxGoalsAway: number): number[] {
    switch (button) {
        case QuickButton.HomeTeam:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array(betrixMaxGoalsHome + 1).keys())
            }
            return Array.from(Array(betrixMaxGoalsHome).keys()).map(item => item + 0.5)

        case QuickButton.AwayTeam:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array(betrixMaxGoalsAway + 1).keys())
            }
            return Array.from(Array(betrixMaxGoalsAway).keys()).map(item => item + 0.5)
        case QuickButton.GoalDifference:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array(betrixMaxGoalsAway + 1).keys()).concat(Array.from(Array(betrixMaxGoalsHome + 1).keys()).map(item => -item)).splice(1).sort((a, b) => sortBySize(a, b))
            }
            if (additionalButton === AdditionalButton.Under) {
                return Array.from(Array(betrixMaxGoalsAway).keys()).map(item => -(item + 0.5)).concat(Array.from(Array(betrixMaxGoalsHome).keys()).map(item => item + 0.5)).sort((a, b) => sortBySize(a, b))
            }
            return Array.from(Array(betrixMaxGoalsHome).keys()).map(item => -(item + 0.5)).concat(Array.from(Array(betrixMaxGoalsAway).keys()).map(item => item + 0.5)).sort((a, b) => sortBySize(a, b))
        case QuickButton.Totals:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array((betrixMaxGoalsHome + betrixMaxGoalsAway) + 1).keys())
            }
            return Array.from(Array(betrixMaxGoalsHome + betrixMaxGoalsAway).keys()).map(item => item + 0.5)
        default:
            return []
    }
}

export function GetQuickButtonLines(button: QuickButton, additionalButton: AdditionalButton, betrixMaxGoalsHome: number, betrixMaxGoalsAway: number, liveHomeScore: number, liveAwayScore: number): number[] {
    switch (button) {
        case QuickButton.HomeTeam:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array(betrixMaxGoalsHome + 1).keys()).map(item => item + liveHomeScore)
            }
            return Array.from(Array(betrixMaxGoalsHome).keys()).map(item => item + 0.5 + liveHomeScore)

        case QuickButton.AwayTeam:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array(betrixMaxGoalsAway + 1).keys()).map(item => item + liveAwayScore)
            }
            return Array.from(Array(betrixMaxGoalsAway).keys()).map(item => item + 0.5 + liveAwayScore)
        
        case QuickButton.GoalDifference:
            if (additionalButton === AdditionalButton.Exact) {
                return (Array.from(Array(betrixMaxGoalsAway + 1).keys()).map(item => item - liveHomeScore + liveAwayScore)).concat(Array.from(Array(betrixMaxGoalsHome + 1).keys()).map(item => -(item + liveHomeScore - liveAwayScore))).splice(1).sort((a, b) => sortBySize(a, b))
            }
            if (additionalButton === AdditionalButton.Under) {
                return Array.from(Array(betrixMaxGoalsAway).keys()).map(item => -(item + 0.5 + liveAwayScore - liveHomeScore)).concat(Array.from(Array(betrixMaxGoalsHome).keys()).map(item => item + 0.5 - liveAwayScore + liveHomeScore)).sort((a, b) => sortBySize(a, b))
            }
            return Array.from(Array(betrixMaxGoalsHome).keys()).map(item => -(item + 0.5 - liveAwayScore + liveHomeScore)).concat(Array.from(Array(betrixMaxGoalsAway).keys()).map(item => item + 0.5 + liveAwayScore - liveHomeScore)).sort((a, b) => sortBySize(a, b))

        case QuickButton.Totals:
            if (additionalButton === AdditionalButton.Exact) {
                return Array.from(Array(betrixMaxGoalsHome + betrixMaxGoalsAway + 1).keys()).map(item => item + liveHomeScore + liveAwayScore)
            }
            return Array.from(Array(betrixMaxGoalsHome + betrixMaxGoalsAway).keys()).map(item => item + 0.5 + liveAwayScore + liveHomeScore)
        default:
            return []
    }
}

export function getSportFromPathname(): string {
    const sport: string = window.location.pathname.split('/').filter(Boolean)[0]

    return sport
}

export function getSportIcon(sportId: SportId, green?: boolean): any {
    const sportName: string = SportId[sportId]
    const assetName: string = (green) ? `${sportName}IconGreen` : `${sportName}Icon`

    return Assets[assetName]
}

export function getTeamShortName(team: string): string {
    if (team.length <= 12) {
        return team
    }

    const splittedTeam: string[] = team.split(/[\s, -]+/)
    let longestName: string = splittedTeam[0]

    for (const item of splittedTeam) {
        if (item.length > longestName.length) {
            longestName = item
        }
    }

    return longestName
}

export function getTimeFormat(date: Date): string {
    const minutes: number = date.getMinutes()
    const hours: number = date.getHours()

    const minutesString: string = (minutes < 10) ? `0${minutes}` : minutes.toString()
    const hoursString: string = (hours < 10) ? `0${hours}` : hours.toString()

    const timeFormat: string = `${hoursString}:${minutesString}`

    return timeFormat
}

export function groupHeaderMatches(data: HeaderMatchData[]): HeaderMatch[] {
    const headerMatches: HeaderMatch[] = []

    let odds: MarketOdds[] = []
    for (let i = 0; i < data.length; i++) {
        const currentItem: HeaderMatchData = data[i]
        const nextItem: HeaderMatchData = data[i + 1]
        const { away, bettypegroupid, bettypeid, date, home, league, leagueid, matchid, probability, oddsvalue, outcomeid, outcomename, region, regionid, time } = currentItem
        const newOdds: MarketOdds = addHeaderOdds(bettypegroupid, bettypeid, matchid, probability, oddsvalue, outcomeid, outcomename)
        odds.push(newOdds)
        if (i === (data.length - 1) || (nextItem && nextItem.matchid !== currentItem.matchid)) {
            const newItem: HeaderMatch = {
                awayTeam: away,
                date,
                homeTeam: home,
                league,
                leagueId: leagueid,
                matchId: matchid,
                odds,
                region,
                regionId: regionid,
                time
            }
            headerMatches.push(newItem)
            odds = []
        }
    }

    return headerMatches
}

export function handleQuickLeague(item: QuickLeague): void {
    const { league, region, sportId } = item
    let quickLeagues: QuickLeague[] = store.get(main => main.feed.quickLeagues)
    const quickLeagueFound = quickLeagues.find(q => q.league === league && q.region === region && q.sportId === sportId)

    if (quickLeagueFound) {
        quickLeagues = quickLeagues.filter(q => q.league !== league || q.region !== region || q.sportId !== sportId)
    } else {
        quickLeagues.push(item)
    }
    
    localStorage.setItem('quickLeagues', JSON.stringify(quickLeagues))
    store.update(main => {
        main.feed.quickLeagues = quickLeagues
        return main
    })
}

export function isMobile(): boolean {
    const platform: Platform = store.get(main => main.app.platform)
    const isPlatformMobile: boolean = platform === 'mobile'

    return isPlatformMobile
}

export function openPopup(): void {
    store.update(main => {
        main.popup.isOpened = true
        return main
    })
}

export function removeBSBetrixItem(index: number, matchId: string): void {
    const betslip: BSBetrixItem[] = store.get(main => main.betslip.betrix)
    const newBetslip: BSBetrixItem[] = betslip.filter(b => +b.index !== index)
    store.update(main => {
        main.betslip.betrix = newBetslip
        return main
    })
}

export function removeBSMMItem(matchId: string, selections: MarketMakerSelection[]): void {
    const betslip: BSStandardItem[] = store.get(main => main.betslip.standard)
    const newBetslip: BSStandardItem[] = []

    for (const item of betslip) {
        if (item.matchId === matchId && item.MMSelections) {
            for (const matchSelection of item.MMSelections) {
                let areSelectionsEqual: boolean = true
                const selectionFound = selections.find(s => s.outcomeId === matchSelection.outcomeId && s.insured === matchSelection.insured)
                if (!selectionFound) {
                    areSelectionsEqual = false
                }
                if (!areSelectionsEqual) {
                    newBetslip.push(item)
                }
            }
        } else {
            newBetslip.push(item)
        }
    }

    store.update(main => {
        main.betslip.standard = newBetslip
        return main
    })
}

export function getMMOddsItemStatus(isCS: boolean, data: MarketMaker, matchId: string, betTypeGroupId: BetTypeGroupId, betTypeId: BetTypeId, outcomeId: OutcomeId): string {
    const matchData = data[matchId]
    if (matchData) {
        const typeProperty = (betTypeGroupId === BetTypeGroupId.CorrectScore) ? BetTypeGroupId[betTypeGroupId] : BetTypeId[betTypeId]
        const betTypeData = data[matchId][typeProperty]
        if (betTypeData) {
            const outcomeFound = betTypeData.selections.find(s => (s.betTypeId) ? s.betTypeId === betTypeId : s.outcomeId === outcomeId)
            if (outcomeFound) {
                if (outcomeFound.insured) {
                    return 'insured'
                } else {
                    return 'active'
                }
            }
        }
    }

    return 'empty'
}

export function removeBSStandardItem(index: number, matchId?: string, typeProperty?: string): void {
    const betslip: BSStandardItem[] = store.get(main => main.betslip.standard)
    const newMarketMaker: MarketMaker = store.get(main => main.marketmaker)
    
    const newBetslip: BSStandardItem[] = betslip.filter(b => b.index !== index)
    
    if (matchId && typeProperty) {
        const matchData = newMarketMaker[matchId]
        if (matchData) {
            const betType = matchData[typeProperty]
            if (betType) {
                delete newMarketMaker[matchId][typeProperty]
                if (!Object.keys(newMarketMaker[matchId]).length) {
                    delete newMarketMaker[matchId]
                }
            }
        }
    }

    store.update(main => {
        main.betslip.index = main.betslip.index - 1
        main.betslip.standard = newBetslip
        main.marketmaker = newMarketMaker
        return main
    })
}

function cutTrailingZeros(value: string) {
    const newValue = value
    
    if (!+value) {
        return ''
    } else {
        for (let i = 0; i < value.length; i++) {
            if (+value[i]) {
                if (!value[i + 1]) {
                    return value
                } else if ((value[i + 1] && !+value[i + 1])) {
                    const slicedValue = value.slice(0, i + 1)
                    return slicedValue
                }
            }
        }
    }

    return newValue
}

export function roundOddsValue(value: string, numberOfDecimals: number): string {
    if (value) {
        
        const splittedValue: string[] = value.split('.')
        const base: string = splittedValue[0]

        let rest: string = splittedValue[1] && splittedValue[1].slice(0, numberOfDecimals)
        const newRest = (rest) ? cutTrailingZeros(rest) : ''
        const newValue = (newRest) ? `${base}.${newRest}` : base
        return newValue
    }

    return ''
}

export function roundProduct(value: number): number {
    const productRounded: number = Math.round(value * 1000) / 1000
    return productRounded
}

export function toggleMMOddsItem(token: string, options: MarketOdds[], margin: number, marginParameter: number, oddsMin: number, oddsMax: number, item: MarketOdds, optionsLength: number, sportId: SportId, scopeId: ScopeId, noRequest?: boolean): MarketMaker {
    const { bettypegroupid, bettypeid, matchid, probability, oddsvalue, outcomeid, value } = item
    const newMarketMaker: MarketMaker = store.get(main => main.marketmaker)
    const matchData = newMarketMaker[matchid]
    const isCS = bettypegroupid === BetTypeGroupId.CorrectScore
    if (isCS) {
        const anyOthers: number  = 3
        if (scopeId === ScopeId.FT) {
            if (sportId === SportId.Soccer) {
                optionsLength = (Assets.betrixSoccerMaxGoals + 1) * (Assets.betrixSoccerMaxGoals + 1) + anyOthers
            } else if (sportId === SportId.Hockey) {
                optionsLength = (Assets.betrixHockeyMaxGoals + 1) * (Assets.betrixHockeyMaxGoals + 1) + anyOthers
            } else {
                optionsLength = 30
            }
        } else {
            if (sportId === SportId.Soccer) {
                optionsLength = (Assets.betrixHalfSoccerMaxGoals + 1) * (Assets.betrixHalfSoccerMaxGoals + 1) + anyOthers
            } else if (sportId === SportId.Hockey) {
                optionsLength = (Assets.betrixPeriodHockeyMaxGoals + 1) * (Assets.betrixPeriodHockeyMaxGoals + 1) + anyOthers
            } else {
                optionsLength = 30
            }
        }
    }
    const typeProperty = (isCS) ? BetTypeGroupId[bettypegroupid] : BetTypeId[bettypeid]
    const selectionsBetType = (isCS) ? bettypeid : undefined
    if (matchData) {
        const betTypes: string[] = Object.keys(matchData)
        const betTypeFound = betTypes.find(b => b === typeProperty)
        if (betTypeFound) {
            const betType: MarketMakerData = matchData[typeProperty]
            const betTypeSelections: MarketMakerSelection[] = betType.selections
            const selectionFound = betTypeSelections.find(b => (b.betTypeId) ? b.betTypeId === bettypeid : b.outcomeId === outcomeid)
            if (selectionFound) {
                if (selectionFound.insured) {
                    const newSelections: MarketMakerSelection[] = betTypeSelections.filter(b => (b.betTypeId) ? b.betTypeId !== bettypeid : b.outcomeId !== outcomeid)
                    if (newSelections.length) {
                        betType.selections = newSelections
                        if (betType.selections.length === 1) {
                            betType.probability = betType.selections[0].probability
                            betType.odds = betType.selections[0].oddsValue
                        } else {
                            if (!noRequest) {
                                makeMarketMakerRequest(token, newSelections, matchid, typeProperty, margin, marginParameter, oddsMin, oddsMax, options)
                            }
                        }
                    } else {
                        delete matchData[typeProperty]
                        if (!Object.keys(matchData).length) {
                            delete newMarketMaker[matchid]
                        }
                    }
                } else {
                    const selectionsCount: number = getMarketMakerSelections(betTypeSelections).length
                    if (selectionsCount === 1) {
                        delete matchData[typeProperty]
                        if (!Object.keys(matchData).length) {
                            delete newMarketMaker[matchid]
                        }
                    } else {
                        if (selectionsCount > 1) {
                            const newSelections = betType.selections.filter(b => (b.betTypeId) ? b.betTypeId !== bettypeid : b.outcomeId !== outcomeid)
                            newSelections.push({
                                insured: true,
                                oddsValue: oddsvalue,
                                probability: parseFloat(probability),
                                outcomeId: outcomeid,
                                value,
                                betTypeId: selectionsBetType
                            })
                            betType.selections = newSelections
                            if (!noRequest) {
                                makeMarketMakerRequest(token, newSelections, matchid, typeProperty, margin, marginParameter, oddsMin, oddsMax, options)
                            }
                        }
                    }
                }
            } else {
                if (betTypeSelections.length < (optionsLength - 1)) {
                    if (!betTypeSelections.length) {
                        betType.probability = parseFloat(probability)
                        betType.odds = oddsvalue
                    }
                    betTypeSelections.push({
                        insured: false,
                        oddsValue: oddsvalue,
                        outcomeId: outcomeid,
                        probability: parseFloat(probability),
                        value,
                        betTypeId: selectionsBetType
                    })
                    if (!noRequest) {
                        makeMarketMakerRequest(token, betTypeSelections, matchid, typeProperty, margin, marginParameter, oddsMin, oddsMax, options)
                    }
                } else {
                    matchData[typeProperty] = {
                        odds: oddsvalue,
                        probability: parseFloat(probability),
                        invOdds: '1',
                        invProbability: 0,
                        selections: [{
                            insured: false,
                            oddsValue: oddsvalue,
                            outcomeId: outcomeid,
                            probability: parseFloat(probability),
                            value,
                            betTypeId: selectionsBetType
                        }]
                    }
                }
            }
        } else {
            matchData[typeProperty] = {
                odds: oddsvalue,
                probability: parseFloat(probability),
                invOdds: '1',
                invProbability: 0,
                selections: [{
                    insured: false,
                    oddsValue: oddsvalue,
                    outcomeId: outcomeid,
                    probability: parseFloat(probability),
                    value,
                    betTypeId: selectionsBetType
                }]
            }
        }
    } else {
        newMarketMaker[matchid] = {}
        newMarketMaker[matchid][typeProperty] = {
            odds: oddsvalue,
            probability: parseFloat(probability),
            invOdds: '1',
            invProbability: 0,
            selections: [{
                insured: false,
                oddsValue: oddsvalue,
                outcomeId: outcomeid,
                probability: parseFloat(probability),
                value,
                betTypeId: selectionsBetType
            }]
        }
    }
    store.update(main => {
        main.marketmaker = newMarketMaker
        return main
    })

    return newMarketMaker
}

export function saveBSMultiSystemsItems(betrixItems: Array<BSBetrixItem>, standardItems: Array<BSStandardItem>): Array<BSMultiSystemsItem> {
    const BSBetrixItems = getBSMultiSystemsBetrixItems(betrixItems)
    const BSStandardItems = getBSMultiSystemsStandardItems(standardItems)

    const BSMultiSystemsItems = [...BSBetrixItems, ...BSStandardItems]

    store.update(main => {
        main.betslip.betrixCombo.multiSystems = BSMultiSystemsItems
        return main
    })

    return BSMultiSystemsItems
}

export function checkMMSelectionsEqual(old: Array<MarketMakerSelection>, current: Array<MarketMakerSelection>): boolean {
    if (old.length === current.length) {
        for (const item of current) {
            const selectionFound = old.find(o => o.outcomeId === item.outcomeId && o.insured === item.insured)
            if (!selectionFound) {
                return false
            }
        }

        return true
    }

    return false
}

export function saveStandardBSItem(scopeId: ScopeId, awayTeam: string, homeTeam: string, item: MarketOdds, sportId: SportId, MMSelections?: MarketMakerSelection[], MMBetTypeId?: BetTypeId): void {
    const { bettypegroupid, bettypeid, matchid, name, oddsvalue, outcomeid, value } = item
    const betslip: Betslip = store.get(main => main.betslip)
    const betslipIndex: number = betslip.index
    const index: number = betslipIndex + 1
    const currentBetslip: BSStandardItem[] = betslip.standard
    const newItem: BSStandardItem = {
        awayTeam,
        betTypeGroupId: bettypegroupid,
        betTypeId: bettypeid,
        homeTeam,
        index,
        matchId: matchid,
        outcomeName: name,
        oddsValue: oddsvalue,
        outcomeId: outcomeid,
        sportId,
        outcomeValue: value,
        MMBetTypeId,
        MMSelections,
        scopeId
    }

    const isCorrectScore = bettypegroupid === BetTypeGroupId.CorrectScore
    const betTypeIdToCompare = (MMBetTypeId) ? MMBetTypeId : bettypeid
    const matchFound = currentBetslip.find(b => b.matchId === matchid && (isCorrectScore ? (bettypegroupid === b.betTypeGroupId) : ((b.MMBetTypeId && b.MMBetTypeId === betTypeIdToCompare) || (b.betTypeId === betTypeIdToCompare))))
    if (matchFound) {
        matchFound.betTypeId = bettypeid
        matchFound.oddsValue = oddsvalue
        matchFound.outcomeId = outcomeid
        matchFound.MMBetTypeId = MMBetTypeId
        matchFound.MMSelections = MMSelections
    } else { // match is not found, the item is just pushed
        currentBetslip.push(newItem)
    }

    store.update(main => {
        main.betslip.standard = currentBetslip
        main.betslip.index = index
        return main
    })
}

export function setPopupContent(message: string | JSX.Element): void {
    store.update(main => {
        main.popup.isOpened = true
        main.popup.content = message
        return main
    })
}

export function sortByProperty(array: any[], property: string) {
    return array.sort((a, b) => sortBySize(a[property], b[property]))
}

export function sortBySize(a: any, b: any) {
    if (a < b) {
        return -1
    }
    if (a > b) {
        return 1
    }

    return 0
}

export function clearMMOddsItem(bettypegroupid: number, matchId: string, typeProperty: string, bettypeid?: number): void {
    const newMarketMaker: MarketMaker = store.get(main => main.marketmaker)
    if (bettypeid === undefined) {
        for (const item of Object.keys(newMarketMaker[matchId])) {
            if (item.startsWith(typeProperty)) {
                delete newMarketMaker[matchId][item]
            }
        }
    } else {
        delete newMarketMaker[matchId][typeProperty]
    }
    if (!Object.keys(newMarketMaker[matchId]).length) {
        delete newMarketMaker[matchId]
    }
    const psbodds: Array<{b: number, i: number, o: Array<{o: number, i: number, s: number/*, p?: number*/}>/*, v?: string, skip?: boolean*/}> = store.get(main => main.iframeresponse.psbodds)
    // REMOVE FROM LIST
    if (bettypegroupid === BetTypeGroupId.CorrectScore) {
        for (let i: number = 0; i < psbodds.length; i++) {
            if (psbodds[i].i === bettypegroupid) {
                psbodds.splice(i, 1)
                break
            }
        }
    } else {
        for (let i: number = 0; i < psbodds.length; i++) {
            if (psbodds[i].b === bettypeid && psbodds[i].i === bettypegroupid) {
                psbodds.splice(i, 1)
                break
            }
        }
    }

    const orOddsInsured: Array<{bettypegroupid: number, bettypeid: number, value: number}> = store.get(main => main.iframeresponse.orOddsInsured)
    for (let i: number = 0; i < orOddsInsured.length; i++) {
        if (bettypegroupid === BetTypeGroupId.CorrectScore || bettypegroupid === BetTypeGroupId.EuropeanHandicap) {
            if (orOddsInsured[i].bettypegroupid === bettypegroupid) {
                orOddsInsured.splice(i, 1)
                break
            }
        } else {
            if (orOddsInsured[i].bettypegroupid === bettypegroupid && orOddsInsured[i].bettypeid === bettypeid) {
                orOddsInsured.splice(i, 1)
                break
            }
        }
    }
    store.update(main => {
        main.iframeresponse.psbodds = psbodds
        main.iframeresponse.orOddsInsured = orOddsInsured
        main.marketmaker = newMarketMaker
        return main
    })
}
export function getNameFromMarketGroup(marketName: string, homeTeam: string, awayTeam: string, platform: Platform) {
    if (platform === 'mobile') {
        const words: string[] = marketName.split(' ')
        return words[0]
    }
    const marketNameFromGroup = marketName.replace('{home}', homeTeam).replace('{away}', awayTeam)
    return marketNameFromGroup
}

export function GetNameFromMarketGroup(marketName: string, homeTeam: string, awayTeam: string) {
    const marketNameFromGroup = marketName.replace('{home}', homeTeam).replace('{away}', awayTeam)
    return marketNameFromGroup
}

export const getTeamNameFromBetType = (awayTeam: string, homeTeam: string, betTypeId: BetTypeId): string => {
    if (betTypeId === BetTypeId.TNGHome || betTypeId === BetTypeId.TENGHome || betTypeId === BetTypeId.THSHHome || betTypeId === BetTypeId.THSH2Home) {
        return homeTeam
    }

    if (betTypeId === BetTypeId.TNGAway || betTypeId === BetTypeId.TENGAway || betTypeId === BetTypeId.THSHAway || betTypeId === BetTypeId.THSH2Away) {
        return awayTeam
    }

    return ''
}

export function changeBetslipMode(mode: BetslipMode): void {
    store.update(main => {
        main.betslip.mode = mode
        return main
    })
}

export function changeBetslipSubmode(submode: BetslipSubmode): void {
    store.update(main => {
        main.betslip.submode = submode
        return main
    })
}

export function disableBSSystemSingleItem(currentItems: Array<BSSystemSingleItem>, matchId: string): Array<BSSystemSingleItem> {
    const newItems = currentItems
    const itemFound = newItems.find(n => n.matchId === matchId)

    if (itemFound) {
        if (itemFound.disabled) {
            itemFound.disabled = false
        } else if (!itemFound.disabled && newItems.filter(n => !n.disabled).length > 1) {
            itemFound.disabled = true
        }
        store.update(main => {
            main.betslip.betrixCombo.systemSingle = newItems
            return main
        })
    }

    return newItems
}

export function createMultiSystemsPayout(data: Array<MultiSystemsData>): Array<MatrixValue> {
    const multiSystemsPayout: Array<MatrixValue> = []

    for (let i = 0; i <= data.length; i++) {
        const newPayout = (i === data.length) ? MatrixValue.Selected : MatrixValue.NotSelected
        multiSystemsPayout.push(newPayout)
    }
    
    return multiSystemsPayout
}

function getBSMultiSystemsStandardItems(betslipItems: Array<BSStandardItem>): Array<BSMultiSystemsItem> {
    const allOdds: Array<Odds> = store.get(main => main.feed.odds)
    const newItems: Array<BSMultiSystemsItem> = []

    for (const item of betslipItems) {
        const matchOddsFound = allOdds.find(a => a.matchId === item.matchId)
        if (matchOddsFound) {
            const betTypeIdToCompare = (item.MMBetTypeId) ? item.MMBetTypeId : item.betTypeId
            const betTypeOdds = matchOddsFound.markets[BetTypeGroupId[item.betTypeGroupId]].filter(i => i.bettypeid === betTypeIdToCompare)
            const selection = getMSSelectionStandard(betTypeOdds, item.outcomeId, item.MMSelections)
            const odds = getMSInputDataOdds(betTypeOdds)
            
            const newItem = createNewMultiSystemsItem(item.betTypeGroupId, item.matchId, odds, selection, item.outcomeId, item.betTypeId, item.MMBetTypeId)
            newItems.push(newItem)
        }
    }

    return newItems
}

export function getMSSelectionStandard(odds: Array<MarketOdds>, outcomeId: OutcomeId, MMSelections: Array<MarketMakerSelection> | undefined): Array<number> {    
    if (MMSelections) {
        const selection: Array<number> = []
        for (const item of MMSelections) {
            const outcomeFound = odds.find(o => o.outcomeid === item.outcomeId)
            if (outcomeFound) {
                const index = odds.indexOf(outcomeFound)
                selection.push(index)
            }
        }
        return selection
    } else {
        const outcomeFound = odds.find(o => o.outcomeid === outcomeId)
        if (outcomeFound) {
            const index = odds.indexOf(outcomeFound)
            return [index]
        }
        return  []
    }
}

export function getMSInputDataOdds(odds: Array<MarketOdds>): Array<MultiSystemsInputDataOdds> {
    return odds.map(item => {
        return {
            name: item.outcomename,
            odds: +item.oddsvalue,
            outcome: item.outcomeid,
            probability: +item.probability
        }
    })
}

function convertBetrixOutcome(outcomeId: OutcomeId): OutcomeId {
    if (outcomeId === OutcomeId.BetrixYes) {
        return OutcomeId.HomeWin
    } else if (outcomeId === OutcomeId.BetrixNo) {
        return OutcomeId.AwayWin
    } else {
        return OutcomeId.Draw
    }
}

function getMSSelectionBetrix(odds: Array<MultiSystemsInputDataOdds>, outcomeId: OutcomeId): Array<number> {
    if (outcomeId === OutcomeId.BetrixYes || outcomeId === OutcomeId.BetrixNo || outcomeId === OutcomeId.BetrixOut) {
        const outcomeFound = odds.find(o => o.outcome === convertBetrixOutcome(outcomeId))
        if (outcomeFound) {
            const index = odds.indexOf(outcomeFound)
            return [index]
        }
        return []
    } else if (outcomeId === OutcomeId.BetrixYesNo) {
        const selection: Array<number> = []
        const firstFound = odds.find(o => o.outcome === convertBetrixOutcome(OutcomeId.BetrixYes))
        if (firstFound) {
            const index = odds.indexOf(firstFound)
            selection.push(index)
        }
        const secondFound = odds.find(o => o.outcome === convertBetrixOutcome(OutcomeId.BetrixNo))
        if (secondFound) {
            const index = odds.indexOf(secondFound)
            selection.push(index)
        }
        return selection
    } else if (outcomeId === OutcomeId.BetrixYesOut) {
        const selection: Array<number> = []
        const firstFound = odds.find(o => o.outcome === convertBetrixOutcome(OutcomeId.BetrixYes))
        if (firstFound) {
            const index = odds.indexOf(firstFound)
            selection.push(index)
        }
        const secondFound = odds.find(o => o.outcome === convertBetrixOutcome(OutcomeId.BetrixOut))
        if (secondFound) {
            const index = odds.indexOf(secondFound)
            selection.push(index)
        }
        return selection
    } else {
        const selection: Array<number> = []
        const firstFound = odds.find(o => o.outcome === convertBetrixOutcome(OutcomeId.BetrixNo))
        if (firstFound) {
            const index = odds.indexOf(firstFound)
            selection.push(index)
        }
        const secondFound = odds.find(o => o.outcome === convertBetrixOutcome(OutcomeId.BetrixOut))
        if (secondFound) {
            const index = odds.indexOf(secondFound)
            selection.push(index)
        }
        return selection
    }
}

export function setBSSystemSingleBetweenOption(SSBetweenOption: {name: string, odds: number} | undefined): void {
    const currentOption: {name: string, odds: number} | undefined = store.get(main => main.betslip.betrixCombo.SSBetweenOption)
    let newOption: {name: string, odds: number} | undefined = SSBetweenOption

    if (currentOption && SSBetweenOption && currentOption.name === SSBetweenOption.name && currentOption.odds === SSBetweenOption.odds) {
        newOption = undefined
    }

    store.update(main => {
        main.betslip.betrixCombo.SSBetweenOption = newOption
        return main
    })
}

export function setBSSystemSingleRange(range: Array<MatrixValue>) {
    store.update(main => {
        main.betslip.betrixCombo.SSRange = range
        return main
    })
}

export function createBSSystemSingleRange(length: number): Array<MatrixValue> {
    const SSRange: Array<MatrixValue> = new Array(length + 1)

    for (let i = 0; i <= length; i++) {
        SSRange[i] = MatrixValue.NotSelected
    }

    return SSRange
}

export function setBSSystemSingleMarket(newMarket: SSMarketOptions): void {
    store.update(main => {
        main.betslip.betrixCombo.SSBetweenOption = undefined
        main.betslip.betrixCombo.SSMarket = newMarket
        main.betslip.betrixCombo.SSRange = createBSSystemSingleRange(main.betslip.betrixCombo.systemSingle.length)
        return main
    })
}

export function setBSSystemSingleRangeValue(index: number): Array<MatrixValue> {
    const systemSingle: Array<BSSystemSingleItem> = store.get(main => main.betslip.betrixCombo.systemSingle)
    const currentRange: Array<MatrixValue> = store.get(main => main.betslip.betrixCombo.SSRange)
    const SSRangeCount = getBSSystemSingleRangeCount(currentRange)

    if (currentRange[index] === MatrixValue.Selected && SSRangeCount === 1) {
        const newRange = createBSSystemSingleRange(systemSingle.length)
        setBSSystemSingleRange(newRange)
        
        return newRange
    }

    if (currentRange[index] === MatrixValue.NotSelected && SSRangeCount === (currentRange.length - 1)) {
        return []
    }

    currentRange[index] = (currentRange[index] === MatrixValue.Selected) ? MatrixValue.Insured : (currentRange[index] === MatrixValue.Insured) ? MatrixValue.NotSelected : MatrixValue.Selected
    setBSSystemSingleRange(currentRange)

    return currentRange
}

export function getBSSystemSingleRangeCount(SSRange: Array<MatrixValue>): number {
    const SSRangeSelected = SSRange.filter(s => s === MatrixValue.Selected)
    const SSRangeCount = SSRangeSelected.length
    
    return SSRangeCount
}

export function setCharAt(text: string, index: number, character: string) {
    if (index > (text.length - 1)) {
        return text
    }
    
    return text.substr(0, index) + character + text.substr(index + 1);
}

export function makeSystemSingleRequest(market: SSMarketOptions, selection: Array<MatrixValue>, matchIds: Array<string>, forBetslip?: boolean) {
    const betTypeGroupId: BetTypeGroupId = (market === SSMarketOptions.Goals) ? BetTypeGroupId.OverUnder : BetTypeGroupId.ThreeWay
    const betTypeId: BetTypeId = (market === SSMarketOptions.Goals) ? BetTypeId.OverUnder25 : BetTypeId.ThreeWay
    const odds: Array<Array<number>> = getSystemSingleOdds(matchIds, betTypeGroupId, betTypeId)

    const data: SystemSingleInput = {
        betId: Assets.SystemSingleMarkets[market].inputBetId as SystemSingleBetId,
        odds,
        selection,
        type: Assets.SystemSingleMarkets[market].inputType as SystemSingleType
    }

    if (forBetslip) {
        dataServer.request('post', 'insert-system-single-betslip', undefined, data)
    } else {
        dataServer.request('post', 'insert-system-single', undefined, data, (response) => {
            if (typeof response !== 'string') {
                store.update(main => {
                    main.betrixCombo.systemSingle[SSMarketOptions[market]] = response
                    return main
                })
            }
        })
    }
}

export function getSystemSingleOdds(matchIds: string[], betTypeGroupId: BetTypeGroupId, betTypeId: BetTypeId): Array<Array<number>> {
    const allOdds: Array<Odds> = store.get(main => main.feed.odds)
    const systemSingleOdds: Array<Array<number>> = []

    for (const id of matchIds) {
        const matchOddsFound = allOdds.find(a => a.matchId === id)
        if (matchOddsFound) {
            const marketOdds = matchOddsFound.markets[BetTypeGroupId[betTypeGroupId]].filter(m => m.bettypeid === betTypeId)
            const odds = marketOdds.map(m => +m.oddsvalue)
            systemSingleOdds.push(odds)
        }
    }

    return systemSingleOdds
}

export function getAllowedBetrixComboItems(betslipItems: Array<BSStandardItem>, settings: Array<BetrixComboSettingsItem>): Array<BSStandardItem> {
    const allowedItems: Array<BSStandardItem> = []

    for (const item of betslipItems) {
        if (settings.find(s => s.bettypegroup === item.betTypeGroupId) &&
            (!item.MMSelections || item.MMSelections && !item.MMSelections.map(m => m.insured).filter(Boolean).length)) {
                allowedItems.push(item)
        }
    }
    
    return allowedItems
}

export function getBetIdsForMultiSystems(markets: Array<BetrixComboSettingsItem>): Array<BetTypeId> {
    const betIds: Array<BetTypeId> = []

    for (const market of markets) {
        if (market.bettypes) {
            for (const betType of market.bettypes) {
                betIds.push(betType.id)
            }
        }
    }

    return betIds
}

export function removeSpacesFromString(value: string): string {
    const withoutSpaces = value.replace(/ /g, '');
    return withoutSpaces;
}

export const getTeamNameFromOutcome = (awayTeam: string, homeTeam: string, outcomeId: OutcomeId): string => {
    if (outcomeId === OutcomeId.OverHome || outcomeId === OutcomeId.UnderHome) {
        return homeTeam
    }

    if (outcomeId === OutcomeId.OverAway || outcomeId === OutcomeId.UnderAway) {
        return awayTeam
    }

    return ''
}

export function clearQuickBetrixComboSelections(): void {
    store.update(main => {
        main.betrixCombo.disabledMatches = []
        main.betrixCombo.MSPayout = []
        main.betrixCombo.MSSelections = []
        main.betrixCombo.SSRange = {}
        return main
    })
}

export function getSystemSingleBetweenOptions(odds: Array<Array<number>>, selectedValue: number): Array<{ name: string, odds: number }> {
    const systemSingleOtherOptions: Array<{ name: string, odds: number }> = []

    for (let i = 0; i < odds.length; i++) {
        for (let j = 0; j < odds.length; j++) {
            const oddsItem = odds[i][j]
            if (oddsItem && oddsItem > 1 && (i === selectedValue || j === selectedValue)) {
                const name: string = `${i} - ${j}`
                const value: number = oddsItem
                systemSingleOtherOptions.push({ name, odds: value })
            }
        }
    }

    const valueBefore = selectedValue - 1
    const valueAfter = selectedValue + 1
    if (valueBefore >= 0 && valueAfter < odds.length && odds[valueBefore][valueAfter] && odds[valueBefore][valueAfter] > 1) {
        const name: string = `${valueBefore} - ${valueAfter}`
        const value: number = odds[valueBefore][valueAfter]
        systemSingleOtherOptions.push({ name, odds: value })
    }

    return systemSingleOtherOptions
}

export function getBetrixOddsData(backOdds: string, backProbability: number, layOdds: string, layProbability: number, anyOtherOdds: string, anyOtherProbability: number) {
    const backData = { odds: backOdds, probability: backProbability }
    const layData = { odds: layOdds, probability: layProbability }
    const anyOtherData = { odds: anyOtherOdds, probability: anyOtherProbability }

    return { backData, layData, anyOtherData }
}

export function checkMMItemOnBetslip(matchId: string, selections: Array<MarketMakerSelection>, MMBetTypeId: BetTypeId): boolean {
    const betslip: BSStandardItem[] = store.get(main => main.betslip.standard)

    const matchFound = betslip.find(b => b.matchId === matchId && ((b.MMBetTypeId && b.MMBetTypeId === MMBetTypeId) || (b.betTypeId === MMBetTypeId)))
    if (matchFound) {
        if (matchFound.MMSelections) {
            const selectionsEqual = checkMMSelectionsEqual(matchFound.MMSelections, selections)
            return selectionsEqual
        }
        if (selections.length === 1 && selections[0].outcomeId === matchFound.outcomeId) {
            return true
        }
    }

    return false
}

export function getMarketMakerSelections(selections: MarketMakerSelection[]): MarketMakerSelection[] {
    const MMSelections = selections.filter(s => !s.insured)
    return MMSelections
}

export function getBetrixMaxValues(defaultHome: number, defaultAway: number, maxHome: number, maxAway: number) {
    const maxValueHome = (defaultHome > maxHome) ? defaultHome : maxHome
    const maxValueAway = (defaultAway > maxAway) ? defaultAway : maxAway

    return { maxValueHome, maxValueAway }
}

export function isDoublette(doublettes: Doublette[], oddsItem: {betTypeGroupId: BetTypeGroupId, betTypeId: BetTypeId, outcomeId: OutcomeId}, allOdds: {betTypeGroupId: BetTypeGroupId, betTypeId: BetTypeId, outcomeId: OutcomeId}[]) {
    for (const item of doublettes) {
        const { betTypeGroupId, betTypeId, outcomeId } = oddsItem
        const importantFound = allOdds.filter(a => a.betTypeGroupId !== betTypeGroupId && a.betTypeId !== betTypeId && a.outcomeId !== outcomeId).find(a => a.betTypeGroupId === item[`bettypegroupid${item.important1}`] && a.betTypeId === item[`bettypeid${item.important1}`] && a.outcomeId === item[`outcomeid${item.important1}`])
        if (importantFound) {
            for (let i = 1; i <= 9; i++) {
                if (betTypeGroupId === item[`bettypegroupid${i}`] && betTypeId === item[`bettypeid${i}`] && outcomeId === item[`outcomeid${i}`] && i !== item.important1) {
                    return true
                }
            }
        }
    }

    return false
}

export function isBetrixQuickButtonActive(selectedLines: { [quickButton: string]: { [additionalButton: string]: MatrixValue } }, quickButton: QuickButton, additionalButtonSelected: AdditionalButton) {
    const QuickButtons: string[] = Object.keys(QuickButton)
    const AdditionalButtons: string[] = Object.keys(AdditionalButton)

    if (quickButton !== -1 && additionalButtonSelected !== -1) {
        const keys = Object.keys(selectedLines[QuickButtons[QuickButtons.length / 2 + quickButton]][AdditionalButtons[AdditionalButtons.length / 2 + additionalButtonSelected]])

        for (const item of keys) {
            if (selectedLines[QuickButtons[QuickButtons.length / 2 + quickButton]][AdditionalButtons[AdditionalButtons.length / 2 + additionalButtonSelected]][item]) {
                return true
            }
        }

        return false
    }

    return false
}