import { BetrixCombo } from './models';

const betrixCombo: BetrixCombo = {
    custom: [],
    disabledMatches: [],
    MSPayout: [],
    MSSelections: [],
    multiSystems: [],
    settings: {
        betslip: [],
        quick: []
    },
    SSRange: {},
    systemSingle: {}
}

export default betrixCombo