import React from 'react'
import MarketOptions from './market-options'
import Match from './match'
import { addOddsToMatches, getDateString } from '../../../utils'
import { Match as MatchModel, Odds } from '../../../state/stores/feed/models'
import { SportId, ScopeId } from '../../../state/enums'
import './style.scss'

interface Props {
    date: string
    markets: string[]
    matches: MatchModel[]
    odds: Odds[]
    region: string
    sportId: SportId
    scopeId: ScopeId
}

const DateMatches = ({ scopeId, date, markets, matches, odds, region, sportId }: Props) => {
    const convertedMatchDate: string = getDateString(date)
    const matchesWithOdds: MatchModel[] = addOddsToMatches(matches, odds)

    return (
        <div className="date-wrapper">
            <div className="grid-layout date-top-row">
                <div className="match-column date-title">
                    <span>{convertedMatchDate}</span>
                </div>
                <MarketOptions markets={markets} sportId={sportId}/>
            </div>
            {matchesWithOdds.map(match =>
                match.odds &&
                    <Match
                        key={match.id}
                        match={match}
                        odds={match.odds}
                        markets={markets}
                        region={region}
                        sportId={sportId}
                        scopeId={scopeId}
                    />
            )}
        </div>
    )
}

export default DateMatches