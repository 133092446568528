import React from 'react'
import FaIcon from '../FaIcon'
import Link from '../Link'
import { getSportFromPathname, getLeagueLink, handleQuickLeague } from '../../../utils'
import { League, QuickLeague } from '../../../state/stores/feed/models'
import './style.scss'

interface Props {
    leagues: League[]
    leagueMatchesLength: number[]
    quickLeagues: QuickLeague[]
}

const LeagueList = ({ leagues, leagueMatchesLength, quickLeagues }: Props) => (
    <div className="league-list">
        {leagues.map((league, index) => {
            const { id, name, region,  sportid } = league
            const sport: string = getSportFromPathname()
            const link: string = getLeagueLink(name, region, sport)
            const quickLeagueItem: QuickLeague = { league: name, region, sportId: sportid }
            const quickLeagueFound = quickLeagues.find(q => q.league === quickLeagueItem.league && q.region === quickLeagueItem.region && q.sportId === quickLeagueItem.sportId)
            const selectedClassName: string = (quickLeagueFound) ? 'selected' : ''
            const iconTitle: string = (quickLeagueFound) ? 'Remove from Quick links' : 'Add to Quick links'

            if (leagueMatchesLength[index] === 0) {
                return null
            }

            return (
                <div
                    key={id}
                    className="league"
                >
                    <div className={selectedClassName}>
                        <Link to={link}>{name}</Link>
                        <FaIcon
                            icon="star"
                            type="regular"
                            title={iconTitle}
                            onClick={() => handleQuickLeague(quickLeagueItem)}
                        />
                    </div>
                </div>
            )
        })}
    </div>
)

export default LeagueList