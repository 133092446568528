import React, { useState, useEffect } from 'react'
import dataServer from '../../../network/rest'
import BetrixMatrix from './Matrix'
import BetrixQuickButtons from './QuickButtons'
import BetrixSuggestedMarkets from './SuggestedMarkets'
import BetrixTopRow from './TopRow'
import { BetrixSuggestedMarket as BetrixSuggestedMarketModel, FootballModelSelection, BetrixSelectionOdds, Doublette, FootballModelDirect } from '../../../state/stores/betrix/models'
import { BSBetrixItem, BSBetrixSubitem, Betslip, BSBetrixItemAllOdds } from '../../../state/stores/betslip/models'
import { createEmptyMatrix, getMatrixValues, createFootballModelSelection, checkResetButtonAvailability, checkSuggestedMarketsAvailability, createQuickMatrix, checkMatrixValueSelected/*, checkMatrixValueNotSelected*/, getQuickButtonLines, createNewQuickMatrix } from './functions'
import { getBetrixOddsData, getBetrixMaxValues, removeBSBetrixItem, isMobile, isSportWinner } from '../../../utils'
import { getGoalsText } from '../../../strings'
import { MarketMaker } from '../../../state/stores/marketmaker/models'
import { MatchMarket, MarketOdds} from '../../../state/stores/feed/models'
import { Platform } from '../../../state/singles'
import { SportId, MatrixValue, OutcomeId, BetTypeGroupId, BetTypeId, ScopeId } from '../../../state/enums'
import { store } from '../../../state'
import { UpdateFootballModelRequest } from '../../../network/rest/models'
import { isBetrixFlexible, betrixHockeyMaxGoals, betrixSoccerMaxGoals, betrixPeriodHockeyMaxGoals, betrixHalfSoccerMaxGoals, scopes, betrixSkiJumpingX, betrixSkiJumpingY, betrixCyclingX, betrixCyclingY } from '../../../assets'
import { getBetslipBetrixItemIndex } from '../OddsItem/helpers'
import './style.scss'
import config from '../../../configjson'
import {TrapezoidGeometry, Point} from './Matrix'
import clsx from 'clsx'
export enum QuickButton {
    HomeTeam,
    AwayTeam,
    Totals,
    GoalDifference
}

export enum AdditionalButton {
    Over,
    Under,
    Exact
}

export enum NewQuickButton {
    HomeWin = 1,
    Draw,
    AwayWin,
    Over05,
    Over15,
    Over25,
    Over35,
    Over45
}

export const quickButtons: { name: string, value: QuickButton }[] = [
    { name: 'Home Goals', value: QuickButton.HomeTeam },
    { name: 'Away Goals', value: QuickButton.AwayTeam },
    { name: 'Total Goals', value: QuickButton.Totals },
    { name: 'Goal Difference', value: QuickButton.GoalDifference }
]

export const additionalButtons: { name: string, value: AdditionalButton }[] = [
    { name: 'Over', value: AdditionalButton.Over },
    { name: 'Under', value: AdditionalButton.Under },
    { name: 'Exactly', value: AdditionalButton.Exact }
]

export const newQuickButtons: { name: string, value: NewQuickButton }[][] = [
    [
        { name: '{home} to win', value: NewQuickButton.HomeWin },
        { name: 'Draw', value: NewQuickButton.Draw },
        { name: '{away} to win', value: NewQuickButton.AwayWin },
    ],
    [
        { name: 'Over 0.5', value: NewQuickButton.Over05 },
        { name: 'Over 1.5', value: NewQuickButton.Over15 },
        { name: 'Over 2.5', value: NewQuickButton.Over25 },
        { name: 'Over 3.5', value: NewQuickButton.Over35 },
        { name: 'Over 4.5', value: NewQuickButton.Over45 }
    ]
]

interface Props {
    disableInsurances: boolean
    visible: boolean
    token: string
    awayTeam: string
    doublettes: Doublette[]
    homeTeam: string
    marketMaker: MarketMaker
    matchId: string
    odds: MatchMarket
    odds1: MatchMarket
    odds2: MatchMarket
    odds3: MatchMarket
    platform: Platform
    selectionOdds: BetrixSelectionOdds
    showMarketMaker: boolean | undefined
    showQuickButtons: boolean
    sportId: SportId
    suggestedMarkets: BetrixSuggestedMarketModel[]
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    o0: Array<{name: 'Winner', outcomes: Array<{name: string, value: number, country?: string}>}>
    o01: Array<{name: 'Winner', outcomes: Array<{name: string, value: number, country?: string}>}>
    v2: boolean
    xh?: number
    xa?: number
    xh1?: number
    xa1?: number
    xh2?: number
    xa2?: number
    xh3?: number
    xa3?: number
    hideBetAgainst: boolean
}

interface State {
    betrixToggle: boolean
    selectedNewButton: 0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15
    anyOtherBetTypeId: BetTypeId
    anyOtherBetTypeIdHome: BetTypeId
    anyOtherBetTypeIdDraw: BetTypeId
    anyOtherBetTypeIdAway: BetTypeId
    awayGoals: number
    homeGoals: number
    intersectionOdds: string
    intersectionOdds1: string
    intersectionOdds2: string
    IntersectionOdds: boolean
    quickButtonsMatrix: MatrixValue[][]
    selectedAdditionalButtons: {[quickButton: string]: AdditionalButton}
    selectedItemAnyOtherDraw: MatrixValue
    selectedItemAnyOtherHome: MatrixValue
    selectedItemAnyOtherAway: MatrixValue
    selectedItems: MatrixValue[][]
    selectedLines: {[quickButton: string]: {[additionalButton: string]: MatrixValue}}
    suggestedMarketsOpened: boolean
    touches: Touch[]
    scopeId: ScopeId
}

const initialStateSoccer: State = {
    betrixToggle: true,
    selectedNewButton: 0,
    anyOtherBetTypeId: 0,
    anyOtherBetTypeIdHome: 0,
    anyOtherBetTypeIdDraw: 0,
    anyOtherBetTypeIdAway: 0,
    awayGoals: betrixSoccerMaxGoals,
    homeGoals: betrixSoccerMaxGoals,
    intersectionOdds: '',
    intersectionOdds1: '',
    intersectionOdds2: '',
    IntersectionOdds: false,
    quickButtonsMatrix: [],
    selectedAdditionalButtons: {},
    selectedItemAnyOtherDraw: MatrixValue.NotSelected,
    selectedItemAnyOtherHome: MatrixValue.NotSelected,
    selectedItemAnyOtherAway: MatrixValue.NotSelected,
    selectedItems: [],
    selectedLines: {},
    suggestedMarketsOpened: false,
    touches: [],
    scopeId: ScopeId.FT
}

const initialStateHockey: State = {
    betrixToggle: true,
    selectedNewButton: 0,
    anyOtherBetTypeId: 0,
    anyOtherBetTypeIdHome: 0,
    anyOtherBetTypeIdDraw: 0,
    anyOtherBetTypeIdAway: 0,
    awayGoals: betrixHockeyMaxGoals,
    homeGoals: betrixHockeyMaxGoals,
    intersectionOdds: '',
    intersectionOdds1: '',
    intersectionOdds2: '',
    IntersectionOdds: false,
    quickButtonsMatrix: [],
    selectedAdditionalButtons: {},
    selectedItemAnyOtherDraw: MatrixValue.NotSelected,
    selectedItemAnyOtherHome: MatrixValue.NotSelected,
    selectedItemAnyOtherAway: MatrixValue.NotSelected,
    selectedItems: [],
    selectedLines: {},
    suggestedMarketsOpened: false,
    touches: [],
    scopeId: ScopeId.FT
}

const initialStateSkiJumping: State = {
    betrixToggle: true,
    selectedNewButton: 0,
    anyOtherBetTypeId: -31,
    anyOtherBetTypeIdHome: -32,
    anyOtherBetTypeIdDraw: -33,
    anyOtherBetTypeIdAway: -34,
    awayGoals: betrixSkiJumpingX,
    homeGoals: betrixSkiJumpingY,
    intersectionOdds: '',
    intersectionOdds1: '',
    intersectionOdds2: '',
    IntersectionOdds: false,
    quickButtonsMatrix: [],
    selectedAdditionalButtons: {},
    selectedItemAnyOtherDraw: MatrixValue.NotSelected,
    selectedItemAnyOtherHome: MatrixValue.NotSelected,
    selectedItemAnyOtherAway: MatrixValue.NotSelected,
    selectedItems: [],
    selectedLines: {},
    suggestedMarketsOpened: false,
    touches: [],
    scopeId: ScopeId.FT
}

const initialStateCycling: State = {
    betrixToggle: true,
    selectedNewButton: 0,
    anyOtherBetTypeId: -31,
    anyOtherBetTypeIdHome: -32,
    anyOtherBetTypeIdDraw: -33,
    anyOtherBetTypeIdAway: -34,
    awayGoals: betrixCyclingX,
    homeGoals: betrixCyclingY,
    intersectionOdds: '',
    intersectionOdds1: '',
    intersectionOdds2: '',
    IntersectionOdds: false,
    quickButtonsMatrix: [],
    selectedAdditionalButtons: {},
    selectedItemAnyOtherDraw: MatrixValue.NotSelected,
    selectedItemAnyOtherHome: MatrixValue.NotSelected,
    selectedItemAnyOtherAway: MatrixValue.NotSelected,
    selectedItems: [],
    selectedLines: {},
    suggestedMarketsOpened: false,
    touches: [],
    scopeId: ScopeId.FT
}

function getTrapezoidGeometry(homeGoals: number, awayGoals: number, h: number, b: number, t: number): TrapezoidGeometry {
    const _: TrapezoidGeometry = {}
    _.width = b
    _.height = h
    _.c = {top: 100, left: 0}
    _.d = {top: 100, left: 100}
    _.homeGoals = homeGoals + 1
    _.awayGoals = awayGoals + 1

    const afLeft: number = ((b - t) / 2)/b * 100
    const gjLeft: number = 100 - afLeft
    _.f = {top: 0, left: afLeft}
    _.g = {top: 0, left: gjLeft}
    _.B = new Array(_.homeGoals)
    _.T = new Array(_.homeGoals)
    _.T[0] = t / _.awayGoals
    _.B[_.B.length - 1] = b / _.awayGoals
    const TB: number =  (b - t) / _.awayGoals / _.awayGoals
    for (let n: number = 1; n < _.T.length; n++) {
        _.T[n] = _.T[0] + TB * n 
    }
    _.B[0] = _.T[1]

    for (let n: number = 1; n < _.T.length - 1; n++) {
        _.B[n] = _.B[0] + TB * n 
    }

    _.W = _.T[0] / _.B[_.B.length - 1]
    _.H = []
    _.H.push(_.W)
    const stepH = (1 - _.H[0]) / _.homeGoals

    let sumH: number = _.W
    for (let n: number = 1; n < _.homeGoals; n++) {
        _.H.push(_.W + n * stepH)
        sumH += _.H[n]
    }
    for (let n: number = 0; n < _.homeGoals; n++) {
        _.H[n] = _.H[n] / sumH * h
    }
    _.eb = new Array(_.awayGoals + 1)
    _.eb[0] = _.c
    _.eb[_.eb.length - 1] = _.d
    const ebStepTop: number = (_.d.top - _.c.top) / (_.awayGoals) 
    const ebStepLeft: number = (_.d.left - _.c.left) / (_.awayGoals) 

    _.et = new Array(_.awayGoals + 1)
    _.et[0] = _.f
    _.et[_.et.length - 1] = _.g
    const etStepTop: number = (_.g.top - _.f.top) / (_.awayGoals) 
    const etStepLeft: number = (_.g.left - _.f.left) / (_.awayGoals) 
    for (let n: number = 1; n <= _.awayGoals; n++) {
        _.eb[n] = {top: _.eb[0].top + n * ebStepTop, left: _.eb[0].left + n * ebStepLeft} 
        _.et[n] = {top: _.et[0].top + n * etStepTop, left: _.et[0].left + n * etStepLeft} 
    }

    _.el = new Array(_.homeGoals + 1)
    _.el[0] = _.f
    _.el[_.el.length - 1] = _.c
    const elStepLeft: number = (_.c.left - _.f.left) / (_.homeGoals) 

    _.er = new Array(_.homeGoals + 1)
    _.er[0] = _.g
    _.er[_.er.length - 1] = _.d
    const erStepLeft: number = (_.d.left - _.g.left) / (_.homeGoals) 
    for (let n: number = 1; n <= _.homeGoals; n++) {
        _.el[n] = {top: _.el[n-1].top + _.H[n-1]/h * 100, left: _.el[0].left + n * elStepLeft} 
        _.er[n] = {top: _.er[n-1].top + _.H[n-1]/h * 100, left: _.er[0].left + n * erStepLeft} 
    }

    _.C = new Array(_.homeGoals)
    _.A = new Array(_.homeGoals)
    _.D = new Array(_.homeGoals)
    _.F = new Array(_.homeGoals)
    _.J = new Array(_.homeGoals)
    _.G = new Array(_.homeGoals)
    _.w1 = new Array(_.homeGoals)
    _.w2 = new Array(_.homeGoals)
    _.h1 = new Array(_.homeGoals)
    _.h2 = new Array(_.homeGoals)

    for (let n: number = 0; n < _.homeGoals; n++) {
        _.C[n] = new Array(_.awayGoals)
        _.A[n] = new Array(_.awayGoals)
        _.D[n] = new Array(_.awayGoals)
        _.F[n] = new Array(_.awayGoals)
        _.J[n] = new Array(_.awayGoals)
        _.G[n] = new Array(_.awayGoals)
        _.w1[n] = new Array(_.awayGoals)
        _.w2[n] = new Array(_.awayGoals)
        _.h1[n] = new Array(_.awayGoals)
        _.h2[n] = new Array(_.awayGoals)
    }

    for (let n: number = 0; n < _.et.length - 1; n++) {
        _.F[0][n] = _.et[n]
        _.G[0][n] = _.et[n + 1]
    }

    for (let n: number = 0; n < _.eb.length - 1; n++) {
        _.C[homeGoals][n] = _.eb[n]
        _.D[homeGoals][n] = _.eb[n + 1]
    }

    for (let n: number = 0; n < _.el.length - 1; n++) {
        _.F[n][0] = _.el[n]
        _.C[n][0] = _.el[n + 1]
    }

    for (let n: number = 0; n < _.er.length - 1; n++) {
        _.G[n][awayGoals] = _.er[n]
        _.D[n][awayGoals] = _.er[n + 1]
    }

    for (let n: number = 0; n < homeGoals; n++) {
        const starting: Point = _.C[n][0]
        const ending: Point = _.D[n][awayGoals]
        const topDifference: number = (ending.top - starting.top) / (_.awayGoals)
        const leftDifference: number = (ending.left - starting.left) / (_.awayGoals)
        for (let m: number = 1; m < _.awayGoals; m++) {
            _.C[n][m] = {top: _.C[n][0].top + m * topDifference, left: _.C[n][0].left + m * leftDifference}
            _.D[n][m - 1] = _.C[n][m]
        }
    }

    for (let n: number = 1; n < _.homeGoals; n++) {
        const starting: Point = _.F[n][0]
        const ending: Point = _.G[n][awayGoals]
        const topDifference: number = (ending.top - starting.top) / (_.awayGoals)
        const leftDifference: number = (ending.left - starting.left) / (_.awayGoals)
        for (let m: number = 1; m < _.awayGoals; m++) {
            _.F[n][m] = {top: _.F[n][0].top + m * topDifference, left: _.F[n][0].left + m * leftDifference}
            _.G[n][m - 1] = _.F[n][m]
        }
    }

    for (let n: number = 0; n < _.homeGoals; n++) {
        for (let m: number = 0; m < _.homeGoals; m++) {
            _.A[n][m] = {top: _.C[n][m].top, left: _.F[n][m].left}
            _.J[n][m] = {top: _.F[n][m].top, left: _.D[n][m].left}

            _.w1[n][m] = _.A[n][m].left - _.C[n][m].left
            _.h1[n][m] = _.A[n][m].top - _.F[n][m].top

            _.w2[n][m] = _.G[n][m].left - _.J[n][m].left
            _.h2[n][m] = _.D[n][m].top - _.J[n][m].top
        }
    }
    return _
}



function Betrix({ disableInsurances, hideBetAgainst, visible, xh, xa, xh1, xa1, xh2, xa2, xh3, xa3, token, v2, o0, o01, minOdds, maxOdds, minCSOdds, maxCSOdds, marginParameter, margin, awayTeam, doublettes, homeTeam, marketMaker, matchId, odds, odds1, odds2, odds3, platform, showMarketMaker, showQuickButtons, sportId, suggestedMarkets }: Props) {
    const [stateData, setStateData] = useState(
            sportId === SportId.Hockey ?
                initialStateHockey
            : sportId === SportId.Soccer ?
                initialStateSoccer
            : sportId === SportId.Cycling ?
                initialStateCycling
            : initialStateSkiJumping
    )

    const {
        selectedNewButton,
        anyOtherBetTypeId,
        anyOtherBetTypeIdHome,
        anyOtherBetTypeIdAway,
        anyOtherBetTypeIdDraw,
        awayGoals,
        homeGoals,
        intersectionOdds,
        intersectionOdds2,
        IntersectionOdds,
        quickButtonsMatrix,
        selectedAdditionalButtons,
        selectedItemAnyOtherDraw,
        selectedItemAnyOtherHome,
        selectedItemAnyOtherAway,
        selectedItems,
        selectedLines,
        suggestedMarketsOpened,
        scopeId,
        betrixToggle
    } = stateData
    
    useEffect(() => {
        emptyCorrectScoreOdds()
        const defaultAway =
            sportId === SportId.Hockey ? betrixHockeyMaxGoals :
            sportId === SportId.Soccer ? betrixSoccerMaxGoals :
            sportId === SportId.Cycling ? betrixCyclingY : betrixSkiJumpingY
        
        const awayMax =
                sportId === SportId.Hockey ? betrixHockeyMaxGoals :
                sportId === SportId.Soccer ? betrixSoccerMaxGoals :
                sportId === SportId.Cycling ? betrixCyclingY : betrixSkiJumpingY
        
        const defaultHome =
                sportId === SportId.Hockey ? betrixHockeyMaxGoals :
                sportId === SportId.Soccer ? betrixSoccerMaxGoals :
                sportId === SportId.Cycling ? betrixCyclingX : betrixSkiJumpingX
        
        const homeMax =
                sportId === SportId.Hockey ? betrixHockeyMaxGoals :
                sportId === SportId.Soccer ? betrixSoccerMaxGoals :
                sportId === SportId.Cycling ? betrixCyclingX : betrixSkiJumpingX
        
        const defaultBetrixDimensions = getDefaultBetrixDimensions(defaultAway, awayMax, defaultHome, homeMax)
        const newAnyOtherBetTypeId = getAnyOtherBetTypeId(defaultBetrixDimensions.homeGoals, defaultBetrixDimensions.awayGoals)
        const newAnyOtherBetTypeIdHome = getAnyOtherBetTypeId2(defaultBetrixDimensions.homeGoals, defaultBetrixDimensions.awayGoals, 'home')
        const newAnyOtherBetTypeIdAway = getAnyOtherBetTypeId2(defaultBetrixDimensions.homeGoals, defaultBetrixDimensions.awayGoals, 'away')
        const newAnyOtherBetTypeIdDraw = getAnyOtherBetTypeId2(defaultBetrixDimensions.homeGoals, defaultBetrixDimensions.awayGoals, 'draw')
        const newSelectedAdditionalButtons = createInitialAdditionalButtons()
        const newSelectedLines = createEmptyLines(defaultBetrixDimensions.homeGoals, defaultBetrixDimensions.awayGoals)
        const newSelectedItems = createEmptyMatrix(defaultBetrixDimensions.homeGoals, defaultBetrixDimensions.awayGoals)
        const newQuickButtonsMatrix = createEmptyMatrix(defaultBetrixDimensions.homeGoals, defaultBetrixDimensions.awayGoals)

        const newAwayGoals =
                sportId === SportId.Hockey ?
                    betrixHockeyMaxGoals
                : sportId === SportId.Soccer ?
                    betrixSoccerMaxGoals
                : sportId === SportId.Cycling ?
                    betrixCyclingX
                :
                    betrixSkiJumpingX

        const newHomeGoals =
                sportId === SportId.Hockey ?
                    betrixHockeyMaxGoals
                : sportId === SportId.Soccer ?
                    betrixSoccerMaxGoals
                : sportId === SportId.Cycling ?
                    betrixCyclingY
                : betrixSkiJumpingY

        setStateData({
            betrixToggle: true,
            selectedNewButton: 0,
            anyOtherBetTypeId: newAnyOtherBetTypeId,
            anyOtherBetTypeIdHome: newAnyOtherBetTypeIdHome,
            anyOtherBetTypeIdAway: newAnyOtherBetTypeIdAway,
            anyOtherBetTypeIdDraw: newAnyOtherBetTypeIdDraw,
            awayGoals: newAwayGoals,
            homeGoals: newHomeGoals,
            intersectionOdds: '',
            intersectionOdds1: '',
            intersectionOdds2: '',
            IntersectionOdds: false,
            quickButtonsMatrix: newQuickButtonsMatrix,
            selectedAdditionalButtons: newSelectedAdditionalButtons,
            selectedItemAnyOtherDraw: MatrixValue.NotSelected,
            selectedItemAnyOtherHome: MatrixValue.NotSelected,
            selectedItemAnyOtherAway: MatrixValue.NotSelected,
            selectedItems: newSelectedItems,
            selectedLines: newSelectedLines,
            suggestedMarketsOpened: false,
            touches: [],
            scopeId: ScopeId.FT
        })
    }, [matchId])

    useEffect(() => {
        if (selectedItems.length) {
            const itemsIntersection = createItemsIntersection(selectedItems, quickButtonsMatrix)
            const suggestedMarketsAvailable = checkSuggestedMarketsAvailability(itemsIntersection)
            if (suggestedMarketsAvailable) {
                requestSuggestedMarkets(token, itemsIntersection)
            } else {
                emptyBetrixSuggestedMarkets()
            }

            const footballModelSelection = createFootballModelSelection(itemsIntersection, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway, homeGoals, awayGoals)

            if (!showMarketMaker) {
                makeFootballModelRequestAndSaveToBetslip(token, o0, o01, scopeId, margin, marginParameter, minCSOdds, maxCSOdds, correctScoreOdds, awayTeam, homeTeam, anyOtherBetTypeIdHome, anyOtherBetTypeIdDraw, anyOtherBetTypeIdAway, matchId, itemsIntersection, footballModelSelection, sportId, OutcomeId.BetrixYes, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway, homeGoals, awayGoals, setIntersectionOdds)
            } else {
                makeFootballModelRequest(token, scopeId, sportId, correctScoreOdds, matchId, footballModelSelection, homeGoals, awayGoals, setIntersectionOdds)
            }

            setStateData(currentState => ({
                ...currentState,
                suggestedMarketsOpened: (suggestedMarketsAvailable) ? currentState.suggestedMarketsOpened : false
            }))
        }
    }, [JSON.stringify(selectedItems), JSON.stringify(quickButtonsMatrix), selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway])

    const correctScoreOdds = scopeId === ScopeId.P1 || scopeId === ScopeId.H1 ?
    (odds1 && odds1[BetTypeGroupId[BetTypeGroupId.CorrectScore]]) ? odds1[BetTypeGroupId[BetTypeGroupId.CorrectScore]] : [] :
        scopeId === ScopeId.P2 || scopeId === ScopeId.H2 ?
    (odds2 && odds2[BetTypeGroupId[BetTypeGroupId.CorrectScore]]) ? odds2[BetTypeGroupId[BetTypeGroupId.CorrectScore]] : [] :
        scopeId === ScopeId.P3 ?
    (odds3 && odds3[BetTypeGroupId[BetTypeGroupId.CorrectScore]]) ? odds3[BetTypeGroupId[BetTypeGroupId.CorrectScore]] : [] :
    isSportWinner(sportId) ? scopeId === ScopeId.J1 ? (odds1 && odds1[BetTypeGroupId[BetTypeGroupId.Winner]]) ? odds1[BetTypeGroupId[BetTypeGroupId.Winner]] : [] :
        (odds && odds[BetTypeGroupId[BetTypeGroupId.Winner]]) ? odds[BetTypeGroupId[BetTypeGroupId.Winner]] : [] :
    (odds && odds[BetTypeGroupId[BetTypeGroupId.CorrectScore]]) ? odds[BetTypeGroupId[BetTypeGroupId.CorrectScore]] : []
    if (selectedAdditionalButtons === {} || !selectedItems.length || selectedLines === {} || !correctScoreOdds.length) {
        return null
    }

    function countAllByMatrixValue(countBy: MatrixValue, selectedItems: MatrixValue[][], selectedItemAnyOtherHome: MatrixValue, selectedItemAnyOtherDraw: MatrixValue, selectedItemAnyOtherAway: MatrixValue): number {
        let count = 0;
        for (const item of selectedItems) {
            for (const Item of item) {
                if (Item !== countBy) {
                    count++
                }
            } 
        }
        if (selectedItemAnyOtherHome !== countBy) {
            count++
        }

        if (selectedItemAnyOtherDraw !== countBy) {
            count++
        }

        if (selectedItemAnyOtherAway !== countBy) {
            count++
        }

        return count
    }
    const handleBetrixToggle = () => {
        setStateData(currentState => {
            return {
                ...currentState,
                betrixToggle: !currentState.betrixToggle
            }
        })
    }

    const handleNewQuickButton = (value: NewQuickButton) => {
        setStateData(currentState => {
            let selectedItemAnyOtherHome = MatrixValue.NotSelected
            let selectedItemAnyOtherDraw = MatrixValue.NotSelected
            let selectedItemAnyOtherAway = MatrixValue.NotSelected
            
            const isOver = NewQuickButton[value].includes('Over')
            if (value === NewQuickButton.HomeWin || isOver) {
                selectedItemAnyOtherHome = MatrixValue.Selected
            }

            if (value === NewQuickButton.Draw || isOver) {
                selectedItemAnyOtherDraw = MatrixValue.Selected
            }

            if (value === NewQuickButton.AwayWin || isOver) {
                selectedItemAnyOtherAway = MatrixValue.Selected
            }


            const newItems = createNewQuickMatrix(currentState.selectedItems.length - 1, currentState.selectedItems[0].length - 1, value)
            const newQuickButtonsMatrix = createEmptyMatrix(currentState.selectedItems.length - 1, currentState.selectedItems[0].length - 1)

            if (currentState.selectedNewButton === value && JSON.stringify(newItems) === JSON.stringify(currentState.selectedItems)) {                
                store.update(main => {
                    main.betslip.betrix = []
                    return main
                })

                return {
                    ...currentState,
                    quickButtonsMatrix: newQuickButtonsMatrix,
                    selectedItems: createEmptyMatrix(currentState.selectedItems.length - 1, currentState.selectedItems[0].length - 1),
                    selectedItemAnyOtherHome: MatrixValue.NotSelected,
                    selectedItemAnyOtherDraw: MatrixValue.NotSelected,
                    selectedItemAnyOtherAway: MatrixValue.NotSelected,
                    selectedNewButton: 0
                }
            } else {
                return {
                    ...currentState,
                    quickButtonsMatrix: newQuickButtonsMatrix,
                    selectedItems: newItems,
                    selectedItemAnyOtherHome,
                    selectedItemAnyOtherDraw,
                    selectedItemAnyOtherAway,
                    selectedNewButton: value
                }
            }
        })
    }

    function handleSelectedItem(awayGoalsValue: number, homeGoalsValue: number) {
        setStateData(currentState => {
            let selectedItemAnyOtherHome = currentState.selectedItemAnyOtherHome
            let selectedItemAnyOtherDraw = currentState.selectedItemAnyOtherDraw
            let selectedItemAnyOtherAway = currentState.selectedItemAnyOtherAway

            const newQuickButtonsMatrix = currentState.quickButtonsMatrix
            let newItems = JSON.parse(JSON.stringify(currentState.selectedItems))
            let newIntersectionOdds = currentState.intersectionOdds
            let newIntersectionOdds2 = currentState.intersectionOdds2
            if (disableInsurances) {
                if (currentState.selectedItems[homeGoalsValue][awayGoalsValue] === MatrixValue.Selected || currentState.quickButtonsMatrix[homeGoalsValue][awayGoalsValue] === MatrixValue.Selected) {
                    newItems[homeGoalsValue][awayGoalsValue] = MatrixValue.NotSelected
                } else {
                    newItems[homeGoalsValue][awayGoalsValue] = MatrixValue.Selected
                }

            } else {
                if (currentState.selectedItems[homeGoalsValue][awayGoalsValue] === MatrixValue.Selected || currentState.quickButtonsMatrix[homeGoalsValue][awayGoalsValue] === MatrixValue.Selected) {
                    newItems[homeGoalsValue][awayGoalsValue] = MatrixValue.Insured
                } else if (currentState.selectedItems[homeGoalsValue][awayGoalsValue] === MatrixValue.Insured) {
                    newItems[homeGoalsValue][awayGoalsValue] = MatrixValue.NotSelected
                } else {
                    newItems[homeGoalsValue][awayGoalsValue] = MatrixValue.Selected
                }
            }
            const allOptions: number = currentState.selectedItems.length * currentState.selectedItems[0].length + (isSportWinner(sportId) ? 0 : 3)
            const count: number = countAllByMatrixValue(MatrixValue.NotSelected, newItems, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway)
            if (count === allOptions) {
                return {
                    ...currentState
                }
            }

            if (currentState.quickButtonsMatrix[homeGoalsValue][awayGoalsValue] === MatrixValue.Selected) {
                newQuickButtonsMatrix[homeGoalsValue][awayGoalsValue] = MatrixValue.NotSelected
            }
            const isMatrixValueSelectedHome = checkMatrixValueSelected(newItems, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway, newItems.length - 1, newItems[0].length - 1)
            const isQuickButtonValueSelectedHome = checkMatrixValueSelected(currentState.quickButtonsMatrix, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway, currentState.quickButtonsMatrix.length - 1, currentState.quickButtonsMatrix[0].length - 1)
            if (!isMatrixValueSelectedHome && !isQuickButtonValueSelectedHome) {
                removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
                newItems = createEmptyMatrix(newItems.length - 1, newItems[0].length - 1)
                newIntersectionOdds = ''
                newIntersectionOdds2 = ''
                selectedItemAnyOtherHome = MatrixValue.NotSelected
                selectedItemAnyOtherDraw = MatrixValue.NotSelected
                selectedItemAnyOtherAway = MatrixValue.NotSelected
            }
            return {
                ...currentState,
                intersectionOdds: newIntersectionOdds,
                intersectionOdds2: newIntersectionOdds2,
                quickButtonsMatrix: newQuickButtonsMatrix,
                selectedItems: newItems,
                selectedItemAnyOtherHome,
                selectedItemAnyOtherDraw,
                selectedItemAnyOtherAway,
                selectedNewButton: 0
            }
        })
    }

    function handleSelectAnyOtherResult(what: 'home'|'away'|'draw') {
        
        if (what === 'home') {

            setStateData(currentState => {
                const newAnyOtherHome: MatrixValue = disableInsurances ? currentState.selectedItemAnyOtherHome === MatrixValue.NotSelected ? MatrixValue.Selected : MatrixValue.NotSelected :
                    (currentState.selectedItemAnyOtherHome === MatrixValue.NotSelected) ?
                        MatrixValue.Selected
                    : (currentState.selectedItemAnyOtherHome === MatrixValue.Insured) ?
                        MatrixValue.NotSelected
                    :
                        (checkMatrixValueSelected(currentState.selectedItems, MatrixValue.Insured, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherAway, homeGoals, awayGoals)) ?
                            MatrixValue.Insured
                        :
                            MatrixValue.NotSelected
                let newItems = JSON.parse(JSON.stringify(currentState.selectedItems))
                let newIntersectionOdds = currentState.intersectionOdds
                let newIntersectionOdds2 = currentState.intersectionOdds2
                const allOptions: number = currentState.selectedItems.length * currentState.selectedItems[0].length + (isSportWinner(sportId) ? 0 : 3) // any other
                const count: number = countAllByMatrixValue(MatrixValue.NotSelected, currentState.selectedItems, newAnyOtherHome, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherAway)
                if (count === allOptions) {
                    return {
                        ...currentState,
                    }
                }

                const countInsured: number = allOptions - countAllByMatrixValue(MatrixValue.Insured, currentState.selectedItems, newAnyOtherHome, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherAway)
                const countSelected: number = allOptions - countAllByMatrixValue(MatrixValue.Selected, currentState.selectedItems, newAnyOtherHome, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherAway)

                if (!countSelected && countInsured) {
                    removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
                    newItems = createEmptyMatrix(currentState.homeGoals, currentState.awayGoals)
                    newIntersectionOdds = ''
                    newIntersectionOdds2 = ''
                    currentState.selectedItemAnyOtherAway = MatrixValue.NotSelected
                    currentState.selectedItemAnyOtherDraw = MatrixValue.NotSelected
                }

                const isMatrixValueSelected = checkMatrixValueSelected(newItems, newAnyOtherHome, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherAway, currentState.homeGoals, currentState.awayGoals)
                const isQuickButtonValueSelected = checkMatrixValueSelected(currentState.quickButtonsMatrix, newAnyOtherHome, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherAway, currentState.homeGoals, currentState.awayGoals)
                if (!isMatrixValueSelected && !isQuickButtonValueSelected) {
                    removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
                    newItems = createEmptyMatrix(currentState.homeGoals, currentState.awayGoals)
                    newIntersectionOdds = ''
                    newIntersectionOdds2 = ''
                    currentState.selectedItemAnyOtherAway = MatrixValue.NotSelected
                    currentState.selectedItemAnyOtherDraw = MatrixValue.NotSelected
                }

                return {
                    ...currentState,
                    selectedItems: newItems,
                    selectedItemAnyOtherHome: newAnyOtherHome,
                    intersectionOdds: newIntersectionOdds,
                    intersectionOdds2: newIntersectionOdds2,
                    selectedNewButton: 0
                }
            })
        }

        if (what === 'away') {

            setStateData(currentState => {
                const newAnyOtherAway: MatrixValue = disableInsurances ? currentState.selectedItemAnyOtherAway === MatrixValue.NotSelected ? MatrixValue.Selected : MatrixValue.NotSelected :
                    (currentState.selectedItemAnyOtherAway === MatrixValue.NotSelected) ?
                        MatrixValue.Selected
                    : (currentState.selectedItemAnyOtherAway === MatrixValue.Insured) ?
                        MatrixValue.NotSelected
                    :
                        (checkMatrixValueSelected(currentState.selectedItems, MatrixValue.Insured, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherHome, homeGoals, awayGoals)) ?
                            MatrixValue.Insured
                        :
                            MatrixValue.NotSelected
                let newItems = JSON.parse(JSON.stringify(currentState.selectedItems))
                let newIntersectionOdds = currentState.intersectionOdds
                let newIntersectionOdds2 = currentState.intersectionOdds2
                const allOptions: number = currentState.selectedItems.length * currentState.selectedItems[0].length + (isSportWinner(sportId) ? 0 : 3) // any other
                const count: number = countAllByMatrixValue(MatrixValue.NotSelected, currentState.selectedItems, currentState.selectedItemAnyOtherHome, currentState.selectedItemAnyOtherDraw, newAnyOtherAway)
                if (count === allOptions) {
                    return {
                        ...currentState,
                    }
                }

                const countInsured: number = allOptions - countAllByMatrixValue(MatrixValue.Insured, currentState.selectedItems, currentState.selectedItemAnyOtherHome, currentState.selectedItemAnyOtherDraw, newAnyOtherAway)
                const countSelected: number = allOptions - countAllByMatrixValue(MatrixValue.Selected, currentState.selectedItems, currentState.selectedItemAnyOtherHome, currentState.selectedItemAnyOtherDraw, newAnyOtherAway)

                if (!countSelected && countInsured) {
                    removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
                    newItems = createEmptyMatrix(currentState.homeGoals, currentState.awayGoals)
                    newIntersectionOdds = ''
                    newIntersectionOdds2 = ''
                    currentState.selectedItemAnyOtherDraw = MatrixValue.NotSelected
                    currentState.selectedItemAnyOtherHome = MatrixValue.NotSelected
                }

                const isMatrixValueSelected = checkMatrixValueSelected(newItems, newAnyOtherAway, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherHome, currentState.homeGoals, currentState.awayGoals)
                const isQuickButtonValueSelected = checkMatrixValueSelected(currentState.quickButtonsMatrix, newAnyOtherAway, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherHome, currentState.homeGoals, currentState.awayGoals)
                if (!isMatrixValueSelected && !isQuickButtonValueSelected) {
                    removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
                    newItems = createEmptyMatrix(currentState.homeGoals, currentState.awayGoals)
                    newIntersectionOdds = ''
                    newIntersectionOdds2 = ''
                    currentState.selectedItemAnyOtherDraw = MatrixValue.NotSelected
                    currentState.selectedItemAnyOtherHome = MatrixValue.NotSelected    
                }

                return {
                    ...currentState,
                    selectedItems: newItems,
                    selectedItemAnyOtherAway: newAnyOtherAway,
                    intersectionOdds: newIntersectionOdds,
                    intersectionOdds2: newIntersectionOdds2,
                    selectedNewButton: 0
                }
            })
        }

        if (what === 'draw') {

            setStateData(currentState => {
                const newAnyOtherDraw: MatrixValue = disableInsurances ? currentState.selectedItemAnyOtherDraw === MatrixValue.NotSelected ? MatrixValue.Selected : MatrixValue.NotSelected :
                    (currentState.selectedItemAnyOtherDraw === MatrixValue.NotSelected) ?
                        MatrixValue.Selected
                    : (currentState.selectedItemAnyOtherDraw === MatrixValue.Insured) ?
                        MatrixValue.NotSelected
                    :
                        (checkMatrixValueSelected(currentState.selectedItems, MatrixValue.Insured, currentState.selectedItemAnyOtherHome, currentState.selectedItemAnyOtherAway, homeGoals, awayGoals)) ?
                            MatrixValue.Insured
                        :
                            MatrixValue.NotSelected
                let newItems = JSON.parse(JSON.stringify(currentState.selectedItems))
                let newIntersectionOdds = currentState.intersectionOdds
                let newIntersectionOdds2 = currentState.intersectionOdds2
                const allOptions: number = currentState.selectedItems.length * currentState.selectedItems[0].length + (isSportWinner(sportId) ? 0 : 3) // any other
                const count: number = countAllByMatrixValue(MatrixValue.NotSelected, currentState.selectedItems, currentState.selectedItemAnyOtherHome, newAnyOtherDraw, currentState.selectedItemAnyOtherAway)
                if (count === allOptions) {
                    return {
                        ...currentState,
                    }
                }

                const countInsured: number = allOptions - countAllByMatrixValue(MatrixValue.Insured, currentState.selectedItems, currentState.selectedItemAnyOtherHome, newAnyOtherDraw, currentState.selectedItemAnyOtherAway)
                const countSelected: number = allOptions - countAllByMatrixValue(MatrixValue.Selected, currentState.selectedItems, currentState.selectedItemAnyOtherHome, newAnyOtherDraw, currentState.selectedItemAnyOtherAway)

                if (!countSelected && countInsured) {
                    removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
                    newItems = createEmptyMatrix(currentState.homeGoals, currentState.awayGoals)
                    newIntersectionOdds = ''
                    newIntersectionOdds2 = ''
                    currentState.selectedItemAnyOtherAway = MatrixValue.NotSelected
                    currentState.selectedItemAnyOtherHome = MatrixValue.NotSelected
                }

                const isMatrixValueSelected = checkMatrixValueSelected(newItems, newAnyOtherDraw, currentState.selectedItemAnyOtherHome, currentState.selectedItemAnyOtherAway, currentState.homeGoals, currentState.awayGoals)
                const isQuickButtonValueSelected = checkMatrixValueSelected(currentState.quickButtonsMatrix, newAnyOtherDraw, currentState.selectedItemAnyOtherHome, currentState.selectedItemAnyOtherAway, currentState.homeGoals, currentState.awayGoals)
                if (!isMatrixValueSelected && !isQuickButtonValueSelected) {
                    removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
                    newItems = createEmptyMatrix(currentState.homeGoals, currentState.awayGoals)
                    newIntersectionOdds = ''
                    newIntersectionOdds2 = ''
                    currentState.selectedItemAnyOtherAway = MatrixValue.NotSelected
                    currentState.selectedItemAnyOtherHome = MatrixValue.NotSelected
                }

                return {
                    ...currentState,
                    selectedItems: newItems,
                    selectedItemAnyOtherDraw: newAnyOtherDraw,
                    intersectionOdds: newIntersectionOdds,
                    intersectionOdds2: newIntersectionOdds2,
                    selectedNewButton: 0
                }
            })
        }
    }

    function handleLine(line: string, additionalButton: AdditionalButton, quickButton: QuickButton, standardView?: boolean) {
        setStateData(currentState => {

            const newSelectedLines = currentState.selectedLines
            const quickButtonText = QuickButton[quickButton]
            const additionalButtonText = AdditionalButton[additionalButton]
            
            let newSelectedItems = currentState.selectedItems
        
            const newSelectedAdditionalButtons = currentState.selectedAdditionalButtons
            if (additionalButton !== AdditionalButton.Exact) {
                const lines = Object.keys(newSelectedLines[quickButtonText][additionalButtonText])
                if (quickButton === QuickButton.GoalDifference && !line.endsWith('+')) {
                    for (let i = 0; i < lines.length; i++) {
                        if (lines[i].endsWith('+')) {
                            newSelectedLines[quickButtonText][additionalButtonText][lines[i]] = MatrixValue.NotSelected
                        } else if (lines[i] === line) {
                            if (newSelectedLines[quickButtonText][additionalButtonText][line] === MatrixValue.NotSelected) {
                                newSelectedLines[quickButtonText][additionalButtonText][line] = MatrixValue.Selected
                            } else {
                                newSelectedLines[quickButtonText][additionalButtonText][line] = MatrixValue.NotSelected
                            }
                        }
                    }
                } else {
                    lines.map(l => newSelectedLines[quickButtonText][additionalButtonText][l] = (l === line && newSelectedLines[quickButtonText][additionalButtonText][l] === MatrixValue.NotSelected) ? MatrixValue.Selected : MatrixValue.NotSelected)
                    if (standardView) {
                        if (newSelectedLines[quickButtonText][additionalButtonText][line] === MatrixValue.Selected) {
                            newSelectedAdditionalButtons[quickButtonText] = AdditionalButton[additionalButtonText]
                        }
                    }

                }
            } else {
                newSelectedLines[quickButtonText][additionalButtonText][line] = (currentState.selectedLines[quickButtonText][additionalButtonText][line] === MatrixValue.Selected) ? MatrixValue.NotSelected : MatrixValue.Selected
                if (standardView) {
                    if (newSelectedLines[quickButtonText][additionalButtonText][line] === MatrixValue.Selected) {
                        newSelectedAdditionalButtons[quickButtonText] = AdditionalButton[additionalButtonText]
                    }
                }
            }

            const newQuickButtonsMatrix = createQuickMatrix(currentState.selectedLines, currentState.homeGoals, currentState.awayGoals)
            
            const isQuickButtonValueSelected = checkMatrixValueSelected(newQuickButtonsMatrix, currentState.selectedItemAnyOtherHome, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherAway, currentState.homeGoals, currentState.awayGoals)
            const isItemSelected = checkMatrixValueSelected(currentState.selectedItems, currentState.selectedItemAnyOtherHome, currentState.selectedItemAnyOtherDraw, currentState.selectedItemAnyOtherAway, currentState.homeGoals, currentState.awayGoals)
            if (!isQuickButtonValueSelected && !isItemSelected) {
                newSelectedItems = createEmptyMatrix(currentState.homeGoals, currentState.awayGoals)
                emptyBetrixSelectionOdds()
                removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
            }

            return {
                ...currentState,
                quickButtonsMatrix: newQuickButtonsMatrix,
                selectedNewButton: 0,
                selectedItems: newSelectedItems
            }
        })
    }

    function emptyMatrix() {
        const newSelectedAdditionalButtons = createInitialAdditionalButtons()
        const newSelectedLines = createEmptyLines(homeGoals, awayGoals)
        const newSelectedItems = createEmptyMatrix(homeGoals, awayGoals)
        const newQuickButtonsMatrix = createEmptyMatrix(homeGoals, awayGoals)

        setStateData(currentState => ({
            ...currentState,
            intersectionOdds: '',
            intersectionOdds2: '',
            quickButtonsMatrix: newQuickButtonsMatrix,
            selectedAdditionalButtons: newSelectedAdditionalButtons,
            // selectedItemAnyOther: MatrixValue.NotSelected,
            selectedItemAnyOtherHome: MatrixValue.NotSelected,
            selectedItemAnyOtherAway: MatrixValue.NotSelected,
            selectedItemAnyOtherDraw: MatrixValue.NotSelected,
            selectedItems: newSelectedItems,
            selectedLines: newSelectedLines,
            suggestedMarketsOpened: false,
            selectedNewButton: 0
        }))
        
        emptyBetrixSelectionOdds()
        removeBSBetrixItem(getBetslipBetrixItemIndex(matchId), matchId)
        
        if (isMobile()) {
            setTimeout(() => emptyBetrixSuggestedMarkets(), 500)
        } else {
            emptyBetrixSuggestedMarkets()
        }
    }

    function handleAdditionalButton(additionalButton: AdditionalButton, quickButton: QuickButton) {
        const quickButtonName = QuickButton[quickButton]
        setStateData(currentState => ({
            ...currentState,
            selectedAdditionalButtons: {
                ...currentState.selectedAdditionalButtons,
                [quickButtonName]: (additionalButton === currentState.selectedAdditionalButtons[quickButtonName]) ? -1 : additionalButton
            }
        }))
    }

    function betAgainst() {
        setStateData(currentState => { 
            let selectedItemAnyOtherHome = currentState.selectedItemAnyOtherHome
            let selectedItemAnyOtherDraw = currentState.selectedItemAnyOtherDraw
            let selectedItemAnyOtherAway = currentState.selectedItemAnyOtherAway
            const newItems = JSON.parse(JSON.stringify(currentState.selectedItems))
            const newQuickButtonsMatrix = createEmptyMatrix(homeGoals, awayGoals)

            if (selectedItemAnyOtherHome === MatrixValue.Selected) {
                selectedItemAnyOtherHome = MatrixValue.NotSelected
            } else if (selectedItemAnyOtherHome === MatrixValue.NotSelected) {
                selectedItemAnyOtherHome = MatrixValue.Selected
            }

            if (selectedItemAnyOtherDraw === MatrixValue.Selected) {
                selectedItemAnyOtherDraw = MatrixValue.NotSelected
            } else if (selectedItemAnyOtherDraw === MatrixValue.NotSelected) {
                selectedItemAnyOtherDraw = MatrixValue.Selected
            }

            if (selectedItemAnyOtherAway === MatrixValue.Selected) {
                selectedItemAnyOtherAway = MatrixValue.NotSelected
            } else if (selectedItemAnyOtherAway === MatrixValue.NotSelected) {
                selectedItemAnyOtherAway = MatrixValue.Selected
            }

            for (let i: number = 0; i < newItems.length; i++) {
                for (let j: number = 0; j < newItems[i].length; j++) {
                    if (newItems[i][j] === MatrixValue.Selected) {
                        newItems[i][j] = MatrixValue.NotSelected
                    } else if (newItems[i][j] === MatrixValue.NotSelected) {
                        newItems[i][j] = MatrixValue.Selected
                    }
                }
            }

            return ({
                ...currentState,
                quickButtonsMatrix: newQuickButtonsMatrix,
                selectedItems: newItems,
                selectedItemAnyOtherHome,
                selectedItemAnyOtherDraw,
                selectedItemAnyOtherAway,
                selectedNewButton: 0
            })
        })
    }

    function handleSuggestedMarketsOpened(value: boolean) {
        setStateData(currentState => ({
            ...currentState,
            suggestedMarketsOpened: value
        }))
    }

    function handleChangeScope (scopeId: ScopeId) {
        emptyMatrix()

        setStateData(currentState => ({
            ...currentState,
            scopeId
        }))
    }

    function setGoals(type: 'home' | 'away', value: string) {
        const { maxValueHome, maxValueAway } = getBetrixMaxValues(4, 4, 4, 4)
        const newAwayGoals = (type === 'away') ? +value : awayGoals
        const newHomeGoals = (type === 'home') ? +value : homeGoals

        if ((type === 'home' && newHomeGoals >= 0 && newHomeGoals <= maxValueHome) ||
            (type === 'away' && newAwayGoals >= 0 && newAwayGoals <= maxValueAway)) {
            const awayGoalsText = getGoalsText(newAwayGoals)
            const homeGoalsText = getGoalsText(newHomeGoals)
            const anyOtherText = `AnyOther${homeGoalsText}${awayGoalsText}`
            const anyOtherFound = correctScoreOdds.find(c => c.bettypeid === BetTypeId[anyOtherText])

            let checkedAwayGoals = newAwayGoals
            let checkedHomeGoals = newHomeGoals
            if (!anyOtherFound) {
                if (type === 'away') {
                    if (newAwayGoals === 0 || newAwayGoals === maxValueAway) {
                        checkedAwayGoals = awayGoals
                    } else {
                        checkedAwayGoals = newAwayGoals - 1
                    }
                } else {
                    if (newHomeGoals === 0 || newHomeGoals === maxValueHome) {
                        checkedHomeGoals = homeGoals
                    } else {
                        checkedHomeGoals = newHomeGoals - 1
                    }
                }
            }

            const newSelectedAdditionalButtons = createInitialAdditionalButtons()
            const newAnyOtherBetTypeId = getAnyOtherBetTypeId(checkedHomeGoals, checkedAwayGoals)
            const newAnyOtherBetTypeIdHome = getAnyOtherBetTypeId2(checkedHomeGoals, checkedAwayGoals, 'home')
            const newAnyOtherBetTypeIdAway = getAnyOtherBetTypeId2(checkedHomeGoals, checkedAwayGoals, 'away')
            const newAnyOtherBetTypeIdDraw = getAnyOtherBetTypeId2(checkedHomeGoals, checkedAwayGoals, 'draw')
            const newSelectedLines = createEmptyLines(checkedHomeGoals, checkedAwayGoals)
            const newSelectedItems = createEmptyMatrix(checkedHomeGoals, checkedAwayGoals)
            const newQuickButtonsMatrix = createEmptyMatrix(checkedHomeGoals, checkedAwayGoals)

            setStateData({
                betrixToggle: true,
                selectedNewButton: 0,
                anyOtherBetTypeId: newAnyOtherBetTypeId,
                anyOtherBetTypeIdHome: newAnyOtherBetTypeIdHome,
                anyOtherBetTypeIdAway: newAnyOtherBetTypeIdAway,
                anyOtherBetTypeIdDraw: newAnyOtherBetTypeIdDraw,
                awayGoals: checkedAwayGoals,
                homeGoals: checkedHomeGoals,
                intersectionOdds: '',
                intersectionOdds1: '',
                intersectionOdds2: '',
                IntersectionOdds: false,
                quickButtonsMatrix: newQuickButtonsMatrix,
                selectedAdditionalButtons: newSelectedAdditionalButtons,
                selectedItemAnyOtherHome: MatrixValue.NotSelected,
                selectedItemAnyOtherDraw: MatrixValue.NotSelected,
                selectedItemAnyOtherAway: MatrixValue.NotSelected,
                selectedItems: newSelectedItems,
                selectedLines: newSelectedLines,
                suggestedMarketsOpened: false,
                touches: [],
                scopeId: ScopeId.FT
            })
        }
    }

    function setIntersectionOdds(intersectionOdds: string, intersectionOdds2: string) {
        setStateData(currentState => ({
            ...currentState,
            intersectionOdds,
            intersectionOdds2,
            IntersectionOdds: true
        }))

        setTimeout(() => {
            setStateData(currentState => ({...currentState, IntersectionOdds: false}))
        }, 2500)
    }

    const selectedValues = getMatrixValues(selectedItems, MatrixValue.Selected)
    const insuredValues = getMatrixValues(selectedItems, MatrixValue.Insured)
    const quickValues = getMatrixValues(quickButtonsMatrix, MatrixValue.Selected)

    const isResetButtonAvailable = checkResetButtonAvailability(selectedItems, quickButtonsMatrix, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway, homeGoals, awayGoals)
    const showBetSuggestions = (selectedValues.length + insuredValues.length + quickValues.length) === 1
    const trapezoidGeometry: TrapezoidGeometry = sportId === SportId.Soccer && v2 ? getTrapezoidGeometry(homeGoals, awayGoals, 497, 1000, 714) : undefined

    return (
        <div className={clsx('betrix', !visible && 'hidden')}>
            <div className="main-betrix-wrapper" style = {{width: sportId === SportId.Cycling ? '95%' : ''}}>
                <div className="background-wrapper">
                    <BetrixTopRow
                        hideBetAgainst = {hideBetAgainst}
                        betAgainst = {betAgainst}
                        Odds={[
                            odds && odds[BetTypeGroupId[BetTypeGroupId.Winner]] && isSportWinner(sportId) ? odds[BetTypeGroupId[BetTypeGroupId.Winner]].length :
                            odds && odds[BetTypeGroupId[BetTypeGroupId.CorrectScore]] ? odds[BetTypeGroupId[BetTypeGroupId.CorrectScore]].length : 0,
                            odds1 && odds1[BetTypeGroupId[BetTypeGroupId.Winner]] && isSportWinner(sportId) ? odds1[BetTypeGroupId[BetTypeGroupId.Winner]].length : 
                            odds1 && odds1[BetTypeGroupId[BetTypeGroupId.CorrectScore]] ? odds1[BetTypeGroupId[BetTypeGroupId.CorrectScore]].length : 0,
                            odds2 && odds2[BetTypeGroupId[BetTypeGroupId.CorrectScore]] ? odds2[BetTypeGroupId[BetTypeGroupId.CorrectScore]].length : 0,
                            odds3 && odds3[BetTypeGroupId[BetTypeGroupId.CorrectScore]] ? odds3[BetTypeGroupId[BetTypeGroupId.CorrectScore]].length : 0]}
                        scopes = {scopes[sportId]}
                        scopeId = {scopeId}
                        intersectionOdds2={intersectionOdds2}
                        showBetSuggestions={showBetSuggestions}
                        suggestedMarketsOpened={suggestedMarketsOpened}
                        handleSuggestedMarketsOpened={handleSuggestedMarketsOpened}
                        handleChangeScope={handleChangeScope}
                        sportId = {sportId}
                    />
                    <BetrixMatrix
                        betrixToggle = {betrixToggle}
                        o0={o0}
                        o01={o01}
                        intersectionOdds={intersectionOdds}
                        IntersectionOdds={IntersectionOdds}
                        selectedItemAnyOtherHome={selectedItemAnyOtherHome}
                        selectedItemAnyOtherDraw={selectedItemAnyOtherDraw}
                        selectedItemAnyOtherAway={selectedItemAnyOtherAway}
                        // anyOtherBetTypeId={anyOtherBetTypeId}
                        anyOtherBetTypeIdDraw={anyOtherBetTypeIdDraw}
                        anyOtherBetTypeIdHome={anyOtherBetTypeIdHome}
                        anyOtherBetTypeIdAway={anyOtherBetTypeIdAway}
                        handleSelectAnyOtherResult={handleSelectAnyOtherResult}
                        trapezoidGeometry = {trapezoidGeometry}
                        awayGoals = {scopeId === ScopeId.FT && xa ? xa-1 : (scopeId === ScopeId.P1 ||scopeId === ScopeId.H1) && xa1 ? xa1-1 : (scopeId === ScopeId.P2 ||scopeId === ScopeId.H2) && xa2 ? xa2-1 : (scopeId === ScopeId.P3) && xa3 ? xa3-1 : scopeId !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : sportId === SportId.Soccer ? betrixHalfSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingX -1: betrixSkiJumpingX - 1: sportId === SportId.Hockey ? betrixHockeyMaxGoals : sportId === SportId.Soccer ? betrixSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingX - 1 : betrixSkiJumpingX - 1}
                        homeGoals = {scopeId === ScopeId.FT && xh ? xh-1 : (scopeId === ScopeId.P1 ||scopeId === ScopeId.H1) && xh1 ? xh1-1 : (scopeId === ScopeId.P2 ||scopeId === ScopeId.H2) && xh2 ? xh2-1 : (scopeId === ScopeId.P3) && xh3 ? xh3-1 : scopeId !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : sportId === SportId.Soccer ? betrixHalfSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingY -1: betrixSkiJumpingY - 1: sportId === SportId.Hockey ? betrixHockeyMaxGoals : sportId === SportId.Soccer ? betrixSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingY - 1 : betrixSkiJumpingY - 1}
                        scopeId = {scopeId}
                        sportId={sportId}
                        awayTeam={awayTeam}
                        correctScoreOdds={correctScoreOdds}
                        homeTeam={homeTeam}
                        isResetButtonAvailable={isResetButtonAvailable}
                        quickButtonsMatrix={quickButtonsMatrix}
                        selectedItems={selectedItems}
                        emptyMatrix={emptyMatrix}
                        handleSelectedItem={handleSelectedItem}
                        setGoals={setGoals}
                        HomeGoals = {sportId === SportId.Cycling ? betrixCyclingY : betrixSkiJumpingY}
                        v2 = {v2}
                    />
                    {!(isSportWinner(sportId)) ?
                    <BetrixQuickButtons
                        handleBetrixToggle={handleBetrixToggle}
                        betrixToggle = {betrixToggle}
                        homeTeam = {homeTeam}
                        awayTeam = {awayTeam}
                        newQuickButtons = {newQuickButtons}
                        selectedNewQuickButton = {selectedNewButton}
                        handleNewQuickButton = {handleNewQuickButton}
                        intersectionOdds2={intersectionOdds2}
                        intersectionOdds={intersectionOdds}
                        IntersectionOdds={IntersectionOdds}
                        v2 = {v2}
                        anyOtherBetTypeId={anyOtherBetTypeId}
                        anyOtherBetTypeIdDraw={anyOtherBetTypeIdDraw}
                        anyOtherBetTypeIdHome={anyOtherBetTypeIdHome}
                        anyOtherBetTypeIdAway={anyOtherBetTypeIdAway}
                        odds={correctScoreOdds}
                        handleSelectAnyOtherResult={handleSelectAnyOtherResult}
                        selectedItemAnyOtherDraw={selectedItemAnyOtherDraw}
                        selectedItemAnyOtherHome={selectedItemAnyOtherHome}
                        selectedItemAnyOtherAway={selectedItemAnyOtherAway}
                        awayGoals = {scopeId !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : sportId === SportId.Soccer ? betrixHalfSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingX -1: betrixSkiJumpingX - 1: sportId === SportId.Hockey ? betrixHockeyMaxGoals : sportId === SportId.Soccer ? betrixSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingX - 1 : betrixSkiJumpingX - 1}
                        homeGoals = {scopeId !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : sportId === SportId.Soccer ? betrixHalfSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingY -1: betrixSkiJumpingY - 1: sportId === SportId.Hockey ? betrixHockeyMaxGoals : sportId === SportId.Soccer ? betrixSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingY - 1 : betrixSkiJumpingY - 1}
                        platform={platform}
                        selectedAdditionalButtons={selectedAdditionalButtons}
                        selectedLines={selectedLines}
                        showQuickButtons={showQuickButtons}
                        handleAdditionalButton={handleAdditionalButton}
                        handleLine={handleLine}
                        sportId={sportId}
                    /> : void 0}
                </div>
                {doublettes ?
                <BetrixSuggestedMarkets
                    token = {token}
                    margin={margin}
                    marginParameter={marginParameter}
                    minOdds={minOdds}
                    maxOdds={maxOdds}
                    minCSOdds={minCSOdds}
                    maxCSOdds={maxCSOdds}
                    awayTeam={awayTeam}
                    homeTeam={homeTeam}
                    doublettes={doublettes}
                    handleSuggestedMarketsOpened={handleSuggestedMarketsOpened}
                    insuredValues={insuredValues}
                    marketMaker={marketMaker}
                    odds={odds}
                    openedButton={suggestedMarketsOpened}
                    selectedValues={selectedValues}
                    suggestedMarkets={suggestedMarkets}
                    sportId={sportId}
                    scopeId={scopeId}
                /> : void 0}
            </div>
        </div>
    )
}

export default Betrix

function createEmptyLines(homeMax: number, awayMax: number): {[quickButton: string]: {[additionalButton: string]: MatrixValue}} {
    const emptyLines = {}
    const quickButtonKeys: string[] = Object.keys(QuickButton).filter(k => isNaN(+k))
    const additionalButtonKeys: string[] = Object.keys(AdditionalButton).filter(k => isNaN(+k))

    for (const quickButton of quickButtonKeys) {
        const quickButtonValue: QuickButton = QuickButton[quickButton]
        emptyLines[quickButton] = {}
        for (const additionalButton of additionalButtonKeys) {
            emptyLines[quickButton][additionalButton] = {}
            const additionalButtonValue: AdditionalButton = AdditionalButton[additionalButton]
            
            if (quickButtonValue === QuickButton.GoalDifference && additionalButtonValue === AdditionalButton.Exact) {
                continue
            }
            
            const lines = getQuickButtonLines(quickButtonValue, additionalButtonValue, homeMax, awayMax)
            for (const line of lines) {
                emptyLines[quickButton][additionalButton][line] = MatrixValue.NotSelected
            }
        }
    }

    return emptyLines
}

function createInitialAdditionalButtons(): {[quickButton: string]: AdditionalButton} {
    const initialAdditionalButtons = {}
    const quickButtonKeys: string[] = Object.keys(QuickButton).filter(k => isNaN(+k))

    for (const quickButton of quickButtonKeys) {
        initialAdditionalButtons[quickButton] = 0// -1
    }

    return initialAdditionalButtons
}

function createItemsIntersection(selectedItems: MatrixValue[][], quickButtonsMatrix: MatrixValue[][]) {
    const itemsIntersection: MatrixValue[][] = createEmptyMatrix(selectedItems.length - 1, selectedItems[0].length - 1)

    for (let i = 0; i <= selectedItems.length - 1; i++) {
        for (let j = 0; j <= selectedItems[i].length - 1; j++) {
            if (selectedItems[i][j] !== MatrixValue.NotSelected) {
                itemsIntersection[i][j] = selectedItems[i][j]
            } else if (quickButtonsMatrix && quickButtonsMatrix[i] && quickButtonsMatrix[i][j] !== MatrixValue.NotSelected) {
                itemsIntersection[i][j] = quickButtonsMatrix[i][j]
            }
        }
    }

    return itemsIntersection
}

function emptyBetrixSelectionOdds() {
    store.update(main => {
        main.betrix.selectionOdds = {
            anyOther: 0,
            back: 0,
            lay: 0,

            anyOtherProbability: 0,
            backProbability: 0,
            layProbability: 0,

            backlay: 0,
            backany: 0,
            layany: 0,

            backLAY: 0,
            backANY: 0,

            layBACK: 0,
            layANY: 0,

            anyBACK: 0,
            anyLAY: 0,
        }
        return main
    })
}

function emptyBetrixSuggestedMarkets() {
    store.update(main => {
        main.betrix.suggestedMarkets = []
        return main
    })
}

function emptyCorrectScoreOdds() {
    store.update(main => {
        main.betrix.correctScoreOdds = []
        return main
    })
}

function fillBetrixSubitems(selectedItems: MatrixValue[][], homeMax: number, awayMax: number, scopeId: ScopeId, sportId: SportId, o0: Array<{name:'Winner', outcomes: Array<{name: string, value: number, country?: string}>}>, o01: Array<{name:'Winner', outcomes: Array<{name: string, value: number, country?: string}>}>): BSBetrixSubitem[] {
    const subitems: BSBetrixSubitem[] = []
    for (let i = 0; i <= selectedItems.length - 1; i++) {
        for (let j = 0; j <= selectedItems[0].length - 1; j++) {
            const item: MatrixValue = selectedItems[i][j]
            if (item !== MatrixValue.NotSelected) {
                const insured: boolean = item === MatrixValue.Insured
                const subitem: BSBetrixSubitem = isSportWinner(sportId) ? {
                    awayGoals: j,
                    homeGoals: i,
                    insured,
                    scopeId,
                    desc: scopeId === ScopeId.FT ? o0[0].outcomes[i * (awayMax + 1) + j].name : o01[0].outcomes[i * (awayMax + 1) + j].name,
                    Desc: scopeId === ScopeId.FT ? o0[0].outcomes[i * (awayMax + 1) + j].country : o01[0].outcomes[i * (awayMax + 1) + j].country,
                    max: selectedItems.length - 1,
                    may: selectedItems[0].length - 1
                }
                :
                {
                    awayGoals: j,
                    homeGoals: i,
                    insured,
                    scopeId,
                    max: selectedItems.length - 1,
                    may: selectedItems[0].length - 1
                }
                subitems.push(subitem)
            }
        }
    }

    return subitems
}

function getDefaultBetrixDimensions(defaultAway: number, awayMax: number, defaultHome: number, homeMax: number) {
    const awayGoals = (isBetrixFlexible) ? defaultAway : awayMax
    const homeGoals = (isBetrixFlexible) ? defaultHome : homeMax
    
    return { awayGoals, homeGoals }
}

function getOdds(correctScoreOdds: MarketOdds[], homeGoals: number, awayGoals: number): {oddsvalue: string, probability: string, name: string, away: number, home: number}[] {
    const Odds: {oddsvalue: string, probability: string, name: string, away: number, home: number}[] = []
    for (let i: number = 0; i < correctScoreOdds.length; i++) {
        if (correctScoreOdds[i].bettypeid > 0) {
            const isAnyOtherHome: boolean = BetTypeId[correctScoreOdds[i].bettypeid].includes('AnyOtherHome') || BetTypeId[correctScoreOdds[i].bettypeid].includes('AnyOtherUnknownHome')
            const isAnyOtherAway: boolean = BetTypeId[correctScoreOdds[i].bettypeid].includes('AnyOtherAway') || BetTypeId[correctScoreOdds[i].bettypeid].includes('AnyOtherUnknownAway')
            const isAnyOtherDraw: boolean = !isAnyOtherHome && !isAnyOtherAway && (BetTypeId[correctScoreOdds[i].bettypeid].includes('AnyOther') || BetTypeId[correctScoreOdds[i].bettypeid].includes('AnyOtherUnknownDraw'))
            
            const Split = (isAnyOtherHome || isAnyOtherDraw || isAnyOtherAway) ? [] : correctScoreOdds[i].name.split(':')
            Odds.push({
                oddsvalue: correctScoreOdds[i].oddsvalue,
                probability: correctScoreOdds[i].probability,
                name: correctScoreOdds[i].name,
                home: (isAnyOtherDraw) ? -1 : (isAnyOtherHome) ? -2 : (isAnyOtherAway) ? -3 : +Split[0],
                away: (isAnyOtherDraw) ? -1 : (isAnyOtherHome) ? -2 : (isAnyOtherAway) ? -3 : +Split[1]
            })
        } else {
            Odds.push({
                oddsvalue: correctScoreOdds[i].oddsvalue,
                probability: correctScoreOdds[i].probability,
                name: correctScoreOdds[i].name,
                home: Math.floor(i / awayGoals),
                away: i % awayGoals
            })
        }
    }
    
    return Odds
}

function makeFootballModelRequest(token: string, scopeId: ScopeId, sportId: SportId, correctScoreOdds: MarketOdds[], matchid: string, selections: FootballModelSelection[], homeGoals: number, awayGoals: number, setIntersectionOdds: (value: string, Value: string) => void) {
    store.update(main => {
        const footballModel: FootballModelDirect = {
            Odds: getOdds(correctScoreOdds, homeGoals, awayGoals),
            maxScoreHome: homeGoals,
            maxScoreAway: awayGoals,
            matchid: +matchid,
            selections,
            showMarketMakerInBetrix: main.settingsSoccer.showMarketMakerInBetrix
        }
        footballModel['t'] = token
        dataServer.request('put', 'update-football-model', footballModel, undefined, response => {
            if (response.data && response.data.output && response.data.output.result &&
                response.data.output.result.back && response.data.output.result.back.odds
            ) {
                let backOdds: string = response.data.output.result.back.odds.toString()
                if (footballModel.selections.length === 1) {
                    let homeGoals: number = footballModel.selections[0].homeGoals
                    let awayGoals: number = footballModel.selections[0].awayGoals
                    for (const item of footballModel.Odds) {
                        if (item.home === homeGoals && item.away === awayGoals) {
                            backOdds = item.oddsvalue
                            break
                        }
                    }
                } 

                setIntersectionOdds(backOdds, response.data.output.result.lay.odds.toString())
            }
        })
    })
}

function makeFootballModelRequestAndSaveToBetslip(token: string, o0, o01, scopeId: ScopeId, margin: number, marginParameter: number, minCSOdds: number, maxCSOdds: number, correctScoreOdds: MarketOdds[], awayTeam: string, homeTeam: string, betTypeIdHome: BetTypeId, betTypeIdDraw: BetTypeId, betTypeIdAway: BetTypeId, matchId: string, selectedItems: MatrixValue[][], selections: FootballModelSelection[], sportId: SportId, outcomeId: OutcomeId, selectedItemAnyOtherHome: MatrixValue, selectedItemAnyOtherDraw: MatrixValue, selectedItemAnyOtherAway: MatrixValue, homeGoals: number, awayGoals: number, setIntersectionOdds: (value: string, Value: string) => void) {
    store.update(main => {
        const footballModel: UpdateFootballModelRequest = {
            Odds: getOdds(correctScoreOdds, homeGoals, awayGoals),
            maxScoreHome: scopeId !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : sportId === SportId.Soccer ? betrixHalfSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingY - 1 : betrixSkiJumpingY - 1 : sportId === SportId.Hockey ? betrixHockeyMaxGoals : sportId === SportId.Soccer ? betrixSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingY - 1: betrixSkiJumpingY - 1,
            maxScoreAway: scopeId !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : sportId === SportId.Soccer ? betrixHalfSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingX - 1 : betrixSkiJumpingX - 1 : sportId === SportId.Hockey ? betrixHockeyMaxGoals : sportId === SportId.Soccer ? betrixSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingX - 1: betrixSkiJumpingX - 1,
    
            matchid: matchId,
            selections,
            showMarketMakerInBetrix: isSportWinner(sportId) ? true : main.settingsSoccer.showMarketMakerInBetrix,
            margin,
            marginParameter,
            minCSOdds,
            maxCSOdds
        }
        footballModel['t'] = token
        dataServer.request('put', 'update-football-model', undefined, footballModel, (response: any) => {
            if (response && response.output && response.output.result &&
                response.output.result.back && response.output.result.back.odds
            ) {
                const { anyOther, back, lay } = response.output.result
                let backOdds = back.odds.toString()
                if (footballModel.selections.length === 1) {
                    let homeGoals: number = footballModel.selections[0].homeGoals
                    let awayGoals: number = footballModel.selections[0].awayGoals
                    for (const item of footballModel.Odds) {
                        if (item.home === homeGoals && item.away === awayGoals) {
                            backOdds = item.oddsvalue
                            break
                        }
                    }
                }

                const backProbability = back.probability
                const layOdds = lay && lay.odds ? lay.odds.toString() : '0'
                const layProbability = lay ? lay.probability : 1
                const anyOtherOdds = anyOther && anyOther.odds ? anyOther.odds.toString() : '0'
                const anyOtherProbability = anyOther ? anyOther.probability : 1
                const selectionOdds = (outcomeId === OutcomeId.BetrixOut) ? anyOtherOdds : backOdds

                setIntersectionOdds(backOdds, layOdds)
                const { backData, layData, anyOtherData } = getBetrixOddsData(backOdds, backProbability, layOdds, layProbability, anyOtherOdds, anyOtherProbability)
                saveBetrixBSItem(o0, o01, scopeId, awayTeam, homeTeam, betTypeIdHome, betTypeIdDraw, betTypeIdAway, matchId, selectionOdds, backData, layData, anyOtherData, outcomeId, selectedItems, sportId, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway, scopeId !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : sportId === SportId.Soccer ? betrixHalfSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingY - 1 : betrixSkiJumpingY - 1 : sportId === SportId.Hockey ? betrixHockeyMaxGoals : sportId === SportId.Soccer ? betrixHalfSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingY - 1 : betrixSkiJumpingY - 1/*homeGoals*/, scopeId !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : sportId === SportId.Soccer ? betrixSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingX - 1: betrixSkiJumpingX - 1 : sportId === SportId.Hockey ? betrixHockeyMaxGoals : sportId === SportId.Soccer ? betrixSoccerMaxGoals : sportId === SportId.Cycling ? betrixCyclingX - 1: betrixSkiJumpingX - 1)
            }
        })
    })
}

function requestSuggestedMarkets(token: string, newItems: MatrixValue[][]) {
    const selectedValues = getMatrixValues(newItems, MatrixValue.Selected)
    dataServer.request('get', 'get-suggested-markets', {
        awayScore: selectedValues[0].awayGoals,
        homeScore: selectedValues[0].homeGoals,
        id: config.configuration_id,
        t: token
    })
}

function getItemAllOdds(backData: any, layData: any, anyOtherData: any) {
    const itemAllOdds: BSBetrixItemAllOdds[] = []
    
    itemAllOdds.push({
        outcomeId: OutcomeId.BetrixYes,
        value: backData.odds,
        probability: backData.probability
    })
    itemAllOdds.push({
        outcomeId: OutcomeId.BetrixNo,
        value: layData.odds,
        probability: layData.probability
    })
    itemAllOdds.push({
        outcomeId: OutcomeId.BetrixOut,
        value: anyOtherData.odds,
        probability: anyOtherData.probability
    })

    return itemAllOdds
}

function saveBetrixBSItem(o0, o1, scopeId: ScopeId, awayTeam: string, homeTeam: string, anyOtherBetTypeIdHome: BetTypeId, anyOtherBetTypeIdDraw: BetTypeId, anyOtherBetTypeIdAway: BetTypeId, matchId: string, selectedOdds: string, backData: any, layData: any, anyOtherData: any, outcomeId: OutcomeId, selectedItems: MatrixValue[][], sportId: SportId, selectedItemAnyOtherHome: MatrixValue, selectedItemAnyOtherDraw: MatrixValue, selectedItemAnyOtherAway: MatrixValue, homeGoals: number, awayGoals: number) {
    const betslip: Betslip = store.get(main => main.betslip)
    const currentBetslip: BSBetrixItem[] = betslip.betrix
    const betslipIndex: number = betslip.index
    let index: number = betslipIndex

    const subitems = fillBetrixSubitems(selectedItems, homeGoals, awayGoals, scopeId, sportId, o0, o1)
    if (selectedItemAnyOtherDraw !== MatrixValue.NotSelected) {
        subitems.push({
            awayGoals: -1,
            homeGoals: -1,
            insured: selectedItemAnyOtherDraw === MatrixValue.Insured,
            scopeId,
            desc: '',
            Desc: '',
            max: selectedItems.length - 1,
            may: selectedItems[0].length - 1
        })
    }
    if (selectedItemAnyOtherHome !== MatrixValue.NotSelected) {
        subitems.push({
            awayGoals: -2,
            homeGoals: -2,
            insured: selectedItemAnyOtherHome === MatrixValue.Insured,
            scopeId,
            desc: '',
            Desc: '',
            max: selectedItems.length - 1,
            may: selectedItems[0].length - 1
        })
    }
    if (selectedItemAnyOtherAway !== MatrixValue.NotSelected) {
        subitems.push({
            awayGoals: -3,
            homeGoals: -3,
            insured: selectedItemAnyOtherAway === MatrixValue.Insured,
            scopeId,
            desc: '',
            Desc: '',
            max: selectedItems.length - 1,
            may: selectedItems[0].length - 1
        })
    }

    const allOdds = getItemAllOdds(backData, layData, anyOtherData)
    const homeGoalsText = getGoalsText(homeGoals)
    const awayGoalsText = getGoalsText(awayGoals)
    const betType = BetTypeId[`Betrix${homeGoalsText}${awayGoalsText}`]
    const betTypeId = (subitems.length === 1) ? BetTypeId[`${getGoalsText(subitems[0].homeGoals)}${getGoalsText(subitems[0].awayGoals)}`] : betType

    const newItem: BSBetrixItem = {
        allOdds,
        anyOtherBetTypeIdHome,
        anyOtherBetTypeIdDraw,
        anyOtherBetTypeIdAway,
        awayTeam,
        betTypeId,
        homeTeam,
        index,
        matchId,
        oddsValue: selectedOdds,
        outcomeId,
        outcomeName: '',
        sportId,
        subitems,
        scopeId
    }

    const isBetrixOutcome = OutcomeId[outcomeId].includes('Betrix')
    const betrixItemFound = currentBetslip.find(c => c.matchId === newItem.matchId && isBetrixOutcome)
    
    if (betrixItemFound) {
        currentBetslip[currentBetslip.indexOf(betrixItemFound)] = newItem
    } else {
        currentBetslip.push(newItem)
        const newIndex = betslipIndex + 1
        newItem.index = newIndex
        index = newIndex
    }

    store.update(main => {
        main.betslip.betrix = currentBetslip
        main.betslip.index = index
        return main
    })
}

function getAnyOtherBetTypeId(homeGoals: number, awayGoals: number) {
    const homeGoalsText = getGoalsText(homeGoals)
    const awayGoalsText = getGoalsText(awayGoals)
    const betTypeId: BetTypeId = BetTypeId[`AnyOther${homeGoalsText}${awayGoalsText}`]
    return betTypeId
}

function getAnyOtherBetTypeId2(homeGoals: number, awayGoals: number, what: 'home'|'draw'|'away') {
    const homeGoalsText = getGoalsText(homeGoals)
    const awayGoalsText = getGoalsText(awayGoals)
    if (what === 'home') {
        return BetTypeId[`AnyOtherHome${homeGoalsText}${awayGoalsText}`]
    } else if (what === 'away') {
        return BetTypeId[`AnyOtherAway${homeGoalsText}${awayGoalsText}`]
    } else {
        return BetTypeId[`AnyOther${homeGoalsText}${awayGoalsText}`]
    }
}