import React, { useState } from 'react'
import OddsItem from '../../OddsItem'
import clsx from 'clsx'
import FaIcon from '../../FaIcon'
import { SportId, BetTypeId, BetTypeGroupId, ScopeId } from '../../../../state/enums'
import { getMarketNameAndOptions, getOutcomeText, isMobile, roundOddsValue, getTeamNameFromBetType, GetNameFromMarketGroup, clearMMOddsItem, setPopupContent } from '../../../../utils'
import { MarketOdds } from '../../../../state/stores/feed/models'
import { MarketMaker } from '../../../../state/stores/marketmaker/models'
import { MarketGroupItemElement } from '../../../../state/stores/settings/models'
import { marketsWithDescriptions, scopes } from '../../../../assets'
import { betrixHockeyMaxGoals, betrixSoccerMaxGoals, betrixPeriodHockeyMaxGoals, betrixHalfSoccerMaxGoals } from '../../../../assets'
import { store } from '../../../../state'

interface Props {
    hideBetAgainst: boolean
    token: string
    awayTeam: string
    homeTeam: string
    isWide: boolean
    market: string
    marketMaker: MarketMaker
    odds: MarketOdds[]
    odds1: MarketOdds[]
    odds2: MarketOdds[]
    odds3: MarketOdds[]
    sportId: SportId
    marketGroupData0: MarketGroupItemElement
    marketGroupData1: MarketGroupItemElement
    marketGroupData2: MarketGroupItemElement
    marketGroupData3: MarketGroupItemElement
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    oldOdds?: boolean
    psbOdds?: boolean
    Odds: number[]
    betAgainst: (invOddsValue: string, matchid: string, typeProperty: string, options) => void
}
import config from '../../../../configjson'
import MarketScope from '../../MarketScope'
const NormalMarketBoxMM = ({
    token, 
    betAgainst,
    Odds,
    minOdds,
    maxOdds,
    minCSOdds,
    maxCSOdds,
    marginParameter,
    margin,
    awayTeam,
    homeTeam,
    isWide,
    market,
    marketGroupData0,
    marketGroupData1,
    marketGroupData2,
    marketGroupData3,
    marketMaker,
    odds,
    odds1,
    odds2,
    odds3,
    sportId,
    oldOdds,
    psbOdds,
    hideBetAgainst
}: Props) => {
    const [activeScope, setActiveScope] = useState<ScopeId>(ScopeId.FT)

    let ScopeOdds: MarketOdds[] =
        activeScope === ScopeId.FT ?
            odds
        : activeScope === ScopeId.P1 || activeScope === ScopeId.H1 || activeScope === ScopeId.J1 ?
            odds1
        : activeScope === ScopeId.P2 || activeScope === ScopeId.H2 ?
            odds2
        : activeScope === ScopeId.P3 ?
            odds3
        : []

    let marketGroupData: MarketGroupItemElement =
        activeScope === ScopeId.FT ?
            marketGroupData0
        : activeScope === ScopeId.P1 || activeScope === ScopeId.H1 || activeScope === ScopeId.J1 ?
            marketGroupData1
        : activeScope === ScopeId.P2 || activeScope === ScopeId.H2 ?
            marketGroupData2
        : activeScope === ScopeId.P3 ?
            marketGroupData3
        : undefined

    if (!odds.length || !odds[0] || !ScopeOdds || !ScopeOdds[0]) {
        return null
    }
    const bettypegroupid = ScopeOdds[0].bettypegroupid

    let scopeOdds: MarketOdds[]
    if (bettypegroupid === BetTypeGroupId.CorrectScore && (sportId === SportId.Soccer || sportId === SportId.Hockey)) {
        scopeOdds = []
        const max: number = activeScope !== ScopeId.FT ? sportId === SportId.Hockey ? betrixPeriodHockeyMaxGoals : betrixHalfSoccerMaxGoals : sportId === SportId.Hockey ? betrixHockeyMaxGoals : betrixSoccerMaxGoals
        for (let ii: number = 0; ii < ScopeOdds.length; ii++) {
            scopeOdds.push(ScopeOdds[ii])
            if (sportId === SportId.Soccer && max !== 3 && (ii - max) % (max + 1) === 0) {
                // (ii - max) % (max + 1) === 0 && ii < MarketOdds.length - (max + 1)*/) {
                scopeOdds.push({
                    bettypegroupid: 8,
                    bettypeid: ii,
                    islive: false,
                    matchid: "",
                    name: "",
                    oddsvalue: "",
                    oldoddsvalue: "",
                    outcomeid: 11,
                    outcomename: "Yes",
                    probability: "0",
                    value: "0"
                })
                // }
            }
        }
        if (max === betrixHockeyMaxGoals) {
            scopeOdds.push({
                bettypegroupid: 8,
                bettypeid: 0,
                islive: false,
                matchid: "",
                name: "",
                oddsvalue: "",
                oldoddsvalue: "",
                outcomeid: 11,
                outcomename: "Yes",
                probability: "0",
                value: "0"
            })
        }
    } else {
        scopeOdds = ScopeOdds
    }
    if (!scopeOdds || scopeOdds.length === 0) {return <div/>}
    const teamName = getTeamNameFromBetType(awayTeam, homeTeam, scopeOdds[0].bettypeid)
    const marketName: string = (marketGroupData && marketGroupData.name) ? GetNameFromMarketGroup(marketGroupData.name, homeTeam, awayTeam) : getMarketNameAndOptions(market, sportId, { teamName }).name
    const betTypeGroupId = scopeOdds[0].bettypegroupid
    const betTypeId = scopeOdds[0].bettypeid
    const matchId = scopeOdds[0].matchid
    const isCS: boolean = betTypeGroupId === BetTypeGroupId.CorrectScore
    const typeProperty = (isCS) ? BetTypeGroupId[betTypeGroupId] : BetTypeId[betTypeId]
    const betType = marketMaker[matchId] && marketMaker[matchId][typeProperty]
    const shouldCheckMM = betType && betType.selections && betType.selections.length > 1
    const oddsValueToShow: string = (shouldCheckMM) ? roundOddsValue(betType.odds, 2) : ''
    const invOddsValueToShow: string = (betType) ? roundOddsValue(betType.invOdds, 2) : ''
    const hasDescription = Object.keys(marketsWithDescriptions).includes(BetTypeGroupId[betTypeGroupId])
    
    const handleChangeScope = (newValue: ScopeId) => {
        if ((!isCS || scopeOdds[0].bettypeid === BetTypeId.ZeroZero) && shouldCheckMM) {
            clearMMOddsItem(betTypeGroupId, matchId, typeProperty, betTypeId)
        }
        
        setActiveScope(newValue)
    }

    const orOddsInsured: Array<{bettypegroupid: number, bettypeid: number, value: number}> = store.get(main => main.iframeresponse.orOddsInsured)
    let OrOddsInsured: number
    for (const item of orOddsInsured) {
        if (item.bettypegroupid === betTypeGroupId && item.bettypeid === betTypeId) {
            OrOddsInsured = item.value
            break
        }
    }

    const sportScopes = scopes[sportId]

    return (
        <div className={clsx('market-box', isWide && 'wide', isCS && 'correct-score')}>
            {!isCS || scopeOdds[0].bettypeid === BetTypeId.ZeroZero ?
                <>
                    {shouldCheckMM &&
                        <FaIcon
                            className="reset-market-maker"
                            icon="times"
                            type="solid"
                            onClick={() => clearMMOddsItem(betTypeGroupId, matchId, typeProperty, betTypeId)}
                        />
                    }
                    <h3 style = {{position: 'relative'}}
                        className={clsx('single-odds', 'market-name')}
                    >
                        {OrOddsInsured ? <div className = "or-odds-insured">
                            ({OrOddsInsured.toFixed(2)})
                        </div> : void 0}
                        <span className="market-name-value">
                            <div>{marketName}</div>
                            <MarketScope
                                Odds={Odds}
                                activeScope={activeScope}
                                scopes={sportScopes}
                                handleChangeScope={handleChangeScope}
                            />
                            {hasDescription &&
                                <FaIcon
                                    className="market-info-button"
                                    icon="info-circle"
                                    type="solid"
                                    onClick={() => setPopupContent(generateMarketInfoContent(marketsWithDescriptions[BetTypeGroupId[betTypeGroupId]], marketGroupData, awayTeam, homeTeam))}
                                />
                            }
                        </span>
                        {/*(oddsValueToShow) ?
                            <div className="multi-selection-label">
                                <span>Your multi selection:</span>
                                <span>{oddsValueToShow}</span>
                            </div>
                        : null*/}
                        {(invOddsValueToShow && parseFloat(invOddsValueToShow) !== 1) && !hideBetAgainst ?
                            <button onClick = {() => betAgainst(invOddsValueToShow, matchId, typeProperty, scopeOdds)} className="invert-button">
                                <span>Bet against: </span>
                                <span>{roundOddsValue(invOddsValueToShow, 3)}</span>
                            </button>
                        : null}
                    </h3>
                </>
                : void 0}

            <div className="odds-wrapper">
                {marketGroupData ?
                    isCS ?
                        scopeOdds.map(oddsitem => {
                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    margin={margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={oddsitem.bettypeid}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className={clsx(oddsitem.name.startsWith('Any other result') ? 'single-odds-special' : '', sportId === SportId.Soccer ? activeScope === ScopeId.FT ? 'single-odds single-odds-' + config.odds_in_soccer_correct_score_row : 'single-odds single-odds-' + config.odds_in_soccer_period_correct_score_row : activeScope === ScopeId.FT ? 'single-odds single-odds-'+ config.odds_in_hockey_correct_score_row : 'single-odds single-odds-' + config.odds_in_hockey_period_correct_score_row, scopeOdds.length < config.odds_in_hockey_correct_score_row ? oddsitem.name.startsWith('Any other result') ? 'any-other-result-single-odds' + scopeOdds.length : '' : '')}
                                    item={oddsitem}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={scopeOdds.length}
                                    options={scopeOdds}
                                    selectionText={oddsitem.name}
                                    sportId={sportId}
                                    scopeId={activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })
                        : marketGroupData.outcomes.map(outcome => {
                            const singleOdds = scopeOdds.find(o => o.outcomeid === outcome.id)
                            if (!singleOdds) {
                                return null
                            }

                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    margin={margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={outcome.id}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className="single-odds"
                                    item={singleOdds}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={scopeOdds.length}
                                    options={scopeOdds}
                                    selectionText={GetNameFromMarketGroup(outcome.name, homeTeam, awayTeam)}
                                    sportId={sportId}
                                    scopeId={activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })
                    :
                    scopeOdds.map((item, index) => {
                        const { bettypegroupid, outcomeid, outcomename, value } = item
                        const selectionText: string =
                            (isMobile() ||
                                bettypegroupid === BetTypeGroupId.CorrectScore ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime2 ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime3 ||
                                bettypegroupid === BetTypeGroupId.FirstHalfSecondHalf) ?
                                outcomename
                                :
                                getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                        return (
                            <OddsItem token = {token} psbOdds = {psbOdds}
                                margin={margin}
                                marginParameter = {marginParameter}
                                minOdds={minOdds}
                                maxOdds={maxOdds}
                                minCSOdds={minCSOdds}
                                maxCSOdds={maxCSOdds}
                                key={index}
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                className="single-odds"
                                item={item}
                                marketMaker={true}
                                marketMakerData={marketMaker}
                                optionsLength={scopeOdds.length}
                                options={scopeOdds}
                                selectionText={selectionText}
                                sportId={sportId}
                                scopeId={activeScope}
                                oddsValueToShow = {oddsValueToShow}
                            />
                        )
                    })
                }
            </div>
            {oldOdds ?
            <div className="odds-wrapper odds-wrapper-disabled">
                {marketGroupData ?
                    isCS ?
                        scopeOdds.map(oddsitem => {
                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    margin={margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={oddsitem.bettypeid}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className={clsx(sportId === SportId.Soccer ? 'single-odds single-odds-' + config.odds_in_soccer_correct_score_row : 'single-odds', scopeOdds.length < config.odds_in_hockey_correct_score_row ? oddsitem.name.startsWith('Any other result') ? 'any-other-result-single-odds' + scopeOdds.length : '' : '')}
                                    item={oddsitem}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={scopeOdds.length}
                                    options={scopeOdds}
                                    selectionText={oddsitem.name}
                                    sportId={sportId}
                                    disabled = {true}
                                    scopeId={activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })
                        : marketGroupData.outcomes.map(outcome => {
                            const singleOdds = scopeOdds.find(o => o.outcomeid === outcome.id)
                            if (!singleOdds) {
                                return null
                            }

                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    margin={margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={outcome.id + '_'}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className="single-odds"
                                    item={singleOdds}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={scopeOdds.length}
                                    options={scopeOdds}
                                    selectionText={GetNameFromMarketGroup(outcome.name, homeTeam, awayTeam)}
                                    sportId={sportId}
                                    disabled = {true}
                                    scopeId={activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })
                    :
                    scopeOdds.map((item, index) => {
                        const { bettypegroupid, outcomeid, outcomename, value } = item

                        const selectionText: string =
                            (isMobile() ||
                                bettypegroupid === BetTypeGroupId.CorrectScore ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime2 ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime3 ||
                                bettypegroupid === BetTypeGroupId.FirstHalfSecondHalf) ?
                                outcomename
                                :
                                getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                        return (
                            <OddsItem token = {token} psbOdds = {psbOdds}
                                margin={margin}
                                marginParameter = {marginParameter}
                                minOdds={minOdds}
                                maxOdds={maxOdds}
                                minCSOdds={minCSOdds}
                                maxCSOdds={maxCSOdds}
                                key={index}
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                className="single-odds"
                                item={item}
                                marketMaker={true}
                                marketMakerData={marketMaker}
                                optionsLength={scopeOdds.length}
                                options={scopeOdds}
                                selectionText={selectionText}
                                sportId={sportId}
                                disabled = {true}
                                scopeId={activeScope}
                                oddsValueToShow = {oddsValueToShow}
                            />
                        )
                    })
                }
            </div> : void 0}
            {psbOdds ?
            <div className="odds-wrapper odds-wrapper-enabled">
                {marketGroupData ?
                    isCS ?
                        scopeOdds.map(oddsitem => {
                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    margin={margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={oddsitem.bettypeid}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className={clsx(sportId === SportId.Soccer ? 'single-odds single-odds-' + config.odds_in_soccer_correct_score_row : 'single-odds', scopeOdds.length < config.odds_in_hockey_correct_score_row ? oddsitem.name.startsWith('Any other result') ? 'any-other-result-single-odds' + scopeOdds.length : '' : '')}
                                    item={oddsitem}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={scopeOdds.length}
                                    options={scopeOdds}
                                    selectionText={oddsitem.name}
                                    sportId={sportId}
                                    disabled = {false}
                                    scopeId={activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })
                        : marketGroupData.outcomes.map(outcome => {
                            const singleOdds = scopeOdds.find(o => o.outcomeid === outcome.id)
                            if (!singleOdds) {
                                return null
                            }

                            return (
                                <OddsItem token = {token} psbOdds = {psbOdds}
                                    margin={margin}
                                    marginParameter = {marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    key={outcome.id + '__'}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    className="single-odds"
                                    item={singleOdds}
                                    marketMaker={true}
                                    marketMakerData={marketMaker}
                                    optionsLength={scopeOdds.length}
                                    options={scopeOdds}
                                    selectionText={GetNameFromMarketGroup(outcome.name, homeTeam, awayTeam)}
                                    sportId={sportId}
                                    disabled = {false}
                                    scopeId={activeScope}
                                    oddsValueToShow = {oddsValueToShow}
                                />
                            )
                        })
                    :
                    scopeOdds.map((item, index) => {
                        const { bettypegroupid, outcomeid, outcomename, value } = item

                        const selectionText: string =
                            (isMobile() ||
                                bettypegroupid === BetTypeGroupId.CorrectScore ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime2 ||
                                bettypegroupid === BetTypeGroupId.HalfTimeFullTime3 ||
                                bettypegroupid === BetTypeGroupId.FirstHalfSecondHalf) ?
                                outcomename
                                :
                                getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                        return (
                            <OddsItem token = {token} psbOdds = {psbOdds}
                                margin={margin}
                                marginParameter = {marginParameter}
                                minOdds={minOdds}
                                maxOdds={maxOdds}
                                minCSOdds={minCSOdds}
                                maxCSOdds={maxCSOdds}
                                key={index}
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                className="single-odds"
                                item={item}
                                marketMaker={true}
                                marketMakerData={marketMaker}
                                optionsLength={scopeOdds.length}
                                options={scopeOdds}
                                selectionText={selectionText}
                                sportId={sportId}
                                disabled = {false}
                                scopeId={activeScope}
                                oddsValueToShow = {oddsValueToShow}
                            />
                        )
                    })
                }
            </div> : void 0}
        </div>
    )
}

export default NormalMarketBoxMM

function generateMarketInfoContent(marketData: {[outcomeId: string]: string}, marketGroupData: MarketGroupItemElement | undefined, awayTeam: string, homeTeam: string) {   
    return (
        <div className="market-info">
            {Object.keys(marketData).map((item, index) => {
                if (!marketData[item]) {
                    return null
                }

                const outcomeFound = marketGroupData && marketGroupData.outcomes.find(o => o.id === +item)
                const outcomeName = (outcomeFound) ? outcomeFound.name : getOutcomeText(awayTeam, homeTeam, +item, null)
                
                return (
                    <div key={index}>
                        <span>{outcomeName}</span>
                        <span>: </span>
                        <span>{marketData[item]}</span>
                    </div>
                )
            })}
        </div>
    )
}