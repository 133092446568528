import { Settings } from './models'

export const settingsSoccer: Settings = {
    betrixDimensions: {
        awayMax: 0,
        homeMax: 0
    },
    defaultMarketGroup: 0,
    defaultSport: 0,
    firstPageSports: [],
    marketGroups: [],
    marketGroupsBySport: [],
    showMarketMakerInBetrix: false
}

export const settingsHockey: Settings = {
    betrixDimensions: {
        awayMax: 0,
        homeMax: 0
    },
    defaultMarketGroup: 0,
    defaultSport: 0,
    firstPageSports: [],
    marketGroups: [],
    marketGroupsBySport: [],
    showMarketMakerInBetrix: false
}

// export default settings