import React from 'react'
import DatePicker from 'react-date-picker'

interface Props {
    value: Date | undefined
    onChange: (value: Date) => void
}

const ReactDatePicker = ({ onChange, value }: Props) => (
    <DatePicker
        onChange={onChange}
        value={value}
    />
)

export default ReactDatePicker