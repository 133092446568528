import React from 'react'
import FaIcon from '../../FaIcon'

interface Props {
    awayScore: number
    hasResult: boolean
    homeScore: number
    wintype: string
}

const BetResult = ({ awayScore, hasResult, homeScore, wintype }: Props) => {
    if (!hasResult) {
        return null
    }
    
    return (
        <>
            <div className="score">
                <span>{homeScore}</span>
                <span>:</span>
                <span>{awayScore}</span>
            </div>
            <div className="result">{getResultIcon(wintype)}</div>
        </>
    )
}

export default BetResult

const getResultIcon = (wintype: string): JSX.Element | null => {
    if (wintype) {
        if (wintype === 'WW') {
            return (
                <FaIcon
                    icon="check"
                    type="solid"
                    title="Win"
                />
            )
        }
        if (wintype === 'CO') {
            return (
                <FaIcon
                    icon="sign-out-alt"
                    type="solid"
                    title="Cashout"
                />
            )
        }
        if (wintype === 'PU') {
            return (
                <FaIcon
                    icon="undo"
                    type="solid"
                    title="Money Back"
                />
            )
        }
        return (
            <FaIcon
                icon="times"
                type="solid"
                title="Loss"
            />
        )
    }

    return null
}