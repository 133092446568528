import React from 'react'
import './style.scss'

function BetrixInfoPopup() {
    return (
        <div className="BetrixInfoPopup">
            <div className="explanation">
                <div className="selection">
                    <span className="color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="text">&nbsp;&nbsp;Selected Results</span>
                </div>
                <div className="insurance">
                    <span className="color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="text">&nbsp;&nbsp;Money Back</span>
                </div>
            </div>
            <p>You can choose any combination of scores to bet on by selecting them in the Betrix, or by building a bet using the quick buttons to the right.</p>
            <p>Clicking twice on a score creates an insurance: your stake is refunded if this result occurs.</p>
            <p>The odds of your selection is displayed at the bottom. You may also choose to bet against your selection.</p>
            <p>You can also include scores outside of the Betrix, and these are grouped into &quot;Any other home&quot;, &quot;Any other draw&quot; and &quot;Any other away&quot;.</p>
            <p>&quot;Any other home&quot; are all scores outside of the Betrix that results in a home win etc.</p>
        </div>
    )
}

export default BetrixInfoPopup