import React from 'react';
import FaIcon from '../FaIcon';
import './style.scss';

interface Props {
    description: string
}

const InfoTooltip = ({ description }: Props) => {
    return (
        <div className="InfoTooltip">
            <FaIcon
                icon="info-circle"
                type="solid"
            />
            <span>{description}</span>
        </div>
    );
}

export default InfoTooltip;