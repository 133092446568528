import React from 'react'
import MarketNames from './market-names'
import { getFlagElement } from '../../../helpers'
import { SportId } from '../../../state/enums'
import './style.scss'

interface Props {
    leagueName: string
    regionName: string
    markets: string[]
    sportId: SportId
}

const LeagueTableTitle = ({ leagueName, regionName, markets, sportId }: Props) => (
    <div className="grid-layout league-table-title">
        <div className="match-column league-title">
            {getFlagElement(regionName)}
            <span>{`${regionName} ${leagueName}`}</span>
        </div>
        <MarketNames markets={markets} sportId={sportId}/>
    </div>        
)

export default LeagueTableTitle