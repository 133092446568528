import { BetTypeId, SportId, BetTypeGroupId, OutcomeId, MatrixValue, ScopeId/*, ScopeName*/ } from '../../enums';
import { MarketMakerSelection } from '../marketmaker/models';
import { MultiSystemsInputData } from '../../../network/rest/models';
import { MultiSystemsData, SystemSingleData } from '../betrix-combo/models';

export interface Betslip {
    betrix: Array<BSBetrixItem>
    betrixCombo: BetslipBetrixCombo
    cashout: string
    history: BetslipHistory
    index: number
    mode: BetslipMode
    submode: BetslipSubmode
    standard: Array<BSStandardItem>
}

export enum BetslipMode {
    Standard,
    BetrixCombo
}

export enum BetslipSubmode {
    MultiSystems,
    SystemSingle
}

export enum SSMarketOptions {
    HomeWins,
    AwayWins,
    Draws,
    Goals
}

export interface BetslipBetrixCombo {
    MSData: Array<MultiSystemsData>
    MSPayout: Array<MatrixValue>
    multiSystems: Array<BSMultiSystemsItem>
    SSBetweenOption: {
        name: string
        odds: number
    } | undefined
    SSData: SystemSingleData
    SSMarket: SSMarketOptions
    SSRange: Array<MatrixValue>
    systemSingle: Array<BSSystemSingleItem>
}

export interface BSMultiSystemsItem extends MultiSystemsInputData {
    disabled: boolean
    matchId: string
    opened: boolean
    outcomeId: OutcomeId
    betTypeId?: BetTypeId
    MMBetTypeId?: BetTypeId
    subitems?: Array<BSBetrixSubitem>
}

export interface BSSystemSingleItem {
    awayTeam: string
    disabled: boolean
    homeTeam: string
    matchId: string
    sportId: SportId
}

export interface BetslipHistory {
    items: Array<BetslipHistoryItem>
    subitems: Array<Array<BetslipHistoryItem>>
    standard: Array<BetslipHistoryStandard>
}

export interface BetslipHistoryItem {
    away: string
    awayscore: number
    betslip_uuid: string
    betodds: string
    betoutcome: OutcomeId
    bettype: BetTypeId
    bettype2: BetTypeId
    home: string
    homescore: number
    issettled: boolean
    item_uuid: string
    league: string
    match_id: string
    matchscope_id: number
    payout: string
    plannedstartdate: string
    plannedstarttime: string
    region: string
    result: boolean
    sportid: SportId
    stake: string
    uuid: string
    wintype: string
}

export interface BetslipHistoryStandard {
    account_id: number
    head_iswon: boolean
    head_payout: string
    head_odds: string
    head_stake: string
    insertedat: string
    issettled: boolean
    tot_stake: string
    uuid: string
}

export interface Selection {
    insurance: boolean // false = normal bet, true = insured bet
    outcome: OutcomeName
}

export interface SelectionInternal {
    insurance: boolean
    outcomeid: number
    bettypeid: number
}

export type OutcomeName = '1'|'X'|'2'|'0'|'3'|'4'|'5'|'6'|'0-1'|'0-2'|'0-3'|'0-4'|'3+'|'4+'|'5+'|'6+'
|'7+'|'First'|'Second'|'Tie'|'First & Yes'|'First & No'|'Second & Yes'|'Second & No'|'Tie & Yes'
|'Tie & No'|'-5+'|'2-3'|'0-30'|'31-60'|'61+'|'None'|'7'|'8'|'9'|'10'|'11'|'8+'|'9+'|'10+'|'11+'|'12+'
|'0-5'|'0-6'|'-1+'|'-6+'|'1/1'|'X/1'|'2/1'|'1/X'|'X/X'|'2/X'|'1/2'|'X/2'|'2/2'|'Both'|'Home'|'Away'
|'Under 2 goals'|'X2/1'|'1/X2'|'1-'|'X2/X2'|'1X/1X'|'1X/2'|'2/1X'|'0-10'|'11-20'|'21-30'|'31-40'
|'41-50'|'51-60'|'61-70'|'71-80'|'81+'|'-7+'|'1+'|'0-15'|'16-30'|'31-45'|'46-60'|'61-75'|'76+'|'-1'
|'-2'|'-3'|'-4'|'-5'|'-6'|'-3+'|'-4+'|'-2+'|'2+'|'0+'|'0-'|'Any other result'|'Betrix'|string


|'Error'
export type MultiSelection = '1X2'|'European Handicap -9'|'European Handicap -8'|'European Handicap -7'|
'European Handicap -6'|'European Handicap -5'|'European Handicap -4'|'European Handicap -3'|
'European Handicap -2'|'European Handicap -1'|'European Handicap 0'|'European Handicap 1'|
'European Handicap 2'|'European Handicap 3'|'European Handicap 4'|'European Handicap 5'|
'European Handicap 6'|'European Handicap 7'|'European Handicap 8'|'European Handicap 9'|
'Win Margin'|'Half Time/Full Time'|'First Half/Second Half'|'Highest Scoring Half'|
'Time of First Goal'|'First Two Goals'|'Exact Number of Goals'|'Number of Goals'|
'Team First to Score'|'Teams to Score'|'Home Number of Goals'|'Home Exact Number of Goals'|
'Home Time of First Goal'|'Home Highest Scoring Half'|'Away Number of Goals'|'Correct Score'
|'Away Exact Number of Goals'|'Away Time of First Goal'|'Away Highest Scoring Half'|'Error'

export interface BetslipInsertBetslipItemOutput {
    odds: number
    marketName: MultiSelection
    matchId: string
    stake: number
    // betslipSubItems?: BetslipInsertBetslipSubitemOutput[]
    scope: string // ScopeName
    selection?: Selection[]
}

export interface BetslipInsertBetslipSubitemOutput {
    odds: number
    outcome: OutcomeName
    marketName: MultiSelection
/*
    matchId: string
    stake: number
    selection?: Selection[]
*/
}

export interface BetslipInsert {
    betslip: BetslipInsertBetslip
    betslipItems: BetslipInsertBetslipItem[]
    betrixMaxScoreHome: number
    betrixMaxScoreAway: number
    betrixMaxScoreP1Home: number
    betrixMaxScoreP2Home: number
    betrixMaxScoreP3Home?: number
    betrixMaxScoreP1Away: number
    betrixMaxScoreP2Away: number
    betrixMaxScoreP3Away?: number
}

export interface BetslipInsertOutput {
    // betslip: BetslipInsertBetslip
    accountId: string
    tenantId: number
    betslipItems: BetslipInsertBetslipItemOutput[]
    // betrixMaxScore: number
    // betrixMaxScorePeriod: number
    betrixMaxScoreHome: number
    betrixMaxScoreAway: number
    betrixMaxScoreP1Home: number
    betrixMaxScoreP2Home: number
    betrixMaxScoreP3Home?: number
    betrixMaxScoreP1Away: number
    betrixMaxScoreP2Away: number
    betrixMaxScoreP3Away?: number
    currency: string
}

export interface BetslipInsertBetslip {
    account_id: string
    tenant_id: number
    tot_stake: number
    client_ip?: string
    head_odds?: number
    head_stake?: number
}

export interface BetslipInsertBetslipItem {
    betodds: number
    betoutcome: OutcomeId
    bettype: BetTypeId
    match_id: string
    stake: number
    scopeId: ScopeId
    betslipSubItems?: BetslipInsertBetslipSubitem[]
    bettype2?: BetTypeId
    selection?: SelectionInternal[]
}

export interface BetslipInsertBetslipSubitem {
    betodds: number
    betoutcome: OutcomeId
    bettype: BetTypeId
    insurance: boolean
}

export interface BetslipItem {
    awayTeam: string
    betTypeId: BetTypeId
    homeTeam: string
    index: number
    matchId: string
    oddsValue: string
    outcomeId: OutcomeId
    outcomeName: string
    sportId: number
    scopeId: ScopeId
}

export interface BSStandardItem extends BetslipItem {
    betTypeGroupId: BetTypeGroupId
    betTypeId: BetTypeId
    outcomeValue: string | null
    MMSelections?: Array<MarketMakerSelection>
    MMBetTypeId?: BetTypeId
}

export interface BSBetrixItemAllOdds {
    outcomeId: OutcomeId
    probability: number
    value: string
}

export interface BSBetrixItem extends BetslipItem {
    allOdds: Array<BSBetrixItemAllOdds>
    anyOtherBetTypeIdHome: BetTypeId
    anyOtherBetTypeIdDraw: BetTypeId
    anyOtherBetTypeIdAway: BetTypeId
    subitems: Array<BSBetrixSubitem>
    
}

export interface BSBetrixSubitem {
    awayGoals: number
    homeGoals: number
    insured: boolean
    scopeId: ScopeId
    desc?: string
    Desc?: string
    max: number
    may: number
}