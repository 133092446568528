import React, { useState } from 'react'
import clsx from 'clsx'
import BetrixQuickButtonsNewView from './NewView'
import BetrixQuickButtonsExtendedView from './ExtendedView'
import FaIcon from '../../FaIcon'
import { MatrixValue, BetTypeId, SportId } from '../../../../state/enums'
import { QuickButton, AdditionalButton, NewQuickButton } from '../index'
import { roundOddsValue } from '../../../../utils'
import { MarketOdds } from '../../../../state/stores/feed/models'
import { Platform } from '../../../../state/singles'
import './style.scss'
import {aorSoccer, aorHockey} from '../../../../assets'
interface Props {
    awayGoals: number
    homeGoals: number
    platform: Platform
    selectedAdditionalButtons: {[quickButton: string]: AdditionalButton}
    selectedLines: {[quickButton: string]: {[additionalButton: string]: MatrixValue}}
    showQuickButtons: boolean
    handleAdditionalButton: (additionalButton: AdditionalButton, quickButton: QuickButton) => void
    handleLine: (line: string, additionalButton: AdditionalButton, quickButton: QuickButton) => void
    selectedItemAnyOtherDraw: MatrixValue
    selectedItemAnyOtherAway: MatrixValue
    selectedItemAnyOtherHome: MatrixValue
    odds: MarketOdds[]
    handleSelectAnyOtherResult: (what: 'home'|'away'|'draw') => void
    anyOtherBetTypeId: BetTypeId
    anyOtherBetTypeIdDraw: BetTypeId
    anyOtherBetTypeIdHome: BetTypeId
    anyOtherBetTypeIdAway: BetTypeId
    homeTeam: string
    awayTeam: string
    sportId: SportId
    v2: boolean
    intersectionOdds: string
    intersectionOdds2: string
    IntersectionOdds: boolean
    linesExtendable?: boolean
    newQuickButtons: { name: string, value: NewQuickButton }[][]
    selectedNewQuickButton: NewQuickButton
    handleNewQuickButton: (type: NewQuickButton) => void
    betrixToggle: boolean
    handleBetrixToggle: () => void
}

function BetrixQuickButtons({ handleBetrixToggle, betrixToggle, homeTeam, awayTeam, newQuickButtons, selectedNewQuickButton, handleNewQuickButton, intersectionOdds2, intersectionOdds, IntersectionOdds, linesExtendable, v2, sportId, anyOtherBetTypeId, anyOtherBetTypeIdDraw, anyOtherBetTypeIdHome, anyOtherBetTypeIdAway, selectedItemAnyOtherDraw, selectedItemAnyOtherHome, selectedItemAnyOtherAway, odds, handleSelectAnyOtherResult, awayGoals, homeGoals, platform, selectedAdditionalButtons, selectedLines, showQuickButtons, handleAdditionalButton, handleLine }: Props) {
    const [isExtendedView, setIsExtendedView] = useState(false)

    let oddsFoundHome = odds.find(o => o.bettypeid === anyOtherBetTypeIdHome)
    if (oddsFoundHome === undefined) {
        oddsFoundHome = odds.find(o => o.bettypeid === BetTypeId.AnyOtherUnknownHome)
    }
    const oddsValueHome = (oddsFoundHome) ? oddsFoundHome.oddsvalue : '1.00'

    let oddsFoundAway = odds.find(o => o.bettypeid === anyOtherBetTypeIdAway)
    if (oddsFoundAway === undefined) {
        oddsFoundAway = odds.find(o => o.bettypeid === BetTypeId.AnyOtherUnknownAway)
    }
    const oddsValueAway = (oddsFoundAway) ? oddsFoundAway.oddsvalue : '1.00'

    let oddsFoundDraw = odds.find(o => o.bettypeid === anyOtherBetTypeIdDraw)
    if (oddsFoundDraw === undefined) {
        oddsFoundDraw = odds.find(o => o.bettypeid === BetTypeId.AnyOtherUnknownDraw)
    }
    const oddsValueDraw = (oddsFoundDraw) ? oddsFoundDraw.oddsvalue : '1.00'

    const iconName = (isExtendedView) ? 'compress-arrows-alt' : 'expand-arrows-alt'
    const aor = sportId === SportId.Soccer ? aorSoccer : sportId === SportId.Hockey ? aorHockey : undefined
    
    const onNewButtonClick = (value: NewQuickButton) => {
        handleNewQuickButton(value)

        if (isExtendedView) {
            setIsExtendedView(false)
        }
    }

    const extensionDisabled = selectedNewQuickButton !== 0
    const isLineSelected = checkLineSelection(selectedLines)
    
    return (
        <div className="BetrixQuickButtons">
            <FaIcon
                className={clsx('expand-icon', (!showQuickButtons || extensionDisabled) && 'disabled')}
                icon={iconName}
                type="solid"
                onClick={() => showQuickButtons && !extensionDisabled && setIsExtendedView(!isExtendedView)}
            />
            {/*sportId === SportId.Hockey || !v2 ?
            <button
                className={clsx('any-other-button', selectedItemAnyOther === MatrixValue.Selected && 'selected', selectedItemAnyOther === MatrixValue.Insured && 'insured')}
                onClick={() => handleSelectAnyOtherResult()}
            >
                ANY OTHER RESULT
                &nbsp;&nbsp;
                {roundOddsValue(oddsValue, 3)}
            </button> : void 0*/}
            {sportId === SportId.Hockey || !v2 ?
            <div className={'any-other-wrapper aobsize' + awayGoals}>
                <div className={clsx('betrix-odds', IntersectionOdds ? 'animated' : '')}>
                    {betrixToggle ? <span>Odds:</span> : <span>&nbsp;</span>}
                    {!linesExtendable && intersectionOdds && betrixToggle ? <span className = "animate">{roundOddsValue(intersectionOdds, 2)}</span> : null}
                </div>
                <button
                    className={clsx(sportId === SportId.Hockey && 'aor-hockey', 'any-other-button aob1', selectedItemAnyOtherHome === MatrixValue.Selected && 'selected', selectedItemAnyOtherHome === MatrixValue.Insured && 'insured')}
                    onClick={() => handleSelectAnyOtherResult('home')}
                >
                    <img src = {aor}/>
                    <div title = "Any other result (Home win)">
                        Home:
                        &nbsp;
                        {betrixToggle ? roundOddsValue(oddsValueHome, 3) : selectedItemAnyOtherHome === MatrixValue.Selected ? roundOddsValue(intersectionOdds, 2) : selectedItemAnyOtherHome === MatrixValue.Insured ? platform === 'desktop' ? 'Insured' : 'Ins.' : roundOddsValue(oddsValueHome, 3)}
                    </div>
                </button>

                <button
                    className={clsx(sportId === SportId.Hockey && 'aor-hockey', 'any-other-button aob2', selectedItemAnyOtherDraw === MatrixValue.Selected && 'selected', selectedItemAnyOtherDraw === MatrixValue.Insured && 'insured')}
                    onClick={() => handleSelectAnyOtherResult('draw')}
                >
                    <img src = {aor}/>
                    <div title = "Any other result (Draw)">
                        Draw:
                        &nbsp;
                        {betrixToggle ? roundOddsValue(oddsValueDraw, 3) :selectedItemAnyOtherDraw === MatrixValue.Selected ? roundOddsValue(intersectionOdds, 2) : selectedItemAnyOtherDraw === MatrixValue.Insured ? platform === 'desktop' ? 'Insured' : 'Ins.' : roundOddsValue(oddsValueDraw, 3)}
                    </div>
                </button>

                <button
                    className={clsx(sportId === SportId.Hockey && 'aor-hockey', 'any-other-button aob3', selectedItemAnyOtherAway === MatrixValue.Selected && 'selected', selectedItemAnyOtherAway === MatrixValue.Insured && 'insured')}
                    onClick={() => handleSelectAnyOtherResult('away')}
                >
                    <img src = {aor}/>
                    <div title = "Any other result (Away win)">
                        Away:
                        &nbsp;
                        {betrixToggle ? roundOddsValue(oddsValueAway, 3) :selectedItemAnyOtherAway === MatrixValue.Selected ? roundOddsValue(intersectionOdds, 2) : selectedItemAnyOtherAway === MatrixValue.Insured ? platform === 'desktop' ? 'Insured' : 'Ins.' : roundOddsValue(oddsValueAway, 3)}
                    </div>
                </button>

            </div> : void 0}

            <FaIcon onClick={() => handleBetrixToggle()} className = {clsx('betrix-toggle', betrixToggle && 'selected')} icon = "delicious" type="brand"/>
            <div className={clsx('button-container', showQuickButtons && 'visible')}>
                {isExtendedView ?
                    <>
                    <BetrixQuickButtonsNewView
                        homeTeam = {homeTeam}
                        awayTeam = {awayTeam}
                        newQuickButtons = {newQuickButtons}
                        selectedNewQuickButton = {selectedNewQuickButton}
                        handleNewQuickButton = {onNewButtonClick}
                        isLineSelected={isLineSelected}
                    />
                    <BetrixQuickButtonsExtendedView
                        awayGoals={awayGoals}
                        homeGoals={homeGoals}
                        platform={platform}
                        selectedAdditionalButtons={selectedAdditionalButtons}
                        selectedLines={selectedLines}
                        handleAdditionalButton={handleAdditionalButton}
                        handleLine={handleLine}
                    />
                    </>
                :
                    <BetrixQuickButtonsNewView
                        homeTeam = {homeTeam}
                        awayTeam = {awayTeam}
                        newQuickButtons = {newQuickButtons}
                        selectedNewQuickButton = {selectedNewQuickButton}
                        handleNewQuickButton = {handleNewQuickButton}
                        isLineSelected={isLineSelected}
                    />
                }
            </div>
        </div>
    )
}

export default BetrixQuickButtons


const checkLineSelection = (selectedLines: {[quickButton: string]: {[additionalButton: string]: MatrixValue}}) => {
    const marketKeys = Object.keys(selectedLines)

    for (const market of marketKeys) {
        const buttons = selectedLines[market]
        const buttonKeys = Object.keys(buttons)
        for (const button of buttonKeys) {
            const lines = buttons[button]
            const lineKeys = Object.keys(lines)
            for (const line of lineKeys) {
                const value = lines[line]
                if (value !== MatrixValue.NotSelected) {
                    return true
                }
            }
        }
    }

    return false
}