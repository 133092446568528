import React from 'react'
import clsx from 'clsx'
import { QuickButton, quickButtons, additionalButtons, AdditionalButton } from '../../index'
import { MatrixValue } from '../../../../../state/enums'
import { Platform } from '../../../../../state/singles'
import { isBetrixQuickButtonActive } from '../../../../../utils'
import { getQuickButtonLines } from '../../functions'

interface Props {
    awayGoals: number
    homeGoals: number
    platform: Platform
    selectedAdditionalButtons: {[quickButton: string]: AdditionalButton}
    selectedLines: {[quickButton: string]: {[additionalButton: string]: MatrixValue}}
    handleAdditionalButton: (additionalButton: AdditionalButton, quickButton: QuickButton) => void
    handleLine: (line: string, additionalButton: AdditionalButton, quickButton: QuickButton) => void
}

function BetrixQuickButtonsExtendedView({ awayGoals, homeGoals, platform, selectedAdditionalButtons, selectedLines, handleAdditionalButton, handleLine }: Props) {
    return (
        <>
            {quickButtons.map(quickButton => {
                const quickButtonName = quickButton.name
                const additionalButtonSelected = selectedAdditionalButtons[QuickButton[quickButton.value]]
                const selectedButtonLines = getQuickButtonLines(quickButton.value, additionalButtonSelected, homeGoals, awayGoals)
                const active = isBetrixQuickButtonActive(selectedLines, quickButton.value, selectedAdditionalButtons[QuickButton[quickButton.value]])

                return (
                    <div key={quickButton.value} className="buttons-wrapper">
                        <div className="left-column">
                            <button className={`quick-button-active-${active}`}>{quickButtonName}</button>
                        </div>
                        <div className="right-column">
                            <div className={`additional-buttons ${platform}`}>
                                {additionalButtons.map(additionalButton => {
                                    const { name, value } = additionalButton

                                    if (quickButton.value === QuickButton.GoalDifference && value === AdditionalButton.Exact) {
                                        return null
                                    }

                                    const additionalButtonName = (quickButton.value === QuickButton.GoalDifference) ? (value === AdditionalButton.Over ? 'Home' : 'Away') : name

                                    return (
                                        <button
                                            key={value}
                                            className={clsx(value === additionalButtonSelected && 'selected')}
                                            onClick={() => handleAdditionalButton(value, quickButton.value)}
                                        >
                                            {additionalButtonName}
                                        </button>
                                    )
                                })}
                            </div>
                            {(quickButton.value === QuickButton.GoalDifference) ?
                                <>
                                    <div className={`button-lines ${platform}`}>
                                        {selectedButtonLines.map((line, index) => {
                                            if (line.includes('+')) {
                                                return null
                                            }

                                            const quickButtonText = QuickButton[quickButton.value]
                                            const additionalButtonText = AdditionalButton[additionalButtonSelected]
                                            const selectedLineValue = (quickButtonText && additionalButtonText) ? selectedLines[quickButtonText][additionalButtonText][line] : MatrixValue.NotSelected

                                            return (
                                                <button
                                                    key={index}
                                                    className={clsx(selectedLineValue === MatrixValue.Selected && 'selected')}
                                                    disabled={additionalButtonSelected < 0}
                                                    onClick={() => additionalButtonSelected >= 0 && handleLine(line, additionalButtonSelected, quickButton.value)}
                                                >
                                                    {line}
                                                </button>
                                            )
                                        })}
                                    </div>
                                    <div className={`button-lines ${platform}`}>
                                        {selectedButtonLines.map((line, index) => {
                                            if (!line.includes('+')) {
                                                return null
                                            }

                                            const quickButtonText = QuickButton[quickButton.value]
                                            const additionalButtonText = AdditionalButton[additionalButtonSelected]
                                            const selectedLineValue = (quickButtonText && additionalButtonText) ? selectedLines[quickButtonText][additionalButtonText][line] : MatrixValue.NotSelected

                                            return (
                                                <button
                                                    key={index}
                                                    className={clsx(selectedLineValue === MatrixValue.Selected && 'selected')}
                                                    disabled={additionalButtonSelected < 0}
                                                    onClick={() => additionalButtonSelected >= 0 && handleLine(line, additionalButtonSelected, quickButton.value)}
                                                >
                                                    {line}
                                                </button>
                                            )
                                        })}
                                    </div>
                                </>
                            :
                                <div className={`button-lines ${platform}`}>
                                    {selectedButtonLines.map((line, index) => {
                                        const quickButtonText = QuickButton[quickButton.value]
                                        const additionalButtonText = AdditionalButton[additionalButtonSelected]
                                        const selectedLineValue = (quickButtonText && additionalButtonText) ? selectedLines[quickButtonText][additionalButtonText][line] : MatrixValue.NotSelected

                                        return (
                                            <button
                                                key={index}
                                                className={clsx(selectedLineValue === MatrixValue.Selected && 'selected')}
                                                disabled={additionalButtonSelected < 0}
                                                onClick={() => additionalButtonSelected >= 0 && handleLine(line, additionalButtonSelected, quickButton.value)}
                                            >
                                                {line}
                                            </button>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default BetrixQuickButtonsExtendedView