import React from 'react'
import EmptyCells from './empty-cells'
import { SportId, BetTypeGroupId, OutcomeId, ScopeId } from '../../../state/enums'
import { MarketOdds } from '../../../state/stores/feed/models'
import { roundOddsValue, saveStandardBSItem, checkStandardItemOnBetslip, isMobile } from '../../../utils'

interface Props {
    awayTeam: string
    homeTeam: string
    market: string
    marketOdds: MarketOdds[]
    sportId: SportId
    scopeId: ScopeId
}

const MatchMarket = ({ scopeId, awayTeam, homeTeam, market, marketOdds, sportId }: Props) => (
    <div className="market-odds">
        {marketOdds ?
            marketOdds.map((item, index) => {
                const { bettypegroupid, bettypeid, matchid, oddsvalue, outcomeid, value } = item
                const isItemOnBetslip: boolean = checkStandardItemOnBetslip(matchid, bettypeid, outcomeid)
                const activeClassName: string = (isItemOnBetslip) ? 'active' : 'empty'
                const mobileClassName: string = (isMobile()) ? 'mobile' : 'desktop'

                return (
                    <div
                        key={index}
                        className={`odds-wrapper ${activeClassName} ${mobileClassName}`}
                        onClick={() => saveStandardBSItem(scopeId, awayTeam, homeTeam, item, sportId)}
                    >
                        {((bettypegroupid === BetTypeGroupId.OverUnder && outcomeid === OutcomeId.Over) ||
                        (bettypegroupid === BetTypeGroupId.AsianHandicap && outcomeid === OutcomeId.AHHome)) ?
                            <>
                                <span className="outcome-value">{value}</span>
                                <span className="odds-value">{roundOddsValue(oddsvalue, 2)}</span>
                            </>
                        :
                            <span className="odds-value">{roundOddsValue(oddsvalue, 2)}</span>
                        }
                    </div>
                )
            })
        :
            <EmptyCells market={market}/>
        }
    </div>
)

export default MatchMarket