import { Feed } from './models';
import { defaultQuickLeagues } from '../../../assets';

const quickLeaguesStorage = localStorage.getItem('quickLeagues')
const quickLeagues = (quickLeaguesStorage) ? JSON.parse(quickLeaguesStorage) : defaultQuickLeagues

const feed: Feed = {
    headerMatches: [],
    leagues: [],
    mainMarkets: [],
    matches: [],
    matchesToday: [],
    odds: [],
    oddsToday: [],
    quickLeagues,
    regions: {},
    sports: []
}

export default feed