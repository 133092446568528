import React from 'react'
import Base from '../../components/Base'
import liveServer, { liveServerId, liveServerPort, liveServerSecret } from '../../../network/websocket'
import config from '../../../configjson'
class LiveBetting extends React.Component {
    constructor(props) {
        super(props)

        establishWSConnection()
    }
    render() {
        return (
            <Base>
                <span
                    style={{ color: '#FFF', cursor: 'pointer' }}
                    onClick={() => liveServer.send('change-interest', {
                        id: config.configuration_id,
                        tenant: config.tenant_id
                    })}
                >
                    LIVE BETTING
                </span>
            </Base>
        )
    }
}

export default LiveBetting

const establishWSConnection = (): void => {
    fetch(`http://${liveServerId}:${liveServerPort}/authorize?secret=${liveServerSecret}&id=${config.configuration_id}&tenant=${config.tenant_id}`)
        .then(response => response.json())
        .then(data => {
            if (data.token) {
                liveServer.connect({
                    path: 'ws',
                    params: {
                        token: data.token
                    }
                })
            }
        })
}