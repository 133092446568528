import React from 'react'
import OddsItem from '../../OddsItem'
import FaIcon from '../../FaIcon'
import clsx from 'clsx'
import { SportId, BetTypeId, BetTypeGroupId, OutcomeId, ScopeId } from '../../../../state/enums'
import { getMarketNameAndOptions, getOutcomeText, isMobile, saveStandardBSItem, roundOddsValue, checkMMItemOnBetslip, clearMMOddsItem, getTeamNameFromBetType, GetNameFromMarketGroup } from '../../../../utils'
import { MarketOdds } from '../../../../state/stores/feed/models'
import { MarketMaker, MarketMakerSelection } from '../../../../state/stores/marketmaker/models'
import { store } from '../../../../state'
import { MarketGroupItemElement } from '../../../../state/stores/settings/models'

interface Props {
    token: string
    awayTeam: string
    homeTeam: string
    isWide: boolean
    market: string
    marketMaker: MarketMaker
    odds: Array<MarketOdds>
    sportId: SportId
    marketGroupData: MarketGroupItemElement
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    scopeId: ScopeId
}

const NormalMarketBoxMM = ({ token, scopeId, minOdds, maxOdds, minCSOdds, maxCSOdds, marginParameter, margin, awayTeam, homeTeam, isWide, market, marketGroupData, marketMaker, odds, sportId }: Props) => {
    const teamName = (odds && odds[0]) ? getTeamNameFromBetType(awayTeam, homeTeam, odds[0].bettypeid) : '';
    const marketName: string = (marketGroupData && marketGroupData.name) ? GetNameFromMarketGroup(marketGroupData.name, homeTeam, awayTeam) : getMarketNameAndOptions(market, sportId, { teamName }).name
    if (!odds || odds.length === 0) {return <div/>}
    const betTypeGroupId = odds[0].bettypegroupid
    const betTypeId = odds[0].bettypeid
    const matchId = odds[0].matchid
    const isCS = betTypeGroupId === BetTypeGroupId.CorrectScore
    const idToSave = (isCS) ? betTypeGroupId : betTypeId
    const typeProperty = (isCS) ? BetTypeGroupId[betTypeGroupId] : BetTypeId[betTypeId]
    const betType = marketMaker[matchId] && marketMaker[matchId][typeProperty]
    const shouldCheckMM = betType && betType.selections && betType.selections.length > 1
    const oddsValueToShow: string = (shouldCheckMM) ? roundOddsValue(betType.odds, 2) : ''
    const oddsItem: MarketOdds = {
        bettypegroupid: BetTypeGroupId[market],
        bettypeid: (betType && betType.selections.length > 1) ? BetTypeId.MMGeneral : idToSave,
        islive: false,
        matchid: matchId,
        name: '',
        oddsvalue: oddsValueToShow,
        oldoddsvalue: oddsValueToShow,
        // psboddsvalue: oddsValueToShow,
        outcomename: '',
        outcomeid: (betType && betType.selections.length === 1) ? betType.selections[0].outcomeId : OutcomeId.BetrixYes,
        probability: (betType) ? ((betType.selections.length === 1) ? betType.selections[0].probability.toString() : betType.probability.toString()) : '',
        value: null
    }

    const selectionsToSave = (shouldCheckMM) ? betType.selections : undefined
    const betTypeIdToSave = (shouldCheckMM) ? idToSave : undefined
    
    return (
        <div className={clsx('market-box', isWide && 'wide')}>
            {shouldCheckMM &&
                <FaIcon
                    className="reset-market-maker"
                    icon="times"
                    type="solid"
                    onClick={() => clearMMOddsItem(betTypeGroupId, matchId, typeProperty, betTypeId)}
                />
            }
            <h3
                className={clsx('single-odds', 'market-name', shouldCheckMM && 'marketmaker', shouldCheckMM && checkMMItemOnBetslip(matchId, betType.selections, idToSave) && 'active')}
                onClick = {() => shouldCheckMM && saveMultiSelection(scopeId, marketMaker, awayTeam, homeTeam, oddsItem, sportId, selectionsToSave, betTypeIdToSave, typeProperty)}
            >
                <span className="market-name-value">{marketName}</span>
                <span>{oddsValueToShow}</span>
            </h3>
            <div className="odds-wrapper">
                {marketGroupData ?
                    marketGroupData.outcomes.map(outcome => {
                        const singleOdds = odds.find(o => o.outcomeid === outcome.id)
                        if (!singleOdds) {
                            return null
                        }

                        return (
                            <OddsItem token = {token} psbOdds = {false}
                                margin={margin}
                                marginParameter = {marginParameter}
                                minOdds={minOdds}
                                maxOdds={maxOdds}
                                minCSOdds={minCSOdds}
                                maxCSOdds={maxCSOdds}
                                key={outcome.id}
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                className="single-odds"
                                item={singleOdds}
                                marketMaker={true}
                                marketMakerData={marketMaker}
                                optionsLength={odds.length}
                                options={odds}
                                selectionText={GetNameFromMarketGroup(outcome.name, homeTeam, awayTeam)}
                                sportId={sportId}
                                scopeId={scopeId}
                            />
                        )
                    })
                :
                    odds.map((item, index) => {
                        const { bettypegroupid, outcomeid, outcomename, value } = item
                        const selectionText: string = 
                            (isMobile() ||
                            bettypegroupid === BetTypeGroupId.CorrectScore ||
                            bettypegroupid === BetTypeGroupId.HalfTimeFullTime ||
                            bettypegroupid === BetTypeGroupId.HalfTimeFullTime2 ||
                            bettypegroupid === BetTypeGroupId.HalfTimeFullTime3 ||
                            bettypegroupid === BetTypeGroupId.FirstHalfSecondHalf) ?
                                outcomename
                            :
                                getOutcomeText(awayTeam, homeTeam, outcomeid, value)

                        return (
                            <OddsItem token = {token} psbOdds = {false}
                                margin = {margin}
                                marginParameter = {marginParameter}
                                minOdds={minOdds}
                                maxOdds={maxOdds}
                                minCSOdds={minCSOdds}
                                maxCSOdds={maxCSOdds}
                                key={index}
                                awayTeam={awayTeam}
                                homeTeam={homeTeam}
                                className="single-odds"
                                item={item}
                                marketMaker={true}
                                marketMakerData={marketMaker}
                                optionsLength={odds.length}
                                options={odds}
                                selectionText={selectionText}
                                sportId={sportId}
                                scopeId={scopeId}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default NormalMarketBoxMM

const saveMultiSelection = (scopeId: ScopeId, marketMaker: MarketMaker, awayTeam: string, homeTeam: string, oddsItem: MarketOdds, sportId: SportId, selections: Array<MarketMakerSelection> | undefined, idToSave: number | undefined, typeProperty: string) => {
    if (selections && idToSave) {
        const { matchid } = oddsItem
        saveStandardBSItem(scopeId, awayTeam, homeTeam, oddsItem, sportId, selections, idToSave)

        const newMarketMaker = marketMaker
        delete newMarketMaker[matchid][typeProperty]

        if (!Object.keys(newMarketMaker[matchid]).length) {
            delete newMarketMaker[matchid]
        }

        store.update(main => {
            main.marketmaker = newMarketMaker
            return main
        })
    }
}