import React from 'react'
import Base from '../../../views/components/Base'
import dataServer from '../../../network/rest'
import MainTitle from '../../components/MainTitle'
import MarketBoxes from '../../components/MarketBoxes'
import { wideMarkets, betrixDefaultLocale } from '../../../assets'
import { BetTypeGroupId, SportId, MatchStatus, ScopeId } from '../../../state/enums'
import config from '../../../configjson'
import { emptyMarketMaker } from '../../../utils'
import { BetrixSuggestedMarket, BetrixSelectionOdds, Doublette } from '../../../state/stores/betrix/models'
import { MarketMaker } from '../../../state/stores/marketmaker/models'
import { Odds, League, Match, QuickLeague } from '../../../state/stores/feed/models'
import { Platform } from '../../../state/singles'
import { RouteChildrenProps } from 'react-router'
import { store } from '../../../state'
import './style.scss'

interface Props extends RouteChildrenProps {
    betrixSelectionOdds: BetrixSelectionOdds
    betrixSuggestedMarkets: BetrixSuggestedMarket[]
    doublettes: Doublette[]
    leagues: League[]
    marketMaker: MarketMaker
    matches: Match[]
    odds: Odds[]
    odds1: Odds[]
    odds2: Odds[]
    odds3: Odds[]
    platform: Platform
    quickLeagues: QuickLeague[]
    scopeId: ScopeId
    token: string
}

interface State {
    requestSent: boolean
}

class SportMatch extends React.Component<Props, State> {
    private match: Match = {
        away: '',
        awayid: 0,
        home: '',
        homeid: 0,
        id: '0',
        league: '',
        leagueid: 0,
        region: '',
        plannedstartdate: '',
        plannedstarttime: '',
        sportid: SportId.Soccer,
        status: MatchStatus.NotStartedYet
    }
    constructor(props: Props) {
        super(props)

        const { match } = props

        if (match && match.params && match.params['matchid']) {
            const matchid: string = match.params['matchid']
            dataServer.request('get', 'get-odds', {
                bookmakerid: 0,
                id: config.configuration_id,
                matchid
            })
        }

        emptyMarketMaker()
    }
    componentDidUpdate(prevProps: Props) {
        const { leagues, match, matches } = this.props
        if (!this.match.id && matches.length && leagues.length && match && match.params && match.params['matchid']) {
            const matchFound = matches.find(m => m.id === match.params['matchid'])
            if (matchFound) {
                const leagueFound = leagues.find(l => l.id === matchFound.leagueid)
                if (matchFound && leagueFound) {
                    this.match = matchFound
                    this.match.region = leagueFound.region
                }
            }
        }

        if (prevProps.match && prevProps.match.params && prevProps.match.params['matchid'] &&
            match && match.params && match.params['matchid'] &&
            prevProps.match.params['matchid'] !== match.params['matchid']) {
                window.location.reload()
            }
    }

    betAgainst() {
        store.update(main => {
            return main
        })
    }

    render() {
        const { token, odds1, odds2, odds3, scopeId, marketMaker, matches, odds} = this.props
        const { away, home, league, id, region, sportid } = this.match

        const matchOdds: Odds = odds[0]
        const markets: string[] = getMarkets(matchOdds)
        const matchOdds1: Odds = odds1[0]
        const matchOdds2: Odds = odds2[0]
        const matchOdds3: Odds = odds3[0]

        return (
            <Base>
                <div className="sport-match">
                    <MainTitle
                        locale = {betrixDefaultLocale}
                        allLeagues={[]}
                        allMatches={matches}
                        league={league}
                        match={`${home} vs ${away}`}
                        matchId={id.toString()}
                        region={region}
                        sportId={sportid}
                    />
                    {matchOdds &&
                        <>
                            {/*
                            <Betrix
                                awayTeam={away}
                                doublettes={doublettes}
                                homeTeam={home}
                                marketMaker={marketMaker}
                                matchId={id}
                                odds={matchOdds.markets}
                                platform={platform}
                                selectionOdds={betrixSelectionOdds}
                                sportId={sportid}
                                suggestedMarkets={betrixSuggestedMarkets}
                            />
                            */}
                            <MarketBoxes
                                hideBetAgainst = {false}
                                token = {token}
                                betAgainst = {this.betAgainst}
                                margin = {0}
                                marginParameter = {0}
                                minOdds={0}
                                maxOdds={0}
                                minCSOdds={0}
                                maxCSOdds={0}
                                awayTeam={away}
                                homeTeam={home}
                                markets={markets}
                                marketMaker={marketMaker}
                                odds={matchOdds}
                                odds1={matchOdds1}
                                odds2={matchOdds2}
                                odds3={matchOdds3}
                                sportId={sportid}
                                scopeId={scopeId}
                            />
                        </>
                    }
                </div>
            </Base>
        )
    }
}

export default store.subscribe(main => ({
    betrixSelectionOdds: main.betrix.selectionOdds,
    betrixSuggestedMarkets: main.betrix.suggestedMarkets,
    doublettes: main.betrix.doublettes,
    leagues: main.feed.leagues,
    marketMaker: main.marketmaker,
    matches: main.feed.matches,
    odds: main.feed.odds,
    platform: main.app.platform,
    quickLeagues: main.feed.quickLeagues
}))(SportMatch)

const getMarkets = (matchOdds: Odds): string[] => {
    if (matchOdds) {
        const marketsNormal: string[] = []
        const marketsWide: string[] = []
        for (const market of Object.keys(matchOdds.markets)) {
            const isWide: boolean = wideMarkets.includes(BetTypeGroupId[market])
            if (isWide) {
                marketsWide.push(market)
            } else {
                marketsNormal.push(market)
            }
        }

        const markets: string[] = [...marketsNormal, ...marketsWide]
        return markets
    }

    return []
}