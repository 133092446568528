import React from 'react'
import FaIcon from '../FaIcon'
import './style.scss'

const leftColumnArray: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0']
const rightColumnArray: string[] = ['+5', '+10', '+15']

interface Props {
    keyboardOpened: boolean
    stake: string
    closeKeyboard: () => void
    handleStake: (stake: string) => void
}

const MobileKeyboard = ({ closeKeyboard, handleStake, keyboardOpened, stake }: Props) => {
    const openedClassName: string = (keyboardOpened) ? 'opened' : ''

    return (
        <div className={`mobile-keyboard ${openedClassName}`}>
            <div className="left-column grid-layout">
                {leftColumnArray.map(item =>
                    <span
                        key={item}
                        onClick={() => setStake(stake, item, handleStake)}
                    >
                        {item}
                    </span>    
                )}
                <span>
                    <FaIcon
                        icon="backspace"
                        type="solid"
                        onClick={() => backspace(stake, handleStake)}
                    />
                </span>
            </div>
            <div className="right-column grid-layout">
                {rightColumnArray.map(item =>
                    <span
                        key={item}
                        onClick={() => addBigNumber(stake, item, handleStake)}
                    >
                        {item}
                    </span>
                )}
                <span onClick={closeKeyboard}>Done</span>
            </div>
        </div>
    )
}

export default MobileKeyboard

const addBigNumber = (currentStake: string, item: string, handleStake: (stake: string) => void) => {
    const newStakeNumber: number = +currentStake + +item
    const newStake: string = newStakeNumber.toString()
    handleStake(newStake)
}

const backspace = (currentStake: string, handleStake: (stake: string) => void) => {
    const newStake: string = currentStake.slice(0, -1)
    handleStake(newStake)
}

const setStake = (currentStake: string, item: string, handleStake: (stake: string) => void) => {
    const newStake: string = `${currentStake}${item}`
    handleStake(newStake)
}