
import React from 'react'
import { getSportIcon, isSportWinner} from '../../../utils'
import { League, Match} from '../../../state/stores/feed/models'
import { SportId } from '../../../state/enums'
import './style.scss'
import {dayInWeek, monthInYear, OddscraftLogo} from '../../../assets'
interface Props {
    stage?: string
    timezone?: string
    allLeagues: League[]
    allMatches: Match[]
    league: string
    region: string
    sportId: SportId
    match?: string
    matchId?: string
    venue?: string
    city?: string
    date?: string
    locale: string
    tenant?: string
    
    // liveBet?: boolean
    // liveHomeScore?: number
    // liveAwayScore?: number
    // liveMinutesPlayed?: number
    // liveSpecialInfo?: string
}

const getDay = (day: number, locale: string) => {
    return dayInWeek[locale][day]
}

const getMonth = (month: number, locale: string) => {
    return monthInYear[locale][month]
}

const MainTitle = ({ stage, timezone, allLeagues, allMatches, league, match, matchId, region, sportId, venue, city, date, locale, tenant/*, liveBet, liveHomeScore, liveAwayScore, liveMinutesPlayed, liveSpecialInfo */}: Props) => {
    // const [extended, setExtended] = useState(false)
    const sport: string = SportId[sportId]
    let date_: Date
    let day: string = ''
    let month: string = ''
    if (date) {
        date_ = new Date(date)
        day = getDay(date_.getDay(), locale)
        month = getMonth(date_.getMonth(), locale)
    }
    let hs: string
    let type: string
    if (isSportWinner(sportId)) {
        const Match: string[] = match.split(' vs ')
        hs = Match[0]
        type = Match[1]
    }
    const sportIcon: string = getSportIcon(sportId)
    return (
        <div className="main-title">
            {tenant === 'Oddscraft' ? <img className = "tenant" src = {OddscraftLogo}/>:
            <span className = "tenant">{tenant}</span>}
            {sportIcon ? <img src={sportIcon}/> : void 0}
            <span className = "pre">{sport} |</span>
            {(match) ?
                <>
                    <span className = "pre"> {region} | {league} |</span>
                    {stage ? <span className = "pre"> {stage} |</span>: void 0}
                    {city ? <span className = "pre"> {city} |</span>: void 0}
                    {venue ? <span className = "pre"> {venue} |</span>: void 0}
                    {date ? <span className = "pre"> {day} | {date.substring(8,10)} {month} {date.substring(0,4)} | {date.substring(11,16)} |</span> : void 0}
                    {timezone ? <span className = "pre"> {timezone} |</span>: void 0}
                    <div>
                        {isSportWinner(sportId) ?
                        <>
                            <span className = "pre"> {type} | {sportId === SportId['Ski Jumping'] ? 'HS' : ''}{hs}</span>
                        </>
                        :
                        <h1 style = {{whiteSpace: 'pre'}}> {match}</h1>}
                    </div>
                    {/*liveBet ? <span className = "lime">&nbsp;{liveHomeScore}:{liveAwayScore} | {liveMinutesPlayed}' | {liveSpecialInfo}</span> : void 0*/}
                </>
            :
                <>
                    <div>
                        <h1>{`${region} ${league}`}</h1>
                    </div>
                </>
            }
        </div>
    )
}

export default MainTitle