import React from 'react'
import MarketBoxWithValue from '../MarketBoxes/WithValue'
import NormalMarketBoxMM from '../MarketBoxes/Normal/market-maker'
import MarketBoxWithValueMM from '../MarketBoxes/WithValue/market-maker'
import { MarketGroupItem, MarketGroupItemElement } from '../../../state/stores/settings/models'
import { Odds } from '../../../state/stores/feed/models'
import { BetTypeGroupId, SportId} from '../../../state/enums'
import { checkMarketWithValue } from '../../../utils'
import { marketMakerMarkets, nonMarketMakerMarkets } from '../../../assets'
import { MarketMaker } from '../../../state/stores/marketmaker/models'
import './style.scss'

interface Props {
    overUnderOdds: boolean
    awayTeam: string
    homeTeam: string
    marketGroupItems: Array<MarketGroupItem>
    marketMaker: MarketMaker
    odds: Odds
    sportId: SportId
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    oldOdds: boolean
    psbOdds: boolean
    betAgainst: (invOddsValue: string, matchid: string, typeProperty: string, options) => void
    token: string
    hideCorrectScore: boolean
    // disableInsurances: boolean
    hideBetAgainst: boolean
}

function itemLengthMultiselection(items: Array<MarketGroupItemElement>): number {
    let countMultiSelection: number = 0
    if (items) {
        for (const item of items) {
            if (marketMakerMarkets.includes(item.bettypegroupid)) {
                countMultiSelection++
            }
        }

        for (const item of items) {
            if (nonMarketMakerMarkets.includes(item.bettypegroupid)) {
                countMultiSelection++
            }
        }

    }
    return countMultiSelection
}
const CustomMarketBoxes = ({ hideCorrectScore, /*disableInsurances, */hideBetAgainst, token, betAgainst, overUnderOdds, minOdds, maxOdds, minCSOdds, maxCSOdds, marginParameter, margin, awayTeam, homeTeam, marketGroupItems, marketMaker, odds, sportId, oldOdds, psbOdds }: Props) => {
    const marketsWithOdds: MarketGroupItem[] = getMarketsWithOdds(marketGroupItems, odds)
    return (
        <div className="CustomMarketBoxes">
            {marketsWithOdds.map((item, i) => (
                <div
                    key={i}
                    className={`column-${itemLengthMultiselection(item.items)}`}
                >
                    {item.items.map((market, j) => {
                        const marketName: string = BetTypeGroupId[market.bettypegroupid]
                        const oddsArray = odds.markets && odds.markets[marketName] ? odds.markets[marketName] : []
                        const marketOdds0 = oddsArray.filter(o => (market.market && market.market.id) ? o.bettypeid === market.market.id : o)
                        let oddsArray1 = []
                        let marketOdds1 = []
                        let oddsArray2 = []
                        let marketOdds2 = []
                        let oddsArray3 = []
                        let marketOdds3 = []
                        let marketName1: string = marketName
                        let marketName2: string = marketName
                        let marketName3: string = marketName
                        if (marketName.startsWith('WinMargin') || marketName.startsWith('ExactNumberOfGoals')) {
                            const MarketName: string = marketName.startsWith('WinMargin') ? 'WinMargin' : marketName.startsWith('ExactNumberOfGoals') ? 'ExactNumberOfGoals' : ''
                            const keys1: string[] = Object.keys(odds.markets1)
                            for (const item of keys1) {
                                if (item.startsWith(MarketName)) {
                                    marketName1 = item
                                    break
                                }
                            }

                            const keys2: string[] = Object.keys(odds.markets2)
                            for (const item of keys2) {
                                if (item.startsWith(MarketName)) {
                                    marketName2 = item
                                    break
                                }
                            }

                            const keys3: string[] = Object.keys(odds.markets3)
                            for (const item of keys3) {
                                if (item.startsWith(MarketName)) {
                                    marketName3 = item
                                    break
                                }
                            }
                            if (marketName1) {
                                oddsArray1 = odds.markets1 && odds.markets1[marketName1] ? odds.markets1[marketName1] : []
                                marketOdds1 = oddsArray1.filter(o => (market.market && market.market.id) ? o.bettypeid === market.market.id : o)
                            }
                            if (marketName1) {
                                oddsArray2 = odds.markets2 && odds.markets2[marketName2] ? odds.markets2[marketName2] : []
                                marketOdds2 = oddsArray2.filter(o => (market.market && market.market.id) ? o.bettypeid === market.market.id : o)
                            }
                            if (marketName3) {
                                oddsArray3 = odds.markets3 && odds.markets3[marketName3] ? odds.markets3[marketName3] : []
                                marketOdds3 = oddsArray3.filter(o => (market.market && market.market.id) ? o.bettypeid === market.market.id : o)
                            }
                        } else {
                            oddsArray1 = odds.markets1 && odds.markets1[marketName] ? odds.markets1[marketName] : []
                            marketOdds1 = oddsArray1.filter(o => (market.market && market.market.id) ? o.bettypeid === market.market.id : o)
                            oddsArray2 = odds.markets2 && odds.markets2[marketName] ? odds.markets2[marketName] : []
                            marketOdds2 = oddsArray2.filter(o => (market.market && market.market.id) ? o.bettypeid === market.market.id : o)
                            oddsArray3 = odds.markets3 && odds.markets3[marketName] ? odds.markets3[marketName] : []
                            marketOdds3 = oddsArray3.filter(o => (market.market && market.market.id) ? o.bettypeid === market.market.id : o)

                        }
                        const isMarketWithValue = checkMarketWithValue(marketName)
                        const hasMarketMaker = marketMakerMarkets.includes(BetTypeGroupId[marketName])
                        if (isMarketWithValue) {
                            if (hasMarketMaker) {
                                return (
                                    <MarketBoxWithValueMM
                                        hideBetAgainst = {hideBetAgainst}
                                        token = {token}
                                        betAgainst = {betAgainst}
                                        Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                                            odds && odds.markets1 && odds.markets1[marketName1] ? odds.markets1[marketName1].length : 0,
                                            odds && odds.markets2 && odds.markets2[marketName2] ? odds.markets2[marketName2].length : 0,
                                            odds && odds.markets3 && odds.markets3[marketName3] ? odds.markets3[marketName3].length : 0
                                        ]}
                                        margin={margin}
                                        marginParameter={marginParameter}
                                        minOdds={minOdds}
                                        maxOdds={maxOdds}
                                        minCSOdds={minCSOdds}
                                        maxCSOdds={maxCSOdds}
                                        key={j}
                                        awayTeam={awayTeam}
                                        homeTeam={homeTeam}
                                        market={marketName}
                                        marketGroupData={market}
                                        marketMaker={marketMaker}
                                        odds={marketOdds0}
                                        odds1={marketOdds1}
                                        odds2={marketOdds2}
                                        odds3={marketOdds3}
                                        sportId={sportId}
                                        oldOdds = {oldOdds}
                                        psbOdds = {psbOdds}
                                    />
                                )
                            }
                            // not a market maker
                            return (overUnderOdds ?
                                <MarketBoxWithValue
                                    token = {token}
                                    marketGroupData={market}
                                    key={j}
                                    Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                                        odds && odds.markets1 && odds.markets1[marketName1] ? odds.markets1[marketName1].length : 0,
                                        odds && odds.markets2 && odds.markets2[marketName2] ? odds.markets2[marketName2].length : 0,
                                        odds && odds.markets3 && odds.markets3[marketName3] ? odds.markets3[marketName3].length : 0
                                    ]}
                                    awayTeam={awayTeam}
                                    homeTeam={homeTeam}
                                    market={marketName}
                                    marketMaker={marketMaker}
                                    odds={marketOdds0}
                                    odds1={marketOdds1}
                                    odds2={marketOdds2}
                                    odds3={marketOdds3}
                                    sportId={sportId}
                                    margin={margin}
                                    marginParameter={marginParameter}
                                    minOdds={minOdds}
                                    maxOdds={maxOdds}
                                    minCSOdds={minCSOdds}
                                    maxCSOdds={maxCSOdds}
                                    bettypegroupid={market.bettypegroupid}
                                />
                            : null)
                        }

                        if (hasMarketMaker) {
                           if (marketName === 'CorrectScore' && !hideCorrectScore || marketName !== 'CorrectScore') {
                                return (
                                    <NormalMarketBoxMM
                                        hideBetAgainst = {hideBetAgainst}
                                        token = {token}
                                        betAgainst = {betAgainst}
                                        Odds={[odds && odds.markets && odds.markets[marketName] ? odds.markets[marketName].length : 0,
                                            odds && odds.markets1 && odds.markets1[marketName1] ? odds.markets1[marketName1].length : 0,
                                            odds && odds.markets2 && odds.markets2[marketName2] ? odds.markets2[marketName2].length : 0,
                                            odds && odds.markets3 && odds.markets3[marketName3] ? odds.markets3[marketName3].length : 0
                                        ]}
                                        marginParameter={marginParameter}
                                        margin={margin}
                                        minOdds={minOdds}
                                        maxOdds={maxOdds}
                                        minCSOdds={minCSOdds}
                                        maxCSOdds={maxCSOdds}
                                        key={marketName}
                                        awayTeam={awayTeam}
                                        homeTeam={homeTeam}
                                        isWide={false}
                                        market={marketName}
                                        marketGroupData0={market}
                                        marketGroupData1={marketsWithOdds[i].items1[j]}
                                        marketGroupData2={marketsWithOdds[i].items2[j]}
                                        marketGroupData3={marketsWithOdds[i].items3[j]}
                                        marketMaker={marketMaker}
                                        odds={marketOdds0}
                                        odds1={marketOdds1}
                                        odds2={marketOdds2}
                                        odds3={marketOdds3}
                                        sportId={sportId}
                                        oldOdds = {oldOdds}
                                        psbOdds = {psbOdds}
                                    />
                                )
                            }
                        }
                        return <span key={j}/>
                    })}
                </div>
            ))}
        </div>
    )
}

export default CustomMarketBoxes

function getMarketsWithOdds(groups: MarketGroupItem[], odds: Odds) {
    const marketsWithOdds: MarketGroupItem[] = []

    for (const group of groups) {
        const groupItems: MarketGroupItemElement[] = []
        const groupItems1: MarketGroupItemElement[] = []
        const groupItems2: MarketGroupItemElement[] = []
        const groupItems3: MarketGroupItemElement[] = []
        let MarketName: string
        for (const item of group.items) {
            const { bettypegroupid, market } = item
            const marketName = BetTypeGroupId[bettypegroupid]
            MarketName = item.name
            const oddsArray = odds.markets[marketName]
            if (oddsArray) {
                const marketOdds = oddsArray.filter(o => (market && market.id) ? o.bettypeid === market.id : o)
                if (marketOdds.length) {
                    groupItems.push(item)
                }
            }

            const oddsArray1 = odds.markets1[marketName]
            if (oddsArray1) {
                const marketOdds = oddsArray1.filter(o => (market && market.id) ? o.bettypeid === market.id : o)
                if (marketOdds.length) {
                    groupItems1.push(item)
                }
            }

            const oddsArray2 = odds.markets2[marketName]
            if (oddsArray2) {
                const marketOdds = oddsArray2.filter(o => (market && market.id) ? o.bettypeid === market.id : o)
                if (marketOdds.length) {
                    groupItems2.push(item)
                }
            }

            const oddsArray3 = odds.markets3[marketName]
            if (oddsArray3) {
                const marketOdds = oddsArray3.filter(o => (market && market.id) ? o.bettypeid === market.id : o)
                if (marketOdds.length) {
                    groupItems3.push(item)
                }
            }

        }
        if (groupItems.length || groupItems1.length || groupItems2.length || groupItems3.length) {

            let found: boolean = false
            if (MarketName === 'Win Margin' || MarketName === 'Total Number of Goals') {
                for (let i: number = 0; i < marketsWithOdds.length; i++) {
                    if (marketsWithOdds[i].items.length && marketsWithOdds[i].items[0].name === MarketName || marketsWithOdds[i].items1.length && marketsWithOdds[i].items1[0].name === MarketName ||
                        marketsWithOdds[i].items2.length && marketsWithOdds[i].items2[0].name === MarketName || marketsWithOdds[i].items3.length && marketsWithOdds[i].items3[0].name === MarketName) {
                        found = true
                        if (groupItems.length) {
                            marketsWithOdds[i].items = groupItems
                        }
                        if (groupItems1.length) {
                            marketsWithOdds[i].items1 = groupItems1
                        }
                        if (groupItems2.length) {
                            marketsWithOdds[i].items1 = groupItems2
                        }
                        if (groupItems3.length) {
                            marketsWithOdds[i].items1 = groupItems3
                        }

                        break
                    }
                }
            }
            if (!found) {

                marketsWithOdds.push({
                    items: groupItems,
                    items1: groupItems1,
                    items2: groupItems2,
                    items3: groupItems3
                })
            }
        }
    }
    return marketsWithOdds
}