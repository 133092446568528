class Query {
    create = (queries: object): string => {
        let q: string = '?';
        const keys = Object.keys(queries);
        if (!keys.length) return '';
        for (let i = 0; i < keys.length; i++) {
            q += `${keys[i]}=${queries[keys[i]]}`;
            if (i < (keys.length - 1)) {
                q += '&';
            }
        }

        return q;

    }
    decode = (url: string): object => {
        let queryString: string = url.split('?')[1]
        if (!queryString) {
            return {}
        }
        const keyValuePairs: string[] = queryString.split('&');
        const object = {}; 
        for (const item of keyValuePairs) {
            const splittedItem = item.split('=');
            object[splittedItem[0]] = splittedItem[1];
        }
        return object;
    }
}

const query = new Query();

export default query;