import React from 'react'
import FaIcon from './views/components/FaIcon'
import { getRegionFlag } from './utils'

export function getFlagElement(region: string, className?: string, ignoreEuropeRegion?: boolean): JSX.Element {
    let icon: string
    if (!ignoreEuropeRegion && region.toLowerCase() === 'europe') {
        icon = 'globe-europe'
    } else if (region.toLowerCase() === 'africa') {
        icon = 'globe-africa'
    } else if (region.toLowerCase() === 'asia' || region.toLowerCase() === 'australia') {
        icon = "globe-asia"
    } else if (region.toLowerCase() === 'south america' || region.toLowerCase() === 'north america' || region.toLowerCase() === 'central america') {
        icon = "globe-americas"
    } else if (region.toLowerCase() === 'world') {
        icon = "globe"
    }

    if (icon) {
        return (
            <span style = {{position: 'absolute', right: '0px', top: '40px', color: '#fff'}}>
                <FaIcon
                    style = {{fontSize: '20px'}}
                    title = {region}
                    icon={icon}
                    type="solid"
                />
            </span>
        )
    }
    const Region: {flag: string, name: string} = getRegionFlag(region)
    return (
        <img className = {className} title = {Region.name} src={Region.flag}/>
    )
}