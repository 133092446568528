import React from 'react'
import { getMarketNameAndOptions } from '../../../utils'
import { SportId } from '../../../state/enums'

interface Props {
    markets: string[]
    sportId: SportId
}

const MarketNames = ({ markets, sportId }: Props) => (
    <div className="columns-equal-width main-markets">
        {markets.map(market => {
            const marketData = getMarketNameAndOptions(market, sportId)
            const marketName: string = marketData.name
            return (
                <div key={market}>
                    <span>{marketName}</span>
                </div>
            )
        })}
    </div>
)

export default MarketNames