import React from 'react'
import { BetslipIcon } from '../../../../assets'
import './style.scss'

interface Props {
    betslipLength: number
    mobileBetslipOpened: boolean
    mobileSportSelectorOpened: boolean
    handleMobileBetslip: () => void
}

const BetslipMobileIcon = ({ betslipLength, handleMobileBetslip, mobileBetslipOpened, mobileSportSelectorOpened }: Props) => {
    const disabledClassName: string = (!betslipLength || mobileBetslipOpened || mobileSportSelectorOpened) ? 'disabled' : ''
    
    return (
        <div
            className={`betslip-mobile-icon ${disabledClassName}`}
            onClick={handleMobileBetslip}
        >
            <img src={BetslipIcon}/>
            <span>{betslipLength}</span>
        </div>
    )
}

export default BetslipMobileIcon