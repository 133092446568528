import React from 'react'
import clsx from 'clsx'
import { ScopeId, ScopeName } from '../../../state/enums'
import { isMobile } from '../../../utils'
import './style.scss'

interface Props {
    Odds: number[];
    activeScope: ScopeId;
    scopes: ScopeId[];
    handleChangeScope: (value: ScopeId) => void;
}

function MarketScope({ Odds, activeScope, scopes, handleChangeScope }: Props) {
    return (
        <div className="MarketScope">
            {scopes.map((item, index) => {
                const scopeName = isMobile() ? ScopeId[item] : ScopeName[item]

                return (
                    <button
                        key={index}
                        className={clsx('scope', activeScope === item && 'active')}
                        onClick={() => handleChangeScope(item)}
                        disabled={Odds[index] === 0}
                    >
                        {scopeName}
                    </button>
                )
            })}
        </div>
    )
}

export default MarketScope