import React from 'react'
import FaIcon from '../FaIcon'
import Link from '../Link'
import MatchMarket from './match-market'
import { SportId, ScopeId } from '../../../state/enums'
import { getMatchTime, getMatchLink } from '../../../utils'
import { MatchMarket as MatchMarketModel, Match } from '../../../state/stores/feed/models'

interface Props {
    markets: string[]
    match: Match
    odds: MatchMarketModel
    region: string
    sportId: SportId
    scopeId: ScopeId
}

const Match = ({ scopeId, markets, match, odds, region, sportId }: Props) => {
    const { away, home, league, id, plannedstartdate, plannedstarttime } = match
    const matchTime: string = getMatchTime(plannedstartdate, plannedstarttime)
    const matchLink: string = getMatchLink(id, league, region, sportId)

    return (
        <div className="grid-layout date-match">
            <Link
                className="match-column match-name"
                to={matchLink}
            >
                <span>{matchTime}</span>
                <div className="match-teams">
                    <span>{home}</span>
                    <span> - </span>
                    <span>{away}</span>
                </div>
                <FaIcon icon="chevron-circle-right" type="solid"/>
            </Link>
            <div className="columns-equal-width">
                {markets.map(market => 
                    <MatchMarket
                        key={market}
                        awayTeam={away}
                        homeTeam={home}
                        market={market}
                        marketOdds={odds[market]}
                        sportId={sportId}
                        scopeId={scopeId}
                    />
                )}
            </div>
        </div>
    )
}

export default Match