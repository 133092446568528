import React from 'react'
import './style.scss'

interface Props {
    message: string
}

function PageBlock({ message }: Props) {
    return (
        <div className="PageBlock">
            <div className="content-wrapper">
                <p>{message}</p>
            </div>
        </div>
    )
}

export default PageBlock