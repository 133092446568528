import React from 'react'
import { Link as RouterLink, withRouter, RouteComponentProps } from 'react-router-dom'

interface ToObject {
    pathname: string
    state: any
}

interface Props extends RouteComponentProps {
    to: string | ToObject
    className?: string
    style?: React.CSSProperties
    onClick?: (e?: any) => void
}

class Link extends React.Component<Props, {}> {
    render() {
        const { children, className, onClick, style, to } = this.props
        const pathname: string | ToObject = (typeof to === 'string') ? to : { pathname: to.pathname, state: to.state }

        return (
            <RouterLink
                className={className}
                onClick={onClick}
                style={style}
                to={pathname}
            >
                {children}
            </RouterLink>
        )
    }
}

export default withRouter(Link)