import React from 'react'
import BetrixOddsRow from './odds-row'
import TeamName from './team-name'
import FaIcon from '../../FaIcon'
import clsx from 'clsx'
import { FootballPitchV2, FootballPitch, IceHockeyRink, SkiJumpingHill, CyclingRoad } from '../../../../assets'
import { MarketOdds } from '../../../../state/stores/feed/models'
import { SportId, MatrixValue, ScopeId, BetTypeId} from '../../../../state/enums'
import './style.scss'
import CSS from 'csstype';
import { roundOddsValue, isSportWinner } from '../../../../utils'

interface Props {
    awayGoals: number
    awayTeam: string
    correctScoreOdds: MarketOdds[]
    homeGoals: number
    homeTeam: string
    isResetButtonAvailable: boolean
    quickButtonsMatrix: MatrixValue[][]
    selectedItems: MatrixValue[][]
    emptyMatrix: () => void
    handleSelectedItem: (awayGoals: number, homeGoals: number) => void
    setGoals: (type: 'home' | 'away', value: string) => void
    sportId: SportId
    scopeId: ScopeId
    trapezoidGeometry: TrapezoidGeometry
    intersectionOdds: string
    IntersectionOdds: boolean
    handleSelectAnyOtherResult: (what: 'home'|'away'|'draw') => void
    anyOtherBetTypeIdHome: BetTypeId
    anyOtherBetTypeIdDraw: BetTypeId
    anyOtherBetTypeIdAway: BetTypeId
    selectedItemAnyOtherHome: MatrixValue
    selectedItemAnyOtherDraw: MatrixValue
    selectedItemAnyOtherAway: MatrixValue
    o0: Array<{name: 'Winner', outcomes: Array<{name: string, value: number, country?: string}>}>,
    o01: Array<{name: 'Winner', outcomes: Array<{name: string, value: number, country?: string}>}>,
    HomeGoals: number
    v2: boolean
    betrixToggle: boolean
}

export interface Point {
    top: number
    left: number
}

export interface TrapezoidGeometry {
    c?: Point,
    d?: Point,
    f?: Point,
    g?: Point,
    homeGoals?: number, // +1
    awayGoals?: number // +1
    B?: number[], // bottoms
    T?: number[], // tops
    H?: number[], // heights
    W?: number, // main weight
    eb?: Point[], // edge bottom
    et?: Point[], // edge top
    el?: Point[], // edge left
    er?: Point[], // edge right
    C?: Point[][]
    D?: Point[][]
    F?: Point[][]
    G?: Point[][]
    A?: Point[][]
    J?: Point[][]
    w1?: number[][]
    w2?: number[][]
    h1?: number[][]
    h2?: number[][]
    width?: number
    height?: number
}

function anyOtherResult(IntersectionOdds: boolean, intersectionOdds: string, selectedItemAnyOtherHome: MatrixValue, selectedItemAnyOtherDraw: MatrixValue, selectedItemAnyOtherAway: MatrixValue, correctScoreOdds: MarketOdds[], _: TrapezoidGeometry, anyOtherBetTypeIdHome: BetTypeId, anyOtherBetTypeIdDraw: BetTypeId, anyOtherBetTypeIdAway: BetTypeId, handleSelectAnyOtherResult: (what: 'home'|'away'|'draw') => void): JSX.Element[] {
    const aor: JSX.Element[] = []

    const StyleLeft: CSS.Properties = {
        right: (100 - _.f.left) + '%'
    }

    const StyleLeft2: CSS.Properties = {
        right: (100 - _.f.left) + '%'
    }

    const StyleRight: CSS.Properties = {
        left: _.g.left + '%',
    }

    const StyleRight2: CSS.Properties = {
        left: _.g.left + '%',
    }

    const c: number = 1.52
    const HEIGHT: number = 60
    const height: string = HEIGHT + 'px'
    const PADDINGLEFT: number = 3
    const paddingLeft: string = PADDINGLEFT + 'px'
    const PADDINGRIGHT: number = 5
    const paddingRight: string = PADDINGRIGHT + 'px'
    const buttonRatio: number = HEIGHT / _.height
    const Left: number = _.g.left + (100 - _.g.left) * buttonRatio * c
    const left: string = 'calc(' + Left + '% + ' + paddingRight + ')'
    const C = 2.6
    const left2: string = 'calc(' + (Left + C) + '% + ' + paddingRight + ')'

    const styleRight = {
        left,
        height
    }
    
    const styleRight2 = {
        left: left2,
        height
    }
    const Right: number = (100 - _.f.left) + _.f.left * buttonRatio * c
    const right2: string = 'calc(' + (Right + C) + '% + ' + paddingLeft + ')'

    const styleLeft2 = {
        right: right2,
        height
    }
    const borderLeftRight: number = Math.abs(Right - (100 -_.f.left))
    const styleLefT2 = {
        right: 'calc(' + (100 -(_.f.left - C)) + '% + ' + paddingLeft + ')',
        width: '0',
        height: '0',
        borderRight: (borderLeftRight * _.width / c / 100) + 'px solid transparent',
        borderTop: height,
    }

    const styleRighT = {
        left: 'calc(' + _.g.left + '% + ' + paddingRight + ')',
        width: '0',
        height: '0',
        borderLeft: (borderLeftRight * _.width / c / 100) + 'px solid transparent',
        borderTop: height,
    }

    const styleRighT2 = {
        left: 'calc(' + (_.g.left + C) + '% + ' + paddingRight + ')',
        width: '0',
        height: '0',
        borderLeft: (borderLeftRight * _.width / c / 100) + 'px solid transparent',
        borderTop: height,
    }

    aor.push(<div onClick={() => handleSelectAnyOtherResult( 'draw')} className={clsx('rightRectangle', selectedItemAnyOtherDraw === MatrixValue.Selected && 'selected', selectedItemAnyOtherDraw === MatrixValue.Insured && 'insured')} key = "rightRectangle" style = {styleRight}>&nbsp;</div>)
    aor.push(<div className={clsx('rightTriangle', selectedItemAnyOtherDraw === MatrixValue.Selected && 'selected', selectedItemAnyOtherDraw === MatrixValue.Insured && 'insured')}
                    onClick={() => handleSelectAnyOtherResult( 'draw')} key = "rightTriangle" style = {styleRighT}>&nbsp;</div>)

    aor.push(<div onClick={() => handleSelectAnyOtherResult( 'away')} className={clsx('rightRectangle2', selectedItemAnyOtherAway === MatrixValue.Selected && 'selected', selectedItemAnyOtherAway === MatrixValue.Insured && 'insured')} key = "rightRectangle2" style = {styleRight2}>&nbsp;</div>)
    aor.push(<div className={clsx('rightTriangle2', selectedItemAnyOtherAway === MatrixValue.Selected && 'selected', selectedItemAnyOtherAway === MatrixValue.Insured && 'insured')}
                    onClick={() => handleSelectAnyOtherResult( 'away')} key = "rightTriangle2" style = {styleRighT2}>&nbsp;</div>)

    if (selectedItemAnyOtherAway === MatrixValue.NotSelected) {
        aor.push(<div onClick={() => handleSelectAnyOtherResult( 'away')} className={clsx('rightRectangle2 darker')} key = "RightRectangle2" style = {styleRight2}>&nbsp;</div>)
        aor.push(<div className={clsx('rightTriangle2 darker')}
                        onClick={() => handleSelectAnyOtherResult( 'away')} key = "RightTriangle2" style = {styleRighT2}>&nbsp;</div>)
    }

    aor.push(<div onClick={() => handleSelectAnyOtherResult( 'home')} className={clsx('leftRectangle2', selectedItemAnyOtherHome === MatrixValue.Selected && 'selected', selectedItemAnyOtherHome === MatrixValue.Insured && 'insured')} key = "leftRectangle2" style = {styleLeft2}>&nbsp;</div>)
    aor.push(<div className={clsx('leftTriangle2', selectedItemAnyOtherHome === MatrixValue.Selected && 'selected', selectedItemAnyOtherHome === MatrixValue.Insured && 'insured')}
                    onClick={() => handleSelectAnyOtherResult( 'home')} key = "leftTriangle2" style = {styleLefT2}>&nbsp;</div>)

    if (selectedItemAnyOtherHome === MatrixValue.NotSelected) {
        aor.push(<div onClick={() => handleSelectAnyOtherResult( 'home')} className={clsx('leftRectangle2 darker')} key = "LeftRectangle2" style = {styleLeft2}>&nbsp;</div>)
        aor.push(<div className={clsx('leftTriangle2 darker')}
                        onClick={() => handleSelectAnyOtherResult( 'home')} key = "LeftTriangle2" style = {styleLefT2}>&nbsp;</div>)
    }

    let homeOddsFound = correctScoreOdds.find(o => o.bettypeid === anyOtherBetTypeIdHome)
    if (homeOddsFound === undefined) {
        homeOddsFound = correctScoreOdds.find(o => o.bettypeid === BetTypeId.AnyOtherUnknownHome)
    }
    const homeOddsValue = (homeOddsFound) ? homeOddsFound.oddsvalue : '1.00'

    let awayOddsFound = correctScoreOdds.find(o => o.bettypeid === anyOtherBetTypeIdAway)
    if (awayOddsFound === undefined) {
        awayOddsFound = correctScoreOdds.find(o => o.bettypeid === BetTypeId.AnyOtherUnknownAway)
    }
    const awayOddsValue = (awayOddsFound) ? awayOddsFound.oddsvalue : '1.00'

    let drawOddsFound = correctScoreOdds.find(o => o.bettypeid === anyOtherBetTypeIdDraw)
    if (drawOddsFound === undefined) {
        drawOddsFound = correctScoreOdds.find(o => o.bettypeid === BetTypeId.AnyOtherUnknownDraw)
    }
    const drawOddsValue = (drawOddsFound) ? drawOddsFound.oddsvalue : '1.00'

    aor.push(<div style = {StyleLeft2} key = "Left2" className = "any-other-result-left-2">
        <div onClick={() => handleSelectAnyOtherResult( 'home')}>ANY OTH.</div>
        <div onClick={() => handleSelectAnyOtherResult( 'home')}>R. HOME</div>
        <div onClick={() => handleSelectAnyOtherResult( 'home')} className = "odds">{roundOddsValue(homeOddsValue, 3)}</div>
    </div>)

    aor.push(<div style = {StyleRight2} key = "Right2" className = "any-other-result-right-2">
        <div onClick={() => handleSelectAnyOtherResult( 'away')}>ANY OTH.</div>
        <div onClick={() => handleSelectAnyOtherResult( 'away')}>R. AWAY</div>
        <div onClick={() => handleSelectAnyOtherResult( 'away')} className = "odds">{roundOddsValue(awayOddsValue, 3)}</div>
    </div>)

    aor.push(<div style = {StyleRight} key = "Right" className = "any-other-result-right">
        <div onClick={() => handleSelectAnyOtherResult( 'draw')}>ANY OTHER</div>
        <div onClick={() => handleSelectAnyOtherResult( 'draw')}>RES. DRAW</div>
        <div onClick={() => handleSelectAnyOtherResult( 'draw')} className = "odds">{roundOddsValue(drawOddsValue, 3)}</div>
    </div>)

    if (intersectionOdds) {
        aor.push(<div style = {StyleLeft} key = "Left" className = "any-other-result-left">
            <div>BETRIX™</div>
            <div>ODDS *</div>
            <div className = {IntersectionOdds ? 'animated odds' : 'odds'}>{intersectionOdds}</div>
        </div>)
    }

    return aor
}

function BetrixMatrix({ betrixToggle, v2, HomeGoals, o0, o01, IntersectionOdds, intersectionOdds, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway, /*anyOtherBetTypeId, */anyOtherBetTypeIdHome, anyOtherBetTypeIdDraw, anyOtherBetTypeIdAway, handleSelectAnyOtherResult, trapezoidGeometry, scopeId, sportId, awayGoals, awayTeam, correctScoreOdds, homeGoals, homeTeam, isResetButtonAvailable, quickButtonsMatrix, selectedItems, emptyMatrix, handleSelectedItem, setGoals }: Props) {
    const homeGoalsArray = Array.from(Array(homeGoals + 1).keys())
    const awayGoalsArray = Array.from(Array(awayGoals + 1).keys())
    return (
        <div className="main-matrix-wrapper">
            <FaIcon
                className={clsx('reset-icon', !isResetButtonAvailable && 'disabled')}
                icon="window-close"
                type="regular"
                onClick={() => isResetButtonAvailable && emptyMatrix()}
            />
            <TeamName
                className="away"
                goalsValue={awayGoals}
                name={isSportWinner(sportId) ? '' : awayTeam}
                setGoals={setGoals}
            />
            <TeamName
                className="home"
                goalsValue={homeGoals}
                name={isSportWinner(sportId) ? '' : homeTeam}
                setGoals={setGoals}
            />
            <div className="matrix-wrapper" id="matrix-wrapper">
                <img className = {sportId === SportId.Hockey ? 'hockey-rink' : ''} src={sportId === SportId.Hockey ? IceHockeyRink : sportId === SportId.Soccer ? v2 ? FootballPitchV2 : FootballPitch : sportId === SportId.Cycling ? CyclingRoad : SkiJumpingHill} />
                {trapezoidGeometry ? anyOtherResult(IntersectionOdds, intersectionOdds, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway, correctScoreOdds, trapezoidGeometry, anyOtherBetTypeIdHome, anyOtherBetTypeIdDraw, anyOtherBetTypeIdAway, handleSelectAnyOtherResult) : void 0}
                <div className="row-wrapper">
                    {homeGoalsArray.map(item => {
                        const selectedItemsNumber: number = countSelectedItems(selectedItems, selectedItemAnyOtherHome, selectedItemAnyOtherDraw, selectedItemAnyOtherAway)
                        return (
                            <BetrixOddsRow
                                betrixToggle={betrixToggle}
                                intersectionOdds={intersectionOdds}
                                o0={o0}
                                o01={o01}
                                trapezoidGeometry={trapezoidGeometry}
                                scopeId = {scopeId}
                                sportId = {sportId}
                                goals={awayGoalsArray}
                                homeGoals={item}
                                handleSelectedItem={handleSelectedItem}
                                key={item}
                                odds={correctScoreOdds}
                                quickButtonsMatrix={quickButtonsMatrix}
                                selectedHomeGoals={homeGoals}
                                selectedItems={selectedItems}
                                selectedItemsNumber={selectedItemsNumber}
                                AwayGoals={awayGoalsArray.length}
                                HomeGoals = {HomeGoals}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function countSelectedItems(selectedItems: MatrixValue[][], s1: MatrixValue, s2?: MatrixValue, s3?: MatrixValue): number {
    let selected: number = 0;
    if (selectedItems) {
        for (const item of selectedItems) {
            for (const Item of item) {
                if (Item !== MatrixValue.NotSelected) {
                    selected++
                }
            }
        }
    }
    if (s1 !== MatrixValue.NotSelected) {
        selected++
    }
    if (s2 !== MatrixValue.NotSelected) {
        selected++
    }
    if (s3 !== MatrixValue.NotSelected) {
        selected++
    }

    return selected
}

export default BetrixMatrix