import React from 'react'
import { MarketMaker } from '../../../state/stores/marketmaker/models'
import { MarketOdds } from '../../../state/stores/feed/models'
import { roundOddsValue, checkStandardItemOnBetslip, isMobile, getMMOddsItemStatus } from '../../../utils'
import { SportId, BetTypeId, BetTypeGroupId, ScopeId } from '../../../state/enums'
import { handleOddsItemClick } from './helpers'
import { store } from '../../../state'

interface Props {
    token: string
    margin: number
    marginParameter: number
    minOdds: number
    maxOdds: number
    minCSOdds: number
    maxCSOdds: number
    awayTeam: string
    className: string
    homeTeam: string
    item: MarketOdds
    marketMaker: boolean
    marketMakerData: MarketMaker
    optionsLength: number
    options: MarketOdds[]
    selectionText: string
    sportId: SportId
    setActiveBetTypeId?: (betTypeId: BetTypeId) => void
    disabled?: boolean
    psbOdds: boolean
    scopeId: ScopeId
    oddsValueToShow?: string
}

const getPSBOddsCS = (i: number, b: number): string|undefined => {
    const psbodds: Array<{b: number, i: number, o: Array<{o: number, i: number, s: number}>}> = store.get(main => main.iframeresponse.psbodds)
    if (psbodds) {
        for(const item of psbodds) {
            if (item.i === i) {
                for (const Item of item.o) {
                    if (Item.i === b) {
                        return roundOddsValue(Item.o.toString(), 2)
                    }
                }
                break
            }
        }
    }
    return undefined
}

    const getPSBOdds = (i: number, b: number, id: number): string|undefined => {
        const psbodds: Array<{b: number, i: number, o: Array<{o: number, i: number, s: number/*, p?: number*/}>/*, v?: string, skip?: boolean*/}> = store.get(main => main.iframeresponse.psbodds)
        if (psbodds) {
            for(const item of psbodds) {
                if (item.b === b && item.i === i) {
                    for (const Item of item.o) {
                        if (Item.i === id) {
                            return roundOddsValue(Item.o.toString(), 2)
                        }
                    }
                    break
                }
            }
        }
        return undefined
    }

const OddsItem = ({ token, oddsValueToShow, scopeId, minOdds, maxOdds, minCSOdds, maxCSOdds, margin, marginParameter, awayTeam, className, homeTeam, item, marketMaker, marketMakerData, optionsLength, selectionText, setActiveBetTypeId, sportId, disabled, options, psbOdds }: Props) => {
    const { bettypegroupid, bettypeid, matchid, oddsvalue, outcomeid, oldoddsvalue} = item
    const isCS: boolean = bettypegroupid === BetTypeGroupId.CorrectScore
    const typeProperty = isCS ? BetTypeGroupId[bettypegroupid] : BetTypeId[bettypeid]
    const betTypeData = marketMakerData[matchid] && marketMakerData[matchid][typeProperty]
    const oldOddsValue: boolean = disabled === true

    const oddsValue: string = oldOddsValue ? oldoddsvalue : oddsvalue
    const hasOddsValue: boolean = oddsValue !== ''
    let psboddsvalue: string
    if (bettypegroupid === BetTypeGroupId.CorrectScore) {
        psboddsvalue = getPSBOddsCS(bettypegroupid, bettypeid) || oddsvalue
    } else {
        psboddsvalue = getPSBOdds(bettypegroupid, bettypeid, outcomeid) || oddsvalue
    }
    let oddsValueText: string = (hasOddsValue) ? roundOddsValue(oddsValue, 2) : ''
    const disabledClassName: string = (!hasOddsValue || item.skip) ? 'disabled' : ''
    const mobileClassName: string = (isMobile()) ? 'mobile' : 'desktop'
    let activeClassName: string = 'empty'
    if (betTypeData && betTypeData.selections && betTypeData.selections.length > 1) {
        activeClassName = getMMOddsItemStatus(isCS, marketMakerData, matchid, bettypegroupid, bettypeid, outcomeid)
    } else {
        const isItemOnBetslip: boolean = checkStandardItemOnBetslip(matchid, bettypeid, outcomeid)
        if (isItemOnBetslip) {
            activeClassName = 'active'
        }
    }

    let allOk: boolean = true
    if (bettypegroupid === BetTypeGroupId.CorrectScore) {
        if (parseFloat(oddsValue) < minCSOdds || parseFloat(oddsvalue) < minCSOdds) {
            allOk = false
        } else if (parseFloat(oddsValue) > maxCSOdds || parseFloat(oddsvalue) > maxCSOdds) {
            allOk = false
        }
    } else {
        if (parseFloat(oddsValue) < minOdds || parseFloat(oddsvalue) < minOdds) {
            allOk = false
        } else if (parseFloat(oddsValue) > maxOdds || parseFloat(oddsvalue) > maxOdds) {
            allOk = false
        }
    }

    return allOk ?
        disabled === false ?
        <div
            className={`${className} ${mobileClassName} ${activeClassName} ${disabledClassName}`}
            onClick={() => handleOddsItemClick(token, isCS, scopeId, psbOdds, options, margin, marginParameter, minOdds, maxOdds, marketMaker, item, optionsLength, awayTeam, homeTeam, sportId, typeProperty, setActiveBetTypeId)}
        >
            <span title={item.nameLong ? item.nameLong : selectionText}>{selectionText}</span>
            <span>{psboddsvalue}</span>
        </div>
        :
        <div
            className={`${className} ${mobileClassName} ${activeClassName} ${disabledClassName}`}
            onClick={() => oddsValueText.trim().length === 0 ? void 0 : oldOddsValue ? void 0 : handleOddsItemClick(token, isCS, scopeId, psbOdds, options, margin, marginParameter, minOdds, maxOdds, marketMaker, item, optionsLength, awayTeam, homeTeam, sportId, typeProperty, setActiveBetTypeId)}
        >
            <span title={item.nameLong ? item.nameLong : selectionText}>{selectionText}</span>
            <span className = "for">{!disabled && oddsValueToShow && activeClassName !== 'empty' ? activeClassName === 'insured' ? 'Insured' : oddsValueToShow : oddsValueText}</span>
        </div>
        :
        <div className={`${className} ${mobileClassName} empty disabled`}>
            <span>-</span>
            <span>-</span>
        </div>
}

export default OddsItem