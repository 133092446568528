import React from 'react'
import { MatrixValue } from '../../../state/enums'
import { roundOddsValue } from '../../../utils'
import { MultiSystemsData } from '../../../state/stores/betrix-combo/models'
import './style.scss'
import { store } from '../../../state'

interface Props {
    data: Array<MultiSystemsData>
    forBetslip: boolean
    payout: Array<MatrixValue>
}

const MultiSystemsPayout = ({ data, forBetslip, payout }: Props) => {
    const payoutCount = getMSPayoutCount(payout)
    const payoutData = data.find(d => d.n === payoutCount)

    function handlePayoutClick(index: number) {
        if (index > 0 && index < (payout.length - 1)) {
            const newPayout = payout
    
            if (newPayout[index] === MatrixValue.NotSelected) {
                for (let i = index; i < newPayout.length; i++) {
                    newPayout[i] = MatrixValue.Selected
                }
            } else {
                for (let i = 0; i < newPayout.length; i++) {
                    if (i <= index) {
                        newPayout[i] = MatrixValue.NotSelected
                    } else {
                        newPayout[i] = MatrixValue.Selected
                    }
                }
            }
    
            updateStore(newPayout, forBetslip)
        }
    }

    return (
        <div className="MultiSystemsPayout">
            <div className="payouts-container">
                <div className="predictions-row">
                    <span># Correct</span>
                    <div className="numbers-container">
                        {payout.map((item, index) => {
                            const backgroundClassName = (item === MatrixValue.Selected) ? 'selected' : (item === MatrixValue.Insured) ? 'insured' : 'has-background'
                            const clickableClassName = (index === 0) ? 'not-clickable' : ''

                            return (
                                <button
                                    key={index}
                                    className={`${backgroundClassName} ${clickableClassName}`}
                                    onClick={() => handlePayoutClick(index)}
                                >
                                    {index}
                                </button>
                            )
                        })}
                    </div>
                </div>
                <div className="predictions-row">
                    <span>Payout</span>
                    <div className="numbers-container">
                        {payout.map((item, index) => {
                            const itemFound = payoutData && payoutData.odds.find(o => o.correct === index)
                            const coloredClassName = (itemFound) ? 'colored-selected' : ''
                            const odds = (itemFound) ? itemFound.odds.toString() : '0'

                            return (
                                <button
                                    key={index}
                                    className={`not-clickable ${coloredClassName}`}
                                >
                                    {roundOddsValue(odds, 3)}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>
            {payoutData &&
                <div className="max-stake">
                    <span>Max Stake:</span>
                    <span>{`${payoutData.maxStake} €`}</span>
                </div>
            }
        </div>
    )
}

export default MultiSystemsPayout

const getMSPayoutCount = (payout: Array<MatrixValue>): number => {
    for (let i = 0; i < payout.length; i++) {
        if (payout[i] === MatrixValue.Selected) {
            return i
        }
    }

    return 0
}

const updateStore = (payout: Array<MatrixValue>, forBetslip: boolean) => {
    if (forBetslip) {
        store.update(main => {
            main.betslip.betrixCombo.MSPayout = payout
            return main
        })
    } else {
        store.update(main => {
            main.betrixCombo.MSPayout = payout
            return main
        })
    }
}