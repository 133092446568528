import React from 'react'
import Betslip from '../Betslip'
import BetslipMobileIcon from '../Betslip/MobileIcon'
import Loader from '../Loader'
import Popup from '../Popup'
import { availableRESTRoutes } from '../../../network/rest/routes'
import { BSBetrixItem, BSStandardItem } from '../../../state/stores/betslip/models'
import { isMobile } from '../../../utils'
import { store } from '../../../state'
import './style.scss'

interface Props {
    betslipBetrixItems: BSBetrixItem[]
    betslipStandardItems: BSStandardItem[]
    isPopupOpened: boolean
    loaders: availableRESTRoutes[]
    token: string
    currency: string
}

interface State {
    mobileBetslipOpened: boolean
    mobileSportSelectorOpened: boolean
}

class Base extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            mobileBetslipOpened: false,
            mobileSportSelectorOpened: false
        }
    }
    handleMobileBetslip = () => {
        if (isMobile()) {
            const { mobileBetslipOpened } = this.state
            const bodyStyle: string = (mobileBetslipOpened) ? 'auto' : 'hidden'
            document.body.style.overflow = bodyStyle
            this.setState({
                mobileBetslipOpened: !mobileBetslipOpened
            })
        }
    }
    handleMobileSportSelector = () => {
        if (isMobile()) {
            const { mobileSportSelectorOpened } = this.state
            const bodyStyle: string = (mobileSportSelectorOpened) ? 'auto' : 'hidden'
            document.body.style.overflow = bodyStyle
            this.setState({
                mobileSportSelectorOpened: !mobileSportSelectorOpened
            })
        }
    }
    render() {
        const { betslipBetrixItems, betslipStandardItems, children, isPopupOpened, loaders, token, currency} = this.props
        const { mobileBetslipOpened, mobileSportSelectorOpened } = this.state
        const openedClassName: string = (mobileSportSelectorOpened) ? 'sport-selector-opened' : ''
        const betslipLength: number = betslipBetrixItems.length + betslipStandardItems.length
        return (
            <div className="base">
                <div style = {{position: 'fixed', left: '200px', width: '100%', top: '30px', color: 'red'}}>iframe-multi-selection</div>
                {checkLoader(loaders)}
                <div className={`base-children ${openedClassName}`}>
                    <div className="main-wrapper">
                        {children}
                    </div>
                </div>
                <Betslip
                    handleMobileBetslip={this.handleMobileBetslip}
                    mobileBetslipOpened={mobileBetslipOpened}
                    token={token}
                    currency = {currency}
                />
                <BetslipMobileIcon
                    betslipLength={betslipLength}
                    handleMobileBetslip={this.handleMobileBetslip}
                    mobileBetslipOpened={mobileBetslipOpened}
                    mobileSportSelectorOpened={mobileSportSelectorOpened}
                />
                {isPopupOpened && <Popup/>}
            </div>
        )
    }
}

export default store.subscribe(main => ({
    currency: main.app.currency,
    token: main.app.token,
    betslipBetrixItems: main.betslip.betrix,
    betslipStandardItems: main.betslip.standard,
    isPopupOpened: main.popup.isOpened,
    loaders: main.loaders.rest,
}))(Base)

const checkLoader = (loaders: availableRESTRoutes[]): JSX.Element | null => {
    if (loaders.length) {
        return (
            <Loader/>
        )
    }

    return null
}