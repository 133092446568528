import app from './stores/app'
import betrix from './stores/betrix'
import betrixCombo from './stores/betrix-combo'
import betslip from './stores/betslip'
import feed from './stores/feed'
import loaders from './stores/loaders'
import marketmaker from './stores/marketmaker'
import popup from './stores/popup'
import {settingsSoccer, settingsHockey} from './stores/settings'
import iframeresponse from './stores/iframeresponse'
import Store from './manager'
import { Main } from './models'

const main: Main = {
    app,
    betrix,
    betrixCombo,
    betslip,
    feed,
    loaders,
    marketmaker,
    popup,
    settingsSoccer,
    settingsHockey,
    iframeresponse
}

export const store = new Store(main)
export const Provide = store.provide()
export const subscribe = store.subscribe
export const update = store.update
export const get = store.get