import React from 'react'
import Base from '../../components/Base'
import dataServer from '../../../network/rest'
import { isSportWinner } from '../../../utils'
import { MarketMaker } from '../../../state/stores/marketmaker/models'
import { MarketGroup, Settings, MarketGroupItem } from '../../../state/stores/settings/models'
import { Match, Odds, Sport, HeaderMatch, League, IframeRequest, QuickLeague} from '../../../state/stores/feed/models'
import { SportId, BetTypeGroupId, BetTypeId, OutcomeId} from '../../../state/enums'
import { store } from '../../../state'
import Betrix from '../../components/Betrix'
import './style.scss'
import MainTitle from '../../components/MainTitle'
import { BetrixSuggestedMarket, BetrixSelectionOdds, Doublette } from '../../../state/stores/betrix/models'
import { Platform } from '../../../state/singles';
import { betrixDefaultLocale} from '../../../assets'
import { BSBetrixItem } from '../../../state/stores/betslip/models'
import CustomMarketBoxes from '../../components/CustomMarketBoxes'
import MarketGroupSelector from '../../components/MarketGroupSelector'
import { getFlagElement } from '../../../helpers'

import {betrixHockeyMaxGoals, betrixSoccerMaxGoals, betrixHalfSoccerMaxGoals, betrixPeriodHockeyMaxGoals} from '../../../assets'
import { BSStandardItem} from '../../../state/stores/betslip/models';
import PageBlock from '../../components/PageBlock'

interface Props {
    headerMatches: HeaderMatch[]
    leagues: League[]
    marketMaker: MarketMaker
    matchesToday: Match[]
    oddsToday: Odds[]
    sports: Sport[]
    k: Array<{ b: number, i: number, o: Array<{ o: number, i: number, p?: number }>, v?: string, skip?: boolean}>
    p: number[][]
    newCorrectScoreOdds: number[][]
    newAnyOtherProbDraw: number
    newAnyOtherProbHome: number
    newAnyOtherProbAway: number
    newAnyOtherOddDraw: number
    newAnyOtherOddHome: number
    newAnyOtherOddAway: number
    newAnyOtherOddBettypeidDraw: number
    newAnyOtherOddBettypeidHome: number
    newAnyOtherOddBettypeidAway: number
   
    k1: Array<{ b: number, i: number, o: Array<{ o: number, i: number, p?: number }>, v?: string, skip?: boolean}>
    p1: number[][]
    newCorrectScoreOdds1: number[][]
    // newAnyOtherOdd1: number
    newAnyOtherProb1Draw: number
    newAnyOtherProb1Home: number
    newAnyOtherProb1Away: number
    newAnyOtherOdd1Draw: number
    newAnyOtherOdd1Home: number
    newAnyOtherOdd1Away: number

    newAnyOtherOddBettypeid1Draw: number
    newAnyOtherOddBettypeid1Home: number
    newAnyOtherOddBettypeid1Away: number
   
    k2: Array<{ b: number, i: number, o: Array<{ o: number, i: number, p?: number }>, v?: string, skip?: boolean}>
    p2: number[][]
    newCorrectScoreOdds2: number[][]
    newAnyOtherProb2Draw: number
    newAnyOtherProb2Home: number
    newAnyOtherProb2Away: number
    newAnyOtherOdd2Draw: number
    newAnyOtherOdd2Home: number
    newAnyOtherOdd2Away: number

    newAnyOtherOddBettypeid2Draw: number
    newAnyOtherOddBettypeid2Home: number
    newAnyOtherOddBettypeid2Away: number

    k3: Array<{ b: number, i: number, o: Array<{ o: number, i: number, p?: number }>, v?: string, skip?: boolean}>
    p3: number[][]
    newCorrectScoreOdds3: number[][]
    newAnyOtherProb3Draw: number
    newAnyOtherProb3Home: number
    newAnyOtherProb3Away: number
    newAnyOtherOdd3Draw: number
    newAnyOtherOdd3Home: number
    newAnyOtherOdd3Away: number
    newAnyOtherOddBettypeid3Draw: number
    newAnyOtherOddBettypeid3Home: number
    newAnyOtherOddBettypeid3Away: number
    
    betrixSelectionOdds: BetrixSelectionOdds
    betrixSuggestedMarkets: BetrixSuggestedMarket[]
    doublettes: Doublette[]
    matches: Match[]
    platform: Platform
    quickLeagues: QuickLeague[]
    betrix: BSBetrixItem[]
    settingsSoccer: Settings
    settingsHockey: Settings
    psbodds: Array<{ b: number, i: number, o: Array<{ o: number, i: number, s: number}>}>
    betrixShowQuickButtons: boolean
    hideBetrix: boolean
}

interface State {
    calculateOddsRequestMade: boolean
    iframeRequest?: IframeRequest
    activeMarketGroup: number
    scoreBoardDetail?: boolean
    oldOdds: boolean
    psbOdds: boolean
    t: string
}

class Home extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const tokenIndexOf: number = window.location.href.indexOf('?token=')
        const token = tokenIndexOf !== -1 ? window.location.href.substring(tokenIndexOf + '?token='.length, window.location.href.length) : undefined

        const TokenIndexOf: number = window.location.href.indexOf('?Token=')
        const Token = TokenIndexOf !== -1 ? window.location.href.substring(TokenIndexOf + '?Token='.length, window.location.href.length) : undefined

        const TOKENIndexOf: number = window.location.href.indexOf('?TOKEN=')
        const TOKEN = TOKENIndexOf !== -1 ? window.location.href.substring(TOKENIndexOf + '?TOKEN='.length, window.location.href.length) : undefined
        const t: string = token ? token : Token ? Token : TOKEN ? TOKEN : undefined
        this.state = {
            scoreBoardDetail: true,
            calculateOddsRequestMade: false,
            oldOdds: TokenIndexOf !== -1 || TOKENIndexOf !== -1,
            psbOdds: TOKENIndexOf !== -1,
            t,
            activeMarketGroup: 0
        }
    }

    getBettypeidstring = (num: number): string => {
        if (num === 0) {
            return 'Zero'
        } else if (num === 1) {
            return 'One'
        } else if (num === 2) {
            return 'Two'
        } else if (num === 3) {
            return 'Three'
        } else if (num === 4) {
            return 'Four'
        } else if (num === 5) {
            return 'Five'
        } else if (num === 6) {
            return 'Six'
        } else if (num === 7) {
            return 'Seven'
        } else if (num === 8) {
            return 'Eight'
        } else if (num === 9) {
            return 'Nine'
        } else if (num === 10) {
            return 'Ten'
        } else if (num === 11) {
            return 'Eleven'
        } else if (num === 12) {
            return 'Twelve'
        } else if (num === 13) {
            return 'Thirteen'
        } else if (num === 14) {
            return 'Fourteen'
        } else if (num === 15) {
            return 'Fifteen'
        } else if (num === 16) {
            return 'Sixteen'
        } else if (num === 17) {
            return 'Seventeen'
        } else if (num === 18) {
            return 'Eighteen'
        } else if (num === 19) {
            return 'Nineteen'
        } else if (num === 20) {
            return 'Twenty'
        }

        return '?'
    }

    getBettypeid = (home: number, away: number): number => {
        return BetTypeId[this.getBettypeidstring(home) + this.getBettypeidstring(away)]
    }

    getCurrency = (currency: '$'|'€'|'£'): 'USD'|'EUR'|'GBP' => {
        if (currency === '$') {
            return 'USD'
        } else if (currency === '€') {
            return 'EUR'
        } else if (currency === '£') {
            return 'GBP'
        } else {
            return currency
        }
    }

    getCurrencySymbol = (currency: 'USD'|'EUR'|'GBP'): '$'|'€'|'£' => {
        if (currency === 'USD') {
            return '$'
        } else if (currency === 'EUR') {
            return '€'
        } else if (currency === 'GBP') {
            return '£'
        } else {
            return currency
        }
    }

    getAllMarkets = (marketGroups: MarketGroup[]): Array<MarketGroupItem> => {
        const array: Array<MarketGroupItem> = []
        for (const item of marketGroups) {
            array.push(...item.marketGroupItem)
        }
        return array
    }

    setActiveMarketGroup = (activeMarketGroup: number) => {
        this.setState({ activeMarketGroup })
    }

    getOutcomeName = (g: number, o: number, marketGroups: MarketGroup[]): string => {
        for (const item of marketGroups) {
            if (item.marketGroupItem) {
                for (const Item of item.marketGroupItem) {
                    for (const ITEM of Item.items) {
                        if (ITEM.bettypegroupid === g && ITEM.outcomes) {
                            for (const ITEM2 of ITEM.outcomes) {
                                if (ITEM2.id === o) {
                                    return ITEM2.name
                                }
                            }
                        }
                    }
                }
            }
        }
        return ''
    }

    getPosition = (short: string): string => {
        if (short === 'GK') {
            return 'Goalkeeper'
        } else if (short === 'DF') {
            return 'Defender'
        } else if (short === 'MF') {
            return 'Midfielder'
        } else if (short === 'FW') {
            return 'Forward'
        } else if (short === 'RB') {
            return 'Right back'
        } else if (short === 'CB') {
            return 'Centre-back'
        } else if (short === 'LB') {
            return 'Left back'
        } else if (short === 'RW') {
            return 'Right wing'
        } else if (short === 'AM') {
            return 'Attacking midfielder'
        } else if (short === 'DM') {
            return 'Defensive midfielder'
        } else if (short === 'LW') {
            return 'Left wing'
        } else if (short === 'CM') {
            return 'Centre midfielder'
        } else if (short === 'RF') {
            return 'Right forward'
        } else if (short === 'CF') {
            return 'Centre forward'
        } else if (short === 'LF') {
            return 'Left forward'
        } else if (short === 'SS') {
            return 'Second striker'
        } else if (short === 'LM') {
            return 'Left midfielder'
        } else if (short === 'RM') {
            return 'Right midfielder'
        } else if (short === 'SW') {
            return 'Sweeper'
        }
        return ''
    }
/*
    getLineups = (lineups: Lineups, home: string, away: string, homeLong: string, awayLong: string, homeCountry?: string, awayCountry?: string): JSX.Element[] => {
        const line: JSX.Element[] = []
        const h: JSX.Element[] = []
        const hs: JSX.Element[] = []
        const a: JSX.Element[] = []
        const as: JSX.Element[] = []
        if (lineups.h) {
            for (const item of lineups.h) {
                h.push(<div key={item.u.toString()}>
                    <span>{item.u}</span>
                    <span>{item.n}{item.a ? ' (c)' : ''}
                        {item.c ? getFlagElement(item.c) : void 0}</span>
                    <span title={this.getPosition(item.p)}>{item.p}</span>
                </div>)
            }
        }

        if (lineups.a) {
            for (const item of lineups.a) {
                a.push(<div key={item.u.toString()}>
                    <span>{item.u}</span>
                    <span>{item.n}{item.a ? ' (c)' : ''}
                        {item.c ? getFlagElement(item.c) : void 0}</span>
                    <span title={this.getPosition(item.p)}>{item.p}</span>
                </div>)
            }
        }

        if (lineups.hs) {
            for (const item of lineups.hs) {
                hs.push(<div key={item.u.toString()}>
                    <span>{item.u}</span>
                    <span>{item.n}{item.a ? ' (c)' : ''}
                        {item.c ? getFlagElement(item.c) : void 0}</span>
                    <span title={this.getPosition(item.p)}>{item.p}</span>
                </div>)
            }
        }

        if (lineups.as) {
            for (const item of lineups.as) {
                as.push(<div key={item.u.toString()}>
                    <span>{item.u}</span>
                    <span>{item.n}{item.a ? ' (c)' : ''}
                        {item.c ? getFlagElement(item.c) : void 0}</span>
                    <span title={this.getPosition(item.p)}>{item.p}</span>
                </div>)
            }
        }


        line.push(<div key="home" className="lineup lineup-home">
            <div className="lineup-team">{homeLong}&nbsp;{homeCountry ? getFlagElement(homeCountry) : void 0}</div>
            {h}<hr />{hs}
            <div className="lineup-coach lineup-coach-home"><strong>Head coach:</strong> {lineups.hc}{lineups.hcc ? getFlagElement(lineups.hcc) : void 0}</div>
        </div>)
        line.push(<div key="away" className="lineup lineup-away">
            <div className="lineup-team">{awayLong}&nbsp;{awayCountry ? getFlagElement(awayCountry) : void 0}</div>
            {a}<hr />{as}
            <div className="lineup-coach lineup-coach-away"><strong>Head coach:</strong> {lineups.ac}{lineups.acc ? getFlagElement(lineups.acc) : void 0}</div>
        </div>)
        return line
    }
*/
/*
    getReferees = (referees: Referees): JSX.Element[] => {
        const ref: JSX.Element[] = []

        if (referees.m) {
            ref.push(<div key="1" className="referee"><img className="whistle" src={whistle} /><span className="name">{referees.m.n}</span>{referees.m.c ? getFlagElement(referees.m.c) : void 0}</div>)
        }

        if (referees.a) {
            ref.push(<div key="2" className="referee"><img className="flags" src={flags} /><span className="name">{referees.a.n}</span>{referees.a.c ? getFlagElement(referees.a.c) : void 0}</div>)
        }

        if (referees.b) {
            ref.push(<div key="3" className="referee"><img className="flags" src={flags} /><span className="name">{referees.b.n}</span>{referees.b.c ? getFlagElement(referees.b.c) : void 0}</div>)
        }

        if (referees.f) {
            ref.push(<div key="4" className="referee"><span className="init">4<sup>th</sup></span><span className="name">{referees.f.n}</span>{referees.f.c ? getFlagElement(referees.f.c) : void 0}</div>)
        }

        if (referees.v) {
            ref.push(<br key="0" />)
            ref.push(<div key="5" className="referee"><img className="var" src={Var} /></div>)

            if (referees.v.m) {
                ref.push(<div key="6" className="referee"><img className="whistle" src={whistle} /><span className="name">{referees.v.m.n}</span>{referees.v.m.c ? getFlagElement(referees.v.m.c) : void 0}</div>)
            }

            if (referees.v.a) {
                ref.push(<div key="7" className="referee"><img className="flags" src={flags} /><span className="name">{referees.v.a.n}</span>{referees.v.a.c ? getFlagElement(referees.v.a.c) : void 0}</div>)
            }

            if (referees.v.b) {
                ref.push(<div key="8" className="referee"><img className="flags" src={flags} /><span className="name">{referees.v.b.n}</span>{referees.v.b.c ? getFlagElement(referees.v.b.c) : void 0}</div>)
            }

            if (referees.v.o) {
                ref.push(<div key="9" className="referee"><span className="init">OF.</span><span className="name">{referees.v.o.n}</span>{referees.v.o.c ? getFlagElement(referees.v.o.c) : void 0}</div>)
            }

        }

        return ref
    }
*/
/*
    getPlayerName = (home?: boolean, num?: number, lineups?: Lineups): string => {
        if (lineups) {
            if (home) {
                if (lineups.h) {
                    for (const item of lineups.h) {
                        if (item.u === num) {
                            return item.n
                        }
                    }
                }
                if (lineups.hs) {
                    for (const item of lineups.hs) {
                        if (item.u === num) {
                            return item.n
                        }
                    }
                }
            } else {
                if (lineups.a) {
                    for (const item of lineups.a) {
                        if (item.u === num) {
                            return item.n
                        }
                    }
                }
                if (lineups.as) {
                    for (const item of lineups.as) {
                        if (item.u === num) {
                            return item.n
                        }
                    }
                }
            }
        }
        return ''
    }
*/
/*
    getLiveEvents = (liveEvents: Event[], homeTeam: string, awayTeam: string, lineups?: Lineups): JSX.Element[] => {
        const events: JSX.Element[] = []
        for (let i: number = 0; i < liveEvents.length; i++) {
            const specialClass: string = liveEvents[i].m || liveEvents[i].f ? 'live-event-special' : ''
            events.push(
                <div key={i.toString()} className={`live-event ${specialClass}`}>
                    {liveEvents[i].u !== undefined ? <span className={liveEvents[i].h ? 'player-number player-number-home' : 'player-number player-number-away'}>{liveEvents[i].u}</span> : void 0}
                    {liveEvents[i].n ? <span className="player-name">{liveEvents[i].n}</span> : void 0}
                    {liveEvents[i].u !== undefined && liveEvents[i].h !== undefined && lineups ? <span className="player-name">{this.getPlayerName(liveEvents[i].h, liveEvents[i].u, lineups)}</span> : void 0}
                    {liveEvents[i].h !== undefined ? <span className={liveEvents[i].h ? 'player-team player-team-home' : 'player-team player-team-away'}>{liveEvents[i].h ? homeTeam : awayTeam}</span> : void 0}
                    {liveEvents[i].t ? <span className="player-time">{liveEvents[i].t}</span> : void 0}
                    {liveEvents[i].r && !liveEvents[i].y ? <img src={redCard} /> : void 0}
                    {liveEvents[i].y && !liveEvents[i].r ? <img src={yellowCard} /> : void 0}
                    {liveEvents[i].y && liveEvents[i].r ? <img src={yellowRedCard} /> : void 0}
                    {liveEvents[i].g === false && liveEvents[i].d !== false ? <img src={goal} /> : void 0}
                    {liveEvents[i].g === true ? <><img src={goal} /><span>&nbsp;OG (own goal)</span></> : void 0}
                    {liveEvents[i].s === true ? <><img src={substIn} /><span>&nbsp;player in</span></> : void 0}
                    {liveEvents[i].s === false ? <><img src={substOut} /><span>&nbsp;player out</span></> : void 0}
                    {liveEvents[i].e === true ? <span>&nbsp;penalty</span> : void 0}
                    {liveEvents[i].d === true ? <span>&nbsp;scored</span> : void 0}
                    {liveEvents[i].d === false ? <span>&nbsp;missed</span> : void 0}
                    {liveEvents[i].m ? <span>Half-time result</span> : void 0}
                    {liveEvents[i].f ? <span>Full-time result</span> : void 0}
                    {liveEvents[i].l ? <span>&nbsp;({liveEvents[i].l})</span> : void 0}
                    {liveEvents[i].a ? <span>&nbsp;({liveEvents[i].a}{liveEvents[i].b ? '; ' + liveEvents[i].b : ''})</span> : void 0}
                </div>)
        }
        return events
    }
*/
    public getOldOdds(data: Array<{b: number, i: number, o: Array<{o: number, i: number, p?: number}>, v?: string}>, bettypegroupid: number, bettypeid: number, outcomeid: number): string {
        if (data) {
            for (const item of data) {
                if (item.i === bettypegroupid && item.b === bettypeid) {
                    for (const Item of item.o) {
                        if (Item.i === outcomeid) {
                            return (Item.o).toString()
                        }
                    }
                }
            }
        }
        return '1'
    }

    public decrypt(token: string) {
        dataServer.request('get', 'get-decrypt', {t: token}, {}, (iframeRequest) => {
            this.setState({iframeRequest, activeMarketGroup: iframeRequest.sb ? 0 : 5})

            const body = {
/*
                anyOtherOddsDraw: iframeRequest.j,
                anyOtherOddsHome: iframeRequest.h0,
                anyOtherOddsAway: iframeRequest.a0,
*/
                anyOtherOddsDraw: iframeRequest.j && iframeRequest.j.length ? iframeRequest.j : [[0]],
                anyOtherOddsHome: iframeRequest.h0 && iframeRequest.h0.length ? iframeRequest.h0 : [[0]],
                anyOtherOddsAway: iframeRequest.a0 && iframeRequest.a0.length ? iframeRequest.a0 : [[0]],

                anyOtherOdds1Draw: iframeRequest.j1 && iframeRequest.j1.length ? iframeRequest.j1 : [[0]],
                anyOtherOdds1Home: iframeRequest.h1 && iframeRequest.h1.length ? iframeRequest.h1 : [[0]],
                anyOtherOdds1Away: iframeRequest.a1 && iframeRequest.a1.length ? iframeRequest.a1 : [[0]],
                
                anyOtherOdds2Draw: iframeRequest.j2 && iframeRequest.j2.length ? iframeRequest.j2 : [[0]],
                anyOtherOdds2Home: iframeRequest.h2 && iframeRequest.h2.length ? iframeRequest.h2 : [[0]],
                anyOtherOdds2Away: iframeRequest.a2 && iframeRequest.a2.length ? iframeRequest.a2 : [[0]],
                
                anyOtherOdds3Draw: iframeRequest.j3 && iframeRequest.j3.length ? iframeRequest.j3 : [[0]],
                anyOtherOdds3Home: iframeRequest.h3 && iframeRequest.h3.length ? iframeRequest.h3 : [[0]],
                anyOtherOdds3Away: iframeRequest.a3 && iframeRequest.a3.length ? iframeRequest.a3 : [[0]],

                odds: iframeRequest.k,
                correctScoreOdds: iframeRequest.o,
                anyOtherOdd: iframeRequest.jj,

                odds1: iframeRequest.k1 && iframeRequest.k1.length ? iframeRequest.k1 : [],
                correctScoreOdds1: iframeRequest.o1 && iframeRequest.o1.length ? iframeRequest.o1 : [[0]],
                anyOtherOdd1: iframeRequest.jj1,

                odds2: iframeRequest.k2 && iframeRequest.k2.length ? iframeRequest.k2 : [],
                correctScoreOdds2: iframeRequest.o2 && iframeRequest.o2.length ? iframeRequest.o2 : [[0]],
                anyOtherOdd2: iframeRequest.jj2,

                odds3: iframeRequest.k3 && iframeRequest.k3.length ? iframeRequest.k3 : [],
                correctScoreOdds3: iframeRequest.o3 && iframeRequest.o3.length ? iframeRequest.o3 : [[0]],
                anyOtherOdd3: iframeRequest.jj3,

                anyOtherOddHome: iframeRequest.jh,
                anyOtherOddHome1: iframeRequest.jh1,
                anyOtherOddHome2: iframeRequest.jh2,
                anyOtherOddHome3: iframeRequest.jh3,

                anyOtherOddAway: iframeRequest.ja,
                anyOtherOddAway1: iframeRequest.ja1,
                anyOtherOddAway2: iframeRequest.ja2,
                anyOtherOddAway3: iframeRequest.ja3,

                anyOtherOddDraw: iframeRequest.jd,
                anyOtherOddDraw1: iframeRequest.jd1,
                anyOtherOddDraw2: iframeRequest.jd2,
                anyOtherOddDraw3: iframeRequest.jd3,

                maxScoreHome: iframeRequest.xh,
                maxScoreAway: iframeRequest.xa,
                margin: iframeRequest.m,
                marginparameter: iframeRequest.mp,
                n: iframeRequest.n,
                fullThreshold: iframeRequest.s === 'Hockey' ? betrixHockeyMaxGoals : betrixSoccerMaxGoals,
                periodThreshold: iframeRequest.s === 'Hockey' ? betrixPeriodHockeyMaxGoals : betrixHalfSoccerMaxGoals,
                account_id: iframeRequest.u,
                tenant_id: iframeRequest.id,
                min_bet: iframeRequest.b,
                max_bet: iframeRequest.xb,
                currency: iframeRequest.y,
                o0: iframeRequest.o0,
                o01: iframeRequest.o01,
                stage: iframeRequest.e,
                league: iframeRequest.l,
                xh: iframeRequest.xh,
                xa: iframeRequest.xa,
                xh1: iframeRequest.xh1,
                xa1: iframeRequest.xa1,
                xh2: iframeRequest.xh2,
                xa2: iframeRequest.xa2,
                xh3: iframeRequest.xh3,
                xa3: iframeRequest.xa3,
            }
            body['t'] = token
            dataServer.request('post', 'get-calculate-probabilities', {}, body)
        })
    }

    betAgainst(invOddsValue: string, matchid: string, typeProperty: string, options) {
        const newMarketMaker = store.get(main => main.marketmaker)

        store.update(main => {
            const newSelections = []
            for (const item of options) {
                let found: boolean = false
                let insured: boolean = false
                for (const Item of newMarketMaker[matchid][typeProperty].selections) {
                    if (Item.outcomeId === item.outcomeid) {
                        found = true
                        insured = Item.insured
                        break
                    }
                }

                if (!found || insured) {
                    newSelections.push({
                        betTypeId: undefined,
                        insured,
                        oddsValue: item.oddsvalue,
                        outcomeId: item.outcomeid,
                        probability: parseFloat(item.probability),
                        value: item.value
                    })
                }
            }
            newMarketMaker[matchid][typeProperty].selections = newSelections
            const bettypeid = BetTypeId[typeProperty]
            const newBetslipCount: number = newSelections.length
            const newBetslip: BSStandardItem[] = []
            for (const ITEM of main.betslip.standard) {
                newBetslip.push(JSON.parse(JSON.stringify(ITEM)))
                if (ITEM.betTypeId !== bettypeid && ITEM.MMBetTypeId !== bettypeid) {
                } else {
                    newBetslip[newBetslip.length - 1].oddsValue = invOddsValue
                    if (newBetslipCount === 1) {
                        newBetslip[newBetslip.length - 1].betTypeId = bettypeid
                        newBetslip[newBetslip.length - 1].outcomeId = newSelections[0].outcomeId
                        newBetslip[newBetslip.length - 1].MMBetTypeId = undefined
                        newBetslip[newBetslip.length - 1].MMSelections = undefined
                    } else {
                        newBetslip[newBetslip.length - 1].betTypeId = 45
                        newBetslip[newBetslip.length - 1].outcomeId = 63
                        newBetslip[newBetslip.length - 1].MMBetTypeId = bettypeid
                        newBetslip[newBetslip.length - 1].MMSelections = newSelections
                    }
                }
            }

            main.betslip.standard = newBetslip
            main.marketmaker = newMarketMaker
            const tempOdds = newMarketMaker[matchid][typeProperty].odds
            const tempProbability = newMarketMaker[matchid][typeProperty].probability
            newMarketMaker[matchid][typeProperty].odds = newMarketMaker[matchid][typeProperty].invOdds
            newMarketMaker[matchid][typeProperty].probability = newMarketMaker[matchid][typeProperty].invProbability
            newMarketMaker[matchid][typeProperty].invOdds = tempOdds
            newMarketMaker[matchid][typeProperty].invProbability = tempProbability
            return main
        })
    }

    render() {
        const {iframeRequest} = this.state
        if (iframeRequest === undefined) {
            store.update(main => {
                main.app.token = this.state.t
                return main
            })
            this.decrypt(this.state.t)
            this.setState({iframeRequest: null})
        }

        if (!iframeRequest) {
            return <div style={{ color: '#fff' }}>Loading...</div>
        }
        if (iframeRequest['message'] === 'jwt malformed' && iframeRequest['name'] === 'JsonWebTokenError') {
            return (
                <PageBlock
                    message="Token is not provided or malformed!"
                />
            )
        }
        if (iframeRequest.s === undefined) {
            return (
                <PageBlock
                    message="Sport is not provided!"
                />
            )
        }

        const { marketGroups, marketGroupsBySport } = iframeRequest.s === 'Hockey' ? this.props.settingsHockey : this.props.settingsSoccer

        const sportId: SportId = SportId[iframeRequest.s]
        let MarketGroups: MarketGroup[] = []
        if (marketGroupsBySport) {
            for (const item of marketGroupsBySport) {
                if (item.sportid === sportId) {
                    if (item.marketGroups) {
                        MarketGroups = item.marketGroups
                    }
                    break
                }
            }
        }
        if (MarketGroups.length === 0 && !(isSportWinner(sportId))) {
            MarketGroups = marketGroups
        }
        if (!iframeRequest.a) {
            return (
                <PageBlock
                    message="Away team is not provided!"
                />
            )
        }

        if (!iframeRequest.d) {
            return (
                <PageBlock
                    message="Date is not provided!"
                />
            )
        }

        if (!iframeRequest.h) {
            return (
                <PageBlock
                    message="Home team is not provided!"
                />
            )
        }

        if (iframeRequest.iat === undefined) {
            return (
                <PageBlock
                    message="Issued at is not provided!"
                />
            )
        }

        if (!iframeRequest.l) {
            return (
                <PageBlock
                    message="League is not provided!"
                />
            )
        }

        if (iframeRequest.m === undefined) {
            return (
                <PageBlock
                    message="Margin is not provided!"
                />
            )
        }

        if (iframeRequest.mp === undefined) {
            return (
                <PageBlock
                    message="Margin parameter is not provided!"
                />
            )
        }

        if (iframeRequest.i === undefined) {
            return (
                <PageBlock
                    message="Match id is not provided!"
                />
            )
        }

        if (iframeRequest.g === undefined) {
            return (
                <PageBlock
                    message="Region is not provided!"
                />
            )
        }

        if (iframeRequest.w === undefined || iframeRequest.w === true) { // we do not support true anymore
            return (
                <PageBlock
                    message="Show market maker setting is not provided!"
                />
            )
        }

        if (!iframeRequest.t) {
            return (
                <PageBlock
                    message="Tenant is not provided!"
                />
            )
        }

        if (iframeRequest.id === undefined) {
            return (
                <PageBlock
                    message="Tenant id is not provided!"
                />
            )
        }

        if (!iframeRequest.n) {
            return (
                <PageBlock
                    message="Odds information is not provided!"
                />
            )
        }

        if (!iframeRequest.n[0]) {
            return (
                <PageBlock
                    message="Minimum odds is not provided!"
                />
            )
        }

        if (!iframeRequest.n[1]) {
            return (
                <PageBlock
                    message="Maximum odds is not provided!"
                />
            )
        }

        if (!iframeRequest.n[2]) {
            return (
                <PageBlock
                    message="Minimum correct score odds is not provided!"
                />
            )
        }

        if (!iframeRequest.n[3]) {
            return (
                <PageBlock
                    message="Maximum correct score odds is not provided!"
                />
            )
        }

        if (!iframeRequest.b) {
            return (
                <PageBlock
                    message="Minimum bet is not provided!"
                />
            )
        }

        if (!iframeRequest.xb) {
            return (
                <PageBlock
                    message="Maximum bet is not provided!"
                />
            )
        }

        if (!iframeRequest.y) {
            return (
                <PageBlock
                    message="Currency is not provided!"
                />
            )
        }

        if (!iframeRequest.u) {
            return (
                <PageBlock
                    message="User id is not provided!"
                />
            )
        }
        const { doublettes, platform, betrixSuggestedMarkets, betrixShowQuickButtons, betrixSelectionOdds, marketMaker /*, odds */, matches, k, p, /*q_,*/ newCorrectScoreOdds, newAnyOtherProbDraw, newAnyOtherProbHome, newAnyOtherProbAway, newAnyOtherOddDraw, newAnyOtherOddHome, newAnyOtherOddAway, newAnyOtherOddBettypeidDraw, newAnyOtherOddBettypeidHome, newAnyOtherOddBettypeidAway, k1, p1, /*q1_,*/ newCorrectScoreOdds1, newAnyOtherProb1Draw, newAnyOtherProb1Home, newAnyOtherProb1Away, newAnyOtherOdd1Draw, newAnyOtherOdd1Home, newAnyOtherOdd1Away, newAnyOtherOddBettypeid1Draw, newAnyOtherOddBettypeid1Home, newAnyOtherOddBettypeid1Away, k2, p2, /*q2_,*/ newCorrectScoreOdds2, newAnyOtherProb2Draw, newAnyOtherProb2Home, newAnyOtherProb2Away, newAnyOtherOdd2Draw, newAnyOtherOdd2Home, newAnyOtherOdd2Away, newAnyOtherOddBettypeid2Draw, newAnyOtherOddBettypeid2Home, newAnyOtherOddBettypeid2Away, k3, p3, /*q3_,*/ newCorrectScoreOdds3, newAnyOtherProb3Draw, newAnyOtherProb3Home, newAnyOtherProb3Away, newAnyOtherOdd3Draw, newAnyOtherOdd3Home, newAnyOtherOdd3Away, newAnyOtherOddBettypeid3Draw, newAnyOtherOddBettypeid3Home, newAnyOtherOddBettypeid3Away} = this.props
        const matchOdds: Odds = {
            matchId: iframeRequest.i,
            markets: {CorrectScore: [], ThreeWay: []},
            markets1: {CorrectScore: [], ThreeWay: []},
            markets2: {CorrectScore: [], ThreeWay: []},
            markets3: {CorrectScore: [], ThreeWay: []},
        }
        
        const currentHomeScore: number = iframeRequest.lh ? iframeRequest.lh : 0
        const currentAwayScore: number = iframeRequest.la ? iframeRequest.la : 0
        if (!k) { return <div className="nofound">&nbsp;</div> }

        if (iframeRequest.o0 && k && k[0]) {
            matchOdds.markets.Winner = []
            for (const item of k[0].o) {
                const P: {name: string, country: string} = {name: '', country: ''}
                P.name = iframeRequest.o0[0].outcomes[- item.i - 1].name
                P.country = iframeRequest.o0[0].outcomes[- item.i - 1].country
                matchOdds.markets.Winner.push({
                    bettypegroupid: k[0].i,
                    bettypeid: item.i,
                    matchid: iframeRequest.i,
                    outcomeid: OutcomeId.Winner,
                    outcomename: P.country,
                    islive: iframeRequest.lb ? iframeRequest.lb : false,
                    name: P.name,
                    oldoddsvalue: item.o.toString(),
                    oddsvalue: item.o.toString(),
                    probability: item.p ? item.p.toString() : '0',
                    value: '0',
                })
            }
            if (iframeRequest.o01 && k1[0]) {
                matchOdds.markets1.Winner = []
                for (const item of k1[0].o) {
                    const P: {name: string, country: string} = {name: '', country: ''}
                    P.name = iframeRequest.o01[0].outcomes[- item.i - 1].name
                    P.country = iframeRequest.o01[0].outcomes[- item.i - 1].country
                    matchOdds.markets1.Winner.push({
                        bettypegroupid: k1[0].i,
                        bettypeid: item.i,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.Winner,
                        outcomename: P.country,
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: P.name,
                        oldoddsvalue: item.o.toString(),
                        oddsvalue: item.o.toString(),
                        probability: item.p ? item.p.toString() : '0',
                        value: '0',
                    })
                }
            }
        } else {
            if (p && newCorrectScoreOdds && iframeRequest.o) {
                for (let i: number = 0; i < p.length; i++) {
                    for (let j: number = 0; j < p[i].length; j++) {
                        if (!newCorrectScoreOdds[i][j]) { continue }
                        matchOdds.markets.CorrectScore.push({
                            bettypegroupid: BetTypeGroupId.CorrectScore,
                            bettypeid: this.getBettypeid(i + currentHomeScore, j + currentAwayScore),
                            matchid: iframeRequest.i,
                            outcomeid: OutcomeId.CorrectScore,
                            outcomename: 'Yes',
                            islive: iframeRequest.lb ? iframeRequest.lb : false,
                            name: (i + currentHomeScore) + ':' + (j + currentAwayScore),
                            oddsvalue: iframeRequest.o[i] && iframeRequest.o[i][j] ? iframeRequest.o[i][j].toString() : '1',
                            oldoddsvalue: newCorrectScoreOdds[i] && newCorrectScoreOdds[i][j] ? newCorrectScoreOdds[i][j].toString() : '1',
                            probability: p[i] && p[i][j] ? p[i][j].toString() : '0',
                            value: '0',
                        })
                    }
                }

                if (newAnyOtherProbHome !== undefined && newAnyOtherOddHome !== undefined && newAnyOtherOddBettypeidHome !== undefined) {
                    matchOdds.markets.CorrectScore.push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeidHome,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Home win)',
                        oldoddsvalue: iframeRequest.jh ? iframeRequest.jh.toString() : '1',
                        oddsvalue: newAnyOtherOddHome.toString(),
                        probability: newAnyOtherProbHome.toString(),
                        value: '0',
                        nameLong: 'Any other result (Home win)'
                    })
                }

                if (newAnyOtherProbDraw !== undefined && newAnyOtherOddDraw !== undefined && newAnyOtherOddBettypeidDraw !== undefined) {
                    matchOdds.markets.CorrectScore.push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeidDraw,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Draw)',
                        oldoddsvalue: iframeRequest.jd ? iframeRequest.jd.toString() : '1',
                        oddsvalue: newAnyOtherOddDraw.toString(),
                        probability: newAnyOtherProbDraw.toString(),
                        value: '0',
                        nameLong: 'Any other result (Draw)'
                    })
                }

                if (newAnyOtherProbAway !== undefined && newAnyOtherOddAway !== undefined && newAnyOtherOddBettypeidAway !== undefined) {
                    matchOdds.markets.CorrectScore.push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeidAway,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Away win)',
                        oldoddsvalue: iframeRequest.ja ? iframeRequest.ja.toString() : '1',
                        oddsvalue: newAnyOtherOddAway.toString(),
                        probability: newAnyOtherProbAway.toString(),
                        value: '0',
                        nameLong: 'Any other result (Away win)'
                    })
                }
            }

            if (k) {
                for (let i: number = 0; i < k.length; i++) {
                    if (!matchOdds.markets[BetTypeGroupId[k[i].i]]) {
                        matchOdds.markets[BetTypeGroupId[k[i].i]] = []
                    }
                    for (let j: number = 0; j < k[i].o.length; j++) {
                        matchOdds.markets[BetTypeGroupId[k[i].i]].push({
                            bettypegroupid: k[i].i,
                            bettypeid: k[i].b,
                            matchid: iframeRequest.i,
                            outcomeid: k[i].o[j].i,
                            outcomename: this.getOutcomeName(k[i].i, k[i].o[j].i, MarketGroups),
                            islive: iframeRequest.lb ? iframeRequest.lb : false,
                            name: '',
                            oldoddsvalue: this.getOldOdds(iframeRequest.k, k[i].i, k[i].b, k[i].o[j].i),
                            oddsvalue: k[i] && k[i].o && k[i].o[j] && k[i].o[j].o ? k[i].o[j].o.toString() : '1',
                            probability: k[i].o[j].p ? k[i].o[j].p.toString() : '0',
                            value: k[i].v || '0',
                            skip: k[i].skip
                        })
                    }
                }
            }

            if (p1 && newCorrectScoreOdds1 && iframeRequest.o1) {
                for (let i: number = 0; i < p1.length; i++) {
                    for (let j: number = 0; j < p1[i].length; j++) {
                        if (!newCorrectScoreOdds1[i][j] || !p1[i] || !p1[i][j]) { continue }
                        matchOdds.markets1['CorrectScore'].push({
                            bettypegroupid: BetTypeGroupId.CorrectScore,
                            bettypeid: this.getBettypeid(i + currentHomeScore, j + currentAwayScore),
                            matchid: iframeRequest.i,
                            outcomeid: OutcomeId.CorrectScore,
                            outcomename: 'Yes',
                            islive: iframeRequest.lb ? iframeRequest.lb : false,
                            name: (i + currentHomeScore) + ':' + (j + currentAwayScore),
                            oddsvalue: iframeRequest.o1[i] && iframeRequest.o1[i][j] ? iframeRequest.o1[i][j].toString() : '1',
                            oldoddsvalue: newCorrectScoreOdds1[i] && newCorrectScoreOdds1[i][j] ? newCorrectScoreOdds1[i][j].toString() : '1',
                            probability: p1[i] && p1[i][j] ? p1[i][j].toString() : '0',
                            value: '0',
                        })
                    }
                }

                if (newAnyOtherProb1Home && newAnyOtherOdd1Home !== undefined && newAnyOtherOddBettypeid1Home !== undefined) {
                    matchOdds.markets1['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid1Home,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Home win)',
                        oldoddsvalue: iframeRequest.jh1 ? iframeRequest.jh1.toString() : '1',
                        oddsvalue: newAnyOtherOdd1Home.toString(),
                        probability: newAnyOtherProb1Home.toString(),
                        value: '0',
                        nameLong: 'Any other result (Home win)'
                    })
                }

                if (newAnyOtherProb1Draw && newAnyOtherOdd1Draw !== undefined && newAnyOtherOddBettypeid1Draw !== undefined) {
                    matchOdds.markets1['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid1Draw,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Draw)',
                        oldoddsvalue: iframeRequest.jd1 ? iframeRequest.jd1.toString() : '1',
                        oddsvalue: newAnyOtherOdd1Draw.toString(),
                        probability: newAnyOtherProb1Draw.toString(),
                        value: '0',
                        nameLong: 'Any other result (Draw)'
                    })
                }

                if (newAnyOtherProb1Away && newAnyOtherOdd1Away !== undefined && newAnyOtherOddBettypeid1Away !== undefined) {
                    matchOdds.markets1['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid1Away,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Away win)',
                        oldoddsvalue: iframeRequest.ja1 ? iframeRequest.ja1.toString() : '1',
                        oddsvalue: newAnyOtherOdd1Away.toString(),
                        probability: newAnyOtherProb1Away.toString(),
                        value: '0',
                        nameLong: 'Any other result (Away win)'
                    })
                }

                if (newAnyOtherProb1Home && newAnyOtherOdd1Home !== undefined && newAnyOtherOddBettypeid1Home !== undefined &&
                    newAnyOtherProb1Draw && newAnyOtherOdd1Draw !== undefined && newAnyOtherOddBettypeid1Draw !== undefined &&
                    newAnyOtherProb1Away && newAnyOtherOdd1Away !== undefined && newAnyOtherOddBettypeid1Away !== undefined &&
                    (iframeRequest.s === 'Soccer' || iframeRequest.s === 'Hockey')) {
                    matchOdds.markets1['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: 0,
                        matchid: iframeRequest.i,
                        outcomeid: 0,
                        outcomename: '',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: '',
                        oldoddsvalue: iframeRequest.jj1 ? iframeRequest.jj1.toString() : '1',
                        oddsvalue: '',
                        probability: '',
                        value: '0',
                        nameLong: ''
                    })
                }
            }

            if (k1) {
                for (let i: number = 0; i < k1.length; i++) {
                    if (!matchOdds.markets1[BetTypeGroupId[k1[i].i]]) {
                        matchOdds.markets1[BetTypeGroupId[k1[i].i]] = []
                    }
                    for (let j: number = 0; j < k1[i].o.length; j++) {
                        matchOdds.markets1[BetTypeGroupId[k1[i].i]].push({
                            bettypegroupid: k1[i].i,
                            bettypeid: k1[i].b,
                            matchid: iframeRequest.i,
                            outcomeid: k1[i].o[j].i,
                            outcomename: this.getOutcomeName(k1[i].i, k1[i].o[j].i, MarketGroups),
                            islive: iframeRequest.lb ? iframeRequest.lb : false,
                            name: '',
                            oldoddsvalue: this.getOldOdds(iframeRequest.k1, k1[i].i, k1[i].b, k1[i].o[j].i),
                            oddsvalue: k1[i] && k1[i].o && k1[i].o[j] && k1[i].o[j].o ? k1[i].o[j].o.toString() : '1',
                            probability: k1[i].o[j].p ? k1[i].o[j].p.toString() : '0',
                            value: k1[i].v || '0',
                            skip: k1[i].skip
                        })
                    }
                }
            }

            if (p2 && newCorrectScoreOdds2 && iframeRequest.o2) {
                for (let i: number = 0; i < p2.length; i++) {
                    for (let j: number = 0; j < p2[i].length; j++) {
                        if (!newCorrectScoreOdds2[i][j] || !p2[i] || !p2[i][j]) { continue }
                        matchOdds.markets2['CorrectScore'].push({
                            bettypegroupid: BetTypeGroupId.CorrectScore,
                            bettypeid: this.getBettypeid(i + currentHomeScore, j + currentAwayScore),
                            matchid: iframeRequest.i,
                            outcomeid: OutcomeId.CorrectScore,
                            outcomename: 'Yes',
                            islive: iframeRequest.lb ? iframeRequest.lb : false,
                            name: (i + currentHomeScore) + ':' + (j + currentAwayScore),
                            oddsvalue: iframeRequest.o2[i] && iframeRequest.o2[i][j] ? iframeRequest.o2[i][j].toString() : '1',
                            oldoddsvalue: newCorrectScoreOdds2[i] && newCorrectScoreOdds2[i][j] ? newCorrectScoreOdds2[i][j].toString() : '1',
                            probability: p2[i] && p2[i][j] ? p2[i][j].toString() : '0',
                            value: '0',
                        })
                    }
                }

                if (newAnyOtherProb2Home && newAnyOtherOdd2Home !== undefined && newAnyOtherOddBettypeid2Home !== undefined) {
                    matchOdds.markets2['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid2Home,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Home win)',
                        oldoddsvalue: iframeRequest.jh2 ? iframeRequest.jh2.toString() : '1',
                        oddsvalue: newAnyOtherOdd2Home.toString(),
                        probability: newAnyOtherProb2Home.toString(),
                        value: '0',
                        nameLong: 'Any other result (Home win)'
                    })
                }

                if (newAnyOtherProb2Draw && newAnyOtherOdd2Draw !== undefined && newAnyOtherOddBettypeid2Draw !== undefined) {
                    matchOdds.markets2['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid2Draw,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Draw)',
                        oldoddsvalue: iframeRequest.jd2 ? iframeRequest.jd2.toString() : '1',
                        oddsvalue: newAnyOtherOdd2Draw.toString(),
                        probability: newAnyOtherProb2Draw.toString(),
                        value: '0',
                        nameLong: 'Any other result (Draw)'
                    })
                }

                if (newAnyOtherProb2Away && newAnyOtherOdd2Away !== undefined && newAnyOtherOddBettypeid2Away !== undefined) {
                    matchOdds.markets2['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid2Away,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Away win)',
                        oldoddsvalue: iframeRequest.ja2 ? iframeRequest.ja2.toString() : '1',
                        oddsvalue: newAnyOtherOdd2Away.toString(),
                        probability: newAnyOtherProb2Away.toString(),
                        value: '0',
                        nameLong: 'Any other result (Away win)'
                    })
                }

                if (newAnyOtherProb2Home && newAnyOtherOdd2Home !== undefined && newAnyOtherOddBettypeid2Home !== undefined &&
                    newAnyOtherProb2Draw && newAnyOtherOdd2Draw !== undefined && newAnyOtherOddBettypeid2Draw !== undefined &&
                    newAnyOtherProb2Away && newAnyOtherOdd2Draw !== undefined && newAnyOtherOddBettypeid2Away !== undefined &&
                    (iframeRequest.s === 'Soccer' || iframeRequest.s === 'Hockey')) {
                    matchOdds.markets2['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: 0,
                        matchid: iframeRequest.i,
                        outcomeid: 0,
                        outcomename: '',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: '',
                        oldoddsvalue: iframeRequest.jj1 ? iframeRequest.jj1.toString() : '1',
                        oddsvalue: '',
                        probability: '',
                        value: '0',
                        nameLong: ''
                    })
                }
            }

            if (k2) {
                for (let i: number = 0; i < k2.length; i++) {
                    if (!matchOdds.markets2[BetTypeGroupId[k2[i].i]]) {
                        matchOdds.markets2[BetTypeGroupId[k2[i].i]] = []
                    }
                    for (let j: number = 0; j < k2[i].o.length; j++) {
                        matchOdds.markets2[BetTypeGroupId[k2[i].i]].push({
                            bettypegroupid: k2[i].i,
                            bettypeid: k2[i].b,
                            matchid: iframeRequest.i,
                            outcomeid: k2[i].o[j].i,
                            outcomename: this.getOutcomeName(k2[i].i, k2[i].o[j].i, MarketGroups),
                            islive: iframeRequest.lb ? iframeRequest.lb : false,
                            name: '',
                            oldoddsvalue: this.getOldOdds(iframeRequest.k2, k2[i].i, k2[i].b, k2[i].o[j].i),
                            oddsvalue: k2[i] && k2[i].o && k2[i].o[j] && k2[i].o[j].o ? k2[i].o[j].o.toString() : '1',
                            probability: k2[i].o[j].p ? k2[i].o[j].p.toString() : '0',
                            value: k2[i].v || '0',
                            skip: k2[i].skip
                        })
                    }
                }
            }

            if (p3 && newCorrectScoreOdds3 && iframeRequest.o1) {
                for (let i: number = 0; i < p3.length; i++) {
                    for (let j: number = 0; j < p3[i].length; j++) {
                        if (!newCorrectScoreOdds3[i][j] || !p3[i] || !p3[i][j]) { continue }
                        matchOdds.markets3['CorrectScore'].push({
                            bettypegroupid: BetTypeGroupId.CorrectScore,
                            bettypeid: this.getBettypeid(i + currentHomeScore, j + currentAwayScore),
                            matchid: iframeRequest.i,
                            outcomeid: OutcomeId.CorrectScore,
                            outcomename: 'Yes',
                            islive: iframeRequest.lb ? iframeRequest.lb : false,
                            name: (i + currentHomeScore) + ':' + (j + currentAwayScore),
                            oddsvalue: iframeRequest.o3[i] && iframeRequest.o3[i][j] ? iframeRequest.o3[i][j].toString() : '1',
                            oldoddsvalue: newCorrectScoreOdds3[i] && newCorrectScoreOdds3[i][j] ? newCorrectScoreOdds3[i][j].toString() : '1',
                            probability: p3[i] && p3[i][j] ? p3[i][j].toString() : '0',
                            value: '0',
                        })
                    }
                }

                if (newAnyOtherProb3Home && newAnyOtherOdd3Home !== undefined && newAnyOtherOddBettypeid3Home !== undefined) {
                    matchOdds.markets3['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid3Home,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Home win)',
                        oldoddsvalue: iframeRequest.jh3 ? iframeRequest.jh3.toString() : '1',
                        oddsvalue: newAnyOtherOdd3Home.toString(),
                        probability: newAnyOtherProb3Home.toString(),
                        value: '0',
                        nameLong: 'Any other result (Home win)'
                    })
                }

                if (newAnyOtherProb3Draw && newAnyOtherOdd3Draw !== undefined && newAnyOtherOddBettypeid3Draw !== undefined) {
                    matchOdds.markets3['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid3Draw,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Draw)',
                        oldoddsvalue: iframeRequest.jd3 ? iframeRequest.jd3.toString() : '1',
                        oddsvalue: newAnyOtherOdd3Draw.toString(),
                        probability: newAnyOtherProb3Draw.toString(),
                        value: '0',
                        nameLong: 'Any other result (Draw)'
                    })
                }

                if (newAnyOtherProb3Away && newAnyOtherOdd3Away !== undefined && newAnyOtherOddBettypeid3Away !== undefined) {
                    matchOdds.markets3['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: newAnyOtherOddBettypeid3Away,
                        matchid: iframeRequest.i,
                        outcomeid: OutcomeId.CorrectScore,
                        outcomename: 'Yes',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: 'Any other result (Away win)',
                        oldoddsvalue: iframeRequest.ja3 ? iframeRequest.ja3.toString() : '1',
                        oddsvalue: newAnyOtherOdd3Away.toString(),
                        probability: newAnyOtherProb3Away.toString(),
                        value: '0',
                        nameLong: 'Any other result (Away win)'
                    })
                }

                if (newAnyOtherProb3Home && newAnyOtherOdd3Home !== undefined && newAnyOtherOddBettypeid3Home !== undefined &&
                    newAnyOtherProb3Draw && newAnyOtherOdd3Draw !== undefined && newAnyOtherOddBettypeid3Draw !== undefined &&
                    newAnyOtherProb3Away && newAnyOtherOdd3Draw !== undefined && newAnyOtherOddBettypeid3Away !== undefined &&
                    (iframeRequest.s === 'Soccer' || iframeRequest.s === 'Hockey')) {
                    matchOdds.markets3['CorrectScore'].push({
                        bettypegroupid: BetTypeGroupId.CorrectScore,
                        bettypeid: 0,
                        matchid: iframeRequest.i,
                        outcomeid: 0,
                        outcomename: '',
                        islive: iframeRequest.lb ? iframeRequest.lb : false,
                        name: '',
                        oldoddsvalue: iframeRequest.jj1 ? iframeRequest.jj1.toString() : '1',
                        oddsvalue: '',
                        probability: '',
                        value: '0',
                        nameLong: ''
                    })
                }  
            }

            if (k3) {
                for (let i: number = 0; i < k3.length; i++) {
                    if (!matchOdds.markets3[BetTypeGroupId[k3[i].i]]) {
                        matchOdds.markets3[BetTypeGroupId[k3[i].i]] = []
                    }
                    for (let j: number = 0; j < k3[i].o.length; j++) {
                        matchOdds.markets3[BetTypeGroupId[k3[i].i]].push({
                            bettypegroupid: k3[i].i,
                            bettypeid: k3[i].b,
                            matchid: iframeRequest.i,
                            outcomeid: k3[i].o[j].i,
                            outcomename: this.getOutcomeName(k3[i].i, k3[i].o[j].i, MarketGroups),
                            islive: iframeRequest.lb ? iframeRequest.lb : false,
                            name: '',
                            oldoddsvalue: this.getOldOdds(iframeRequest.k3, k3[i].i, k3[i].b, k3[i].o[j].i),
                            oddsvalue: k3[i] && k3[i].o && k3[i].o[j] && k3[i].o[j].o ? k3[i].o[j].o.toString() : '1',
                            probability: k3[i].o[j].p ? k3[i].o[j].p.toString() : '0',
                            value: k3[i].v || '0',
                            skip: k3[i].skip
                        })
                    }
                }
            }
        }

        return (
            <Base>
                <div className="sport-match">
                    <MainTitle
                        tenant={iframeRequest.t}
                        locale={iframeRequest.c ? iframeRequest.c : betrixDefaultLocale}
                        date={iframeRequest.d}
                        venue={iframeRequest.v}
                        city={iframeRequest.f}
                        allLeagues={[]}
                        allMatches={matches}
                        league={iframeRequest.l}
                        stage={iframeRequest.e}
                        timezone={iframeRequest.z}
                        match={`${iframeRequest.h} vs ${iframeRequest.a}`}
                        matchId={iframeRequest.i}
                        region={iframeRequest.g}
                        sportId={sportId}
                    />
                    {getFlagElement(iframeRequest.g, 'flag', true)}
                    {isSportWinner(sportId) || iframeRequest.sb ?
                    <Betrix
                        disableInsurances = {this.state.iframeRequest.di}
                        hideBetAgainst = {this.state.iframeRequest.hg}
                        visible = {true}
                        xh = {this.state.iframeRequest.xh}
                        xa = {this.state.iframeRequest.xa}
                        xh1 = {this.state.iframeRequest.xh1}
                        xa1 = {this.state.iframeRequest.xa1}
                        xh2 = {this.state.iframeRequest.xh2}
                        xa2 = {this.state.iframeRequest.xa2}
                        xh3 = {this.state.iframeRequest.xh3}
                        xa3 = {this.state.iframeRequest.xa3}

                        token = {this.state.t}
                        o0 ={iframeRequest.o0}
                        o01 ={iframeRequest.o01}
                        awayTeam={iframeRequest.a}
                        doublettes={doublettes}
                        homeTeam={iframeRequest.h}
                        marketMaker={marketMaker}
                        matchId={iframeRequest.i}
                        odds={matchOdds.markets}
                        odds1={matchOdds.markets1}
                        odds2={matchOdds.markets2}
                        odds3={matchOdds.markets3}
                        platform={platform}
                        selectionOdds={betrixSelectionOdds}
                        showMarketMaker={false}
                        showQuickButtons={betrixShowQuickButtons}
                        sportId={sportId}
                        suggestedMarkets={betrixSuggestedMarkets}
                        margin = {iframeRequest.m}
                        marginParameter = {iframeRequest.mp}
                        minOdds = {iframeRequest.n && iframeRequest.n[0] ? iframeRequest.n[0] : 0}
                        maxOdds = {iframeRequest.n && iframeRequest.n[1] ? iframeRequest.n[1] : 0}
                        minCSOdds = {iframeRequest.n && iframeRequest.n[2] ? iframeRequest.n[2] : 0}
                        maxCSOdds = {iframeRequest.n && iframeRequest.n[3] ? iframeRequest.n[3] : 0}
                        v2 = {iframeRequest.b2}
                    />
                    : void 0}

                    {!iframeRequest.sb && MarketGroups && MarketGroups.length && matchOdds ?
                        <MarketGroupSelector
                            platform = {platform}
                            hideBetrix = {iframeRequest.hb}
                            hideAllMarkets = {iframeRequest.hm}
                            awayTeam={iframeRequest.a}
                            activeGroup={this.state.activeMarketGroup}
                            groups={MarketGroups}
                            homeTeam={iframeRequest.h}
                            onItemClick={this.setActiveMarketGroup}
                        /> : void 0}

                            <div>
                                <Betrix
                                    disableInsurances = {this.state.iframeRequest.di}
                                    hideBetAgainst = {this.state.iframeRequest.hg}
                                    visible = {this.state.activeMarketGroup === MarketGroups.length + 1}
                                    xh = {this.state.iframeRequest.xh}
                                    xa = {this.state.iframeRequest.xa}
                                    xh1 = {this.state.iframeRequest.xh1}
                                    xa1 = {this.state.iframeRequest.xa1}
                                    xh2 = {this.state.iframeRequest.xh2}
                                    xa2 = {this.state.iframeRequest.xa2}
                                    xh3 = {this.state.iframeRequest.xh3}
                                    xa3 = {this.state.iframeRequest.xa3}
                                    token = {this.state.t}
                                    o0 ={iframeRequest.o0}
                                    o01 ={iframeRequest.o01}
                                    awayTeam={iframeRequest.a}
                                    doublettes={doublettes}
                                    homeTeam={iframeRequest.h}
                                    marketMaker={marketMaker}
                                    matchId={iframeRequest.i}
                                    odds={matchOdds.markets}
                                    odds1={matchOdds.markets1}
                                    odds2={matchOdds.markets2}
                                    odds3={matchOdds.markets3}
                                    platform={platform}
                                    selectionOdds={betrixSelectionOdds}
                                    showMarketMaker={false}
                                    showQuickButtons={betrixShowQuickButtons}
                                    sportId={sportId}
                                    suggestedMarkets={betrixSuggestedMarkets}
                                    margin = {iframeRequest.m}
                                    marginParameter = {iframeRequest.mp}
                                    minOdds = {iframeRequest.n && iframeRequest.n[0] ? iframeRequest.n[0] : 0}
                                    maxOdds = {iframeRequest.n && iframeRequest.n[1] ? iframeRequest.n[1] : 0}
                                    minCSOdds = {iframeRequest.n && iframeRequest.n[2] ? iframeRequest.n[2] : 0}
                                    maxCSOdds = {iframeRequest.n && iframeRequest.n[3] ? iframeRequest.n[3] : 0}
                                    v2 = {iframeRequest.b2}
                                />
                            </div>
                    {MarketGroups && MarketGroups.length && matchOdds ?

                            this.state.activeMarketGroup === MarketGroups.length ?
                           
                            <CustomMarketBoxes
                                token = {this.state.t}
                                betAgainst = {this.betAgainst}
                                margin={iframeRequest.m}
                                marginParameter={iframeRequest.mp}
                                minOdds = {iframeRequest.n && iframeRequest.n[0] ? iframeRequest.n[0] : 0}
                                maxOdds = {iframeRequest.n && iframeRequest.n[1] ? iframeRequest.n[1] : 0}
                                minCSOdds = {iframeRequest.n && iframeRequest.n[2] ? iframeRequest.n[2] : 0}
                                maxCSOdds = {iframeRequest.n && iframeRequest.n[3] ? iframeRequest.n[3] : 0}
                                awayTeam={iframeRequest.a}
                                homeTeam={iframeRequest.h}
                                marketGroupItems={this.getAllMarkets(MarketGroups)}
                                marketMaker={marketMaker}
                                odds={matchOdds}
                                sportId={sportId}
                                oldOdds = {this.state.oldOdds}
                                psbOdds = {this.state.psbOdds}
                                overUnderOdds = {iframeRequest.ou}
                                hideCorrectScore = {iframeRequest.hs}
                                // disableInsurances = {iframeRequest.di}
                                hideBetAgainst = {iframeRequest.hg}
                            />
                            :
                            !iframeRequest.sb && this.state.activeMarketGroup !== MarketGroups.length + 1 ?
                            <CustomMarketBoxes
                                token = {this.state.t}
                                betAgainst = {this.betAgainst}
                                margin={iframeRequest.m}
                                marginParameter={iframeRequest.mp}
                                minOdds = {iframeRequest.n && iframeRequest.n[0] ? iframeRequest.n[0] : 0}
                                maxOdds = {iframeRequest.n && iframeRequest.n[1] ? iframeRequest.n[1] : 0}
                                minCSOdds = {iframeRequest.n && iframeRequest.n[2] ? iframeRequest.n[2] : 0}
                                maxCSOdds = {iframeRequest.n && iframeRequest.n[3] ? iframeRequest.n[3] : 0}
                                awayTeam={iframeRequest.a}
                                homeTeam={iframeRequest.h}
                                marketGroupItems={MarketGroups[this.state.activeMarketGroup].marketGroupItem}
                                marketMaker={marketMaker}
                                odds={matchOdds}
                                sportId={sportId}
                                oldOdds = {this.state.oldOdds}
                                psbOdds = {this.state.psbOdds}
                                overUnderOdds = {iframeRequest.ou}
                                hideCorrectScore = {iframeRequest.hs}
                                // disableInsurances = {iframeRequest.di}
                                hideBetAgainst = {iframeRequest.hg}
                            /> : void 0
                        : void 0}
                </div>
            </Base>
        )
    }
}

export default store.subscribe(main => ({
    betrix: main.betslip.betrix,
    betrixSelectionOdds: main.betrix.selectionOdds,
    betrixSuggestedMarkets: main.betrix.suggestedMarkets,
    betrixShowQuickButtons: main.betrix.showQuickButtons,
    doublettes: main.betrix.doublettes,
    leagues: main.feed.leagues,
    marketMaker: main.marketmaker,
    matches: main.feed.matches,
    platform: main.app.platform,
    psbodds: main.iframeresponse.psbodds,

    k: main.iframeresponse.odds,
    k1: main.iframeresponse.odds1,
    k2: main.iframeresponse.odds2,
    k3: main.iframeresponse.odds3,

    p: main.iframeresponse.correctScoreProbs,
    p1: main.iframeresponse.correctScoreProbs1,
    p2: main.iframeresponse.correctScoreProbs2,
    p3: main.iframeresponse.correctScoreProbs3,
/*
    q_: main.iframeresponse.anyOtherProb,
    q1_: main.iframeresponse.anyOtherProb1,
    q2_: main.iframeresponse.anyOtherProb2,
    q3_: main.iframeresponse.anyOtherProb3,
*/
    newAnyOtherProbDraw: main.iframeresponse.anyOtherProbDraw,
    newAnyOtherProbHome: main.iframeresponse.anyOtherProbHome,
    newAnyOtherProbAway: main.iframeresponse.anyOtherProbAway,

    newAnyOtherProb1Draw: main.iframeresponse.anyOtherProb1Draw,
    newAnyOtherProb1Home: main.iframeresponse.anyOtherProb1Home,
    newAnyOtherProb1Away: main.iframeresponse.anyOtherProb1Away,

    newAnyOtherProb2Draw: main.iframeresponse.anyOtherProb2Draw,
    newAnyOtherProb2Home: main.iframeresponse.anyOtherProb2Home,
    newAnyOtherProb2Away: main.iframeresponse.anyOtherProb2Away,

    newAnyOtherProb3Draw: main.iframeresponse.anyOtherProb3Draw,
    newAnyOtherProb3Home: main.iframeresponse.anyOtherProb3Home,
    newAnyOtherProb3Away: main.iframeresponse.anyOtherProb3Away,

    newCorrectScoreOdds: main.iframeresponse.newCorrectScoreOdds,
    newCorrectScoreOdds1: main.iframeresponse.newCorrectScoreOdds1,
    newCorrectScoreOdds2: main.iframeresponse.newCorrectScoreOdds2,
    newCorrectScoreOdds3: main.iframeresponse.newCorrectScoreOdds3,
    
    newAnyOtherOddDraw: main.iframeresponse.newAnyOtherOddDraw,
    newAnyOtherOdd1Draw: main.iframeresponse.newAnyOtherOdd1Draw,
    newAnyOtherOdd2Draw: main.iframeresponse.newAnyOtherOdd2Draw,
    newAnyOtherOdd3Draw: main.iframeresponse.newAnyOtherOdd3Draw,

    newAnyOtherOddHome: main.iframeresponse.newAnyOtherOddHome,
    newAnyOtherOdd1Home: main.iframeresponse.newAnyOtherOdd1Home,
    newAnyOtherOdd2Home: main.iframeresponse.newAnyOtherOdd2Home,
    newAnyOtherOdd3Home: main.iframeresponse.newAnyOtherOdd3Home,

    newAnyOtherOddAway: main.iframeresponse.newAnyOtherOddAway,
    newAnyOtherOdd1Away: main.iframeresponse.newAnyOtherOdd1Away,
    newAnyOtherOdd2Away: main.iframeresponse.newAnyOtherOdd2Away,
    newAnyOtherOdd3Away: main.iframeresponse.newAnyOtherOdd3Away,

    newAnyOtherOddBettypeidDraw: main.iframeresponse.newAnyOtherOddBettypeidDraw,
    newAnyOtherOddBettypeid1Draw: main.iframeresponse.newAnyOtherOddBettypeid1Draw,
    newAnyOtherOddBettypeid2Draw: main.iframeresponse.newAnyOtherOddBettypeid2Draw,
    newAnyOtherOddBettypeid3Draw: main.iframeresponse.newAnyOtherOddBettypeid3Draw,

    newAnyOtherOddBettypeidHome: main.iframeresponse.newAnyOtherOddBettypeidHome,
    newAnyOtherOddBettypeid1Home: main.iframeresponse.newAnyOtherOddBettypeid1Home,
    newAnyOtherOddBettypeid2Home: main.iframeresponse.newAnyOtherOddBettypeid2Home,
    newAnyOtherOddBettypeid3Home: main.iframeresponse.newAnyOtherOddBettypeid3Home,

    newAnyOtherOddBettypeidAway: main.iframeresponse.newAnyOtherOddBettypeidAway,
    newAnyOtherOddBettypeid1Away: main.iframeresponse.newAnyOtherOddBettypeid1Away,
    newAnyOtherOddBettypeid2Away: main.iframeresponse.newAnyOtherOddBettypeid2Away,
    newAnyOtherOddBettypeid3Away: main.iframeresponse.newAnyOtherOddBettypeid3Away,

    settingsHockey: main.settingsHockey,
    settingsSoccer : main.settingsSoccer
}))(Home)